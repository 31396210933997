import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-telehealth-sidetab-section',
  templateUrl: './telehealth-sidetab-section.component.html',
  styleUrls: ['./telehealth-sidetab-section.component.scss']
})
export class TelehealthSidetabSectionComponent implements OnInit {
  calls = [
    { name: 'Howard Douglas', date: '10:09 AM PST', elapsed: '23m' },
    { name: 'Henry Washington', date: '10:09 AM PST', elapsed: '14m' },
    { name: 'Ken McKingly', date: '10:09 AM PST', elapsed: '15m' }
  ]

  voiceMails = [
    { name: 'Howard Douglas', date: '10:09 AM PST', elapsed: '23m' },
    { name: 'Henry Washington', date: '10:09 AM PST', elapsed: '14m' },
    { name: 'Ken McKingly', date: '10:09 AM PST', elapsed: '15m' }
  ]

  messages = [
    { name: 'Howard Douglas', date: '10:09 AM PST', elapsed: '23m' },
    { name: 'Henry Washington', date: '10:09 AM PST', elapsed: '14m' },
    { name: 'Ken McKingly', date: '10:09 AM PST', elapsed: '15m' }
  ]

  constructor() {}

  ngOnInit(): void {}
}
