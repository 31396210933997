<div class="quicklook-section"  fxLayout="row wrap" fxLayoutGap="20px grid" fxFlex style="flex-wrap:wrap;">
  <div class="quicklook-block" fxFlex fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="quicklook-icon assessments"><span class="k-icon k-i-txt"></span></div>
    <span class="quicklook-title">ASSESSMENTS</span>
    <span class="quicklook-title-short">ASSESSMENTS</span>
    <span class="quicklook-details">10</span>
  </div>
  <div class="quicklook-block" fxFlex fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="quicklook-icon harm"><span class="k-icon k-i-notification"></span></div>
    <span class="quicklook-title">HARM TRIGGERS</span>
    <span class="quicklook-title-short">HARM</span>
    <span class="quicklook-details">2</span>
    <div class="alert">!</div>
  </div>
  <div class="quicklook-block" fxFlex fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="quicklook-icon taps"><span class="k-icon k-i-calendar"></span></div>
    <span class="quicklook-title">PATIENT TAPS DUE</span>
    <span class="quicklook-title-short">TAPS</span>
    <span class="quicklook-details">4</span>
    <div class="alert">!</div>
  </div>
  <div class="quicklook-block" fxFlex fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="quicklook-icon pretest" (click)="goToApprovals()"><span class="k-icon k-i-edit"></span></div>
    <span class="quicklook-title">PENDING PRE-TESTS</span>
    <span class="quicklook-title-short">PRE-TESTS</span>
    <span class="quicklook-details">2</span>
    <div class="alert">!</div>
  </div>
</div>

