<ng-container *ngIf="approvalsService.pendingAssessments$ | async as pendingAssessments">
  <div class="pretest-approvals-button" #anchor (click)="onToggle()" [ngClass]="{'has-pending': pendingAssessments.length > 0}">
    Pending Pretests
  </div>
  <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show && pendingAssessments.length">
    <div fxLayout="column" fxLayoutGap="10px" class="assessments-container">
      <div fxFlex="100px" fxLayoutAlign="center center" fxLayoutGap="20px" *ngFor="let assessment of pendingAssessments" fxLayout="row" class="assessment-container">
        <div fxFlex="220px" fxLayout="column">
          <div fxFlex="40px" fxLayoutAlign="start center">{{assessment.patient?.givenName + ' ' + assessment.patient?.familyName}} is awaiting approval</div>
          <div fxFlex="40px" fxLayoutAlign="start center">{{assessment.patient?.manager?.firstName + ' ' + assessment.patient?.manager?.lastName}}</div>
        </div>
        <div fxFlex="80px" fxLayoutAlign="center center">
          <button kendoButton fxFlex="40px" look="flat" (click)="goToApprovals(assessment)" [primary]="true">View Pretest</button>
        </div>
      </div>
    </div>
  </kendo-popup>
</ng-container>


