<div class="score-multiple-choice" fxFlexFill fxLayout="row" >
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex="30px">{{answer.questionRef.text}}</div>
    <div fxFlex fxLayout="row wrap" fxLayoutGap="20px grid">
      <div fxFlex="45" *ngFor="let choice of answer.questionRef.items" >
        <div fxFlex="24px">
          <input type="checkbox" [disabled]="true" [(ngModel)]="inputs[choice.value]" kendoCheckBox />
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="10px" >
          <div fxFlex>
            {{choice.text || ' '}}
          </div>
          <div fxFlex="75px" *ngIf="choice.image">
            <img [src]="choice.image" [width]="75" [height]="75"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
