<div class="telehealth" fxLayout="column" fxFlexFill>
  <div class="spacer" fxFlex="20px"></div>
  <div fxLayout="row" fxFlex>
    <app-telehealth-table-section (onOpenCreateDialog)="openCreate()" fxFlex></app-telehealth-table-section>
    <div class="spacer" fxFlex="20px"></div>
    <app-telehealth-sidetab-section fxFlex="30"></app-telehealth-sidetab-section>
  </div>
  <div class="spacer" fxFlex="20px"></div>
</div>
<!-- ALL DIALOGS BELOW -->
<div kendoWindowContainer></div>
<app-telehealth-schedule-dialog [(open)]="scheduleDialogOpen" (onCreate)="notifyCreated()"></app-telehealth-schedule-dialog>
