import { Injectable } from '@angular/core'
import { Connection, Device } from 'twilio-client'
import { APIService, GetUserQuery } from './API.service'
import { BehaviorSubject, interval, Subscription } from 'rxjs'
import Codec = Connection.Codec
import { OfficeFilterService } from './services/office-filter.service'

@Injectable({
  providedIn: 'root'
})
export class CallService {
  /**
   * Refers to the Twilio device in use
   */
  public device$: BehaviorSubject<Device>
  /**
   * Current/Recent Call connection connection
   */
  public activeCall$: BehaviorSubject<CallConnection>
  /**
   * Refers to the office number in Use by the current user
   * @private
   */
  private callerNumber: string

  constructor(
    private apiService: APIService,
    private officeFilterService: OfficeFilterService
  ) {
    this.device$ = new BehaviorSubject<Device>(null)
    this.activeCall$ = new BehaviorSubject<CallConnection>(null)
  }

  async initialize(user: GetUserQuery) {
    const currentDevice = this.device$.getValue()

    if (currentDevice) {
      this.unbindDevice(currentDevice)
    }

    console.log('Initializing CallService with User', user)

    this.callerNumber =
      this.officeFilterService.selectedLocation$.getValue()?.callerId || null

    const { accessToken, identity } = await this.apiService.RequestVoiceAccess(
      {}
    )
    console.log('Setup Voice Access', { accessToken, identity })
    // Setup Twilio.Device
    // @ts-ignore
    const device = new Device(accessToken, {
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
      codecPreferences: [Codec.Opus, Codec.PCMU],
      // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
      // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
      // a second time and sending the tone twice. This will be default in 2.0.
      fakeLocalDTMF: true,
      // Use `enableRingingState` to enable the device to emit the `ringing`
      // state. The TwiML backend also needs to have the attribute
      // `answerOnBridge` also set to true in the `Dial` verb. This option
      // changes the behavior of the SDK to consider a call `ringing` starting
      // from the connection to the TwiML backend to when the recipient of
      // the `Dial` verb answers.
      enableRingingState: true
    })

    device.on('ready', device => {
      console.log('Device Ready!', device)
      this.device$.next(device)
    })

    device.on('error', error => {
      console.log('Device Error: ', error)
    })

    device.on('connect', conn => {
      // log("Successfully established call!");
      // document.getElementById("button-call").style.display = "none";
      // document.getElementById("button-hangup").style.display = "inline";
      // volumeIndicators.style.display = "block";
      // bindVolumeIndicators(conn);
      console.log('Device Connected!', conn)
      const value = this.activeCall$.getValue()
      if (value) {
        value.startedAt = Date.now()
        value.state = CallConnectionState.CONNECTED
        this.activeCall$.next(value)
      }
    })

    device.on('disconnect', conn => {
      console.log('Device Disconnected!', conn)
      // document.getElementById("button-call").style.display = "inline";
      // document.getElementById("button-hangup").style.display = "none";
      // volumeIndicators.style.display = "none";

      const value = this.activeCall$.getValue()
      if (value) {
        value.startedAt = null
        value.state = CallConnectionState.DISCONNECTED
        this.activeCall$.next(value)
      }
    })

    device.on('incoming', conn => {
      console.log('Call Incoming!', conn)
      // log("Incoming connection from " + conn.parameters.From);
      // var archEnemyPhoneNumber = "+12093373517";
      //
      // if (conn.parameters.From === archEnemyPhoneNumber) {
      //   conn.reject();
      //   log("It's your nemesis. Rejected call.");
      // } else {
      //   // accept the incoming connection and start two-way audio
      //   conn.accept();
      // }
    })
  }

  /**
   * Initiates an outbound call to a particular phone
   * number. Title and info are both optional fields for
   * call hud.
   * @param phone
   * @param title
   * @param info
   */
  call({ phone, title, info }) {
    const device = this.device$.getValue()
    if (device && device.isInitialized) {
      const outgoingConnection = device.connect({
        To: phone,
        callerNumber: this.callerNumber
      })

      outgoingConnection.on('ringing', () => {
        const value = this.activeCall$.getValue()
        if (value) {
          value.state = CallConnectionState.RINGING
          this.activeCall$.next(value)
        }
      })

      this.activeCall$.next({
        connection: outgoingConnection,
        title: title || phone,
        info: info || null,
        startedAt: null,
        phone: phone,
        state: CallConnectionState.CONNECTING
      })
    }
  }

  /**
   * End the active call using the call connection
   */
  endCall() {
    const call = this.activeCall$.getValue()
    if (call && call.connection) {
      call.connection.disconnect()
    }
  }

  /**
   * Redial the last call; only do so if it is in disconnected state
   */
  redialCall() {
    const call = this.activeCall$.getValue()
    if (call && call.state === CallConnectionState.DISCONNECTED) {
      this.call({
        phone: call.phone,
        title: call.title,
        info: call.info
      })
    }
  }

  /**
   * Mute the call with a flag
   * @param value
   */
  mute(value: boolean) {
    const call = this.activeCall$.getValue()
    if (call && call.connection) {
      call.connection.mute(value)
    }
  }

  unbindDevice(device: Device) {
    // Handle when user changes log in
    // Disconnect then destroy device instance
    device.disconnectAll()
    device.destroy()
  }
}

export type CallConnection = {
  /**
   * Actual twilio call connection
   */
  connection: Connection
  /**
   * Title to show, title of the call or he phone number if unspecified
   */
  title: string
  /**
   * Phone number to connect to in the call
   */
  phone: string
  /**
   * Additional information about the call, such as patient name, or admin name
   * Which are determined at the very beginning of the call
   */
  info: string
  /**
   * Timestamp when call has been connected
   * At all other times, it will be set to null
   */
  startedAt: number
  /**
   * Current state of the active call
   */
  state: CallConnectionState
}

export enum CallConnectionState {
  /**
   * Default state at the beginning of call
   */
  CONNECTING = 'Connecting',
  /**
   * When the call is awaited on recepient
   */
  RINGING = 'Ringing',
  /**
   * Call has been accepted
   */
  CONNECTED = 'Connected',
  /**
   * When call is either hung up, disconnected or rejected
   */
  DISCONNECTED = 'Disconnected'
}
