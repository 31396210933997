<div class="patients" fxLayout="column" fxFlexFill>
  <div class="patients-list" fxFlex fxLayout="column">
    <kendo-grid fxFlexFill (cellClick)="detail($event)" [data]="patients" [height]="410">
      <ng-template kendoGridToolbarTemplate>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header">
          <h6>Patients Management</h6>
          <button (click)="openRegistration()" [primary]="true" fxFlex="15" kendoButton>New Patient</button>
        </div>
      </ng-template>

      <kendo-grid-column class="cursor-pointer" field="email" title="Email" width="100"></kendo-grid-column>
      <kendo-grid-column class="cursor-pointer" field="givenName" title="First Name" width="75"></kendo-grid-column>
      <kendo-grid-column class="cursor-pointer" field="familyName" title="Last Name" width="75"></kendo-grid-column>
      <kendo-grid-column class="cursor-pointer" field="physicianName" title="Manager" width="50">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem.manager?.firstName + ' ' + dataItem.manager?.lastName}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column class="cursor-pointer" field="assessmentsCount" title="# of Assessments" width="50"></kendo-grid-column>
      <kendo-grid-column class="cursor-pointer" field="lastAssessed" title="Last Test" width="50"></kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="spacer" fxFlex="3"></div>
</div>

<kendo-dialog title="Register Patient" [width]="600" [height]="550" *ngIf="registrationDialogOpen" (close)="closeRegistration()">
  <div fxFlex fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="10px">
      <div class='section-title'>Personal</div>
      <div fxFlex='40px' fxLayout="row" fxLayoutGap='10px'>
        <div fxFlex fxLayoutAlign='start center'>
          <div fxFlex="80px" fxLayoutAlign='start center'>Salutation</div>
          <div fxFlex='60px' fxLayoutAlign='start center'>
            <kendo-dropdownlist fxFlex
              [(ngModel)]="salutationInput"
              [data]="salutations"
            >
            </kendo-dropdownlist>
          </div>
          <div fxFlex='40px'></div>
          <div fxFlex="60px" fxLayoutAlign='start center'>Gender</div>
          <div fxFlex='80px' fxLayoutAlign='start center'>
            <kendo-dropdownlist fxFlex
              [(ngModel)]="genderInput"
              [data]="genders"
            >
            </kendo-dropdownlist>
          </div>
          <div fxFlex='40px'></div>
          <div fxFlex="80px" fxLayoutAlign='start center'>Birthdate</div>
          <div fxFlex="120px">
            <kendo-datepicker fxFlex
              [(value)]="dobInput"
            ></kendo-datepicker>
          </div>
        </div>
      </div>
      <div fxFlex='40px' fxLayout="row" fxLayoutGap='10px'>
        <div fxFlex="100px" fxLayoutAlign='start center'>Patient Name</div>
        <div fxFlex='140px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Given Name" [(ngModel)]="givenNameInput"></kendo-textbox></div>
        <div fxFlex='140px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Middle Name" [(ngModel)]="middleNameInput"></kendo-textbox></div>
        <div fxFlex='140px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Family Name" [(ngModel)]="familyNameInput"></kendo-textbox></div>
      </div>
    </div>

    <div fxLayout='column' fxLayoutGap='10px'>
      <div fxFlex='40px'>
        <kendo-textbox fxFlex placeholder="Address Line 1" [(ngModel)]="addressLine1Input"></kendo-textbox>
      </div>
      <div fxFlex='40px'>
        <kendo-textbox fxFlex placeholder="Address Line 2" [(ngModel)]="addressLine2Input"></kendo-textbox>
      </div>
      <div fxFlex='40px' fxLayout="row" fxLayoutGap='10px'>
        <div fxFlex='140px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Region" [(ngModel)]="regionInput"></kendo-textbox></div>
        <div fxFlex='140px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Country" [(ngModel)]="countryInput"></kendo-textbox></div>
        <div fxFlex='100px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Postal" [(ngModel)]="postalInput"></kendo-textbox></div>
      </div>
    </div>

    <div fxLayout='column' fxLayoutGap='10px'>
      <div>Account Details</div>
      <div fxFlex='40px' fxLayout="row" fxLayoutGap='10px'>
        <div fxFlex='240px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Email" [(ngModel)]="emailInput"></kendo-textbox></div>
        <div fxFlex='180px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Phone" [(ngModel)]="mobileInput"></kendo-textbox></div>
      </div>
      <div fxFlex='40px' >
        <div fxFlex='200px' fxLayoutAlign='start center'><kendo-textbox fxFlex placeholder="Temporary Password" [(ngModel)]="passwordInput"></kendo-textbox>
          <button kendoButton [iconClass]="'fa fa-random'" (click)="refreshRandomPassword()"></button></div>
      </div>
    </div>
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeRegistration()">Cancel</button>
    <button kendoButton (click)="createPatient()" primary="true">Register and Create Pretest</button>
  </kendo-dialog-actions>
</kendo-dialog>
