<div class="payments">
  <kendo-grid fxFlexFill [data]="payments" [height]="410">
    <kendo-grid-column field="paymentDate" title="Date">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div>{{dataItem.paymentDate | customDate:false:'PP'}}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="invoice" title="Billable Hours">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div>{{dataItem.invoice.hours }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="invoice" title="Totals">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div>{{dataItem.invoice.totalBill | currency }}</div>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      No assessment hours found
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
