<div class="admin" fxLayout="column">
  <div fxLayout="row">
    <div class="section section1" fxFlex="50">
      <div class="title">Hourly Rate</div>
      <div class="description">Default hourly rate</div>
      <kendo-textbox placeholder="Rate" [value]="'70'" style="width: 100%">
        <ng-template kendoTextBoxPrefixTemplate>
          <kendo-icon [name]="'dollar'"></kendo-icon>
        </ng-template>
        <ng-template kendoTextBoxSuffixTemplate>
          <button kendoButton [primary]="true">
            Update
          </button>
        </ng-template>
      </kendo-textbox>
    </div>
    <div class="section section2" fxFlex="50">
      <div class="title">Restrict Access</div>
      <div class="description">By turning this on, all physicians under this location will no longer able to start new assessments.</div>
      <button kendoButton [primary]="true" class="btn">
        Restrict access
      </button>
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex="50" class="section"></div>
    <div class="section section3" fxFlex="50">
      <div class="title">Delete this location</div>
      <div class="description">
        <p>Once you delete a location, there is no going back. Please be certain.</p>
        <p>Note: All physicians and patients under this office will not be deleted.</p>
        <p class="confirm">I understand, delete <b>{{location.name}}</b></p>
      </div>
    </div>
  </div>
</div>
