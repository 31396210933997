import { Component, OnInit, Input } from '@angular/core'
import { APIService, GetLocationQuery } from '../../API.service'
import { ASSESSMENT_TYPE_COLOR } from '../../shared/constants'

@Component({
  selector: 'app-location-assessment',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss']
})
export class LocationAssessmentComponent implements OnInit {
  @Input() location: GetLocationQuery;

  loading = true;

  assessments = [
    // {
    //   date: '06/04/2020',
    //   patient: 'Tamera Zuniga',
    //   pretest: {
    //     status: 'Qualified',
    //     date: '06/04/20'
    //   },
    //   assessments: [
    //     { name: 'CESD-R', status: 'Eligible' },
    //     { name: 'PHQ-9', status: 'NotEligible' },
    //     { name: 'PMQ-R', status: 'Eligible' },
    //     { name: 'DAST-10', status: 'Eligible' },
    //     { name: 'CAGE', status: 'Waiting' },
    //     { name: 'GP-COG', status: 'Eligible' }
    //   ],
    //   ccm: 'Harry Cricket'
    // },
    // {
    //   date: '04/15/2019',
    //   patient: 'Herbert Vaughn',
    //   pretest: {
    //     status: 'Qualified',
    //     date: null
    //   },
    //   assessments: [
    //     { name: 'CESD-R', status: 'Eligible' },
    //     { name: 'DAST-10', status: 'NotEligible' },
    //     { name: 'CAGE', status: 'Waiting' },
    //     { name: 'GP-COG', status: 'NotEligible' }
    //   ],
    //   ccm: 'Carrie Yu'
    // },
    // {
    //   date: '04/15/2019',
    //   patient: 'Justin Hertz',
    //   pretest: {
    //     status: 'NotQualified',
    //     date: null
    //   },
    //   assessments: [
    //     { name: 'PHQ-9', status: 'Eligible' },
    //     { name: 'PMQ-R', status: 'Eligible' }
    //   ],
    //   ccm: 'Skye Bradshaw'
    // }
  ]

  constructor(
    private apiService: APIService
  ) {
  }

  ngOnInit(): void {
    this.apiService.ListEncounters({
      locationId: { eq: this.location.id }
    }).then((result) => {
      this.assessments = result.items;
    }).finally(() => {
      this.loading = false;
    });
  }

  getAssessmentColor(type: string): string {
    return ASSESSMENT_TYPE_COLOR[type] ? ASSESSMENT_TYPE_COLOR[type] : '#949494';
  }

}
