import {
  createLocalTracks,
  createLocalVideoTrack,
  createLocalAudioTrack
} from 'twilio-video'

enum MediaKind {
  Audio = 'audio',
  Video = 'video'
}

const localTracks = {
  audio: null,
  video: null
}

const getDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices()
  return {
    audioInputs: devices.filter(device => device.kind === 'audioinput'),
    videoInputs: devices.filter(device => device.kind === 'videoinput')
  }
}

/**
 * Start capturing media from the given input device.
 * @param kind - 'audio' or 'video'
 * @param deviceId - the input device ID
 * @param render - the render callback
 * @returns {Promise<void>} Promise that is resolved if successful
 */
const applyInputDevice = async (
  kind: MediaKind,
  deviceId: string,
  render: (stream: MediaStream) => any
) => {
  // Create a new LocalTrack from the given Device ID.
  const [track] = await createLocalTracks({ [kind]: { deviceId } })

  // Stop the previous LocalTrack, if present.
  if (localTracks[kind]) {
    localTracks[kind].stop()
  }

  // Render the current LocalTrack.
  localTracks[kind] = track
  // render(new MediaStream([track.mediaStreamTrack]))
}

const getLocalTrack = (kind: MediaKind) => {
  return localTracks[kind.toString()]
}

const getMediaSetup = async () => {
  const audioInputDevices = []
  const videoInputDevices = []
  const devices = await navigator.mediaDevices.enumerateDevices()
  devices.forEach(device => {
    console.log('Found device: ' + device.kind + ' ' + device.label)
    if (device.kind === 'audioinput') {
      audioInputDevices.push(device)
    } else if (device.kind === 'videoinput') {
      videoInputDevices.push(device)
    }
  })
  const audioInputDevice = selectDefaultDevice(audioInputDevices)
  const videoInputDevice = selectDefaultDevice(videoInputDevices)
  return {
    audioInputDevice,
    videoInputDevice,
    audioInputDevices,
    videoInputDevices
  }
}

const selectDefaultDevice = (devices: MediaDeviceInfo[]): MediaDeviceInfo => {
  if (!(devices || []).length) {
    return null
  }
  let defaultDevice = devices.find(d => {
    ;(d.label || '').toLowerCase().includes('default')
  })
  if (!defaultDevice) {
    defaultDevice = devices.find(d => {
      ;(d.label || '').toLowerCase().includes('built-in')
    })
  }
  if (!defaultDevice) {
    defaultDevice = devices[0]
  }
  return defaultDevice
}

/**
 *
 * @param deviceId
 * @param localTrack
 * @param kind
 */
const useDeviceOnTrack = (deviceId, localTrack) => {
  var constraints = { deviceId: { exact: deviceId } }
  return localTrack.restart(constraints).then(function () {
    return localTrack
  })
}

export {
  getDevices,
  applyInputDevice,
  MediaKind,
  getLocalTrack,
  getMediaSetup,
  useDeviceOnTrack
}
