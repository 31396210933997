import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  APIService,
  GetEncounterQuery,
  GetPatientQuery,
  GetAssessmentQuery,
  GetUserQuery,
  SoapItemType,
  GetQuestionnaireResponseQuery,
  GetClinicalImpressionQuery,
  AssessmentType
} from '../API.service'

@Component({
  selector: 'app-encounter',
  templateUrl: './encounter.component.html',
  styleUrls: ['./encounter.component.scss']
})
export class EncounterComponent implements OnInit {
  encounter: GetEncounterQuery
  patient: GetPatientQuery
  manager: GetUserQuery
  assessments: Array<GetAssessmentQuery>
  questionnaireResponses: Array<GetQuestionnaireResponseQuery>
  clinicalImpressions: Array<GetClinicalImpressionQuery>
  soapItems: Array<{
    __typename: 'SoapItem'
    type: SoapItemType | null
    text: string | null
    code: string | null
    selected: boolean | null
  }>

  currentTab = 'assessments'

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async params => {
      const encounterId = params.get('id')
      if (encounterId) {
        this.encounter = await this.apiService.GetEncounter(encounterId)
        // @ts-ignore
        this.patient = this.encounter.patient

        // @ts-ignore
        this.manager = this.encounter.manager

        // @ts-ignore
        this.assessments = this.encounter.assessments.items.filter(
          a => a.type === AssessmentType.ASSESSMENT
        )
        // @ts-ignore
        this.questionnaireResponses = this.encounter.questionnaireResponses.items
        // @ts-ignore
        this.clinicalImpressions = this.encounter.clinicalImpressions.items

        this.soapItems = this.encounter.soapItems

        console.log('!! ENCOUNTER IS', this.encounter)
      }
    })
  }

  onChangeTab(tabName) {
    this.currentTab = tabName
  }
}
