<div class="wrapper">
<div class="patient-detail">
    <div fxLayout="row" fxFlexFill fxLayoutAlign="left">
        <div class="patient-detail-avatar">
            <span class="k-icon k-i-user">
            </span>
        </div>
        <div class="patient-detail-content">
            <p class="patient-detail-name">
                {{patientDetail.name}}
            </p>
            <p class="patient-detail-dob">
                <i>Dob: {{patientDetail.dob}} | SS: {{patientDetail.ss}} | Age: {{patientDetail.age}}</i>
            </p>
            <p class="patient-detail-id">
                ID: {{patientDetail.id}} | {{patientDetail.type}} | Last test: <span class="highlight">{{patientDetail.lastTest}}</span>
            </p>
        </div>
    </div>
</div>
<div class="patient-assessments" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
    <div class="patient-center" fxFlex="70" fxLayout="column">
        <div class="patient-tab-content" *ngIf="activeTablist === 0">
            <div class="patient-tab-content-title" fxLayout="row" fxLayoutAlign="space-between center">
                <span><i class="fas fa-stethoscope"></i> {{tabList[0].title}}</span>
                <div class="patient-tab-content-print">
                    <span class="k-icon k-i-print"></span> PDF
                </div>
            </div>
            <div class="patient-tab-content-body">
                <div fxLayout="row" class="personal-info" style="  padding: 10px 10px; font-size: 12px;">
                    <div fxLayout="column">
                        <p>NAME</p>
                        <p><strong>PAKER ALAN</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>DATE OF BIRTH</p>
                        <p><strong>STEP 8, 1964</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PATIENT ID</p>
                        <p><strong>BRE001333</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PHYSICIAN</p>
                        <p><strong>DR. ABC SPECIALIST</strong></p>
                    </div>
                </div>
                <div fxLayout="row" class="psy-test">
                    <div fxLayout="column" fxFlex="40" class="psy-test-score">
                        <div fxLayout="row" fxLayoutAlign="space-around flex-start" style="padding: 10px 0;">
                            <div class="psy-test-score-panel">
                                <p class="psy-test-score-text">Score</p>
                                <p class="psy-test-score-number">0</p>
                            </div>
                            <kendo-lineargauge [pointer]="{ value: 35 }" [scale]="{ vertical: true }">
                            </kendo-lineargauge>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="60" style="padding: 20px">
                        <div class="psy-test-title">Psych Assessment Test</div>
                        <div class="psy-test-type">CESD-R</div>
                        <div class="psy-test-range">
                            <div class="psy-test-range-1 active">
                                <span class="psy-test-range-sample"></span>
                                <span class="psy-test-range-des">Below 16 shows low risk for major depressive episode</span>
                            </div>
                            <div class="psy-test-range-2">
                                <span class="psy-test-range-sample"></span>
                                <span class="psy-test-range-des">Between 17 and 35 shows moderate risk for major depressive episode</span>
                            </div>
                            <div class="psy-test-range-3">
                                <span class="psy-test-range-sample"></span>
                                <span class="psy-test-range-des">36 or above show high risk for major depressive episode</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <button class="button-full-width" [primary]="true" kendoButton><span class="k-icon k-i-print"></span>&nbsp;PDF</button>
        </div>
        <div class="patient-tab-content" *ngIf="activeTablist === 1">
            <div class="patient-tab-content-title" fxLayout="row" fxLayoutAlign="space-between center">
                <span><i class="fas fa-stethoscope"></i> {{tabList[1].title}}</span>
                <div class="patient-tab-content-print">
                    <span class="k-icon k-i-print"></span> PDF
                </div>
            </div>
            <div class="patient-tab-content-body">
                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <div class="physician-header">
                        <p class="physician-header-title">ABC Specialist</p>
                        <p class="physician-header-detail">Physician Interpretation for: Parker Alan</p>
                        <p class="physician-header-date">Date: January 4, 2021</p>
                    </div>
                </div>
                <div fxLayout="row" class="psy-test">
                    <div fxLayout="column" fxFlex="40" class="psy-test-score no-bg">
                        <div fxLayout="row" fxLayoutAlign="space-around flex-start" style="padding: 10px 0;">
                            <div class="psy-test-score-panel">
                                <p class="psy-test-score-text">Score</p>
                                <p class="psy-test-score-number">0</p>
                            </div>
                            <kendo-lineargauge [pointer]="{ value: 10 }" [scale]="{ vertical: true }">
                            </kendo-lineargauge>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="60" style="padding: 20px;">
                        <div class="psy-test-title">Psych Assessment Test</div>
                        <div class="psy-test-type">CESD-R</div>
                        <div class="psy-test-pass">PASS</div>
                        <p>Normal Range: 0 - 16</p>
                    </div>
                </div>
                <div style="padding: 20px;">
                    <p style="font-size: 16px; font-weight: 500;">Emergency Contacts</p>
                    <p><span style="font-weight: 500;">Sue wallace:</span> (764) 980-1267</p>
                </div>
            </div>
            <button class="button-full-width" [primary]="true" kendoButton><span class="k-icon k-i-print"></span> PDF</button>
        </div>
        <div class="patient-tab-content" *ngIf="activeTablist === 2">
            <div class="patient-tab-content-title" fxLayout="row" fxLayoutAlign="space-between center">
                <span><i class="fas fa-stethoscope"></i> {{tabList[2].title}}</span>
                <div class="patient-tab-content-print">
                    <span class="k-icon k-i-print"></span> PDF
                </div>
            </div>
            <div class="patient-tab-content-body">
                <div fxLayout="row" class="personal-info" style="  padding: 10px 10px; font-size: 12px;">
                    <div fxLayout="column">
                        <p>NAME</p>
                        <p><strong>PAKER ALAN</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>DATE OF BIRTH</p>
                        <p><strong>STEP 8, 1964</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PATIENT ID</p>
                        <p><strong>BRE001333</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PHYSICIAN</p>
                        <p><strong>DR. ABC SPECIALIST</strong></p>
                    </div>
                </div>
                <ng-template ngFor let-chart [ngForOf]="charts">
                    <div class="panel panel-comparison">
                        <span><i class="fas fa-stethoscope"></i> {{chart.chartTitle}}</span>
                    </div>
                    <div fxLayout="row" style="padding: 10px 0px;">
                        <div fxFlex="65">
                            <div style="margin: 10px;" *ngIf="!chart.notTest">
                                <div style="text-align: center;">
                                    <span>Graph: </span>
                                    <kendo-dropdownlist [data]="['line', 'column']" [(ngModel)]="chart.chartType">
                                    </kendo-dropdownlist>
                                </div>
                                <kendo-chart [chartArea]="{height: 300}">
                                    <kendo-chart-series>
                                        <kendo-chart-category-axis>
                                            <kendo-chart-category-axis-item [categories]="chart.pureDate">
                                            </kendo-chart-category-axis-item>
                                        </kendo-chart-category-axis>
                                        <kendo-chart-series-item type="{{chart.chartType}}" *ngIf="chart.chartType === 'column'"
                                            [data]="chart.data" field="value" categoryField="date" color="color">
                                        </kendo-chart-series-item>
                                        <ng-template [ngIf]="chart.chartType === 'line'">
                                            <kendo-chart-series-item [color]="'grey'"
                                                type="line" [data]="chart.pureData">
                                            </kendo-chart-series-item>
                                        </ng-template>
                                    </kendo-chart-series>
                                </kendo-chart>
                            </div>
                            <div class="patient-not-test" *ngIf="chart.notTest">
                                <div class="patient-not-test-panel">
                                    Patient not tested
                                </div>
                            </div>
                        </div>
                        <div fxFlex="35">
                            <div style="margin-bottom: 5px;">Score legend</div>
                            <div class="score-legend range-{{(i+1)}}" *ngFor="let checkPoint of chart.checkPoints; let i = index">
                                <span class="psy-test-range-des">{{checkPoint.legend}}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <button class="button-full-width" [primary]="true" kendoButton><span class="k-icon k-i-print"></span> PDF</button>
        </div>
        <div class="patient-tab-content" *ngIf="activeTablist === 3">
            <div class="patient-tab-content-title" fxLayout="row" fxLayoutAlign="space-between center">
                <span><i class="fas fa-stethoscope"></i> {{tabList[3].title}}</span>
                <div class="patient-tab-content-print">
                    <span class="k-icon k-i-print"></span> PDF
                </div>
            </div>
            <div class="patient-tab-content-body">
                <div fxLayout="row" class="personal-info" style="padding: 10px 10px; font-size: 12px;">
                    <div fxLayout="column">
                        <p>NAME</p>
                        <p><strong>PAKER ALAN</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>DATE OF BIRTH</p>
                        <p><strong>STEP 8, 1964</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PATIENT ID</p>
                        <p><strong>BRE001333</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PHYSICIAN</p>
                        <p><strong>DR. ABC SPECIALIST</strong></p>
                    </div>
                </div>
                <div fxLayout="row" class="psy-test">
                    <div fxLayout="column" fxFlex="40" class="psy-test-score">
                        <div fxLayout="row" fxLayoutAlign="center flex-start" style="padding: 10px 0; text-align: center;">
                            <div><span style="font-weight: 500;">PSYCHOLOGICAL</span> <br> <span style="font-size: 12px;">(CESD-R)</span></div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around flex-start" style="padding: 0 0 10px;">
                            <div class="psy-test-score-panel">
                                <p class="psy-test-score-text">Score</p>
                                <p class="psy-test-score-number">0</p>
                            </div>
                            <kendo-lineargauge [pointer]="{ value: 10 }" [scale]="{ vertical: true }">
                            </kendo-lineargauge>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="60" style="padding: 20px">
                    </div>
                </div>
                <div class="panel">
                    DEPRESSION ASSESSMENT (CESD-R)
                </div>
                <div class="depression-assessment"
                    *ngFor="let item of [0,1,2,3,4,5,6,7,8,9]"
                >
                    <div fxlayout="row" class="depression-assessment-section">
                        Sadness (Dysphoia) Realm
                    </div>
                    <div fxlayout="row">
                        <div class="depression-assessment-order">
                            1
                        </div>
                        <div fxFlex="65" class="depression-assessment-detail">
                            I felt depressed
                        </div>
                        <div fxFlex="35" class="depression-assessment-time">
                            Not at all or less than 1 day
                        </div>
                    </div>
                    <div fxlayout="row">
                        <div class="depression-assessment-order">
                            2
                        </div>
                        <div fxFlex="65" class="depression-assessment-detail">
                            I felt depressed
                        </div>
                        <div fxFlex="35" class="depression-assessment-time">
                            Not at all or less than 1 day
                        </div>
                    </div>
                    <div fxlayout="row">
                        <div class="depression-assessment-order">
                            3
                        </div>
                        <div fxFlex="65" class="depression-assessment-detail">
                            I felt depressed
                        </div>
                        <div fxFlex="35" class="depression-assessment-time">
                            Not at all or less than 1 day
                        </div>
                    </div>
                </div>
                <div class="panel">
                    pretest (12/09/20)
                </div>
                <div class="pretest-list"
                    *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10]"
                >
                    <div fxlayout="row" class="pretest-record pretest-negative">
                        <div fxFlex="65">Do you ever feel disoriented</div>
                        <div fxFlex="35" class="pretest-record-answer">Yes</div>
                    </div>
                    <div fxlayout="row" class="pretest-record pretest-positive">
                        <div fxFlex="65">Do you ever feel restless or anxious or unable to relax</div>
                        <div fxFlex="35" class="pretest-record-answer">No</div>
                    </div>
                    <div fxlayout="row" class="pretest-record pretest-positive">
                        <div fxFlex="65">Do you ever feel restless or anxious or unable to relax</div>
                        <div fxFlex="35" class="pretest-record-answer">No</div>
                    </div>
                </div>
            </div>
            <button class="button-full-width" [primary]="true" kendoButton><span class="k-icon k-i-print"></span> PDF</button>
        </div>
        <div class="patient-tab-content suggested-soap-content" *ngIf="activeTablist === 4" >
            <div class="patient-tab-content-title" fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{tabList[4].title}}</span>
                <div class="patient-tab-content-print">
                    <span class="k-icon k-i-print"></span> PDF
                </div>
            </div>

            <div class="patient-tab-content-body p-10">

                <div class="patient-tab-content ex-panel panel-default" *ngFor="let panelItem of suggestedSoapList">
                    <div class="patient-tab-content-title">
                        {{panelItem.title}}
                    </div>
                    <div class="patient-tab-content m-0 shadow-none">
                        <div class="patient-tab-content-body p-10">

                            <div class="d-flex">
                                <div class="left-content p-10">
                                    <div class="my-5">
                                        <b>Observation</b>
                                    </div>
                                    <div class="my-5" *ngFor="let observation of panelItem.observations">
                                        <label class="cursor-pointer">
                                            <input  type="checkbox" /> {{observation.title}}
                                        </label>
                                    </div>
                                </div>
                                <div class="right-content p-10">
                                    <div class="my-5">
                                        <b>Diagnosis</b>
                                    </div>
                                    <div class="my-5" *ngFor="let diagnose of panelItem.diagnosis">
                                        <label class="cursor-pointer">
                                            <input type="checkbox" /> {{diagnose.title}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="patient-tab-content ex-panel panel-danger">
                    <div class="patient-tab-content-title">
                        Following annuals screens and for the additional reasons have ordered psych and neurospych
                        evaluation and disposition for following 12 months
                    </div>
                    <div class="patient-tab-content-body p-10">
                        <div class="my-5">
                            <label class="cursor-pointer">
                                <input type="checkbox" /> Patient is taking medications known to place them at high risk for depression, addition and cognitive concerns.
                            </label>
                        </div>
                        <div class="my-5">
                            <label class="cursor-pointer">
                                <input type="checkbox" /> Patient condition is such that further analysis and treatment is warranted to address suspected behavioral health concerns.
                            </label>
                        </div>
                        <div class="my-5">
                            <label class="cursor-pointer">
                                <input type="checkbox" /> Patient has a history of (alcohol abuse, drug abuse, medication abuse etc)
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <button class="button-full-width" [primary]="true" kendoButton>SUBMIT NOTES</button>
        </div>
        <div class="patient-tab-content cumulative-soap-notes" *ngIf="activeTablist === 5">
            <div class="patient-tab-content-title" fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{tabList[5].title}}</span>
                <div class="patient-tab-content-print">
                    <span class="k-icon k-i-print"></span> PDF
                </div>
            </div>
            <div class="patient-tab-content-body p-10">
                <div fxLayout="row" class="personal-info" style="font-size: 12px;">
                    <div fxLayout="column">
                        <p>NAME</p>
                        <p><strong>PAKER ALAN</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>DATE OF BIRTH</p>
                        <p><strong>STEP 8, 1964</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PATIENT ID</p>
                        <p><strong>BRE001333</strong></p>
                    </div>
                    <div fxLayout="column">
                        <p>PHYSICIAN</p>
                        <p><strong>DR. ABC SPECIALIST</strong></p>
                    </div>
                </div>
                <div class="soap-notes">
                    <div class="head-title">
                        SOAP Notes
                    </div>
                    <div class="note ml-15" *ngFor="let soapNote of soapNotes">
                        <div class="noted-at">
                            {{soapNote.notedAt}}
                        </div>
                        <div class="common-notes">
                            <small>
                                {{soapNote.commonNotes}}
                            </small>
                        </div>
                        <div class="table table-default">
                            <div class="thead" fxlayout="row">
                                <div fxFlex="25"><b>Test Performed</b></div>
                                <div fxFlex="20"><b>Date</b></div>
                                <div fxFlex="40"><b>Description</b></div>
                                <div fxFlex="15"><b>Time</b></div>
                            </div>
                            <div class="tbody" fxlayout="row" *ngFor="let assessment of soapNote.assessments">
                                <div fxFlex="25">{{assessment.testPerformed}}</div>
                                <div fxFlex="20">{{assessment.date}}</div>
                                <div fxFlex="40">{{assessment.description}}</div>
                                <div fxFlex="15">{{assessment.time}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="communication">
                    <div class="head-title">
                        Communication
                    </div>
                    <div class="post">
                        <div>
                            Parker showed up later than expected, so was unable to do the assessments before his appoinment with the doctor.
                            He has been rescheduled, and will come in on a later date.
                        </div>
                        <div class="noted-at">
                            <small>John Doe (Manager) Feb 27</small>
                        </div>
                    </div>
                    <div class="nested-post">
                        <div>
                            Parker does have the tendency to run late from time to time, so not surprising.
                            I will make sure the office calls him ro remind him to come early to his next appointment, so that the assessments
                            may be completed. Thanks!
                        </div>
                        <div class="noted-at">
                            <small>Barry Does (Officer) Feb 27, 2020</small>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
    <div class="patient-sidebar" fxFlex="28" fxLayout="column">
        <ul class="patient-sidebar-list">
            <li class="patient-sidebar-list-item patient-sidebar-list-item-title">
                <span class="k-icon k-i-pin"></span> Pending Approval
            </li>
            <li class="patient-sidebar-list-item">
                <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between center">
                    Psych Asscessment Test (CESD-R) <button [primary]="true" kendoButton (click)="onApproveButtonClick()">Approve</button>
                </div>
            </li>
        </ul>
        <form [formGroup]="timeSpentForm" (ngSubmit)="timeSpentFormSubmit()" *ngIf="timeSpentForm">
            <ul class="patient-sidebar-list">
                <li class="patient-sidebar-list-item patient-sidebar-list-item-title">
                    <span class="k-icon k-i-clock"></span> Time Spent
                </li>
                <li class="patient-sidebar-list-item">
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between center">
                        Differential Diagnostic Therapy
                        <kendo-textbox
                            [clearButton]="true"
                            formControlName="minutes"
                        >
                            <ng-template kendoTextBoxSuffixTemplate>
                                <span>Minutes</span>
                            </ng-template>
                        </kendo-textbox>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between center">
                        <button [primary]="true" kendoButton>Submit</button>
                    </div>
                </li>
            </ul>
        </form>
        <form [formGroup]="teleEncouterForm" (ngSubmit)="teleEncouterFormSubmit()" *ngIf="teleEncouterForm">
            <ul class="patient-sidebar-list">
                <li class="patient-sidebar-list-item patient-sidebar-list-item-single">
                    <kendo-formfield [orientation]="'horizontal'" [showHints]="'initial'" fxFlexFill>
                        <div fxLayout="row" fxFlexFill fxFlexAlign="center">
                            <label class="k-label" fxFlexAlign="center" style="margin-right: 10px;">Telemedicine Encouter:</label>
                            <ul class="k-radio-list k-list-horizontal">
                                <li class="k-radio-item">
                                    <input type="radio" #yes value="yes" kendoRadioButton [formControlName]="'teleEncouter'" />
                                    <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                                </li>

                                <li class="k-radio-item">
                                    <input type="radio" #no value="no" kendoRadioButton [formControlName]="'teleEncouter'" active/>
                                    <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                                </li>
                            </ul>
                            <!-- <kendo-formerror>Error: This field is required</kendo-formerror> -->
                        </div>
                    </kendo-formfield>
                </li>
            </ul>
        </form>
        <ul class="patient-sidebar-tab-list">
            <li *ngFor="let item of tabList"
                (click)="onTabListClick(item.order)"
                class="patient-sidebar-tab-list-item"
                [class.active]="activeTablist === item.order">
                {{item.title}}
            </li>
        </ul>
        <ul class="patient-sidebar-panel-list">
            <li>Id: <b>9085</b></li>
            <li>Date: <b>Jan 29, 2021 12:42pm (PST)</b></li>
            <li>Physician views: <b>3</b></li>
            <li>SOAP Form: <span class="k-icon k-i-close text-danger cursor-pointer"></span></li>
            <li>DDT/PA Form: <span class="k-icon k-i-close text-danger cursor-pointer"></span></li>
        </ul>

        <ul class="patient-sidebar-panel-list">
            <li>
                <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between center">
                    Psych Asscessment Test
                    <button class="alert" [primary]="true" kendoButton>Not Approved</button>
                </div>
            </li>
        </ul>
    </div>
</div>
</div>
