<span class="table heading">User Management</span>
    <kendo-grid [data]="users">
      <ng-template kendoGridToolbarTemplate>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header">
          <button (click)="openRegistration()" [primary]="true" fxFlex="15" kendoButton>New User</button>
        </div>
      </ng-template>
      <kendo-grid-column field="email" title="Email" width="60"></kendo-grid-column>
      <kendo-grid-column field="firstName" title="First Name" width="40"></kendo-grid-column>
      <kendo-grid-column field="lastName" title="Last Name" width="40"></kendo-grid-column>
      <kendo-grid-column field="phone" title="Phone" width="40"></kendo-grid-column>
      <kendo-grid-column field="userType" title="User Type" width="40"></kendo-grid-column>
      <!--      <kendo-grid-column title="Actions" width="30">-->
      <!--        <ng-template kendoGridCellTemplate let-dataItem>-->
      <!--          <button *ngIf="dataItem.UserStatus === 'UNCONFIRMED'" kendoButton [iconClass]="'fa fa-check'" (click)="confirmUser(dataItem.email)"></button>-->
      <!--        </ng-template>-->
      <!--      </kendo-grid-column>-->
    </kendo-grid>

<kendo-dialog title="Create User Account" [width]="450" [height]="450" *ngIf="registrationDialogOpen" (close)="closeRegistration()">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">First Name</div>
      <kendo-textbox fxFlex placeholder="First Name" [(ngModel)]="firstNameInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Last Name</div>
      <kendo-textbox fxFlex placeholder="Last Name" [(ngModel)]="lastNameInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Email</div>
      <kendo-textbox fxFlex placeholder="Email" [(ngModel)]="emailInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Password</div>
      <kendo-textbox fxFlex placeholder="Password" type="password" [(ngModel)]="passwordInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Phone</div>
      <kendo-textbox fxFlex placeholder="Phone" type="phone" [(ngModel)]="phoneInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Account: </div>
      <div fxFlex>{{currentAccount.name}}</div>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Location</div>
      <kendo-multiselect
        [data]="locations"
        [textField]="'name'"
        [valueField]="'id'"
        [valueNormalizer]=""
        (valueChange)="onValueChange($event)"
      ></kendo-multiselect>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">User Type</div>
      <kendo-dropdownlist
        fxFlex
        [data]="userTypes"
        [(ngModel)]="userTypeInput">
      </kendo-dropdownlist>
    </div>
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeRegistration()">Cancel</button>
    <button kendoButton (click)="createAndCloseRegistration()" primary="true">Create</button>
  </kendo-dialog-actions>
</kendo-dialog>
