<div class="enumeration-question" fxFlexFill fxLayout="column" fxLayoutGap="20px">
  <div fxFlex="30px" fxLayoutAlign="start center">{{questionMeta.text}}</div>
  <div fxFlex fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="300px" fxLayoutAlign="end">
      <img [src]="questionMeta.data" [height]="300" [width]="300"/>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="20px">
      <div *ngFor="let blank of blanks" fxFlex="30px" fxLayout="row">
        <kendo-textbox fxFlex="200px" placeholder="" [(ngModel)]="inputs[blank]" (ngModelChange)="onChange($event, blank)">
          <ng-template kendoTextBoxPrefixTemplate>
            <div [style]="'width:30px; text-align: center;'">{{blank}}</div>
            <kendo-textbox-separator></kendo-textbox-separator>
          </ng-template>
        </kendo-textbox>
      </div>
    </div>
    <div fxFlex class="spacer"></div>
  </div>
</div>

<!--Box 7: “Please name these items”-->

<!--Question 12:-->

<!--Use the image of a jacket and a tie from the original test – including the red lines to numbered blank spaces as shown.  Use this same model of red lines leading to numbered blank spaces for all versions.-->

<!--Scoring:-->
<!--To be scored automatically by the program.  Use fuzzy logic for misspellings.  Plurals accepted.-->
<!--One point for each question answered correctly.  No points for other answers.  If there is any question, refer to CCM for scoring decision.-->
<!--OVERALL SCORING TO BE CHECKED BY THE CCM in order to eliminate possibility of the patient having listed the correct answers in the incorrect spaces.  If this has happened, CCM to deduct one point from total score but otherwise give credit for correct answers.-->

<!--Version 1 Scoring:-->
<!--Scoring for the image of jacket and tie on the original test document:-->
<!--Shirt / collar/ shirt collar-->
<!--Lapel-->
<!--Tie-->
<!--Pocket / ticket pocket-->
<!--Button-->



<!--Scoring:-->
<!--Correct answers as listed above.-->


<!--Version 6:-->
<!--The five simple animal drawings shown below should all appear at the same time on the same screen, with lines drawn to answer spaces as in the other versions.  This will keep scoring consistent (e.g. it will remain possible to lose one point for confusing the lines and answer spaces).-->
