import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { APIService, ContactType, ResourceCategory, ResourceType } from '../../../../API.service';
import { RESOURCE_CATEGORY_COLOR, RESOURCE_CATEGORY_TEXT } from '../../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Countries } from '../../../data';
import StringHelper from '../../../helpers/StringHelper';
import { SessionService } from '../../../../services/session.service';

@Component({
  selector: 'app-resources-list-component',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss']
})
export class ResourcesListComponent implements OnInit {
  @Input() location = null;
  @Input() account = null;
  @Input() deletable = false;
  @Input() editable = false;
  @Input() creatable = false;
  @Output() onDelete = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Input()  createResource = false;
  @Output() createResourceChange = new EventEmitter<boolean>();

  resources = [];
  selectedItem;
  showRemoveResourceModal = false;
  loading = false;
  resourceCategoryColor = RESOURCE_CATEGORY_COLOR;

  newResourceForm: FormGroup;
  countriesList = Countries.map(r => ({id: r.country, name: r.country}));
  // tslint:disable-next-line:max-line-length
  categoriesList = StringHelper.enumToArray(ResourceCategory).map(r => ({id: r, name: RESOURCE_CATEGORY_TEXT[r] ? RESOURCE_CATEGORY_TEXT[r] : r}));
  typesList = StringHelper.enumToArray(ResourceType).map(r => ({id: r, name: r}));
  // tslint:disable-next-line:typedef
  get fr() { return this.newResourceForm.controls; }
  editResource;
  selectedResource;

  constructor(
    private apiService: APIService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.fetchEventResources();

    this.newResourceForm = this.formBuilder.group({
      // locationIdInput: ['', Validators.required],
      // businessCategoryIdInput: ['', Validators.required],
      businessTypeIdInput: ['', Validators.required],
      businessNameInput: ['', Validators.required],
      businessAddressInput: ['', Validators.required],
      businessSuiteInput: ['', Validators.required],
      businessCityInput: ['', Validators.required],
      businessCountryInput: ['', Validators.required],
      businessZipCodeInput: ['', Validators.required],
      businessWebsiteInput: ['', Validators.required],
      businessHoursInput: ['', Validators.required],
      contactFirstNameInput: ['', Validators.required],
      contactLastNameInput: ['', Validators.required],
      contactPhoneInput: ['', Validators.required],
      contactEmailAddressInput: ['', Validators.compose([Validators.required, Validators.email])],
      timeSpentInput: ['', Validators.minLength(0)],
    });
  }

  fetchEventResources(): void {
    this.loading = true;
    this.apiService.ListEventResources({
      locationId: {eq: this.location.id}
    }).then((result) => {
      this.resources = result.items;
    }).finally(() => {
      this.loading = false;
    });
  }

  deleteItem(item): void {
    this.selectedItem = item;
    this.showRemoveResourceModal = true;
  }

  editItem(item): void {
    this.editResource = item;
    this.newResourceForm.setValue({
      // locationIdInput: item.locationId,
      // businessCategoryIdInput: item.category,
      businessTypeIdInput: item.type,
      businessNameInput: item.name,
      businessCountryInput: item.address ? item.address.country : '',
      businessZipCodeInput: item.address ? item.address.postal : '',
      businessCityInput: item.address ? item.address.region : '',
      businessAddressInput: item.address ? item.address.line1 : '',
      businessSuiteInput: '',
      businessWebsiteInput: item.website,
      businessHoursInput: item.businessHours,
      contactEmailAddressInput: item.contact ? item.contact.email : '',
      contactLastNameInput: item.contact ? item.contact.familyName : '',
      contactFirstNameInput: item.contact ? item.contact.givenName : '',
      contactPhoneInput: item.contact ? item.contact.mobile : '',
      timeSpentInput: item.billedTime,
    });
    this.createResource = true;
  }

  closeRemoveResourceModal(): void {
    this.showRemoveResourceModal = false;
  }

  async removeResourceAndCloseModal(): Promise<void> {
    this.loading = true;
    try {
      await this.apiService.DeleteEventResource({
        id: this.selectedItem.id
      });
      this.selectedItem = null;
      this.showRemoveResourceModal = false;
      this.fetchEventResources();
      this.onDelete.emit();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  // Resource
  newResource(): void {
    this.createResource = true;
  }

  closeNewResource(): void {
    this.createResource = false;
    this.resetNewResourceInputs();
  }

  async createAndCloseNewResourceModal(): Promise<void> {
    if (this.newResourceForm.invalid || !this.account) {
      return;
    }

    this.loading = true;

    try {
      const payload = {
        accountId: this.account.id,
        address: {
          country: this.newResourceForm.value.businessCountryInput,
          postal: this.newResourceForm.value.businessZipCodeInput,
          region: this.newResourceForm.value.businessCityInput,
          line1: this.newResourceForm.value.businessAddressInput
        },
        billedTime: this.newResourceForm.value.timeSpentInput,
        businessHours: this.newResourceForm.value.businessHoursInput,
        // category: this.newResourceForm.value.businessCategoryIdInput,
        contact: {
          contactType: ContactType.BUSINESS,
          email: this.newResourceForm.value.contactEmailAddressInput,
          familyName: this.newResourceForm.value.contactLastNameInput,
          givenName: this.newResourceForm.value.contactFirstNameInput,
          mobile: this.newResourceForm.value.contactPhoneInput
        },
        locationId: this.location.id,
        name: this.newResourceForm.value.businessNameInput,
        ownerId: this.sessionService.userId$.getValue(),
        type: this.newResourceForm.value.businessTypeIdInput,
        website: this.newResourceForm.value.businessWebsiteInput,

        classification: null,
      };
      if (this.editResource) {
        // Update
        await this.apiService.UpdateEventResource({
          id: this.editResource.id,
          ...payload
        });
      } else {
        await this.apiService.CreateEventResource(payload);
      }

      this.loading = false;
      this.resetNewResourceInputs();
      this.closeNewResource();
      this.fetchEventResources();
      if (this.editResource) {
        this.onEdit.emit();
      }
      else {
        this.onCreate.emit();
      }
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  resetNewResourceInputs(): void {
    this.newResourceForm.reset();
  }

  onResourceSelect(resource): void {
    this.selectedResource = resource;
  }

  closeResourceEvent(): void {
    this.selectedResource = null;
  }

  onEventCreate(): void {
    this.onEdit.emit();
  }

  onEventEdit(): void {
    this.onEdit.emit();
  }

  onEventDelete(): void {
    this.onEdit.emit();
  }
}
