import { Injectable } from '@angular/core'
import { LocalStorageService } from '../../services/local-storage.service'
import { Router } from '@angular/router'
import { SessionService } from '../../services/session.service'
import { PatientSession } from './patient-session.service'
import {
  APIService,
  GetEncounterQuery,
  GetAssessmentQuery
} from 'src/app/API.service'

@Injectable({
  providedIn: 'root'
})
export class AssessmentSessionService {
  session: AssessmentSession
  constructor(
    private router: Router,
    private apiService: APIService,
    private sessionService: SessionService
  ) {}

  public initSession(encounter: GetEncounterQuery) {
    this.session = {
      encounter,
      state: AssessmentSessionState.SELECTION,
      ids: [encounter.pretest.id]
    }
  }

  public navigateToCurrent() {
    console.log(this.session)
  }

  public moveToNextState() {
    // Remove the item to assure  can't go back
    this.session.ids.pop()

    if (this.session.state === AssessmentSessionState.SELECTION) {
      this.session.state = AssessmentSessionState.SCORING
      this.session.ids = this.session.encounter.assessments.items.map(a => a.id)
    } else if (this.session.state === AssessmentSessionState.SCORING) {
      if (this.session.ids.length === 0) {
        this.session.state = AssessmentSessionState.IMPRESSIONS
        this.session.ids = [this.session.encounter.id]
      }
    }
  }
}

export enum AssessmentSessionState {
  SELECTION = 'SELECTION',
  SCORING = 'SCORING',
  IMPRESSIONS = 'IMPRESSIONS'
}

export type AssessmentSession = {
  encounter: GetEncounterQuery
  state: AssessmentSessionState
  ids: Array<string>
  // objects: Array<GetAssessmentQuery>
}
