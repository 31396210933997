import { Injectable } from '@angular/core'
import {
  NotificationRef,
  NotificationService
} from '@progress/kendo-angular-notification'
import { RingNotificationComponent } from '../shared/notification-components/ring-notification/ring-notification.component'
import { BehaviorSubject } from 'rxjs'
import { SoundService } from './sound.service'
import { TransferNotificationComponent } from '../shared/notification-components/transfer-notification/transfer-notification.component'
import { PendingPretestNotificationComponent } from '../shared/notification-components/pending-pretest-notification/pending-pretest-notification.component'
import { WsActions } from '../shared/types/ws-actions'

@Injectable({
  providedIn: 'root'
})
export class WsNotificationService {
  public notificationData$: BehaviorSubject<any>
  private messageMapping = {
    ring: {
      component: RingNotificationComponent,
      sound: 'ring'
    },
    transfer: {
      component: TransferNotificationComponent,
      sound: 'ring'
    },
    [WsActions.PretestSubmission]: {
      component: PendingPretestNotificationComponent,
      sound: 'notification'
    }
  }

  constructor(
    private notificationService: NotificationService,
    private soundService: SoundService
  ) {
    this.notificationData$ = new BehaviorSubject<any>(null)
  }

  processMessage(data: any) {
    console.log('Received WSMessage', data)
    if (data && data.action) {
      const mapping = this.messageMapping[data.action]
      if (mapping) {
        const { component, sound } = mapping
        if (sound) {
          this.soundService.play(sound)
        }
        this.notificationData$.next(data)
        const notificationRef: NotificationRef = this.notificationService.show({
          content: component,
          animation: { type: 'slide', duration: 200 },
          position: { horizontal: 'left', vertical: 'bottom' },
          type: { icon: false },
          closable: true
        })

        if (notificationRef) {
          notificationRef.content.instance.dismissed.subscribe(() =>
            notificationRef.hide()
          )
        }
      }
    }
  }
}
