<div fxFlexFill kendoTooltip>
  <span class="table heading">Assessments</span>
  <kendo-grid [data]="encounters" (cellClick)="onCellClick($event)">
    <ng-template kendoGridToolbarTemplate>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header">
        <kendo-buttongroup [selection]="'single'" fxFlex="20" fxLayout="row">
          <button kendoButton fxFlex [toggleable]="true" [selected]="true">All</button>
          <button kendoButton fxFlex [toggleable]="true">Today</button>
          <button kendoButton fxFlex [toggleable]="true">This Week</button>
        </kendo-buttongroup>
        <button [primary]="true" fxFlex="12" kendoButton>Schedule</button>
      </div>
    </ng-template>
    <kendo-grid-column class="cursor-pointer" field="date" title="Date" width="50">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.date | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="cursor-pointer" field="patient" title="Patient" width="75">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.patient.givenName }} {{ dataItem.patient.familyName }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="cursor-pointer" field="pretest" title="Pretest" width="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.pretest.status === 'Qualified'" class="label-green">Qualified</span>
        <span *ngIf="dataItem.pretest.status === 'NotQualified'" class="label-red">Not Qualified</span>
        <span *ngIf="dataItem.pretest.scheduledDate">&nbsp;({{dataItem.pretest.scheduledDate | date}})</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="assessments" title="Assessments" width="200">
      <ng-template kendoGridCellTemplate let-dataItem let-a="dataItem.assessments">
        <div fxFlexFill fxLayout="row" fxLayoutGap="5px">
          <div *ngFor="let a of dataItem.assessments.items" (click)="openAssessment(a)" [title]="'(' + a.status + ') Click to view'"
               fxFlex fxLayout="column" fxLayoutAlign="center center" [ngClass]="a.status + ' clickable assessment-label'">
            {{a.testCode}}
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="cursor-pointer" field="ccm" title="Clinical Case Manager" width="75">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.manager.firstName }} {{ dataItem.manager.lastName }}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
