<kendo-dialog title="Schedule Telehealth" [width]="450" [height]="500" *ngIf="open" (close)="cancel()">

  <div fxFlex fxLayout="column" fxLayoutGap="20px">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div fxFlex="100px" fxLayoutAlign="end center">Session Title</div>
      <kendo-textbox fxFlex placeholder="Telehealth Session Title" [(ngModel)]="titleInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div fxFlex="100px" fxLayoutAlign="end center">Description</div>
      <textarea kendoTextArea fxFlex [autoSize]="false" [rows]="3" placeholder="Description" [(ngModel)]="descriptionInput"></textarea>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div fxFlex="100px" fxLayoutAlign="end center">Select Patient</div>
      <kendo-combobox
        fxFlex
        [(value)]="patientInput"
        [data]="filteredPatients"
        [valueField]="'id'"
        [textField]="'fullName'"
        [filterable]="true"
        (filterChange)="handlePatientFilter($event)"
      >
      </kendo-combobox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div fxFlex="100px" fxLayoutAlign="end center">Select Date</div>
      <kendo-datetimepicker fxFlex [(value)]="selectedDate">
      </kendo-datetimepicker>
      <kendo-dropdownlist fxFlex="80px" [data]="timeLengths"
        [textField]="'label'" [valueField]="'value'" [(value)]="selectedLength">
      </kendo-dropdownlist>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div fxFlex="100px" fxLayoutAlign="end center">Select Assessments</div>
      <kendo-multiselect
        [data]="tests"
        [valuePrimitive]="false"
        (valueChange)="onSelectedTestsChange($event)"
       textField="title"
       valueField="id">
      </kendo-multiselect>
    </div>
<!--    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">-->
<!--      <div fxFlex="100px" fxLayoutAlign="end center">Session Type</div>-->
<!--      <kendo-dropdownlist-->
<!--        fxFlex="80px"-->
<!--        [(ngModel)]="roomTypeInput"-->
<!--        [data]="roomTypes"-->
<!--      >-->
<!--      </kendo-dropdownlist>-->
<!--    </div>-->
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="cancel()">Cancel</button>
    <button kendoButton [disabled]="!patientInput || titleInput.length < 3" (click)="create()" primary="true">Create</button>
  </kendo-dialog-actions>
</kendo-dialog>
