import { Component, OnDestroy, OnInit } from '@angular/core'
import { DrawerItem, DrawerSelectEvent } from '@progress/kendo-angular-layout'
import { NavigationEnd, Route, Router } from '@angular/router'
import { SessionService } from '../../services/session.service'
import { AuthService } from '../../services/auth.service'
import { Observable, Subscription } from 'rxjs'

import { DashRoute, UserGroup } from '../../shared/types/types'
import { accessFromGroups, getDashRouteByName } from '../../shared/mappings'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  private hasDrawerItems: boolean
  private accessGroupSub: Subscription
  private routeSub: Subscription
  public isLoggedIn$: Observable<boolean>
  public items: Array<DrawerItem> = []
  public expanded = false
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private authService: AuthService
  ) {
    this.hasDrawerItems = false
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.sessionService.loggedIn$

    this.accessGroupSub = this.sessionService.accessGroups$.subscribe(
      accessGroups => {
        this.applyNavItems(accessGroups)
      }
    )
    this.routeSub = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.applyOnRoute()
      }
    })
  }

  ngOnDestroy(): void {
    this.accessGroupSub.unsubscribe()
    this.routeSub.unsubscribe()
  }

  applyOnRoute() {
    const page = this.router.url
    console.log(page, this.items)
    this.items = this.items.map(item => {
      const name = item.text
      const dashRoute = getDashRouteByName(name)
      if (
        dashRoute &&
        dashRoute.path instanceof RegExp &&
        page.match(dashRoute.path)
      ) {
        item.selected = true
      } else if (dashRoute && page === '/' + dashRoute.path) {
        item.selected = true
      } else if (!dashRoute && page === '/' && name === 'Dashboard') {
        item.selected = true
      } else {
        item.selected = false
      }
      return item
    })
  }

  isFullScreen(): boolean {
    return (
      !this.router.url.startsWith('/scoring') &&
      !this.router.url.startsWith('/live-room') &&
      !this.router.url.startsWith('/404')
      // this.hasDrawerItems
    )
  }

  public onSelect(ev: DrawerSelectEvent): void {
    if (ev.item.text === 'Logout') {
      this.authService.logout().then(() => {
        return this.router.navigate(['/login'])
      })
    } else if (ev.item.text === 'Dashboard') {
      this.router.navigate(['/'])
    } else {
      const dashRoute = getDashRouteByName(ev.item.text)
      this.router.navigate([dashRoute.path])
    }
  }

  applyNavItems(accessGroups: Array<any>) {
    this.hasDrawerItems = !!accessGroups.length
    const access = accessFromGroups(accessGroups)
    this.items = access.dashRoutes
      .filter(n => n.isDisplayed)
      .map(
        (dashRoute: DashRoute): DrawerItem => {
          return { text: dashRoute.name, icon: dashRoute.icon }
        }
      )
    this.items.push({ text: 'Logout', icon: 'k-i-logout' })
    this.items.unshift({
      text: 'Dashboard',
      icon: 'k-i-home'
    })
    this.applyOnRoute()
  }
}
