import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-treatment-action-plans',
  templateUrl: './treatment-action-plans.component.html',
  styleUrls: ['./treatment-action-plans.component.scss']
})
export class TreatmentActionPlansComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
