<div class="completion-question" fxFlexFill fxLayout="row">
  <div fxFlex *ngIf="parts.length" fxLayout="row" fxLayoutGap="10px">
    <div class="question-part" fxLayoutAlign="start center" *ngFor="let part of parts" [ngSwitch]="part.type">
        <div fxFlex fxLayoutAlign="center center" *ngSwitchCase="'text'">
          <span>{{part.data}}</span>
        </div>
        <kendo-textbox [fxFlex]="part.width" *ngSwitchCase="'input'" [(ngModel)]="inputs[part.data]" (ngModelChange)="onChange($event,part.data)" [placeholder]="part.placeholder" [clearButton]="true"></kendo-textbox>
    </div>
  </div>
</div>


<!--Complete the statements by filling in the blanks-->
<!--Question 1:  Please write your full name.-->
<!--2 points for correct full name (first and last).   Full middle name may also be included.-->
<!--1 point for an initial or nickname used, or other minor error.  (refer to CCM for decision if unclear)-->

<!--Question 2: Today is _______  (day of the week)-->
<!--1 point if correct-->
<!--*Note the change from the original “Today is ______day” to “Today is ___________ (day of the week)”-->

<!--Question 3:  Today’s date is the _____ of _______ (month),  20_____.-->
<!--1 point for the date (if off by one day, still assign 1 point)-->
<!--1 point for the correct month-->
<!--1 point for the correct year-->

<!--Question 4:  How old are you?  _________ years.-->
<!--1 point for the correct answer.-->

<!--Question 5: On what date were you born?  ______/ ______ (month), 19_____.-->
<!--1 point for the date-->
<!--1 point for the correct month-->
<!--1 point for the correct year-->


<!--Question 7:-->

<!--"Who is the President __________   __________?"-->
<!--Please note the two blank spaces.  These are important as they indicate that first and last name are desired.-->

<!--This question will remain the same on all versions of the test.-->

<!--Scoring:-->
<!--To be automatically scored by the program.-->
<!--Donald Trump – 2 points.-->
<!--Just one name, such as Don, Donald or Trump, 1 point.-->
<!--Use fuzzy logic for spelling errors.-->
<!--After inauguration, update (and again every 4-8 years).-->


<!--Question 8:-->
<!--Scoring:  To be automatically scored by the program.-->

<!--Version 1 (original):-->
<!--In what year did the First World War start? ____________-->
<!--Scoring:  1914.  One point for the correct year.  0 points any other year.-->

<!--Version 2:-->
<!--In what year did the United States declare independence from Great Britain?-->
<!--Scoring:  1776.  One point for the correct year.  0 points any other year.-->

<!--Version 3:-->
<!--In what year did Christopher Columbus first land in the Americas?-->
<!--Scoring:  1492.  One point for the correct year.  0 points any other year.-->

<!--Version 4:-->
<!--In what year did the Second World War start in Europe?-->
<!--Scoring:  1939.  One point for the correct year.  0 points any other year.-->

<!--Version 5:-->
<!--In what year was President John F. Kennedy assassinated?-->
<!--Scoring:  1963.  One point for the correct year.  0 points any other year.-->

<!--Version 6:-->
<!--In what year did the first person land on the moon?-->
<!--Scoring:  1969.  One point for the correct year.  0 points any other year.-->






<!--Version 1-->
<!--20 – 4 = 16-->
<!--16 + 17 = 33-->
<!--8 x 6 = 48-->
<!--4 + 15 – 17 = 2-->

<!--Version 2:-->
<!--47 – 8 = 39-->
<!--17 + 18 = 35-->
<!--5 x 4 = 20-->
<!--7 + 12 – 4 = 15-->

<!--Version 3:-->
<!--24 – 6 = 18-->
<!--13 + 16 = 29-->
<!--7 x 8 = 56-->
<!--4 + 8 – 5 = 7-->

<!--Version 4:-->
<!--37 – 12 = 25-->
<!--13 + 15 = 28-->
<!--9 x 6 = 54-->
<!--23 + 8 – 4 = 27-->

<!--Version 5:-->
<!--24 – 7 = 17-->
<!--34 + 17 = 51-->
<!--7 x 4 = 28-->
<!--33 + 9 – 5 = 37-->

<!--Version 6:-->
<!--46 – 9 = 37-->
<!--12 + 14 = 26-->
<!--6 x 7 = 42-->
<!--21 - 6 + 4 = 19-->
