import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { APIService } from '../API.service'

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  location = null

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: APIService,
  ) { }

  ngOnInit(): void {
    const locationId = this.route.snapshot.paramMap.get('id');
    console.log('locationId', locationId)

    this.apiService.GetLocation(locationId)
      .then((result) => {
        console.log('location data', result);
        this.location = result
      });
  }

}
