import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { APIService, GetLocationQuery, UserType } from '../../../API.service'
import { SessionService } from '../../../services/session.service'

@Component({
  selector: 'app-location-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class LocationAddUserComponent implements OnInit {
  @Input() open = false
  @Output() openChange = new EventEmitter<boolean>()
  @Input() location: GetLocationQuery
  @Output() onResult = new EventEmitter<boolean>()
  loading = false

  users = []
  userTypes = [
    UserType.PROVIDER,
    UserType.ADMINISTRATION,
    UserType.BILLING,
    UserType.NURSE,
    UserType.CCM
  ]
  emailInput = 'jason.wong+user@qwantegy.com'
  passwordInput = 'Password123'
  firstNameInput = 'Jason'
  lastNameInput = 'Wong'
  phoneInput = '+10000000'
  userTypeInput = UserType.PROVIDER
  registrationDialogOpen = false
  usersAccountFilter = undefined

  constructor(
    private apiService: APIService,
    private sessionService: SessionService
  ) {}

  async ngOnInit(): Promise<void> {}

  closeRegistration(): void {
    this.openChange.emit(false)
  }

  async createAndCloseRegistration(): Promise<void> {
    this.loading = true
    try {
      const result = await this.apiService.AddUserAccount({
        email: this.emailInput,
        password: this.passwordInput,
        phone: this.phoneInput,
        accountId: this.location.accountId,
        userType: this.userTypeInput,
        firstName: this.firstNameInput,
        lastName: this.lastNameInput,
        locations: [this.location.id]
      })
      this.closeRegistration()
      this.onResult.emit(true)
    } catch (e) {
      console.error(e)
    }
    this.loading = false
  }
}
