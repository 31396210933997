import { Access, ConsoleUser, DashRoute, UserGroup } from './types/types'

const dashRoutes: Array<DashRoute> = [
  {
    name: 'Accounts',
    path: 'accounts',
    icon: 'k-i-lock',
    src: 'accounts-icon.svg',
    svg: 'lock',
    accessGroups: [UserGroup.Super],
    isDisplayed: true
  },
  {
    name: 'Locations',
    path: 'locations',
    icon: 'k-i-marker-pin',
    src: 'offices-icon.svg',
    svg: 'mapMarker',
    accessGroups: [UserGroup.Super],
    isDisplayed: true
  },
  {
    name: 'Location',
    path: /location\/[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}/gi,
    icon: 'k-i-marker-pin',
    svg: 'mapMarker',
    src: 'offices-icon.svg',
    accessGroups: [UserGroup.Super],
    isDisplayed: false
  },
  {
    name: 'Users',
    path: 'users',
    icon: 'k-i-user',
    svg: 'lock',
    src: 'users-icon.svg',
    accessGroups: [UserGroup.Super, UserGroup.Administrators],
    isDisplayed: true
  },
  {
    name: 'Patients',
    path: 'patients',
    icon: 'k-i-user',
    src: 'patients-icon.svg',
    svg: 'user',
    accessGroups: [
      UserGroup.Super,
      UserGroup.Providers,
      UserGroup.CCM,
      UserGroup.Administrators
    ],
    isDisplayed: true
  },
  {
    name: 'Patient Charts',
    path: 'patient-charts',
    icon: 'k-i-user',
    src: 'patients-icon.svg',
    svg: 'user',
    accessGroups: [
      UserGroup.Super,
      UserGroup.Providers,
      UserGroup.CCM,
      UserGroup.Administrators
    ],
    isDisplayed: true
  },
  {
    name: 'Patient Assessments',
    path: /patient\/[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\/assessments/gi,
    icon: 'k-i-user',
    src: 'patients-icon.svg',
    svg: 'user',
    accessGroups: [
      UserGroup.Super,
      UserGroup.Providers,
      UserGroup.CCM,
      UserGroup.Administrators
    ],
    isDisplayed: true
  },
  {
    name: 'Assessments',
    path: 'assessments',
    icon: 'k-i-paste-plain-text',
    src: 'assessments-icon.svg',
    svg: 'clipboardText',
    accessGroups: [
      UserGroup.Super,
      UserGroup.Providers,
      UserGroup.CCM,
      UserGroup.Administrators
    ],
    isDisplayed: true
  },
  {
    name: 'Remote Patient Monitoring',
    short: 'RPM',
    path: 'remote-patient-monitoring',
    icon: 'k-i-preview',
    src: 'rpm-icon.svg',
    svg: 'eye',
    accessGroups: [UserGroup.Super, UserGroup.Providers, UserGroup.CCM],
    isDisplayed: true
  },
  {
    name: 'Telehealth',
    path: 'telehealth',
    icon: 'k-i-grid',
    src: 'telehealth-icon.svg',
    svg: 'grid',
    accessGroups: [
      UserGroup.Super,
      UserGroup.Providers,
      UserGroup.CCM,
      UserGroup.Administrators,
      UserGroup.Billing
    ],
    isDisplayed: true
  },
  {
    name: 'Treatment Action Plans',
    short: 'TAPs',
    path: 'treatment-action-plans',
    icon: 'k-i-calendar',
    src: 'taps-icon.svg',
    svg: 'calendar',
    accessGroups: [UserGroup.Super, UserGroup.Providers, UserGroup.CCM],
    isDisplayed: true
  },
  {
    name: 'Billing',
    path: 'billing',
    icon: 'k-i-calculator',
    svg: 'calculator',
    src: 'billing-icon2.svg',
    accessGroups: [UserGroup.Super, UserGroup.Billing],
    isDisplayed: true
  },
  {
    name: 'Resources',
    path: 'resources',
    icon: 'k-i-user',
    svg: 'lock',
    src: 'users-icon.svg',
    accessGroups: [UserGroup.Super, UserGroup.Administrators],
    isDisplayed: true
  },
  {
    name: 'Scheduling',
    path: 'scheduling',
    icon: 'k-i-clock',
    svg: 'clock',
    src: 'schedule-icon.svg',
    accessGroups: [
      UserGroup.Super,
      UserGroup.Providers,
      UserGroup.CCM,
      UserGroup.Administrators
    ],
    isDisplayed: true
  },
  {
    name: 'Account Settings',
    short: 'Settings',
    path: 'account-settings',
    icon: 'k-i-gears',
    src: 'settings-icon.svg',
    svg: 'gears',
    accessGroups: [UserGroup.Super, UserGroup.CCM, UserGroup.Providers],
    isDisplayed: true
  },
  {
    name: 'FAQ',
    path: 'faq',
    icon: 'k-i-information',
    svg: 'infoCircle',
    src: 'faq-icon.svg',
    accessGroups: [
      UserGroup.Super,
      UserGroup.Providers,
      UserGroup.CCM,
      UserGroup.Administrators,
      UserGroup.Billing
    ],
    isDisplayed: true
  }
]
const getDashRouteByName = (name: string): DashRoute => {
  return dashRoutes.find(r => r.name === name)
}

const getDashRouteByPath = (path: string): DashRoute => {
  return dashRoutes.find(r => r.path === path)
}

const mapGroupNameToUserType = (groupName: string): ConsoleUser => {
  return {
    Administrators: ConsoleUser.Administrator,
    Billing: ConsoleUser.Biller,
    CCM: ConsoleUser.CCM,
    Nurses: ConsoleUser.Nurse,
    Providers: ConsoleUser.Provider,
    Representative: ConsoleUser.Representative,
    Super: ConsoleUser.Super
  }[groupName]
}

const getPrecedence = (group: UserGroup): number => {
  switch (group) {
    case UserGroup.Super:
      return 5
    case UserGroup.Administrators:
      return 4
    case UserGroup.CCM:
      return 3
    case UserGroup.Providers:
      return 2
    case UserGroup.Billing:
      return 1
    default:
      return 0
  }
}

const accessFromGroups = (userGroups: Array<UserGroup>): Access => {
  if (userGroups.length === 0) {
    return {
      name: 'No Access',
      dashRoutes: [],
      userGroups: []
    }
  }

  const sortedGroups = userGroups.sort((a, b): number => {
    return getPrecedence(a) - getPrecedence(b)
  })
  const mainGroup = sortedGroups[0]
  const name = mapGroupNameToUserType(mainGroup.toString())
  return {
    name,
    dashRoutes: dashRoutes.filter(r => {
      // Using the master list of routes, go through each one of them
      return !!r.accessGroups.filter(g => {
        return userGroups.includes(g)
      }).length
    }),
    userGroups
  }
}

const isPathAccessible = (
  accessGroups: Array<UserGroup>,
  path: string
): boolean => {
  const access = accessFromGroups(accessGroups)
  const dashRoute = access.dashRoutes.find((r: DashRoute) => {
    if (typeof r.path === 'string') {
      return r.path === path
    } else if (r.path instanceof RegExp) {
      return (r.path as RegExp).test(path)
    } else {
      return false
    }
  })
  return !!dashRoute
}

export {
  dashRoutes,
  isPathAccessible,
  accessFromGroups,
  getPrecedence,
  mapGroupNameToUserType,
  getDashRouteByPath,
  getDashRouteByName
}
