class StringHelper {
  stringIsNumber(value): boolean {
    return isNaN(Number(value)) === false;
  }

  enumToArray(input): Array<any> {
    return Object.keys(input)
      .map(key => input[key]);
  }
}

const instance = new StringHelper();

export default instance;
