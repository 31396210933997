import { Component, OnDestroy, OnInit } from '@angular/core'
import { APIService, UserType } from '../API.service'
import { AccountService } from '../services/account.service'
import { SessionService } from '../services/session.service'
import { Observable, Subject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { OfficeFilterService } from '../services/office-filter.service'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  accounts = []
  users = []
  locations = []

  userTypes = [
    UserType.PROVIDER,
    UserType.ADMINISTRATION,
    UserType.BILLING,
    UserType.NURSE,
    UserType.CCM
  ]
  emailInput = 'jason.wong+user@qwantegy.com'
  passwordInput = 'Password123'
  firstNameInput = 'Jason'
  lastNameInput = 'Wong'
  phoneInput = '+10000000'
  userTypeInput = UserType.PROVIDER
  registrationDialogOpen = false
  usersAccountFilter = undefined

  searchFilter = ''
  searchFilterChanged: Subject<string> = new Subject<string>()
  searchFilterChangedSubscription: Subscription

  accountId = null
  accountObservable: Subscription
  currentAccount
  locationsInput = []

  locationsSub: Subscription

  constructor(
    private accountsService: AccountService,
    private apiService: APIService,
    private sessionService: SessionService,
    private officeFilterService: OfficeFilterService
  ) {}

  async ngOnInit(): Promise<void> {
    // await this.refreshUsers()
    // await this.refreshAccounts()

    // @ts-ignore
    this.accountObservable = this.officeFilterService.selectedLocation$.subscribe(
      val => {
        console.log('account changed', val)
        if (val) {
          this.refreshUsers()
        }
      }
    )

    this.locationsSub = this.officeFilterService.locationSelection$.subscribe(
      val => {
        console.log('Location Selection changed', val)
        if (val) {
          this.locations = val
        }
      }
    )
  }

  ngOnDestroy(): void {
    if (this.accountObservable) {
      this.accountObservable.unsubscribe()
      this.accountObservable = null
    }
  }

  openRegistration() {
    this.registrationDialogOpen = true
    this.currentAccount = this.officeFilterService.selectedAccount$.getValue()
  }

  closeRegistration() {
    this.registrationDialogOpen = false
  }

  isSuper$(): Observable<boolean> {
    return this.sessionService.userType$.pipe(map(v => v === 'Super'))
  }

  async refreshUsers() {
    const accountId = this.officeFilterService.selectedAccount$.getValue().id
    const locationId = this.officeFilterService.selectedLocation$.getValue().id

    const { items } = await this.apiService.ListUserLocationConnections({
      accountId: { eq: accountId },
      locationId: { eq: locationId }
    })

    this.users = items.map(item => item.user)

    console.log('Reloaded users', this.users)
  }

  async createAndCloseRegistration() {
    const result = await this.apiService.AddUserAccount({
      email: this.emailInput,
      password: this.passwordInput,
      phone: this.phoneInput,
      accountId: this.currentAccount.id,
      userType: this.userTypeInput,
      firstName: this.firstNameInput,
      lastName: this.lastNameInput,
      locations: this.locationsInput // Array of locationIds
    })
    console.log('Signing up', result)
    this.resetFieldInputs()
    this.closeRegistration()
    await this.refreshUsers()
  }

  resetFieldInputs() {
    this.emailInput = ''
    this.passwordInput = ''
    this.phoneInput = ''
    this.firstNameInput = ''
    this.lastNameInput = ''
    this.locationsInput = []
  }

  onValueChange(event) {
    this.locationsInput = event.map(e => e.id)
    console.log(this.locationsInput)
  }
}
