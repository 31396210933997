<div class="bres-panel bres-panel-info">
    <div class="bres-panel-content">
        <div class="bres-panel-title"><i class="k-icon k-i-file-txt"></i>&nbsp;Notes</div>
        <div class="bres-panel-body">
            <kendo-grid
                [data]="gridView"
                [pageSize]="pageSize"
                [skip]="skip"
                [pageable]="true"
                [height]="400"
                (pageChange)="pageChange($event)">
                    <ng-template kendoGridToolbarTemplate>
                        <div class="p-10">
                            <kendo-textbox class="full-width" placeholder="Search" [clearButton]="true"></kendo-textbox>
                        </div>
                    </ng-template>
                    <!-- <kendo-grid-column [width]="50" field="id" title="">
                        <ng-template kendoGridCellTemplate  let-column let-columnIndex="columnIndex">
                            <div class="text-center">
                                <kendo-icon [name]="'plus'" [themeColor]="'success'"></kendo-icon>
                            </div>
                        </ng-template>
                    </kendo-grid-column> -->
                    <div *kendoGridDetailTemplate="let dataItem">
                        detail...
                    </div>
                    <kendo-grid-column field="date" title="Date"></kendo-grid-column>
                    <kendo-grid-column field="note" title="Note"></kendo-grid-column>
                    <kendo-grid-column field="tag" title="Tag"></kendo-grid-column>
                    <kendo-grid-column field="by" title="By"></kendo-grid-column>
            </kendo-grid>
            
        </div>
    </div>
</div>