<div class="dashboard" fxLayout="row" fxFlexFill>
  <div class="dash" fxFlex fxLayout="column">
    <div class="spacer" fxFlex="3"></div>
    <span class="dashboard heading">Current Metrics</span>
    <app-quicklook-section class="dash-section"></app-quicklook-section>
    <div class="spacer" fxFlex="4"></div>
    <span class="dashboard heading">Actions</span>
    <app-resource-section  class="dash-section"></app-resource-section>
    <div class="spacer" fxFlex="3"></div>
  </div>
  <div class="activities-section" fxFlex="24" fxLayout="column" fxHide fxShow.gt-md>
    <div class="spacer" fxFlex="4"></div>
    <kendo-panelbar style="margin-right:16px;">
      <kendo-panelbar-item [title]="'Active Users'" expanded="true">
        <ng-template kendoPanelBarContent>
          <app-active-users-section class="dash-section"></app-active-users-section>
        </ng-template>
      </kendo-panelbar-item>
      <kendo-panelbar-item [title]="'Trends'" expanded="true">
        <ng-template kendoPanelBarContent>
          <app-trends-section class="dash-section"></app-trends-section>
        </ng-template>
      </kendo-panelbar-item>
      <kendo-panelbar-item [title]="'Recent Activity'">
        <ng-template kendoPanelBarContent>
          <app-recent-activity-section class="dash-section"></app-recent-activity-section>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
  </div>
</div>
