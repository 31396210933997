<div class="score-completion" fxFlexFill fxLayout="row">
  <div fxFlex *ngIf="parts.length" fxLayout="row" fxLayoutGap="10px">
    <div class="question-part" fxLayoutAlign="start center" *ngFor="let part of parts" [ngSwitch]="part.type">
      <div fxFlex fxLayoutAlign="center center" *ngSwitchCase="'text'">
        <span>{{part.data}}</span>
      </div>
      <kendo-textbox [fxFlex]="part.width" *ngSwitchCase="'input'" [(ngModel)]="inputs[part.data]" [disabled]='true' [placeholder]="part.placeholder" [clearButton]="true"></kendo-textbox>
    </div>
  </div>
</div>
