import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-patient-charts-assessment',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss']
})
export class PatientChartAssessmentsComponent implements OnInit {

  @Input() item;

  constructor() { }

  ngOnInit(): void {
  }

}
