import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { NavigationStart, Router } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { Auth as auth } from '@aws-amplify/auth/lib-esm/Auth'
import { getDashRouteByPath, mapGroupNameToUserType } from '../shared/mappings'
import { ConsoleUser, UserGroup } from '../shared/types/types'
import { APIService, GetUserQuery } from '../API.service'

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private loadAuthSub: Subscription

  // Keep a set of observable properties of the session that watches properties
  jwtToken$: BehaviorSubject<string>
  email$: BehaviorSubject<string>
  phoneNumber$: BehaviorSubject<string>
  userType$: BehaviorSubject<ConsoleUser>
  accessGroups$: BehaviorSubject<Array<UserGroup>>
  loggedIn$: BehaviorSubject<boolean>
  loadedSession$: BehaviorSubject<boolean>
  currentRoute$: BehaviorSubject<string>
  currentPageName$: Observable<string>
  userId$: BehaviorSubject<string>
  currentUser$: BehaviorSubject<GetUserQuery>

  constructor(private router: Router, private apiService: APIService) {
    this.currentRoute$ = new BehaviorSubject<string>(null)
    this.currentPageName$ = this.currentRoute$.pipe(
      map((p): string => {
        const path = p.substring(1)
        const dashRoute = getDashRouteByPath(path)
        if (dashRoute) {
          return dashRoute.name
        } else {
          return null
        }
      })
    )
    this.jwtToken$ = new BehaviorSubject<string>(null)
    this.email$ = new BehaviorSubject<string>(null)
    this.phoneNumber$ = new BehaviorSubject<string>(null)
    // @ts-ignore
    this.accessGroups$ = new BehaviorSubject<Array<UserGroup>>([])
    this.userType$ = new BehaviorSubject<ConsoleUser>(null)
    this.loggedIn$ = new BehaviorSubject<boolean>(false)
    this.loadedSession$ = new BehaviorSubject<boolean>(false)
    this.userId$ = new BehaviorSubject<string>(null)
    this.currentUser$ = new BehaviorSubject<GetUserQuery>(null)

    this.loadSession()
  }

  ngOnDestroy(): void {
    this.loadAuthSub.unsubscribe()
    this.jwtToken$.unsubscribe()
    this.email$.unsubscribe()
    this.phoneNumber$.unsubscribe()
    this.accessGroups$.unsubscribe()
    this.userType$.unsubscribe()
    this.loggedIn$.unsubscribe()
    this.loadedSession$.unsubscribe()
    this.currentRoute$.unsubscribe()
    this.userId$.unsubscribe()
    this.currentUser$.unsubscribe()
  }

  async ngOnInit(): Promise<void> {
    await this.loadSession()

    this.loadAuthSub = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(async event => {
        await this.loadSession()
      })
  }

  /***
   * Loads the auth session onto observables
   */
  async loadSession(): Promise<any> {
    try {
      const session = await auth.currentSession()
      const info = await auth.currentUserInfo()
      const jwtToken = session.getAccessToken().getJwtToken()
      const idPayload = session.getIdToken().decodePayload()
      console.log('Decoding id tone: ', idPayload)
      const email = idPayload['email']
      const phoneNumber = idPayload['phone_number']
      const id = idPayload['sub']
      console.log(id)
      const accessGroups =
        session.getAccessToken().decodePayload()['cognito:groups'] || []
      const userType = this.asUserType(accessGroups[0] || null)
      const loggedIn = session.isValid() && !!info
      console.log('Loading Session', {
        loggedIn,
        accessGroups
        // info,
        // session,
        // jwtToken,
        // email,
        // phoneNumber,
        // accessGroups,
        // userType,
        // loggedIn
      })
      this.jwtToken$.next(jwtToken)
      this.email$.next(email)
      this.phoneNumber$.next(phoneNumber)
      this.accessGroups$.next(accessGroups)
      this.userType$.next(userType)
      this.loggedIn$.next(loggedIn)
      this.userId$.next(id)
      const user: GetUserQuery = await this.apiService.GetUser(id)

      console.log('Loading currentUser', user)

      this.currentUser$.next(user)
    } catch (err) {
      // When there's an error, it is probably caused by no auth user
      console.log('Failed at Loading Session', err)
      this.jwtToken$.next(null)
      this.email$.next(null)
      this.phoneNumber$.next(null)
      // @ts-ignore
      this.accessGroups$.next([])
      this.loggedIn$.next(false)
      this.userId$.next(null)
      this.currentUser$.next(null)
    } finally {
      this.loadedSession$.next(true)
    }
  }

  // Convert group name to a userType format
  asUserType(groupName: string): ConsoleUser {
    const userType = mapGroupNameToUserType(groupName)
    if (!userType) {
      // UNSUPPORTED USER TYPE
      alert('Unsupported User Type. Please Contact Administrator.')
      auth.signOut().then(() => {
        this.router.navigate(['login'])
      })
    }
    return userType
  }
}
