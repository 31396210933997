<div class="remotes" fxLayout="column" fxFlexFill>
  <div class="spacer" fxFlex="3"></div>
  <div fxLayout="row" fxFlex>
    <div fxFlex fxLayout="column">
      <div class="remotes-list" fxFlex fxLayout="column">
        <kendo-grid fxFlexFill [data]="remotes" [height]="410">
          <ng-template kendoGridToolbarTemplate>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header">
              <h6>Users Management</h6>
              <kendo-buttongroup [selection]="'single'" fxFlex="20" fxLayout="row">
                <button kendoButton fxFlex [toggleable]="true" [selected]="true">All</button>
                <button kendoButton fxFlex [toggleable]="true">Scheduled</button>
                <button kendoButton fxFlex [toggleable]="true">Eligible</button>
              </kendo-buttongroup>
            </div>
          </ng-template>
          <kendo-grid-column field="name" title="Name" width="75"></kendo-grid-column>
          <kendo-grid-column field="date" title="Date" width="50"></kendo-grid-column>
          <kendo-grid-column field="status" title="Call Status" width="75"></kendo-grid-column>
          <kendo-grid-column field="rpm" title="RPM" width="50"></kendo-grid-column>
          <kendo-grid-column field="lastRpmDate" title="Last Test" width="50"></kendo-grid-column>
          <kendo-grid-column field="call" title="Call" width="40">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton [iconClass]="'fa fa-phone'">
              </button>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
    <div class="spacer" fxFlex="3"></div>
    <div fxFlex="30" fxLayout="column">
      <kendo-tabstrip fxFlex>
        <kendo-tabstrip-tab [title]="'Calls'" [selected]="true">
          <ng-template kendoTabContent>
            <div fxLayout="column" fxFill>
              <div *ngFor="let call of calls" class="call-item" fxFlex="10" fxLayout="row">
                <div fxFlex fxLayoutAlign="start center">{{call.name}}</div>
                <div fxFlex="30" fxLayoutAlign="center center">{{call.date}}</div>
                <div fxFlex="15" fxLayoutAlign="center center">{{call.elapsed}}</div>
                <div fxFlex="15" fxLayoutAlign="end center">
                  <button kendoButton [iconClass]="'fa fa-phone'"></button>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Voicemail'" [selected]="false">
          <ng-template kendoTabContent>
            <div fxLayout="column" fxFill>
              <div *ngFor="let voiceMail of voiceMails" class="call-item" fxFlex="10" fxLayout="row">
                <div fxFlex fxLayoutAlign="start center">{{voiceMail.name}}</div>
                <div fxFlex="30" fxLayoutAlign="center center">{{voiceMail.date}}</div>
                <div fxFlex="15" fxLayoutAlign="center center">{{voiceMail.elapsed}}</div>
                <div fxFlex="15" fxLayoutAlign="end center">
                  <button kendoButton [iconClass]="'fa fa-voicemail'"></button>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'SMS'" [selected]="false">
          <ng-template kendoTabContent>
            <div fxLayout="column" fxFill>
              <div *ngFor="let message of messages" class="call-item" fxFlex="10" fxLayout="row">
                <div fxFlex fxLayoutAlign="start center">{{message.name}}</div>
                <div fxFlex="30" fxLayoutAlign="center center">{{message.date}}</div>
                <div fxFlex="15" fxLayoutAlign="center center">{{message.elapsed}}</div>
                <div fxFlex="15" fxLayoutAlign="end center">
                  <button kendoButton [iconClass]="'fa fa-envelope'"></button>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
  <div class="spacer" fxFlex="3"></div>
</div>
