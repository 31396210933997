import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patients-detail',
  templateUrl: './patients-detail.component.html',
  styleUrls: ['./patients-detail.component.scss']
})
export class PatientsDetailComponent implements OnInit {

  patientDetail = {
    name: 'Parker Alan',
    dob: 'Sep 8, 1964',
    ss: '',
    age: '56',
    id: 'AB33-PA00014',
    type: 'Commerical',
    lastTest: '2 days ago'
  }

  currentTab = 'timeline';

  constructor() { }

  ngOnInit(): void {
  }

  onChangeTab(tabName) {
    this.currentTab = tabName;
  }

}
