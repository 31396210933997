import { Component, Input, OnInit } from '@angular/core'
import {
  SoapItemType,
  GetAssessmentQuery,
  GetEncounterQuery,
  APIService
} from 'src/app/API.service'
@Component({
  selector: 'app-encounter-questionnaires',
  templateUrl: './encounter-questionnaires.component.html',
  styleUrls: ['./encounter-questionnaires.component.scss']
})
export class EncounterQuestionnairesComponent implements OnInit {
  @Input('soapItems') soapItems: Array<{
    __typename: 'SoapItem'
    type: SoapItemType | null
    text: string | null
    code: string | null
    selected: boolean | null
  }>
  @Input('encounter') encounter: GetEncounterQuery
  constructor(private apiService: APIService) {}

  async ngOnInit(): Promise<void> {}
}
