import { Component, OnInit } from '@angular/core'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { SchedulerEvent } from '@progress/kendo-angular-scheduler'
import { PageChangeEvent } from '@progress/kendo-angular-dropdowns/dist/es2015/common/page-change-event'

@Component({
  selector: 'app-patient-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.scss']
})
export class TreatmentsComponent implements OnInit {
  events: SchedulerEvent[] = []
  eventResources: any[] = []
  public selectedDate: Date = new Date('2021-02-21T00:00:00')

  public gridView: GridDataResult
  public pageSize = 10
  public skip = 0
  private data: Object[]

  private items: any[] = []

  constructor() {}

  ngOnInit(): void {
    this.events.push({
      id: 123,
      start: new Date('2021-02-21T13:00:00Z'),
      end: new Date('2021-02-21T13:30:00Z'),
      isAllDay: true,
      title: 'Sample event',
      description: 'Sample description'
    } as SchedulerEvent)
    for (let i = 1; i < 23; i++) {
      this.items.push({
        id: i,
        date: 'Nov 20',
        note:
          'John Does completed all assessments without any complaints. He appeared to be in a happy mood'
      })
    }
    this.loadItems()
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip
    this.loadItems()
  }

  private loadItems(): void {
    this.gridView = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    }
  }
}
