import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { APIService, InvoiceStatus } from '../API.service'
import { SessionService } from '../services/session.service'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { DomSanitizer, SafeStyle } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { OfficeFilterService } from '../services/office-filter.service'

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy {
  accounts = []
  locations = []
  currentDate = new Date().toString()

  registrationDialogOpen = false

  // Input fields
  currentAccount
  nameInput = ''

  accountSub: Subscription

  constructor(
    private apiService: APIService,
    private sessionService: SessionService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private officeFilterService: OfficeFilterService
  ) {}

  async ngOnInit(): Promise<void> {
    // @ts-ignore
    this.accountSub = this.officeFilterService.selectedAccount$.subscribe(
      account => {
        if (account) this.refreshLocations()
      }
    )
  }

  ngOnDestroy(): void {
    if (this.accountSub) this.accountSub.unsubscribe()
  }

  openRegistration() {
    this.registrationDialogOpen = true
    this.currentAccount = this.officeFilterService.selectedAccount$.getValue()
  }

  closeRegistration() {
    this.registrationDialogOpen = false
  }

  isSuper$(): Observable<boolean> {
    return this.sessionService.userType$.pipe(map(v => v === 'Super'))
  }

  async refreshLocations() {
    const account = this.officeFilterService.selectedAccount$.getValue()

    const { items: locations } = await this.apiService.ListLocations({
      accountId: { eq: account.id }
    })

    console.log('Locations Found', locations)

    // @ts-ignore
    this.locations = locations.map(location => {
      // @ts-ignore
      location.assessmentsCount = (location.assessments || []).length
      return location
    })
  }

  clearInputFields() {
    this.nameInput = ''
  }

  async createLocation() {
    const result = await this.apiService.CreateLocation({
      accountId: this.currentAccount.id,
      name: this.nameInput,
      owner: this.sessionService.userId$.getValue()
    })
    this.clearInputFields()
    this.closeRegistration()
    await this.refreshLocations()
    this.officeFilterService.refreshLocations()
  }

  selectionChange(grid, selection) {
    const selectedData = selection.selectedRows[0].dataItem
    this.router.navigate(['/location', selectedData.id])
  }

  invoiceStatusColor(status: string): SafeStyle {
    const colors = {
      [InvoiceStatus.DUE]: '#F6E8E8',
      [InvoiceStatus.PAID]: '#e0ffe6',
      NOT_ACTIVE: '#cacaca'
    }
    return this.sanitizer.bypassSecurityTrustStyle(colors[status])
  }
}
