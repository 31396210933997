<div style="padding:10px 30px 0 50px;display:flex;flex-direction:row;justify-content: space-between;align-items: center;" >

  <kendo-textbox *ngIf="(loggedIn$() | async)" placeholder="Search" class="search-box">
    <ng-template kendoTextBoxPrefixTemplate>
      <kendo-icon [name]="'search'"></kendo-icon>
    </ng-template>
  </kendo-textbox>

  <div class="billing-timer" fxFLex="100px" fxLayout="column" *ngIf="timerService.showTimer$ | async">
    <div>Billed Time</div>
    <div>{{ timerService.timerDisplay | async }}</div>
  </div>

  <div style="display:flex;flex-direction: row;justify-content: flex-start;align-items: center;" *ngIf="(loggedIn$() | async)">
    <div class="management context" *ngIf="accounts!=null" style="display:flex;flex-direction: row;justify-content: flex-start;align-items: center;">
      <span fxHide fxShow.gt-sm>Account</span>
      <kendo-dropdownlist class="ddl ddl-40" [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" [(value)]="selectedAccountId" [data]="accounts" (valueChange)="onAccountSelection($event)"></kendo-dropdownlist>
    </div>
    <div class="management context" *ngIf="locations!=null" style="display:flex;flex-direction: row;justify-content: flex-start;align-items: center;">
      <span fxHide fxShow.gt-sm>Location</span>
      <kendo-dropdownlist class="ddl ddl-40" [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" [(value)]="selectedLocationId" [data]="locations" (valueChange)="onLocationSelection($event)"></kendo-dropdownlist>
    </div>
  </div>
    <div *ngIf="(loggedIn$() | async)" class="identity"  fxHide fxShow.gt-sm>
      <div style="text-align:right;display:flex;flex-direction: column;justify-content: flex-start;margin-right:10px;">
        <h3>{{ ((user$() | async) || {firstName:""}).firstName || (username$() | async) | titlecase }} {{ ((user$() | async) || {lastName:""}).lastName }}</h3>
        <h4>{{ (userType$() | async) }}</h4>
      </div>
      <kendo-icon [name]="'user'" style="width:40px;height:40px;font-size:20px;display:flex;justify-content:center;align-items:center;background:lightgray;color:white;border-radius: 20px;"></kendo-icon>
    </div>
</div>
