import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-trends-section',
  templateUrl: './trends-section.component.html',
  styleUrls: ['./trends-section.component.scss']
})
export class TrendsSectionComponent implements OnInit {
  trends = [
    {
      title: 'Top Office',
      name: 'Interventional Pain Solutions (CA)'
    },
    {
      title: 'Top In-Office CCM',
      name: 'George Clifford',
      details: 'Interventional Pain Solutions (CA)'
    },
    {
      title: 'Top Telehealth CCM',
      name: 'Jane Doe',
      details: 'Associated Pain Specialist (TN)'
    }
  ]
  constructor() {}

  ngOnInit(): void {}
}
