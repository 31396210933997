import { Pipe, PipeTransform } from '@angular/core';
import { format as formatDate, formatDistanceToNow } from 'date-fns'

@Pipe({name: 'customDate'})
export class CustomDatePipe implements PipeTransform {
  transform(value: string, distanceFromNow?: boolean, format: string = 'dd/MM/yyyy'): string {
    if(distanceFromNow) {
      return formatDistanceToNow(new Date(value), { addSuffix: true });
    }
    return formatDate(new Date(value), format);
  }
}
