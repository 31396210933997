<div class="resources has-loader" fxLayout="row wrap" fxLayoutAlign="start start">
  <kendo-grid #grid fxFlexFill [data]="resources" style="height: auto">
    <kendo-grid-column field="type" title="" [width]="40">
      <ng-template kendoGridCellTemplate let-dataItem>
        <i [ngStyle]="{'color': resourceCategoryColor[dataItem.type]}" class="fa fa-circle"></i>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="name" title="Name"></kendo-grid-column>
    <kendo-grid-column field="website" title="Website"></kendo-grid-column>
    <kendo-grid-column field="address" title="Address">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.address.line1}}, {{dataItem.address.region}}, {{dataItem.address.country}}, {{dataItem.address.postal}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="contact" title="Contact">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.contact.givenName}} {{dataItem.contact.familyName}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Action" width="180" *ngIf="deletable || editable">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button *ngIf="editable" kendoGridEditCommand class="k-primary" (click)="editItem(dataItem)">Edit</button>
        <button *ngIf="deletable" kendoGridRemoveCommand (click)="deleteItem(dataItem)">Remove</button>
      </ng-template>
    </kendo-grid-command-column>
    <div *kendoGridDetailTemplate="let dataItem">
      <app-resource-scheduled-events
        [resource]="dataItem"
        [deletable]="deletable"
        [editable]="editable"
        [creatable]="creatable"
        (onCreate)="onEventCreate()"
        (onEdit)="onEventEdit()"
        (onDelete)="onEventDelete()"
      ></app-resource-scheduled-events>
    </div>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>

<kendo-dialog title="Remove Resource" [width]="600" *ngIf="showRemoveResourceModal" (close)="closeRemoveResourceModal()">
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      Are you sure you want to remove resource <b class="d-contents">{{selectedItem.name}}</b>?
    </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeRemoveResourceModal()" [disabled]="loading" type="button">Cancel</button>
    <button kendoButton primary="true" [disabled]="loading" type="button" (click)="removeResourceAndCloseModal()">Yes</button>
  </kendo-dialog-actions>
</kendo-dialog>


<kendo-dialog title="New Resource" [width]="600" [height]="700" *ngIf="createResource" (close)="closeNewResource()">
  <form [formGroup]="newResourceForm" (ngSubmit)="createAndCloseNewResourceModal()">
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <h5 class="h5-dark-blue bottom-line">Business Type</h5>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Type</div>
        <kendo-dropdownlist
          [disabled]="loading"
          fxFlex
          formControlName="businessTypeIdInput"
          [data]="typesList"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
        >
        </kendo-dropdownlist>
      </div>
      <h5 class="h5-dark-blue bottom-line">Business Information</h5>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Business Name</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Business name" formControlName="businessNameInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Street address</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Street address" formControlName="businessAddressInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Suite</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Suite #" formControlName="businessSuiteInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">City</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="City" formControlName="businessCityInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Country</div>
        <kendo-dropdownlist
          fxFlex
          [disabled]="loading"
          formControlName="businessCountryInput"
          [data]="countriesList"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true"
        >
        </kendo-dropdownlist>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Zipcode</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Zipcode" formControlName="businessZipCodeInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Website</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Website" formControlName="businessWebsiteInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Business hours</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Business hours" formControlName="businessHoursInput"></kendo-textbox>
      </div>
      <h5 class="h5-dark-blue bottom-line">Contact Information</h5>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Family name</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Family name" formControlName="contactFirstNameInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Given name</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Given name" formControlName="contactLastNameInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Phone</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Phone" formControlName="contactPhoneInput"></kendo-textbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">Email address</div>
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Email address" formControlName="contactEmailAddressInput"></kendo-textbox>
      </div>
      <h5 class="h5-dark-blue bottom-line">Time spent</h5>
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <kendo-textbox fxFlex [disabled]="loading" placeholder="Time spent" formControlName="timeSpentInput" style="width: 100%">
          <ng-template kendoTextBoxPrefixTemplate>
            <kendo-icon [name]="'clock'"></kendo-icon>
          </ng-template>
          <ng-template kendoTextBoxSuffixTemplate>
            <span>
              Minutes
            </span>
          </ng-template>
        </kendo-textbox>
      </div>

      <kendo-dialog-actions [layout]="'normal'">
        <button kendoButton (click)="closeNewResource()" [disabled]="loading" type="button">Cancel</button>
        <button kendoButton primary="true" [disabled]="loading" type="submit">{{editResource ? 'Update' : 'Create'}}</button>
      </kendo-dialog-actions>
    </div>
  </form>
</kendo-dialog>

