import { Component, Input, OnInit } from '@angular/core'
import { GetEncounterQuery, BilledTimeType, APIService } from '../API.service'
import {
  WriteUpContext,
  WriteUpQuestionnaire,
  WriteUpSection
} from './write-up.type'
import { WriteUpService } from './write-up.service'
import { neuropsychologicalWriteup } from './sample/neuropsychological.writeup'
import { BehaviorSubject } from 'rxjs'
import { SessionService } from '../services/session.service'
import { BillingTimerService } from '../services/billing-timer.service'
import { TestApiService } from '../testing'

@Component({
  selector: 'app-write-up',
  templateUrl: './write-up.component.html',
  styleUrls: ['./write-up.component.scss']
})
export class WriteUpComponent implements OnInit {
  @Input('encounter') selectedEncounter: GetEncounterQuery
  questionnaire: WriteUpQuestionnaire
  context: WriteUpContext

  public current = 0
  public currentSection$: BehaviorSubject<WriteUpSection>
  public steps = []
  public qualifiedSections: Array<WriteUpSection> = []

  constructor(
    private writeUpService: WriteUpService,
    private sessionService: SessionService,
    private billingTimerService: BillingTimerService,
    private apiService: APIService
  ) {
    this.currentSection$ = new BehaviorSubject<WriteUpSection>(null)
  }

  ngOnInit(): void {
    this.billingTimerService.startTimer()

    this.sessionService.currentUser$.subscribe(async currentUser => {
      if (currentUser) {
        this.context = this.writeUpService.getWriteUpContext()
        console.log(
          'WriteUpContext is',
          this.context,
          this.selectedEncounter.id
        )

        const generatedQuestionnaires = await this.apiService.GenerateEncounterQuestionnaires(
          this.selectedEncounter.id
        )

        console.log('Generated Questionnaires', generatedQuestionnaires)

        this.questionnaire = this.writeUpService.normalizeWriteUpQuestionnaire(
          neuropsychologicalWriteup
        )
        this.qualifiedSections = this.questionnaire.sections.filter(section => {
          return this.showSection(section)
        })
        this.steps = this.qualifiedSections.map(s => {
          return { label: s.name, disabled: true }
        })
        this.current = 0
        this.currentSection$.next(this.qualifiedSections[this.current])
      }
    })
  }

  nextSection() {
    if (this.hasNextSection()) {
      this.current += 1
      this.currentSection$.next(this.qualifiedSections[this.current])
      console.log('Current Questionnaire', this.questionnaire)
    }
  }

  hasNextSection() {
    return this.current < this.qualifiedSections.length - 1
  }

  previousSection() {
    if (this.hasPreviousSection()) {
      this.current -= 1
      this.currentSection$.next(this.qualifiedSections[this.current])
    }
    console.log('Current Questionnaire', this.questionnaire)
  }

  hasPreviousSection() {
    return this.current > 0
  }

  showSection(section: WriteUpSection): boolean {
    return this.writeUpService.isSectionVisible(section, this.context)
  }

  async saveWriteUp() {
    const currentUser = this.sessionService.currentUser$.getValue()
    console.log(
      this.writeUpService.isQuestionnaireValid(this.questionnaire, this.context)
    )

    // In this section we need to bill this time
    const timerInfo = this.billingTimerService.stopTimer()

    const billedTime = await this.apiService.CreateBilledTime({
      date: timerInfo.start,
      type: BilledTimeType.ADMINISTRATIVE,
      minutes: timerInfo.billedMinutes,
      userId: currentUser.id
    })

    console.log('Created BilledTime', billedTime)

    const encounterBilledTimeConnection = await this.apiService.CreateEncounterBilledTimeConnection(
      {
        accountId: currentUser.accountId,
        billedTimeId: billedTime.id,
        encounterId: this.selectedEncounter.id
      }
    )
    console.log(
      'Created Encounter BilledTime Connection',
      encounterBilledTimeConnection
    )
  }
}
