import { Component, OnInit } from '@angular/core'
import {
  APIService,
  GetEncounterQuery,
  ScoreGauge,
  GetAssessmentQuery
} from '../API.service'
import { OfficeFilterService } from '../services/office-filter.service'
import { Router } from '@angular/router'
import { AssessmentSessionService } from '../testing/services/assessment-session.service'

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss']
})
export class AssessmentsComponent implements OnInit {
  assessments = [
    {
      date: '06/04/2020',
      patient: 'Tamera Zuniga',
      pretest: {
        status: 'Qualified',
        date: '06/04/20'
      },
      assessments: [
        { name: 'CESD-R', status: 'Eligible' },
        { name: 'PHQ-9', status: 'NotEligible' },
        { name: 'PMQ-R', status: 'Eligible' },
        { name: 'DAST-10', status: 'Eligible' },
        { name: 'CAGE', status: 'Waiting' },
        { name: 'GP-COG', status: 'Eligible' }
      ],
      ccm: 'Harry Cricket'
    },
    {
      date: '04/15/2019',
      patient: 'Herbert Vaughn',
      pretest: {
        status: 'Qualified',
        date: null
      },
      assessments: [
        { name: 'CESD-R', status: 'Eligible' },
        { name: 'DAST-10', status: 'NotEligible' },
        { name: 'CAGE', status: 'Waiting' },
        { name: 'GP-COG', status: 'NotEligible' }
      ],
      ccm: 'Carrie Yu'
    },
    {
      date: '04/15/2019',
      patient: 'Justin Hertz',
      pretest: {
        status: 'NotQualified',
        date: null
      },
      assessments: [
        { name: 'PHQ-9', status: 'Eligible' },
        { name: 'PMQ-R', status: 'Eligible' }
      ],
      ccm: 'Skye Bradshaw'
    }
  ]

  encounters: Array<GetEncounterQuery> = []

  constructor(
    private apiService: APIService,
    private officeFilterService: OfficeFilterService,
    private router: Router,
    private assessmentSessionService: AssessmentSessionService
  ) {}

  ngOnInit(): void {
    // Subscribe to the location so that whenever location changes, it will also update
    this.officeFilterService.selectedLocation$.subscribe(async location => {
      if (!location) return

      const locationId = location.id
      const accountId = this.officeFilterService.selectedAccount$.getValue().id

      const result = await this.apiService.ListEncounters({
        locationId: { eq: locationId },
        accountId: { eq: accountId }
      })

      const encounters = []

      result.items.forEach(item => {
        encounters.push({
          id: item.id,
          accountId: item.accountId,
          assessments: {
            items: item.assessments.items
              .filter(assessment => {
                return assessment.type !== 'PRETEST'
              })
              .map(assessment => {
                return Object.assign({}, assessment, {
                  scoreGauge:
                    assessment.scoreGauge === null
                      ? 'UNSCORED'
                      : assessment.scoreGauge
                })
              })
          },
          __typename: 'Encounter',
          createdAt: item.createdAt,
          date: item.date,
          location: item.location,
          locationId: item.locationId,
          manager: item.manager,
          managerId: item.managerId,
          patient: item.patient,
          patientId: item.patientId,
          updatedAt: item.updatedAt,
          pretest: item.pretest,
          ownerId: item.ownerId
        })
      })

      this.encounters = encounters

      console.log('!!!!!!Loaded encounters', this.encounters)
    })
  }

  async openAssessment(assessment: GetAssessmentQuery) {
    // Navigate to single assessment view
    await this.router.navigate(['scoring'], {
      queryParams: { assessmentId: assessment.id, redirectTo: 'assessments' }
    })
  }

  async onCellClick(event) {
    const encounter: GetEncounterQuery = event.dataItem
    await this.openEncounter(encounter)
  }

  async openEncounter(encounter: GetEncounterQuery) {
    await this.router.navigate(['encounter', encounter.id])
  }
}
