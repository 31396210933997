<div class="chat-conversation-container" >
  <kendo-chat
    *ngIf="user"
    [messages]="messages$ | async"
    [user]="user"
    (sendMessage)="sendMessage($event)"
  >
  </kendo-chat>
</div>

