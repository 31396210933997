import { Component, Input, OnInit } from '@angular/core';
import { GetLocationQuery } from '../../API.service';

@Component({
  selector: 'app-location-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class LocationResourcesComponent implements OnInit {
  @Input() location: GetLocationQuery;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
