<kendo-drawer-container fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
  <kendo-drawer #navDrawer
                fxFlex="none"
                [items]="items"
                [mode]="'push'"
                [mini]="isFullScreen()"
                [expanded]="false"
                (select)="onSelect($event)"
                [autoCollapse]="false"
                [width]="260"
                [miniWidth]="80"
                [(expanded)]="expanded"
                (mouseenter) ="navDrawer.toggle(true) "
                (mouseleave) ="navDrawer.toggle(false)">
    <ng-template kendoDrawerHeaderTemplate>
      <div *ngIf="expanded" style="display:flex;flex-direction: row;justify-content: flex-start;align-items: center;">
        <img [src]="'/assets/brelogo_wh.svg'" style="width:50px;margin:20px;display:block;"/>
        <div class="title">Interventional Pain<br/>Solutions</div>
      </div>
      <div *ngIf="!expanded">
        <img [src]="'/assets/brelogo_wh.svg'" style="width:50px;margin:20px auto;display:block;"/>
      </div>
    </ng-template>
    <ng-template kendoDrawerItemTemplate let-item>
      <span [ngStyle]="{'margin': expanded ? '0' : '0 auto'}" style="color:white;" class="k-icon {{ item.icon }}"></span>
      <div *ngIf="expanded" style="margin-left:10px;color:white;">{{ item.text }}</div>
    </ng-template>
  </kendo-drawer>
  <kendo-drawer-content class="route-content" fxFlex fxFlexAlign="stretch" fxLayout="column" style="border-radius: 30px;">
    <app-header *ngIf="isFullScreen()" style="margin-bottom:20px;"></app-header>
    <div fxFlex fxFlexAlign="stretch" style="margin:0 50px; height: 100%; overflow: hidden;">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="isFullScreen()"></app-footer>
  </kendo-drawer-content>
</kendo-drawer-container>
<ng-container *ngIf="isFullScreen()">
  <app-chat *ngIf="isLoggedIn$ | async"></app-chat>
  <app-pretest-approvals-popup *ngIf="isLoggedIn$ | async"></app-pretest-approvals-popup>
</ng-container>



