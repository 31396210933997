<kendo-dialog title="Create User Account" [width]="450" [height]="450" *ngIf="open" (close)="closeRegistration()">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">First Name</div>
      <kendo-textbox fxFlex placeholder="First Name" [(ngModel)]="firstNameInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Last Name</div>
      <kendo-textbox fxFlex placeholder="Last Name" [(ngModel)]="lastNameInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Email</div>
      <kendo-textbox fxFlex placeholder="Email" [(ngModel)]="emailInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Password</div>
      <kendo-textbox fxFlex placeholder="Password" type="password" [(ngModel)]="passwordInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Phone</div>
      <kendo-textbox fxFlex placeholder="Phone" type="phone" [(ngModel)]="phoneInput"></kendo-textbox>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">User Type</div>
      <kendo-dropdownlist
        fxFlex
        [data]="userTypes"
        [(ngModel)]="userTypeInput">
      </kendo-dropdownlist>
    </div>
    <div *ngIf="loading" class="k-i-loading"></div>
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeRegistration()">Cancel</button>
    <button kendoButton (click)="createAndCloseRegistration()" primary="true">Create</button>
  </kendo-dialog-actions>
</kendo-dialog>
