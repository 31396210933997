import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { isPathAccessible } from '../mappings'

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const userGroups = await this.authService.getAccessGroups()
    if (isPathAccessible(userGroups, next.url.join('/'))) return true
    else await this.router.navigate(['/'])
    return false
  }
}
