<div class="search-section dash-section"  fxLayout="row" fxLayoutAlign="start center" fxFlex>
  <div fxFlex fxLayoutAlign="start center">
    <kendo-textbox fxFlex placeholder="Search (Patient Name, Treatment Plan, Invoice, etc)">
      <ng-template kendoTextBoxPrefixTemplate>
        <kendo-icon [name]="'search'"></kendo-icon>
      </ng-template>
    </kendo-textbox>
  </div>
  <button [primary]="true" fxFlex="15" kendoButton>Search</button>
</div>
