import { Injectable, OnDestroy, OnInit } from '@angular/core'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { BehaviorSubject, Observable, Subscription, timer, zip } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WsConnectionService implements OnInit, OnDestroy {
  public connection$: WebSocketSubject<any>
  public message$: BehaviorSubject<any>
  private _messageSubscription: Subscription
  constructor() {}
  ngOnInit(): void {}

  connect(accessToken: string): WebSocketSubject<any> {
    console.log('Connecting to WS')
    if (this.connection$) {
      console.log('Connection already existing, disconnecting first')
      this.disconnect()
    }
    this.connection$ = webSocket(
      `wss://ozwpm6exa7.execute-api.us-east-1.amazonaws.com/dev?accessToken=${accessToken}`
    )
    this.message$ = new BehaviorSubject<any>(null)
    this._messageSubscription = this.connection$.subscribe(message => {
      this.message$.next(message)
    })

    return this.connection$
  }

  send(target: string, action: string, message: any) {
    if (this.connection$) {
      const data = {
        target,
        action,
        message
      }
      this.connection$.next(data)
    } else {
      console.log('Cannot send message, no connection yet')
    }
  }

  disconnect() {
    if (this.connection$) {
      console.log('Disconnecting from WS')
      this.connection$.complete()
      this.connection$ = null
      this._messageSubscription.unsubscribe()
      this._messageSubscription = null
    } else {
      console.log('Cannot disconnect, no connection yet')
    }
  }

  ngOnDestroy() {
    this.disconnect()
  }
}
