<!-- PRETEST LIST -->
<div *ngIf="selectedAssessment && !isLoadingPretest && !showCompletedDialog" fxFlexFill fxLayout="row" class="pretest-approvals-container">
  <div fxFlex="10" class="spacer"></div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" class="pretest-answer-sheet" *ngIf="selectedAssessmentAnswer">
    <div fxFlex="100px" fxLayoutAlign="center start" fxLayout="column">
      <div>Patient: {{ selectedAssessment.patient?.givenName + ' ' + selectedAssessment.patient?.familyName }}</div>
      <div>Date of Birth: {{ selectedAssessment.patient?.dob }}</div>
    </div>
    <div fxFlex="40px"  fxLayout="row" >
      <div fxFlex fxLayoutAlign="start center">Pretest Results:</div>
      <div fxFlex fxLayoutAlign="end center">Date: {{ selectedAssessmentAnswer.startedAt | kendoDate }}</div>
    </div>
    <div *ngFor="let section of selectedAssessmentAnswer.items" fxFlex fxLayout="column" fxLayoutGap="10px" class="pretest-answer-sheet-section">
      <div *ngFor="let item of section.items" fxFlex fxLayout="column" fxLayoutGap="10px">
        <div fxFlex fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
          <div  fxFlex fxLayout="row"  class="pretest-answer-sheet-item card" fxLayoutGap="40px" [ngClass]="{'marked': item.answerValue}">
            <div fxFlex="60px" fxLayoutAlign="start center">
              {{item.questionRef?.id}}
            </div>
            <div fxFlex fxLayout="column">
              <div fxFlex="60px" class="question-text" fxLayoutAlign="start center">
                {{item.questionRef?.text}}
              </div>
            </div>
            <div fxFlex="60px" fxLayoutAlign="start center" >
              {{item.answerValue ? 'TRUE' : 'FALSE'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="30px" class="spacer"></div>
    <div fxFlex="150px" class="card" fxLayout="column" fxLayoutGap="10px">
      <div fxFlex fxLayoutAlign="start center">
        Patient is eligible for the following assessments:
      </div>
      <div fxFlex fxLayoutAlign="start center">
        <kendo-multiselect
          [data]="eligibleTests" (removeTag)="onRemoveEligibleAssessment($event)"
          [itemDisabled]="isAssessmentRecommended" [(value)]="selectedEligibleTests"
          (valueChange)="onSelectedEligibleTestsChange($event)"
          textField="code"
          valueField="testId"
        >
          <ng-template kendoMultiSelectTagTemplate let-dataItem>
            <span class="template" [title]="dataItem.title">{{ dataItem.code }}</span>
          </ng-template>
        </kendo-multiselect>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button kendoButton [primary]="true" look="outline" (click)="processApproval(true)">
          Approve Pretest
        </button>
        <button kendoButton [primary]="true" look="outline" (click)="processApproval(false)">
          Reject Pretest
        </button>
      </div>
    </div>
    <div fxFlex="30px" class="spacer"></div>
  </div>
  <div fxFlex="10" class="spacer"></div>
</div>

<kendo-dialog *ngIf="showCompletedDialog"
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces style='width: 300px'>
      ## Pretest Complete
      ### This Pretest has been completed and feedback has already been sent.
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton [primary]='true' (click)='close()'>Close</button>
  </kendo-dialog-actions>
</kendo-dialog>

<div *ngIf="isLoadingPretest" fxFlexFill fxLayout="row" class="pretest-approvals-container" fxLayoutAlign="center center">
  <kendo-loader *ngIf="isLoadingPretest"
                [type]="'pulsing'"
                [themeColor]="'light'"
                [size]="'large'"
  ></kendo-loader>
</div>

