import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { DashboardComponent } from './dashboard/dashboard.component'
import { AuthGuard } from './shared/guards/auth.guard'
import { PatientsComponent } from './patients/patients.component'
import { AssessmentsComponent } from './assessments/assessments.component'
import { RemotePatientMonitoringComponent } from './remote-patient-monitoring/remote-patient-monitoring.component'
import { FaqComponent } from './faq/faq.component'
import { AccountSettingsComponent } from './account-settings/account-settings.component'
import { SchedulingComponent } from './scheduling/scheduling.component'
import { BillingComponent } from './billing/billing.component'
import { TreatmentActionPlansComponent } from './treatment-action-plans/treatment-action-plans.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { LoginComponent } from './login/login.component'
import { LoginGuard } from './shared/guards/login.guard'
import { TelehealthComponent } from './telehealth/telehealth.component'
import { AccessGuard } from './shared/guards/access.guard'
import { AccountsComponent } from './accounts/accounts.component'
import { SuperGuard } from './shared/guards/super.guard'
import { LocationsComponent } from './locations/locations.component'
import { UsersComponent } from './users/users.component'
import { LiveRoomComponent } from './live-room/live-room.component'
import { LiveRoomGuard } from './shared/guards/live-room.guard'
import { LocationComponent } from './location/location.component'
import { ApprovalsComponent } from './approvals/approvals.component'
import { ApprovalsGuard } from './shared/guards/approvals.guard'
import { ResourcesComponent } from './resources/resources.component'
import { PatientChartsComponent } from './patient-charts/patient-charts.component'
import { PatientAssessmentsComponent } from './patient-assessments/patient-assessments.component'
import { PatientsDetailComponent } from './patients-detail/patients-detail.component'
import { WriteUpComponent } from './write-up/write-up.component'
import { ScoreGuard } from './shared/guards/score.guard'
import { ScoreComponent } from './testing'
import { SelectSoapNotesComponent } from './select-soap-notes/select-soap-notes.component'
import { SelectSoapNotesGuard } from './shared/guards/select-soap-notes.guard'
import { EncounterGuard } from './shared/guards/encounter.guard'
import { EncounterComponent } from './encounter/encounter.component'

const routes: Routes = [
  // Client Facing
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients',
    component: PatientsComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'patient/:id',
    component: PatientsDetailComponent
  },
  {
    path: 'assessments',
    component: AssessmentsComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'remote-patient-monitoring',
    component: RemotePatientMonitoringComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'treatment-action-plans',
    component: TreatmentActionPlansComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'scheduling',
    component: SchedulingComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'telehealth',
    component: TelehealthComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'accounts',
    component: AccountsComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'location/:id',
    component: LocationComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'patient-charts',
    component: PatientChartsComponent,
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'live-room',
    component: LiveRoomComponent,
    canActivate: [AuthGuard, LiveRoomGuard]
  },
  {
    path: 'approvals',
    component: ApprovalsComponent,
    canActivate: [AuthGuard, ApprovalsGuard]
  },
  {
    path: 'patient/:id/assessments',
    canActivate: [AuthGuard, AccessGuard],
    component: PatientAssessmentsComponent
  },
  {
    path: 'write-up',
    canActivate: [AuthGuard],
    component: WriteUpComponent
  },
  {
    path: 'scoring',
    canActivate: [AuthGuard, ScoreGuard],
    component: ScoreComponent
  },
  {
    path: 'select-soap-notes',
    canActivate: [AuthGuard, SelectSoapNotesGuard],
    component: SelectSoapNotesComponent
  },
  {
    path: 'encounter/:id',
    canActivate: [AuthGuard, EncounterGuard],
    component: EncounterComponent
  },
  // System Pages
  { path: '404', component: PageNotFoundComponent }, // page is not found
  // Redirects
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
]

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        //enableTracing: true,
        useHash: true
      } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class BreAppRoutingModule {}
