import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { getDevices, getMediaSetup, MediaKind } from '../../helpers/media'
import { RoomConnection, RoomConnectionParticipant } from '../../helpers/room'

@Component({
  selector: 'app-live-room-settings',
  templateUrl: './live-room-settings.component.html',
  styleUrls: ['./live-room-settings.component.scss']
})
export class LiveRoomSettingsComponent implements OnInit {
  @Input() roomConnection: RoomConnection
  @Output() onCancel = new EventEmitter<string>()
  @Output() onSave = new EventEmitter<{
    [key: string]: MediaDeviceInfo
  }>()

  audioInputDevices: Array<MediaDeviceInfo>
  videoInputDevices: Array<MediaDeviceInfo>
  audioInputDevice: MediaDeviceInfo
  videoInputDevice: MediaDeviceInfo

  constructor() {}

  async ngOnInit(): Promise<void> {
    const { audioInputDevices, videoInputDevices } = await getMediaSetup()
    this.audioInputDevices = audioInputDevices
    this.videoInputDevices = videoInputDevices
    this.audioInputDevice = audioInputDevices.find(d => {
      return this.roomConnection.audioInputDevice.deviceId === d.deviceId
    })
    this.videoInputDevice = videoInputDevices.find(d => {
      return this.roomConnection.videoInputDevice.deviceId === d.deviceId
    })
  }

  onSelectAudioInput(deviceId: string) {
    this.audioInputDevice = this.audioInputDevices.find(
      d => d.deviceId === deviceId
    )
  }

  onSelectVideoInput(deviceId: string) {
    this.videoInputDevice = this.videoInputDevices.find(
      d => d.deviceId === deviceId
    )
  }

  save() {
    this.onSave.emit({
      videoInputDevice: this.videoInputDevice,
      audioInputDevice: this.audioInputDevice
    })
  }

  cancel() {
    this.onCancel.emit()
  }
}
