import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core'
import { isSupported } from 'twilio-video'
import {
  connectToRoom,
  RoomConnection,
  RoomConnectionParticipant
} from './helpers/room'
import { APIService, TelehealthSessionStatus } from '../API.service'
import { Observable } from 'rxjs'
import { getMediaSetup } from './helpers/media'
import { SessionService } from '../services/session.service'
import { WsConnectionService } from '../services/ws-connection.service'
import { ActivatedRoute, Router } from '@angular/router'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-live-room',
  templateUrl: './live-room.component.html',
  styleUrls: ['./live-room.component.scss']
})
export class LiveRoomComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('scheduledEventId') scheduledEventId: string
  @Input('monitorOnly') monitorOnly: boolean
  @Input('audioOnly') audioOnly: boolean
  @Input('sessionId') sessionId: string

  session: any
  scheduledEvent: any
  redirectTo: string

  roomConnection: RoomConnection
  roomSid: string
  showSettings = false
  showTransfer = false
  loading = true

  audioEnabled = true
  videoEnabled = true

  constructor(
    private apiService: APIService,
    private sessionService: SessionService,
    private wsConnectionService: WsConnectionService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.audioOnly = false
  }

  async ngOnInit(): Promise<void> {}

  async ngOnDestroy(): Promise<void> {
    // TODO: Make sure session is closed and all resources used by the room is also cleared
    // Put all destroy logic here, this ensures support for  destroying resources on routing events,
    // or other events like window closing
    console.log('DESTROYING LiveRoomComponent')
    const participantsLeft = await this.roomConnection.close()
    console.log('Participants left after disconnection:', participantsLeft)
    if (participantsLeft <= 0) {
      console.log('There are no participants left, so destroying the room')
      await this.apiService.DestroyTwilioRoom({
        roomSid: this.roomSid
      })
    }
  }

  async ngAfterViewInit(): Promise<void> {
    // After loading the view, load the query params
    this.activatedRoute.queryParams.subscribe(async params => {
      this.monitorOnly =
        !!(params['monitorOnly'] === 'true') || !!this.monitorOnly
      this.sessionId = params['sessionId'] || this.sessionId
      this.audioOnly = !!(params['audioOnly'] === 'true') || !!this.audioOnly
      this.scheduledEventId =
        params['scheduledEventId'] || this.scheduledEventId
      this.redirectTo = params['redirectTo'] || ''

      // if its a scheduledEventId
      if (this.scheduledEventId) {
        let [scheduledEvent, telehealthSession] = await Promise.all([
          this.apiService.GetScheduledEvent(this.scheduledEventId),
          this.apiService.GetTelehealthSession(this.scheduledEventId)
        ])

        this.scheduledEvent = scheduledEvent

        //we don't have a telehealth session yet, we need to create
        if (!telehealthSession) {
          const participants = scheduledEvent.invitees.map(
            ({ name, type, id }) => {
              return {
                name,
                type,
                // TODO: maybe consider using id (but identity is suited for auth)
                identity: id
              }
            }
          )

          this.session = await this.apiService.CreateTelehealthSession({
            title: scheduledEvent.title,
            id: this.scheduledEventId,
            status: TelehealthSessionStatus.PENDING,
            accountId: scheduledEvent.accountId,
            participants: participants,
            participantIds: participants.map(p => p.identity).filter(id => !!id)
          })
          this.sessionId = this.session.id
        } else {
          // OTHERWISE, We fetched one successfully just load it
          this.session = telehealthSession
          this.sessionId = telehealthSession.id
        }
      } else if (this.sessionId) {
        // if the supplied param is a sessionId, then it is probably an ongoing session
        this.session = await this.apiService.GetTelehealthSession(
          this.sessionId
        )
      }

      console.log('INITIALIZED LiveRoomComponent', {
        session: this.session,
        event: this.scheduledEvent
      })
      if (!isSupported) alert('Not supported')

      if (this.monitorOnly) {
        // If user is only monitoring, we do not need to fetch media devices, just access token
        const { accessToken } = await this.initializeAccessToRoom(
          this.sessionId
        )

        console.log('Connecting [AS MONITOR] to room with', accessToken)

        this.roomConnection = await connectToRoom(
          accessToken,
          this.sessionId,
          {},
          {
            dominantSpeaker: false,
            bandwidthProfile: {
              video: {
                mode: 'grid',
                maxTracks: 1,
                trackSwitchOffMode: 'disabled',
                renderDimensions: {
                  high: { height: 96, width: 128 },
                  standard: { height: 96, width: 128 },
                  low: { height: 96, width: 128 }
                }
              }
            }
          }
        )
      } else if (this.audioOnly) {
        // if it is meant to be audio only
        const [{ audioInputDevice }, { accessToken }] = await Promise.all([
          // Fetch the local Media setup of the user
          getMediaSetup(),
          // Initialize access to the room
          this.initializeAccessToRoom(this.sessionId)
        ])

        console.log('Connecting to room with [AUDIO ONLY]', accessToken)

        this.roomConnection = await connectToRoom(
          accessToken,
          this.sessionId,
          { audioInputDevice },
          {}
        )
      } else {
        // Otherwise, fetch the media setup, then use the defaults
        // We need to show user that it is LOADING
        // Fetch the prerequisites
        const [
          { audioInputDevice, videoInputDevice },
          { accessToken }
        ] = await Promise.all([
          // Fetch the local Media setup of the user
          getMediaSetup(),
          // Initialize access to the room
          this.initializeAccessToRoom(this.sessionId)
        ])

        console.log('Connecting to room with', accessToken)

        this.roomConnection = await connectToRoom(
          accessToken,
          this.sessionId,
          { audioInputDevice, videoInputDevice },
          {}
        )
      }

      this.loading = false
    })
  }

  getParticipants$(): Observable<Array<RoomConnectionParticipant>> {
    return this.roomConnection.getParticipants$()
  }

  getLocalParticipant$(): Observable<RoomConnectionParticipant> {
    return this.roomConnection.getLocalParticipant$()
  }

  getPinnedParticipant$(): Observable<RoomConnectionParticipant> {
    return this.roomConnection.getPinnedParticipant$()
  }

  getNonMonitorParticipants$(): Observable<Array<RoomConnectionParticipant>> {
    return this.roomConnection.getNonMonitorParticipants$()
  }

  getActiveMonitorsCount$(): Observable<number> {
    return this.roomConnection.getMonitorParticipants$().pipe(
      map(p => {
        return p.length
      })
    )
  }

  closeLiveRoom(redirect = true) {
    // TODO: clean up resources
    this.roomConnection.close()
    if (redirect) {
      this.router.navigate([this.redirectTo])
    }
  }

  async initializeAccessToRoom(sessionId: string) {
    console.log('INITIALIZING ACCESS TO ROOM WITH', sessionId)
    // First, make sure the room is created
    // Room name will always be the session id
    // Generate an access token based on the session id
    const { roomSid: roomSidInit } = await this.apiService.InitTwilioRoom({
      roomName: sessionId,
      recordSession: true
    })
    this.roomSid = roomSidInit
    const {
      accessToken,
      roomSid: roomSidAccess
    } = await this.apiService.RequestTelehealthAccess({
      telehealthSessionId: sessionId
    })
    console.log('Initialized Access to Room', {
      roomSidInit,
      roomSidAccess,
      accessToken
    })
    return { sessionId, accessToken, roomSid: roomSidAccess }
  }

  toggleAudio(): boolean {
    this.roomConnection.toggleAudio((this.audioEnabled = !this.audioEnabled))
    return this.audioEnabled
  }

  toggleVideo(): boolean {
    this.roomConnection.toggleVideo((this.videoEnabled = !this.videoEnabled))
    return this.videoEnabled
  }

  toggleSettings() {
    this.showSettings = !this.showSettings
  }

  toggleTransfer() {
    this.showTransfer = !this.showTransfer
  }

  onSettingsCancel(_: any) {
    this.showSettings = false
  }

  onSettingsSaved({
    audioInputDevice,
    videoInputDevice
  }: {
    [key: string]: MediaDeviceInfo
  }) {
    this.roomConnection.useAudioInput(audioInputDevice)
    this.roomConnection.useVideoInput(videoInputDevice)
    this.showSettings = false
  }

  onTransferCancel(_: any) {
    console.log('onTransferCancel')
    this.showTransfer = false
  }

  onTransferSaved(user: any) {
    console.log('onTransferSaved', user)
    this.showTransfer = false
    const transferToUser = user
    const transferFromUser = this.sessionService.currentUser$.getValue()
    const sessionId = this.sessionId
    const sessionTitle = this.session.title

    const title = `${transferFromUser['firstName']} ${transferFromUser['lastName']} requests that you join in ${sessionTitle}`

    console.log('Sending transfer message to user', transferToUser)
    this.wsConnectionService.send(transferToUser.id, 'transfer', {
      title,
      sessionId: sessionId
    })
  }
}
