import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'lodash'

@Component({
  selector: 'app-patient-assessments',
  templateUrl: './patient-assessments.component.html',
  styleUrls: ['./patient-assessments.component.scss']
})
export class PatientAssessmentsComponent implements OnInit {
  lodashMap = map;
  timeSpentForm: FormGroup;
  teleEncouterForm: FormGroup;
  tabList = [
    {
      title: 'Summary report',
      order: 0,
    },
    {
      title: 'Physician report',
      order: 1,
    },
    {
      title: 'Comparison report',
      order: 2,
    },
    {
      title: 'Full report',
      order: 3,
    },
    {
      title: 'Suggested SOAP Notes',
      order: 4,
    },
    {
      title: 'Cumulative SOAP Notes',
      order: 5,
    },
  ]
  activeTablist = this.tabList[0].order;
  patientDetail = {
    name: 'Parker Alan',
    dob: 'Sep 8, 1964',
    ss: '',
    age: '56',
    id: 'AB33-PA00014',
    type: 'Commerical',
    lastTest: '2 days ago'
  }
  charts = [
    {
      chartTitle: 'CESD-R HISTORY',
      checkPoints: [
        {
          value: 0,
          legend: 'Below 16 shows low risk for major depressive episode',
        },
        {
          value: 17,
          legend: 'Between 17 and 35 shows moderate risk for major depressive episode',

        },
        {
          value: 36,
          legend: '36 or above show high risk for major depressive episode',
        }
      ],
      notTest: false,
      chartType: 'column',
      step: [0, 5, 10],
      pureData: [ 40, 22, 3],
      pureDate: [ '09/12/2020', '11/12/2020', '05/12/2020'],
      data: [
        {
          value: 40,
          date: '09/12/2020',
          color: '#ffd9d9',
        },
        {
          value: 22,
          date: '11/12/2020',
          color: '#ffff8d',
        },
        {
          value: 3,
          date: '05/12/2020',
          color: '#ebffeb',
        }
      ]
    },
    {
      chartTitle: 'PMQ-R HISTORY',
      checkPoints: [
        {
          value: 0,
          legend: 'Below 35 shows low risk for major depressive episode',
        },
        {
          value: 35,
          legend: 'Between 35 and 70 shows moderate risk for major depressive episode',

        },
        {
          value: 70,
          legend: '70 or above show high risk for major depressive episode',
        }
      ],
      notTest: false,
      chartType: 'column',
      pureData: [ 40],
      pureDate: [ '09/12/2020'],
      data: [
        {
          value: 40,
          date: '09/12/2020',
          color: '#ffff8d',
        },
      ]
    },
    {
      chartTitle: 'CESD-R HISTORY',
      checkPoints: [
        {
          value: 0,
          legend: 'Below 16 shows low risk for major depressive episode',
        },
        {
          value: 17,
          legend: 'Between 17 and 35 shows moderate risk for major depressive episode',

        },
        {
          value: 36,
          legend: '36 or above show high risk for major depressive episode',
        }
      ],
      notTest: true,
    }
  ]
  public crosshairConfig = {
    color: 'green',
    opacity: 0.8,
    visible: true,
    width: 3
  };

  suggestedSoapList = [
    {
      title: 'Patient reported: feeling of disorientation',
      observations: [
        {
          id: 1,
          title: 'Disorientation'
        },
        {
          id: 2,
          title: 'Confusion'
        },
        {
          id: 3,
          title: 'Difficulty focusing'
        },
        {
          id: 4,
          title: 'Mumbling things'
        },
        {
          id: 5,
          title: 'Not making sense'
        },
        {
          id: 6,
          title: 'Difficulty speaking'
        }
      ],
      diagnosis: [
        {
          id: 1,
          title: 'Disorientation'
        }
      ]
    },
    {
      title: 'Patient reported: speech problems',
      observations: [
        {
          id: 1,
          title: 'Inability to communicate without getting tongue tied'
        },
        {
          id: 2,
          title: 'Slurring words'
        }
      ],
      diagnosis: [
        {
          id: 1,
          title: 'Speech disturbances'
        },
        {
          id: 2,
          title: 'Stuttering'
        },
        {
          id: 3,
          title: 'Apraxia'
        },
        {
          id: 4,
          title: 'Dysarthria'
        }
      ]
    },
    {
      title: 'Patient reported: taking opioids',
      observations: [
        {
          id: 1,
          title: 'Urine analysis positive for opioids'
        },
        {
          id: 2,
          title: 'Blood analysis positive for opioids'
        }
      ],
      diagnosis: [
        {
          id: 1,
          title: 'Anxiety disorder'
        }
      ]
    },
    {
      title: 'Patient reported: feeling very poorly due to stress in life',
      observations: [
        {
          id: 1,
          title: 'signs of stress'
        }
      ],
      diagnosis: [
        {
          id: 1,
          title: 'High physical and mental stress'
        }
      ]
    },
    {
      title: 'Patient reported: bouts with bulimia',
      observations: [
        {
          id: 1,
          title: 'Abnormal serum electrolytes'
        },
        {
          id: 2,
          title: 'Teeth marks on back of hand'
        }
      ],
      diagnosis: [
        {
          id: 1,
          title: 'Bulimia nervosa'
        }
      ]
    }
  ];

  soapNotes = [
    {
      notedAt: 'January 4, 2021',
      commonNotes: 'Provider ordered Psych Assessment Test.',
      assessments: [
        {
          id: 1,
          testPerformed: 'Assessment administration and scoring',
          date: '1/7/21',
          description: 'Psych/or Neuro test admin & scoring',
          time: '16 mins'
        },
        {
          id: 2,
          testPerformed: 'Psych assessment test',
          date: '1/7/21',
          description: 'Psych testing integration and intepretation of dat using standardized tests and related clinical data; decision making with treatment planning including interactive feedback with patien',
          time: '31 mins'
        }
      ]
    },
    {
      notedAt: 'December 9, 2020',
      commonNotes: 'Provider ordered Psych Assessment Test.',
      assessments: [
        {
          id: 1,
          testPerformed: 'Addition Assessment',
          date: '12/9/20',
          description: 'Addiction screen because of disease risk factors and medication heightened risk factors',
          time: '8 mins'
        }
      ]
    }
  ];


  constructor(private formBuilder: FormBuilder,
    ) {    
  }

  ngOnInit(): void {
    this.timeSpentForm = this.formBuilder.group({
      minutes: ['', Validators.minLength(0)],
    })
    this.teleEncouterForm = this.formBuilder.group({
      teleEncouter: 'no',
    })
  }

  onApproveButtonClick() {
    console.log('onApproveButtonClick---')
  }

  timeSpentFormSubmit() {
    console.log('timeSpentFormSubmit----')
  }
 
  teleEncouterFormSubmit() {
    console.log('teleEncouterFormSubmit---')
  }

  onTabListClick(order) {
    this.activeTablist = order;
  }

}
