import { Injectable } from '@angular/core'
import { Resource } from '@progress/kendo-angular-scheduler'
import {
  APIService,
  ScheduledEventType as Evt,
  UserType
} from '../../API.service'
import { SessionService } from '../../services/session.service'
import { ScheduledEventInvitee } from '../../shared/types/types'
import { OfficeFilterService } from '../../services/office-filter.service'

@Injectable({
  providedIn: 'root'
})
export class EventSchedulerResourceService {
  currentAccount;

  constructor(
    private apiService: APIService,
    private sessionService: SessionService,
    private officeFilterService: OfficeFilterService
  ) {
    this.currentAccount = this.officeFilterService.selectedAccount$.getValue();
    console.log('this.currentAccount', this.currentAccount)
  }

  public async getResources(): Promise<{ [key: string]: Resource }> {
    return { eventTypeResource: await this.getTypeResource() }
  }

  public async getInvitees(): Promise<Array<ScheduledEventInvitee>> {
    const [{ items: users }, { items: patients }] = await Promise.all([
      this.apiService.ListUsers({
        accountId: {
          eq: this.currentAccount.id
        }
      }),
      this.apiService.ListPatients({
        accountId: {
          eq: this.currentAccount.id
        }
      })
    ])
    // IMPORTANT:
    // Use the added label property because of Kendo bug that causes the input to NOT use tagMapper initially
    const invitees = users
      .map(u => {
        return {
          id: u.id,
          name: u.firstName + ' ' + u.lastName,
          label: u.userType + ':' + u.firstName + ' ' + u.lastName,
          type: u.userType
        }
      })
      .concat(
        patients.map(p => {
          return {
            id: p.id,
            name: p.givenName + ' ' + p.familyName,
            label: UserType.PATIENT + ':' + p.givenName + ' ' + p.familyName,
            type: UserType.PATIENT
          }
        })
      )
    return invitees
  }

  private typeMappings = {
    [Evt.GENERAL]: { text: 'General', color: '#a4a4a4' },
    [Evt.MEETING]: { text: 'Meeting', color: '#ffd400' },
    [Evt.TELEHEALTH]: { text: 'Telehealth', color: '#0627a7' }
  }

  typeResource(type: Evt) {
    return this.typeMappings[type]
  }

  async getTypeResource(): Promise<Resource> {
    return {
      name: 'Type',
      data: Object.entries(this.typeMappings).map(
        ([value, { text, color }]) => {
          return { text, value, color }
        }
      ),
      field: 'type',
      valueField: 'value',
      textField: 'text',
      colorField: 'color'
    }
  }
}
