import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SessionService } from '../services/session.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private userTypeSub: Subscription
  constructor(private router: Router, private sessionService: SessionService) {}

  ngOnInit(): void {
    this.userTypeSub = this.sessionService.userType$.subscribe(userType => {
      this.applyDashboardUserType()
    })
  }

  applyDashboardUserType() {}

  ngOnDestroy() {
    this.userTypeSub.unsubscribe()
  }
}
