
<div fxFlexFill fxLayout="column" class="statement-block" [ngClass]="{'required': !!statement.required }">
  <div fxFlex fxLayout="row" kendoTooltip class="statement-line">
    <div fxFlex="40px">
      <kendo-icon *ngIf="!writeUpService.isStatementValid(statement,context)" [name]="'warning'" title="This is required" style="color: red"></kendo-icon>
    </div>
    <div *ngIf="indent > 0" [fxFlex]="(indent * 30) + 'px'"></div>
    <div fxFlex="30px" *ngIf="statement.type === 'checkbox'">
      <input [disabled]="statement.readonly" type="checkbox" [(ngModel)]="statement.checked" (ngModelChange)="applyUpdate($event)" kendoCheckBox />
    </div>
    <div fxFlex="30px" *ngIf="statement.type === 'selection'">
      <input [disabled]="statement.readonly" type="radio" [checked]="statement.selected" [name]="parent.id" [value]="statement.id" [(ngModel)]="parent.selected" [ngModelOptions]="{standalone: true}" (ngModelChange)="applyUpdate($event)" kendoRadioButton/>
    </div>
    <div *ngIf="!statement.prepend">
      {{ statement.prepend }} &nbsp;
    </div>
    <div class="statement-text" *ngIf="!statement.input">
      <span *ngIf="statement.text">{{ statement.text }}&nbsp;</span>
      <span *ngIf="statement.required && (!statement.text || !statement.text?.length)" class="required-marker-text">REQUIRED</span>
      <kendo-icon *ngIf="statement.hint" [name]="'info'"  [title]="statement.hint"></kendo-icon>
    </div>
    <div *ngIf="statement.input">
      <input kendoTextBox [(ngModel)]="statement.text" />
    </div>
    <div *ngIf="!statement.append">
      {{ statement.append }} &nbsp;
    </div>
  </div>
  <div fxLayout="column" fxFlex>
    <ng-container *ngFor="let option of statement.options">
      <div *ngIf="showStatement(option)"
        fxFlex fxLayout="column"
            fxLayoutGap="10px" class="write-up-section-container">
        <app-write-up-statement
          [context]="context"
          [questionnaire]="questionnaire"
          [section]="section"
          [statement]="option"
          [parent]="statement"
          [indent]="indent + 1">
        </app-write-up-statement>
      </div>
    </ng-container>
  </div>
</div>

