import { connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video'
import { defaultConnectOptions } from './config'
import { publish } from 'rxjs/operators'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { applyInputDevice, getMediaSetup, useDeviceOnTrack } from './media'
import { isMobile } from './util'
import { throttle } from 'lodash'

type MediaTrack = {
  isSubscribed: boolean
  isTrackEnabled: boolean
  kind: 'audio' | 'video'
  publishPriority: string
  track: null
  trackName: string
  trackSid: string
} & any

type RoomConnectionParticipant = {
  audioTracks: { [key: string]: MediaTrack }
  dataTracks: { [key: string]: MediaTrack }
  identity: string
  sid: string
  state: string
  videoTracks: { [key: string]: MediaTrack }
  tracks: { [key: string]: MediaTrack }
  on: (event: string, handler: Function) => {}
} & any

class RoomConnection {
  // The pinned participant
  private pinnedParticipant$: BehaviorSubject<RoomConnectionParticipant>
  // The local participant
  private localParticipant$: BehaviorSubject<RoomConnectionParticipant>
  // Non dominant speaker participants
  private participants$: BehaviorSubject<Array<RoomConnectionParticipant>>
  // Just ALL participants no filtering
  private allParticipants$: BehaviorSubject<Array<RoomConnectionParticipant>>
  // Monitor Participants
  private monitorParticipants$: BehaviorSubject<
    Array<RoomConnectionParticipant>
  >
  private nonMonitorParticipants$: BehaviorSubject<
    Array<RoomConnectionParticipant>
  >
  private participantSubscription: Subscription
  private room
  private roomName: string
  private options: any
  private accessToken: string

  public audioInputDevice = null
  public videoInputDevice = null

  constructor(
    accessToken,
    roomName,
    { audioInputDevice, videoInputDevice },
    options
  ) {
    this.roomName = roomName
    this.accessToken = accessToken
    this.options = options
    this.room = null
    this.allParticipants$ = new BehaviorSubject<
      Array<RoomConnectionParticipant>
    >([])
    this.participants$ = new BehaviorSubject<Array<RoomConnectionParticipant>>(
      []
    )
    this.pinnedParticipant$ = new BehaviorSubject<RoomConnectionParticipant>(
      null
    )
    this.localParticipant$ = new BehaviorSubject<RoomConnectionParticipant>(
      null
    )
    this.monitorParticipants$ = new BehaviorSubject<
      Array<RoomConnectionParticipant>
    >([])
    this.nonMonitorParticipants$ = new BehaviorSubject<
      Array<RoomConnectionParticipant>
    >([])
    // Refers to the local selected audioInputDevice
    this.audioInputDevice = audioInputDevice
    // Refers to the local selected videoInputDevice
    this.videoInputDevice = videoInputDevice
    this.establishConnection()

    this.participantSubscription = this.allParticipants$.subscribe(items => {
      const nonMonitors = []
      const monitors = []

      // Identify non-monitors and monitor users
      items.forEach(p => {
        const isParticipantLocal = p.constructor.name
          .toString()
          .startsWith('Local')
        const vTracks = Array.from(p.videoTracks.values())
        const aTracks = Array.from(p.audioTracks.values())
        // A monitor should be REMOTE && does not have tracks
        if (isParticipantLocal || (vTracks.length > 0 && aTracks.length > 0)) {
          nonMonitors.push(p)
        } else {
          monitors.push(p)
        }
      })

      console.log('All Participants Changed:', { nonMonitors, monitors })

      if (this.hasMonitorParticipantsChanged(monitors)) {
        this.monitorParticipants$.next(monitors)
      }
      const remoteNonMonitors = nonMonitors.slice(1)
      if (this.hasNonMonitorParticipantsChanged(remoteNonMonitors)) {
        this.nonMonitorParticipants$.next(remoteNonMonitors)
      }

      if (nonMonitors.length === 1) {
        this.pinnedParticipant$.next(nonMonitors[0])
        this.localParticipant$.next(null)
        this.participants$.next([])
      } else if (nonMonitors.length === 2) {
        if (this.hasPinnedParticipantChanged(nonMonitors[1]))
          this.pinnedParticipant$.next(nonMonitors[1])
        if (this.hasLocalParticipantChanged(nonMonitors[0]))
          this.localParticipant$.next(nonMonitors[0])
        if (this.hasParticipantsChanged([])) this.participants$.next([])
      } else if (nonMonitors.length >= 3) {
        if (this.hasPinnedParticipantChanged(nonMonitors[1]))
          this.pinnedParticipant$.next(nonMonitors[1])
        if (this.hasLocalParticipantChanged(nonMonitors[0]))
          this.localParticipant$.next(nonMonitors[0])
        if (this.hasParticipantsChanged(nonMonitors.slice(2)))
          this.participants$.next(nonMonitors.slice(2))
      } else {
        this.pinnedParticipant$.next(null)
        this.localParticipant$.next(null)
        this.participants$.next([])
      }
    })
  }

  hasPinnedParticipantChanged(target: RoomConnectionParticipant) {
    const original = this.pinnedParticipant$.getValue()
    return original && target ? original.sid !== target.sid : true
  }

  hasLocalParticipantChanged(target: RoomConnectionParticipant) {
    const original = this.localParticipant$.getValue()
    return original && target ? original.sid !== target.sid : true
  }

  hasParticipantsChanged(target: Array<RoomConnectionParticipant>) {
    const original = this.participants$.getValue()
    if (original.length === target.length) {
      // if they are the same, check if all id's are included
      const allOriginal = original
        .map(p => p.sid)
        .sort()
        .join()
      const allTarget = target
        .map(p => p.sid)
        .sort()
        .join()
      return allOriginal !== allTarget
    } else {
      return true
    }
  }

  hasMonitorParticipantsChanged(target: Array<RoomConnectionParticipant>) {
    const original = this.monitorParticipants$.getValue()
    if (original.length === target.length) {
      // if they are the same, check if all id's are included
      const allOriginal = original
        .map(p => p.sid)
        .sort()
        .join()
      const allTarget = target
        .map(p => p.sid)
        .sort()
        .join()
      return allOriginal !== allTarget
    } else {
      return true
    }
  }

  hasNonMonitorParticipantsChanged(target: Array<RoomConnectionParticipant>) {
    const original = this.nonMonitorParticipants$.getValue()
    if (original.length === target.length) {
      // if they are the same, check if all id's are included
      const allOriginal = original
        .map(p => p.sid)
        .sort()
        .join()
      const allTarget = target
        .map(p => p.sid)
        .sort()
        .join()
      return allOriginal !== allTarget
    } else {
      return true
    }
  }

  onParticipantConnected(participant: RoomConnectionParticipant) {
    console.log('Participant connected', participant)
    const participants = this.allParticipants$.getValue()
    const index = participants.findIndex(p => p.sid === participant.sid)
    if (index === -1) {
      console.log('Adding connected participant', participant)
      participants.push(participant)
      this.allParticipants$.next(participants)
    }
  }

  onParticipantDisconnected(participant: RoomConnectionParticipant) {
    console.log('Participant disconnected', participant)
    const participants = this.allParticipants$.getValue()
    const index = participants.findIndex(p => p.sid === participant.sid)
    if (index !== -1) {
      console.log('Removing disconnected participant', participant)
      participants.splice(index, 1)
      this.allParticipants$.next(participants)
    }
  }

  onDominantSpeakerChanged() {
    const { dominantSpeaker } = this.room
    const allParticipants = this.allParticipants$.getValue()
    // When there's only two of them, there is no point in updating views for dominant speaker
    if (allParticipants.length > 2) {
      const dominantSpeakerIndex = allParticipants.findIndex(
        _ => _.sid === dominantSpeaker.sid
      )
      if (dominantSpeakerIndex === 0) {
        console.error(
          'Something is not right, dominant index should not be the local participant'
        )
      } else if (dominantSpeakerIndex !== 1) {
        // if it is 1, then it is already in the correct position so no need to swap
        const removed = allParticipants.splice(dominantSpeakerIndex, 1).pop()
        allParticipants.splice(1, 0, removed)
        this.allParticipants$.next(allParticipants)
      }
    }
  }

  async establishConnection() {
    let localTracks = undefined
    if (this.audioInputDevice && this.videoInputDevice) {
      localTracks = await createLocalTracks({
        audio: { name: 'microphone', deviceId: this.audioInputDevice.deviceId },
        video: {
          height: 720,
          frameRate: 24,
          width: 1280,
          name: 'camera',
          facingMode: 'user',
          deviceId: this.videoInputDevice.deviceId
        }
      })
    } else if (this.audioInputDevice && !this.videoInputDevice) {
      // This is probably setup for audio only
      localTracks = await createLocalTracks({
        audio: { name: 'microphone', deviceId: this.audioInputDevice.deviceId },
        video: false
      })
    } else {
      // This is probably setup for monitor only
      localTracks = await createLocalTracks({
        audio: false,
        video: false
      })
    }

    console.log('LocalTracks', localTracks)

    const finalOpts = Object.assign({}, this.options, {
      name: this.roomName,
      tracks: localTracks
    })

    console.log('Final Options', finalOpts)

    const room = await connect(this.accessToken, finalOpts)

    const getLocalVideoTrack = () => {
      const trks = Array.from(room.localParticipant.videoTracks.values())
      if (trks.length === 0) {
        return null
      } else {
        return trks[0]['track']
      }
    }

    const getLocalAudioTrack = () => {
      const trks = Array.from(room.localParticipant.audioTracks.values())
      if (trks.length === 0) {
        return null
      } else {
        return trks[0]['track']
      }
    }

    console.log('Room is', room)

    const {
      dominantSpeaker,
      isRecording,
      localParticipant,
      name,
      participants,
      sid,
      state,
      mediaRegion
    } = room

    this.room = room

    // this.toggleAudio(!!this.audioInputDevice)
    // this.toggleVideo(!!this.videoInputDevice)

    console.log('Connected to room', {
      dominantSpeaker,
      isRecording,
      localParticipant,
      name,
      participants,
      sid,
      state,
      mediaRegion
    })

    room.participants.forEach(p => this.onParticipantConnected(p))

    // Happens when a participant enters the room
    room.on('participantConnected', p => this.onParticipantConnected(p))
    // Happens when participant exits the room
    room.on('participantDisconnected', p => this.onParticipantDisconnected(p))

    room.on('participantDisconnected', p => this.onParticipantDisconnected(p))

    const throttledOnDominantSpeakerChanged = throttle(
      () => {
        this.onDominantSpeakerChanged()
      },
      2000,
      { leading: false, trailing: true }
    )

    if (finalOpts) {
      room.on('dominantSpeakerChanged', _ => () => {
        throttledOnDominantSpeakerChanged()
      })
    }

    // THIS SECTION HANDLES ALL UNLOADING EVENTS

    window.onbeforeunload = () => {
      room.disconnect()
    }

    // if (isMobile) {
    //   // TODO(mmalavalli): investigate why "pagehide" is not working in iOS Safari.
    //   // In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead.
    //   window.onpagehide = () => {
    //     room.disconnect()
    //   }
    //
    //   // On mobile browsers, use "visibilitychange" event to determine when
    //   // the app is backgrounded or foregrounded.
    //   document.onvisibilitychange = async () => {
    //     if (document.visibilityState === 'hidden') {
    //       // When the app is backgrounded, your app can no longer capture
    //       // video frames. So, stop and unpublish the LocalVideoTrack.
    //       const vidTrack = getLocalVideoTrack()
    //       if (vidTrack) {
    //         vidTrack.stop()
    //         room.localParticipant.unpublishTrack(vidTrack)
    //       }
    //     } else if (this.videoInputDevice) {
    //       // When the app is foregrounded, your app can now continue to
    //       // capture video frames. So, publish a new LocalVideoTrack.
    //       const localVideoTrack = await createLocalVideoTrack(finalOpts.video)
    //       await room.localParticipant.publishTrack(localVideoTrack)
    //     }
    //   }
    // }

    room.once('disconnected', (room, error) => {
      // Clear the event handlers on document and window..
      window.onbeforeunload = null
      if (isMobile) {
        window.onpagehide = null
        document.onvisibilitychange = null
      }

      // Stop the LocalVideoTrack.
      const vidTrack = getLocalVideoTrack()
      if (vidTrack) {
        vidTrack.stop()
      }

      // Stop the LocalAudioTrack.
      const audioTrack = getLocalAudioTrack()
      if (audioTrack) {
        audioTrack.stop()
      }

      // Handle the disconnected LocalParticipant.
      this.onParticipantDisconnected(room.localParticipant)

      // Handle the disconnected RemoteParticipants.
      room.participants.forEach(participant => {
        this.onParticipantDisconnected(participant)
      })
    })

    const others = []
    room.participants.forEach(item => {
      others.push(item)
    })
    const totalParticipants = [room.localParticipant].concat(others)
    this.allParticipants$.next(totalParticipants)

    return this
  }

  getParticipants$(): Observable<Array<RoomConnectionParticipant>> {
    return this.participants$
  }

  getPinnedParticipant$(): Observable<RoomConnectionParticipant> {
    return this.pinnedParticipant$
  }

  getLocalParticipant$(): Observable<RoomConnectionParticipant> {
    return this.localParticipant$
  }

  getMonitorParticipants$(): Observable<Array<RoomConnectionParticipant>> {
    return this.monitorParticipants$
  }

  getNonMonitorParticipants$(): Observable<Array<RoomConnectionParticipant>> {
    return this.nonMonitorParticipants$
  }

  private getLocalTrack(kind: 'audio' | 'video') {
    return Array.from(
      kind === 'audio'
        ? this.room.localParticipant.audioTracks.values()
        : this.room.localParticipant.videoTracks.values()
    )[0]['track']
  }

  useAudioInput(media: MediaDeviceInfo) {
    this.audioInputDevice = media
    return useDeviceOnTrack(media.deviceId, this.getLocalTrack('audio'))
  }

  useVideoInput(media: MediaDeviceInfo) {
    this.videoInputDevice = media
    return useDeviceOnTrack(media.deviceId, this.getLocalTrack('video'))
  }

  toggleAudio(flag: boolean) {
    this.room.localParticipant.audioTracks.forEach(publication => {
      if (flag) publication.track.enable()
      else publication.track.disable()
    })
  }

  toggleVideo(flag: boolean) {
    this.room.localParticipant.videoTracks.forEach(publication => {
      if (flag) publication.track.enable()
      else publication.track.disable()
    })
  }

  async close(): Promise<number> {
    const numberOfParticipantsLeft = this.allParticipants$.getValue().length - 1
    // Must be invoked when local participant disconnects from the room
    this.participantSubscription.unsubscribe()
    this.room.disconnect()
    return numberOfParticipantsLeft
  }
}

const connectToRoom = async (
  accessToken: string,
  roomName: string,
  devices: any,
  options: any
): Promise<RoomConnection> => {
  // options.audio = { deviceId: { exact: audioInputDevice.deviceId } }
  // options.video.deviceId = { exact: videoInputDevice.deviceId }
  return new RoomConnection(
    accessToken,
    roomName,
    devices,
    Object.assign({}, defaultConnectOptions, options)
  )
}

export { connectToRoom, RoomConnectionParticipant, RoomConnection }
