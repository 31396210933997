<kendo-scheduler
  #scheduler
  [kendoSchedulerBinding]="editService.events | async"
  [modelFields]="editService.fields"
  [loading]="editService.loading"
  [editable]="true"
  [selectedViewIndex]="0"
  [selectedDate]="selectedDate"
  [resources]="schedulerResources"
  (slotClick)="slotClickHandler($event)"
  (slotDblClick)="slotDblClickHandler($event)"
  (eventDblClick)="eventDblClickHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (dragEnd)="dragEndHandler($event)"
  (resizeEnd)="resizeEndHandler($event)"
  scrollTime="08:00"
  fxFlex
  style="height: 740px;">
<!--  SCHEDULER VIEW OPTIONS -->
  <kendo-scheduler-month-view>
  </kendo-scheduler-month-view>
  <kendo-scheduler-week-view>
  </kendo-scheduler-week-view>
  <kendo-scheduler-day-view>
  </kendo-scheduler-day-view>

<!--  SCHEDULER TEMPLATES -->

  <ng-template kendoSchedulerMonthDaySlotTemplate let-date="date">
    <div [ngClass]="getSlotClassBasedOnDate(date)" class="date-line">
      <strong class="date-text">{{ date | kendoDate : 'dd'}}</strong>
    </div>
  </ng-template>
  <ng-template kendoSchedulerDateHeaderTemplate let-date="date">
    <div [ngClass]="getSlotClassBasedOnDate(date)" class="date-header">
      <strong  class="date-text">{{ date | kendoDate : 'M/dd' }}</strong>
    </div>
  </ng-template>

  <ng-template kendoSchedulerEventTemplate let-event="event" let-resources="resources">
    <span class="scheduler-event-time">{{ '[' + event['dataItem']['timeLabel'] + ']'}}</span>&nbsp;<span [title]="event.title" class="scheduler-event-title">{{ event.title }}</span>
  </ng-template>

  <ng-template kendoSchedulerEditDialogTemplate
               let-formGroup="formGroup" let-isNew="isNew" let-editMode="editMode" let-resources="resources">
    <div fxFlexFill fxLayout="column" fxLayoutGap="10px" class="form-area">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Title</div>
        <input fxFlex kendoTextBox placeholder="Title" [formControl]="formGroup.get('title')"/>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Description</div>
        <textarea kendoTextArea fxFlex placeholder="Description" [rows]="3" [autoSize]="false" [formControl]="formGroup.get('description')" ></textarea>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Type</div>
        <kendo-dropdownlist fxFlex="150px" [formControl]="formGroup.get('type')" [data]="eventTypesResource.data"
                            [valueField]="'value'" [textField]="'text'" [valuePrimitive]="true">
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div  fxFlexFill fxLayout="row">
              <div fxFlex="20" fxLayoutAlign="start center">
                <i [ngStyle]="{'color': dataItem.color}" class="fa fa-circle"></i>
              </div>
              <div fxFlex="80" fxLayoutAlign="start center"><span fxFlex="80">{{ dataItem.text }}</span></div>
            </div>
          </ng-template>

          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div  fxFlexFill fxLayout="row">
              <div fxFlex="20" fxLayoutAlign="start center">
                <i [ngStyle]="{'color': dataItem.color}" class="fa fa-circle"></i>
              </div>
              <div fxFlex="80" fxLayoutAlign="start center"><span fxFlex="80">{{ dataItem.text }}</span></div>
            </div>
          </ng-template>
        </kendo-dropdownlist>
        <div fxFlex class="spacer"></div>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Invitees</div>
        <kendo-multiselect
          fxFlex
          [formControl]="formGroup.get('invitees')"
          [data]="filteredInvitees"
          [placeholder]="'Name or Email'"
          [clearButton]="true"
          [filterable]="true"
          [textField]="'label'"
          [valueField]="'id'"
          [valuePrimitive]="false"
          [allowCustom]="true"
          [tagMapper]="inviteesTagMapper"
          [valueNormalizer]="inviteesValueNormalizer"
          (filterChange)="onInviteesFilterChanged($event)"
          (removeTag)="onInviteesRemove($event)"
        >
        </kendo-multiselect>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Start</div>
        <kendo-datepicker fxFlex="150px" [formControl]="formGroup.get('start')">
        </kendo-datepicker>
        <kendo-timepicker fxFlex="150px" [formControl]="formGroup.get('start')" *ngIf='!formGroup.controls.isAllDay.value'>
        </kendo-timepicker>
        <div fxFlex class="spacer"></div>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">End</div>
        <kendo-datepicker fxFlex="150px" [formControl]="formGroup.get('end')">
        </kendo-datepicker>
        <kendo-timepicker fxFlex="150px" [formControl]="formGroup.get('end')" *ngIf='!formGroup.controls.isAllDay.value'>
        </kendo-timepicker>
        <div fxFlex class="spacer"></div>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">All Day Event</div>
        <input type='checkbox' id='k-is-allday-chkbox' class='k-checkbox' [formControl]="formGroup.get('isAllDay')" />
      </div>

      <div *ngIf="isEditingSeries(editMode)" fxFlex fxLayout="column">
        <kendo-recurrence-editor [formControl]="formGroup.get('recurrenceRule')"
           [start]="formGroup.get('start').value">
        </kendo-recurrence-editor>
      </div>
    </div>
  </ng-template>


</kendo-scheduler>
