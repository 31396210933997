import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import {
  CancelEvent,
  CreateFormGroupArgs,
  CrudOperation,
  EditMode,
  EventClickEvent,
  RemoveEvent,
  Resource,
  SaveEvent,
  SchedulerComponent,
  SlotClickEvent
} from '@progress/kendo-angular-scheduler'
import '@progress/kendo-date-math/tz/regions/Europe'
import '@progress/kendo-date-math/tz/regions/NorthAmerica'
import { delay, filter, map, switchMap, tap } from 'rxjs/operators'
import { EventSchedulerEditService } from './event-scheduler-edit.service'
import {
  ListScheduledEventsQuery,
  ScheduledEventType,
  UserType
} from '../../API.service'
import { EventSchedulerResourceService } from './event-scheduler-resource.service'
import { ScheduledEventInvitee } from '../../shared/types/types'
import { from, Observable } from 'rxjs'

@Component({
  selector: 'app-event-scheduler',
  templateUrl: './event-scheduler.component.html',
  styleUrls: ['./event-scheduler.component.scss'],
  providers: [EventSchedulerEditService, EventSchedulerResourceService]
})
export class EventSchedulerComponent implements OnInit {
  @ViewChild('scheduler') scheduler: SchedulerComponent

  public selectedDate: Date
  public formGroup: FormGroup

  public schedulerResources: Resource[]
  public eventTypesResource: Resource

  public invitees: ScheduledEventInvitee[]
  public filteredInvitees: ScheduledEventInvitee[]

  constructor(
    public formBuilder: FormBuilder,
    public editService: EventSchedulerEditService,
    public resourceService: EventSchedulerResourceService
  ) {
    this.selectedDate = new Date()
    this.selectedDate.setHours(0)
    this.selectedDate.setMinutes(0)
    this.selectedDate.setSeconds(0)
    this.selectedDate.setMilliseconds(0)
  }

  public async ngOnInit(): Promise<void> {
    const { eventTypeResource } = await this.resourceService.getResources()
    this.schedulerResources = [eventTypeResource]
    this.eventTypesResource = eventTypeResource
    this.invitees = await this.resourceService.getInvitees()
    this.filteredInvitees = this.invitees.slice()
    await this.editService.loadEvents()
  }

  /**
   * Handle clicking a slot
   * @param sender
   * @param start
   * @param end
   * @param isAllDay
   */
  public slotClickHandler({ sender, start, end, isAllDay }: SlotClickEvent) {
    console.log('slotClickHandler', { start, end, isAllDay })
    // Change the selected date
    this.selectedDate = this.getTimelessCurrentDate(start)
  }

  /**
   * This handles double clicking a slot
   * @param sender
   * @param event
   */
  public slotDblClickHandler({
    sender,
    start,
    end,
    isAllDay
  }: SlotClickEvent): void {
    console.log('slotDblClickHandler', { sender, start, end, isAllDay })
    this.closeEditor(sender)

    this.formGroup = this.formBuilder.group({
      start: [start, Validators.required],
      end: [end, Validators.required],
      startTimezone: new FormControl(),
      endTimezone: new FormControl(),
      isAllDay: isAllDay,
      title: ['', [Validators.required, Validators.minLength(3)]],
      description: new FormControl(''),
      recurrenceRule: new FormControl(''),
      recurrenceID: new FormControl(''),
      type: [ScheduledEventType.GENERAL, Validators.required],
      invitees: [[]]
    })

    // Pass the responsibility to the schedulers own editForm
    sender.addEvent(this.formGroup)
  }

  /**
   * This handles double clicking an event
   * @param sender
   * @param event
   */
  public eventDblClickHandler({ sender, event }: EventClickEvent): void {
    console.log('eventDblClickHandler', { sender, event })
    this.closeEditor(sender)

    let dataItem = event.dataItem
    if (this.editService.isRecurring(dataItem)) {
      sender
        .openRecurringConfirmationDialog(CrudOperation.Edit)
        // The result will be undefined if the dialog was closed.
        .pipe(filter(editMode => editMode !== undefined))
        .subscribe((editMode: EditMode) => {
          if (editMode === EditMode.Series) {
            dataItem = this.editService.findRecurrenceMaster(dataItem)
          }
          this.formGroup = this.createFormGroup(dataItem)
          sender.editEvent(dataItem, { group: this.formGroup, mode: editMode })
        })
    } else {
      this.formGroup = this.createFormGroup(dataItem)
      sender.editEvent(dataItem, { group: this.formGroup })
    }
  }

  /**
   * This handles creating a form group given the dataItem/event object.
   * @param dataItem
   */
  public createFormGroup(dataItem: any): FormGroup {
    console.log('createFormGroup', dataItem)
    return this.formBuilder.group({
      start: [dataItem.start, Validators.required],
      end: [dataItem.end, Validators.required],
      startTimezone: [dataItem.startTimezone],
      endTimezone: [dataItem.endTimezone],
      isAllDay: [dataItem.isAllDay],
      title: [dataItem.title],
      description: [dataItem.description],
      recurrenceRule: [dataItem.recurrenceRule],
      recurrenceID: [dataItem.recurrenceID],
      type: [dataItem.type, Validators.required],
      invitees: [dataItem.invitees]
    })
  }

  /**
   * When cancel button is clicked
   * @param sender
   */
  public cancelHandler({ sender }: CancelEvent): void {
    console.log('cancelHandler', { sender })
    this.closeEditor(sender)
  }

  /**
   * When event block is deleted
   * @param sender
   * @param dataItem
   */
  public removeHandler({ sender, dataItem }: RemoveEvent): void {
    console.log('removeHandler', { sender, dataItem })
    if (this.editService.isRecurring(dataItem)) {
      sender
        .openRecurringConfirmationDialog(CrudOperation.Remove)
        // The result will be undefined if the dialog was closed.
        .pipe(filter(editMode => editMode !== undefined))
        .subscribe(editMode => {
          this.handleRemove(dataItem, editMode)
        })
    } else {
      sender.openRemoveConfirmationDialog().subscribe(shouldRemove => {
        if (shouldRemove) {
          this.editService.remove(dataItem)
        }
      })
    }
  }

  /**
   * Handle when save is clicked
   * @param scheduler
   * @private
   */
  public saveHandler({
    sender,
    formGroup,
    isNew,
    dataItem,
    mode
  }: SaveEvent): void {
    console.log('saveHandler', { sender, formGroup, isNew, dataItem, mode })
    if (formGroup.valid) {
      const formValue = formGroup.value

      if (isNew) {
        this.editService.create(formValue)
      } else {
        this.handleUpdate(dataItem, formValue, mode)
      }

      this.closeEditor(sender)
    }
  }

  /**
   * Handle dragging of the events
   * @param scheduler
   * @private
   */
  public dragEndHandler({ sender, event, start, end, isAllDay }): void {
    console.log('dragEndHandler', { sender, event, start, end, isAllDay })
    let value = { start, end, isAllDay }
    let dataItem = event.dataItem

    if (this.editService.isRecurring(dataItem)) {
      sender
        .openRecurringConfirmationDialog(CrudOperation.Edit)
        .pipe(filter(editMode => editMode !== undefined))
        .subscribe((editMode: EditMode) => {
          if (editMode === EditMode.Series) {
            dataItem = this.editService.findRecurrenceMaster(dataItem)
            value.start = this.seriesDate(
              dataItem.start,
              event.dataItem.start,
              start
            )
            value.end = this.seriesDate(dataItem.end, event.dataItem.end, end)
          } else {
            value = { ...dataItem, ...value }
          }

          this.handleUpdate(dataItem, value, editMode)
        })
    } else {
      this.handleUpdate(dataItem, value)
    }
  }

  /**
   * Handle resize of the events
   * @param scheduler
   * @private
   */
  public resizeEndHandler({ sender, event, start, end }): void {
    console.log('resizeEndHandler', { sender, event, start, end })
    let value = { start: start, end: end }
    let dataItem = event.dataItem

    if (this.editService.isRecurring(dataItem)) {
      sender
        .openRecurringConfirmationDialog(CrudOperation.Edit)
        .pipe(filter(editMode => editMode !== undefined))
        .subscribe((editMode: EditMode) => {
          if (editMode === EditMode.Series) {
            dataItem = this.editService.findRecurrenceMaster(dataItem)
            value.start = this.seriesDate(
              dataItem.start,
              event.dataItem.start,
              start
            )
            value.end = this.seriesDate(dataItem.end, event.dataItem.end, end)
          } else {
            value = { ...dataItem, ...value }
          }

          this.handleUpdate(dataItem, value, editMode)
        })
    } else {
      this.handleUpdate(dataItem, value)
    }
  }

  /**
   * Handle closing the editor
   * @param scheduler
   * @private
   */
  private closeEditor(scheduler: SchedulerComponent): void {
    console.log('closeEditor', { scheduler })
    scheduler.closeEvent()

    this.formGroup = undefined
  }

  /**
   * Happens when:
   * - Event changed due to drag
   * @param item
   * @param value
   * @param mode
   * @private
   */
  private handleUpdate(item: any, value: any, mode?: EditMode): void {
    console.log('handleUpdate', { item, value, mode })
    const service = this.editService
    if (mode === EditMode.Occurrence) {
      if (service.isException(item)) {
        service.update(item, value)
      } else {
        service.createException(item, value)
      }
    } else {
      // The item is non-recurring or we are editing the entire series.
      service.update(item, value)
    }
  }

  private handleRemove(item: any, mode: EditMode): void {
    console.log('handleRemove', { item, mode })
    const service = this.editService
    if (mode === EditMode.Series) {
      service.removeSeries(item)
    } else if (mode === EditMode.Occurrence) {
      if (service.isException(item)) {
        service.remove(item)
      } else {
        service.removeOccurrence(item)
      }
    } else {
      service.remove(item)
    }
  }

  private seriesDate(head: Date, occurence: Date, current: Date): Date {
    console.log('seriesDate', { head, occurence, current })
    const year =
      occurence.getFullYear() === current.getFullYear()
        ? head.getFullYear()
        : current.getFullYear()
    const month =
      occurence.getMonth() === current.getMonth()
        ? head.getMonth()
        : current.getMonth()
    const date =
      occurence.getDate() === current.getDate()
        ? head.getDate()
        : current.getDate()
    const hours =
      occurence.getHours() === current.getHours()
        ? head.getHours()
        : current.getHours()
    const minutes =
      occurence.getMinutes() === current.getMinutes()
        ? head.getMinutes()
        : current.getMinutes()

    return new Date(year, month, date, hours, minutes)
  }

  /**
   * Convenience method to create a date object and remove the time settings
   * @param date
   * @private
   */
  private getTimelessCurrentDate(date = new Date()): Date {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  }

  /**
   * Determines a slots' class name based on the date
   * it can be one or both or neither of selected-date or
   * current-date
   * @param date
   */
  public getSlotClassBasedOnDate(date) {
    // TODO: To optimize, maybe we can determine the slot class EACH DATA RELOAD instead of deriving it EVERY CYCLE
    const applicable = []
    const currentDate = this.getTimelessCurrentDate()
    if (this.selectedDate.getTime() === date.getTime()) {
      applicable.push('selected-date')
    }
    if (currentDate.getTime() === date.getTime()) {
      applicable.push('current-date')
    }
    return applicable.join(' ')
  }

  /**
   * Convenience method for checking if edit mode is series
   * @param editMode
   */
  public isEditingSeries(editMode: EditMode): boolean {
    return editMode === EditMode.Series
  }

  /**
   * tagMapper coverts an invitee object to its string tag form
   * @param tags
   */
  public inviteesTagMapper(tags) {
    return tags.map(({ name, type }) => {
      return type + ':' + name
    })
  }

  /**
   * inviteesValueNormalizer will turn a typed string into a valid object
   * to match the invitee object
   * @param text$
   */
  public inviteesValueNormalizer(text$: Observable<string>) {
    return text$.pipe(
      map((text: string) => {
        //search for matching item to avoid duplicates
        return {
          label: UserType.GUEST + ':' + text, // Use label property because of Kendo bug that causes the input to NOT use tagMapper initially
          id: text,
          name: text, //generate unique value for the custom item
          type: UserType.GUEST
        }
      })
    )
  }

  /**
   * This is called when the search filter changes in the invitees
   * suppose it needs to filter names from ALL inputs
   * @param value
   */
  public onInviteesFilterChanged(value) {
    // update the filteredInvitees array from a copy of all invitees
    this.filteredInvitees = this.invitees.slice().filter(invitee => {
      return invitee.name.toLowerCase().includes(value.toLowerCase())
    })
  }

  /**
   * This is called when the delete button (on the tag) is clicked in invitees input
   * @param value
   */
  public onInviteesRemove(value) {
    // using the invitees form control
    const invitees = this.formGroup.get('invitees').value
    console.log('Remove', value, 'From', invitees)
    // filter out the value that was deleted
    const newValue = invitees.filter(item => {
      const label = item.type + ':' + item.name
      return label !== value.dataItem
    })
    // then update the new value
    this.formGroup.get('invitees').setValue(newValue)
  }

  /**
   * This is called when the schedule/create event button is clicked
   */
  public createHandler() {
    // Schedule for now, use current time
    const start = new Date()
    // Length would be 30m by default
    const end = new Date(start.getTime() + 1.8e6)
    // Create a form
    this.formGroup = this.formBuilder.group({
      start: [start, Validators.required],
      end: [end, Validators.required],
      startTimezone: new FormControl(),
      endTimezone: new FormControl(),
      isAllDay: false,
      title: ['', [Validators.required, Validators.minLength(3)]],
      description: new FormControl(''),
      recurrenceRule: new FormControl(''),
      type: [ScheduledEventType.GENERAL, Validators.required],
      invitees: [[]]
    })
    // pass the form to the scheduler to process
    this.scheduler.addEvent(this.formGroup)
  }
}
