<div class="copy-sentence-question" fxFlexFill fxLayoutAlign="center center" >
  <div fxFlex="20"></div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
    <div fxFlex fxLayoutAlign="start center">
      <canvas #canvasElement [width]="500" [height]="60"></canvas>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <kendo-textbox fxFlex="500px" [(ngModel)]="input" (ngModelChange)="onChange($event)" placeholder='Copy sentence here.'></kendo-textbox>
    </div>
  </div>
  <div fxFlex="20"></div>
</div>
<!--Copy the following sentences-->

<!--Version 1 (original)-->
<!--Good citizens always wear stout shoes.-->

<!--Version 2:-->
<!--Young students learn well from creative teachers.-->

<!--Version 3:-->
<!--Shingled houses are usually seen in coastal communities.-->

<!--Version 4:-->
<!--Large puzzles with small pieces are quite challenging.-->

<!--Version 5:-->
<!--Solid houses always have good foundations.-->

<!--Version 6:-->
<!--The majority of people prefer warm, sunny weather.-->



<!--Question 15:-->
<!--Writing the sentence from earlier.-->

<!--Scoring:-->
<!--To be scored automatically by the program.  Any question to be flagged for CCM review and final points decision.-->

<!--Assign 1 point for each word remembered, up to a maximum of 6 points.  Spelling not important, provided the word is recognizable (use fuzzy logic).  Ignore extra words.  Ignore punctuation and capitalization.-->

<!--Box 10 maximum total possible points:  6-->

