<!-- PRETEST LIST -->
<div *ngIf="questionnaire" fxFlexFill fxLayout="row" class="write-up-questionnaire-container">
  <div fxFlex="100px" class="spacer" fxLayoutAlign="center center">
    <button kendoButton class='navigation-button'
            [iconClass]="'fa fa-caret-left fa-2x'"
            look='outline'
            [primary]='true'
            [disabled]="!hasPreviousSection()"
            (click)='previousSection()'></button>
  </div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" class="write-up-questionnaire">
    <div fxFlex="30px" class="spacer"></div>
    <div fxFlex="40px" fxLayoutAlign="center center">
      <kendo-stepper
        fxFlex
        [steps]="steps"
        [stepType]="'indicator'"
        [(currentStep)]="current"
        [linear]="false"
        [style.width.px]="570">
      </kendo-stepper>
      <button [disabled]="!writeUpService.isQuestionnaireValid(questionnaire,context)" (click)="saveWriteUp()" fxFlex="60px" look='outline' [primary]='true' kendoButton>Save</button>
    </div>
    <div fxFlex="15px" class="spacer"></div>
    <ng-container *ngIf="currentSection$ | async as currentSection">
      <div *ngIf="showSection(currentSection)" fxFlex fxLayout="column"
            fxLayoutGap="10px" class="write-up-section-container">
        <app-write-up-section
          [section]="currentSection"
          [questionnaire]="questionnaire"
          [context]="context">
        </app-write-up-section>
      </div>
    </ng-container>
    <div fxFlex="30px" class="spacer"></div>
  </div>
  <div fxFlex="100px" class="spacer" fxLayoutAlign="center center">
    <button kendoButton class='navigation-button'
            [iconClass]="'fa fa-caret-right fa-2x'"
            look='outline'
            [primary]='true'
            [disabled]="!hasNextSection()"
            (click)='nextSection()'></button>
  </div>
</div>
