<div class="location" fxLayout="column" fxFlexFill *ngIf="location">
  <div class="header" fxLayout="row">
    <div fxFlex fxLayout="row" class="info">
      <div class="avatar">
        <img src="/assets/images/no-image.png" />
      </div>
      <div class="description">
        <div class="title">{{location.name}}</div>
        <div class="owner" *ngIf="location.owner.name; else elseBlock">{{location.owner.name}} {{location.owner.email}})</div>
        <ng-template #elseBlock>
          <div class="owner">James Kucharski (jason@qwantegy.com)</div>
        </ng-template>
        <div fxLayout="row" class="details">
          <span>Office ID: #{{location.id}}</span>
          <span class="separator"></span>
          <span>App ID: 123</span>
          <span class="separator"></span>
          <span>Group: 3</span>
          <span class="separator"></span>
          <span>Invoice Status: Current</span>
        </div>
      </div>
    </div>
    <div class="statistic" fxLayout="row">
      <div fxLayout="column" fxLayoutAlign="center center">
        <span>{{location.testsCount}}</span>
        <span>Assessments</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <span>{{location.physicianCount}}</span>
        <span>Physicians</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <span>{{location.usersCount}}</span>
        <span>Users</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <span>15</span>
        <span>Resources</span>
      </div>
    </div>
  </div>
  <div class="location-tabs">
    <kendo-tabstrip>
      <kendo-tabstrip-tab [title]="'Assessments'" [selected]="true">
        <ng-template kendoTabContent>
          <app-location-assessment [location]="location"></app-location-assessment>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Physicians'">
        <ng-template kendoTabContent>
          <app-location-physicians></app-location-physicians>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Users'">
        <ng-template kendoTabContent>
          <app-location-users [location]="location"></app-location-users>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Resources'">
        <ng-template kendoTabContent>
          <app-location-resources [location]="location"></app-location-resources>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Payment'">
        <ng-template kendoTabContent>
          <app-location-payments [location]="location"></app-location-payments>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Payment Method'">
        <ng-template kendoTabContent>
          <app-location-payment-methods></app-location-payment-methods>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Admin'">
        <ng-template kendoTabContent>
          <app-location-admin [location]="location"></app-location-admin>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
