<div class="resources-section">
  <div fxLayout="row wrap" fxLayoutGap="20px grid" fxFlex>
    <div *ngFor="let resource of resources" fxFlex="25" fxLayout="column" fxLayoutAlign="space-evenly center">
      <button kendoButton (click)="goToPage(resource.path)" fxFlexFill fxLayout="column wrap" fxLayoutAlign="space-evenly center">
        <!--<kendo-svg-icon [size]="'xlarge'" [icon]="icons[resource.icon]"></kendo-svg-icon>-->
        <img [src]="'/assets/' + resource.src" class="shadow" style="width:50px;"/>
        <div class="resource-title">{{resource.text}}</div>
        <div class="resource-title-short">{{resource.short || resource.text}}</div>
      </button>
    </div>
  </div>
</div>
