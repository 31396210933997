<div fxFlexFill fxLayoutAlign="center center">
  <div fxFlex fxLayoutGap="10px" fxLayout="column" class="live-room-settings">
    <div fxFlex fxLayoutGap="10px" fxLayout="row">
      <div fxFlex="100px" fxLayoutAlign="start center">Audio Input</div>
      <kendo-dropdownlist fxFlex
                          (valueChange)="onSelectAudioInput($event)"
                          [(value)]="audioInputDevice"
                          [data]="audioInputDevices"
                          [textField]="'label'"
                          [valueField]="'deviceId'">
      </kendo-dropdownlist>
    </div>
    <div fxFlex fxLayoutGap="10px" fxLayout="row">
      <div fxFlex="100px" fxLayoutAlign="start center">Video Input</div>
      <kendo-dropdownlist fxFlex
                          (valueChange)="onSelectVideoInput($event)"
                          [(value)]="videoInputDevice"
                          [data]="videoInputDevices"
                          [textField]="'label'"
                          [valueField]="'deviceId'">
      </kendo-dropdownlist>
    </div>
    <div fxFlex="10px" class="spacer"></div>
    <div fxFlex fxLayoutAlign="end center" fxLayout="row">
      <button kendoButton [primary]="true" (click)="save()">Save</button>
      <button kendoButton (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>

