<div class="bres-panel bres-panel-info">
    <div class="bres-panel-content">
        <div class="bres-panel-title"><i class="k-icon k-i-dictionary-add"></i>&nbsp;Billing</div>
        <div class="bres-panel-body">
            <kendo-grid
                [data]="gridView"
                [pageSize]="pageSize"
                [skip]="skip"
                [pageable]="true"
                [height]="400"
                (pageChange)="pageChange($event)">
                    <ng-template kendoGridToolbarTemplate>
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header p-10">
                            <kendo-buttongroup [selection]="'single'" fxFlex="20" fxLayout="row">
                                <button kendoButton fxFlex [toggleable]="true" [selected]="true">PDF</button>
                                <button kendoButton fxFlex [toggleable]="true">Print</button>
                            </kendo-buttongroup>
                            <kendo-textbox placeholder="Filter" [clearButton]="true"></kendo-textbox>
                        </div>
                    </ng-template>
                    <kendo-grid-column field="dateOfService" title="Date of service"></kendo-grid-column>
                    <kendo-grid-column field="billingPhysician" title="Billing Physician"></kendo-grid-column>
                    <kendo-grid-column field="billed" title="Billed"></kendo-grid-column>
                    <kendo-grid-column field="cpt" title="CPT (Diagnosis)"></kendo-grid-column>
                    <kendo-grid-column field="insurance" title="Insurance"></kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
</div>