<kendo-tabstrip fxFlex>
    <kendo-tabstrip-tab [title]="'Calls'" [selected]="true">
      <ng-template kendoTabContent>
        <div fxLayout="column" fxFill>
          <div *ngFor="let call of calls" class="call-item" fxFlex="10" fxLayout="row">
            <div fxFlex fxLayoutAlign="start center">{{call.name}}</div>
            <div fxFlex="30" fxLayoutAlign="center center">{{call.date}}</div>
            <div fxFlex="15" fxLayoutAlign="center center">{{call.elapsed}}</div>
            <div fxFlex="15" fxLayoutAlign="end center">
              <button kendoButton [iconClass]="'fa fa-phone'"></button>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Voicemail'" [selected]="false">
      <ng-template kendoTabContent>
        <div fxLayout="column" fxFill>
          <div *ngFor="let voiceMail of voiceMails" class="call-item" fxFlex="10" fxLayout="row">
            <div fxFlex fxLayoutAlign="start center">{{voiceMail.name}}</div>
            <div fxFlex="30" fxLayoutAlign="center center">{{voiceMail.date}}</div>
            <div fxFlex="15" fxLayoutAlign="center center">{{voiceMail.elapsed}}</div>
            <div fxFlex="15" fxLayoutAlign="end center">
              <button kendoButton [iconClass]="'fa fa-voicemail'"></button>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'SMS'" [selected]="false">
      <ng-template kendoTabContent>
        <div fxLayout="column" fxFill>
          <div *ngFor="let message of messages" class="call-item" fxFlex="10" fxLayout="row">
            <div fxFlex fxLayoutAlign="start center">{{message.name}}</div>
            <div fxFlex="30" fxLayoutAlign="center center">{{message.date}}</div>
            <div fxFlex="15" fxLayoutAlign="center center">{{message.elapsed}}</div>
            <div fxFlex="15" fxLayoutAlign="end center">
              <button kendoButton [iconClass]="'fa fa-envelope'"></button>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
