<div class="enumeration-question" fxFlexFill fxLayout="column" fxLayoutGap="20px">
  <div fxFlex="30px" fxLayoutAlign="center center">{{questionMeta.text}}</div>
  <div fxFlex="350px" fxLayoutAlign="center center">
    <canvas #canvasElement [width]="500" [height]="350"></canvas>
  </div>
  <div fxFlex fxLayoutAlign="start center">
    <button kendoButton [icon]="'reset'" (click)="resetCanvas()">Reset Drawing</button>
  </div>
</div>

<!--Question 13:-->
<!--As in the original version, all of the indicated shapes in the array are used to form the letter indicated.  No additional random indicated shapes appear in the array.  The program must allow the patient to trace on the page to connect the indicated shapes, not just select the shapes.  This is important for the scoring.-->

<!--Version 1:-->
<!--Please join the circles together to form a letter (ignore the squares) – Letter W-->

<!--Version 2:-->
<!--Please join the triangles together to form a letter (ignore the circles) –  Letter S-->

<!--Version 3:-->
<!--Please join the squares together to form a letter (ignore the triangles) – Letter M-->

<!--Version 4:-->
<!--Please join circles together to form a letter ( ignore the triangles ) - Letter U-->

<!--Version 5:-->
<!--Please join the squares together to form a letter (ignore the circles) – Letter A-->

<!--Version 6:-->
<!--Join the triangles together to form a letter (ignore the squares) – Letter O-->
<!--Scoring:-->
<!--3 points:  All the indicated shapes have been touched along the trace line to form the indicated letter (if tracing begins incorrectly but is corrected midway, still award 3 points)-->
<!--2 points:  if another letter if formed (i.e. V instead of W)-->
<!--1 point:  all the indicated shapes touched by the tracing or in another way selected (i.e. circling them), but no letter formed-->

<!--Box 8 maximum possible points: 3-->

<!--Box 9 Drawing (Clock)-->

<!--Question 14:-->

<!--Version 1:-->
<!--Please draw in a clock face, put in the numbers 1-12 and place the hands at 9:20-->
<!--Please draw in a clock face, put in the numbers 1-12 and place the hands at 11:35-->
<!--Please draw in a clock face, put in the numbers 1-12 and place the hands at 6:45-->
<!--Please draw in a clock face, put in the numbers 1-12 and place the hands at 7:05-->
<!--Please draw in a clock face, put in the numbers 1-12 and place the hands at 4:50-->
<!--Please draw in a clock face, put in the numbers 1-12 and place the hands at 5:35-->



<!--Scoring:-->
<!--To be scored by hand.-->

<!--Assign 1 point if the numbers 1-12 listed. No points if the number 0 is used.-->

<!--Assign additional 1 point: The numbers 1-12 are in the correct order and fairly appropriately spaced around the circle, and that the clock hands meet approximately at the center.  No points if these are mistaken.-->

<!--Assign additional 1 point: for each hand that is pointing to the correct number (or close to it).  Full 2 points awarded even if the long hand and short hand are reversed.-->

<!--Box 9 maximum potential points: 4-->
