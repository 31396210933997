<div class="chat-list-container" fxLayout="column" fxLayoutGap="12px">
  <div class="chat-list-search" fxFlex="30px" fxLayout="row">
    <div fxFlex fxLayout="row">
      <kendo-multiselect
        [data]="users$ | async"
        [placeholder]="'Search a User'"
        [clearButton]="true"
        [filterable]="true"
        [textField]="'name'"
        [valueField]="'id'"
        [valuePrimitive]="false"
        [(value)]="selectedUsers"
        (valueChange)="onChatUserSelectionChanged($event)"
        (filterChange)="onChatUserFilterChanged($event)">
      </kendo-multiselect>
    </div>
    <div *ngIf="selectedUsers.length" fxFlex="30px" fxLayout="row" fxLayoutAlign="center center">
      <a class="toggle-create" (click)="toggleShowCreateGroup()"><i class="fa" [ngClass]="{'fa-chevron-up': showCreateGroup,'fa-chevron-down': !showCreateGroup  }"></i></a>
    </div>
  </div>
  <div *ngIf="selectedUsers.length && showCreateGroup" class="chat-list-search" fxFlex="30px" fxLayout="row">
    <div fxFlex fxLayout="row">
      <input fxFlex kendoTextBox [(ngModel)]="groupNameInput"/>
    </div>
    <div fxFlex="70px" fxLayout="row">
      <button [primary]="true" fxFlex kendoButton (click)="onCreateConversation()">Create</button>
    </div>
  </div>
  <div class="chat-list-items" fxFlex fxLayout="column">
    <div *ngFor="let conversation of filteredConversations$ | async" class="chat-list-item" fxLayoutGap="5px" fxLayout="row" (click)="selectConversation(conversation)">
      <div fxFlex="32px" fxLayoutAlign="start center">
        <kendo-avatar [shape]="'circle'" [initials]="conversation.initials"></kendo-avatar>
      </div>
      <div fxFlex="5px" class="spacer"></div>
      <div fxFlex fxLayout="column">
        <div fxFlex="16px" fxLayoutAlign="start center" class="chat-list-item-title">{{ conversation.title }}</div>
        <div fxFlex="16px" fxLayoutAlign="start center" class="chat-list-item-description">{{ conversation.lastMessage }}</div>
      </div>
      <div fxFlex="50px" *ngIf="conversation.status!== 'GROUP'" [ngClass]="conversation.status" fxLayoutAlign="end center">
        <span class="chat-list-item-status">{{ conversation.status }}</span>
      </div>
      <div fxFlex="32px" fxLayoutAlign="center center" [ngClass]="conversation.status">
        <i class="fa " [ngClass]="{'fa-users': conversation.status === 'GROUP','fa-circle': conversation.status !== 'GROUP'}"></i>
      </div>
    </div>
  </div>
</div>
