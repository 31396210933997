<div class="active-users-section" fxFlexFill fxLayout="column">
    <div *ngFor="let user of users" class="active-user-block" fxLayoutAlign="center center" fxFlex="35" fxLayout="row">
      <div class="active-user-avatar" fxFlex="15">
        <kendo-avatar *ngIf="user.img!=null" [shape]="'circle'" [imageSrc]="user.img"></kendo-avatar>
        <kendo-avatar *ngIf="user.img==null" [shape]="'circle'" [initials]="user.initials"></kendo-avatar>
      </div>
      <div class="active-user-details" fxFlex="45" fxLayout="column">
        <span class="active-user-details-name">{{ user.name }}</span>
        <span class="active-user-details-role">{{ user.role }}</span>
      </div>
      <div class="active-user-status" fxFlex="30">
        <span class="active-user-details-status">{{ user.status }}</span>
      </div>
      <div class="active-user-action" fxFlex="10">
        <button kendoButton [primary]="true" [icon]="'envelop'"></button>
      </div>
    </div>
</div>
