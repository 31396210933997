import { Component, Input, OnInit } from '@angular/core'
import {
  WriteUpContext,
  WriteUpQuestionnaire,
  WriteUpSection,
  WriteUpStatement
} from '../../write-up.type'
import { FormControl, FormGroup } from '@angular/forms'
import { WriteUpService } from '../../write-up.service'

@Component({
  selector: 'app-write-up-statement',
  templateUrl: './write-up-statement.component.html',
  styleUrls: ['./write-up-statement.component.scss']
})
export class WriteUpStatementComponent implements OnInit {
  /**
   * Main context object
   */
  @Input('context') context: WriteUpContext
  /**
   * Section this component represents
   */
  @Input('questionnaire') questionnaire: WriteUpQuestionnaire
  /**
   * Section this component represents
   */
  @Input('section') section: WriteUpSection
  /**
   * Statement parent this component represents
   */
  @Input('parent') parent: WriteUpStatement | WriteUpSection
  /**
   * Statement this component represents
   */
  @Input('statement') statement: WriteUpStatement
  /**
   * Statement this component represents
   */
  @Input('indent') indent: number
  constructor(public writeUpService: WriteUpService) {}

  ngOnInit(): void {}

  isStatementNotSatisfied(): boolean {
    const statement = this.statement
    if (statement.required) {
      // If this statement is required
      const notSatisfied = !statement.options.find(o => o.selected || o.checked)
      return notSatisfied
    }
    return false
  }

  showStatement(statement: WriteUpStatement): boolean {
    return this.writeUpService.isStatementVisible(statement, this.context)
  }

  applyUpdate(event) {
    console.log('Applying update', event)
    if ('checked' in this.statement && this.statement.checked) {
      if ('readonly' in this.parent && this.parent?.readonly) {
        if (this.parent?.type === 'checkbox') {
          this.parent.checked = true
        }
      }
    }
  }
}
