import { ConsoleUser } from '../shared/types/types'

export type WriteUpContext = {
  userType: WriteUpUser
}

export type WriteUpQuestionnaire = {
  sections: WriteUpSection[]
}

export type WriteUpSection = {
  name: string
  groups: string[] // HIDDEN IF EMPTY, when it is undefined, EVERYONE can see it
  id?: string
  qualified?: boolean // if false, DONT RENDER / IF missing default to true
  description?: string
  statements: WriteUpStatement[]
}

export type WriteUpStatement = {
  id?: string // GENERATE
  type?: string
  text?: string
  groups?: string[] // HIDDEN IF EMPTY, when it is undefined, EVERYONE can see it
  checked?: any // checkbox is selected
  selected?: any // selection (radio) is selected
  hint?: string // Tooltip text to show next to the text
  auto?: boolean // IGNORE! Just a placeholder
  paragraph?: boolean // IGNORE! Just a placeholder
  required?: boolean // (Only used in radio buttons), put a required marking
  input?: boolean // Flag to tell if it is editable
  readonly?: boolean // CANT select a checkbox; can only select based on the child
  prepend?: string // String to prepend (BEFORE) the text
  append?: string // String to append (AFTER) the text
  silent?: boolean // IGNORE! from system
  qualified?: boolean // if false, DONT RENDER / IF missing default to true
  options?: WriteUpStatement[]
}

export enum WriteUpUser {
  Provider = 'Provider',
  Manager = 'Manager',
  Other = 'Other'
}
