import { Component, OnInit, ViewChild } from '@angular/core'
import { EventSchedulerComponent } from './event-scheduler/event-scheduler.component'

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {
  @ViewChild('eventScheduler') eventScheduler: EventSchedulerComponent

  constructor() {}

  ngOnInit(): void {}

  openScheduleDialog() {
    this.eventScheduler.createHandler()
  }
}
