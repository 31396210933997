import { Component, OnDestroy, OnInit } from '@angular/core';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import {
  APIService,
  ResourceCategory,
} from '../API.service';
import { OfficeFilterService } from '../services/office-filter.service';
import { Subscription, zip } from 'rxjs';
import StringHelper from '../shared/helpers/StringHelper';
import { RESOURCE_CATEGORY_COLOR, RESOURCE_CATEGORY_TEXT } from '../shared/constants';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit, OnDestroy {
  tab = 0;
  events: SchedulerEvent[] = [];
  eventResources: any[] = [];
  selectedDate: Date = new Date();
  loadingEvents = false;
  currentAccount;
  currentLocation;
  accountLocationSub: Subscription;
  resourceCategoryColor = RESOURCE_CATEGORY_COLOR;
  categoriesList = StringHelper.enumToArray(ResourceCategory).map(r => ({id: r, name: RESOURCE_CATEGORY_TEXT[r] ? RESOURCE_CATEGORY_TEXT[r] : r}));
  // submitting = false;
  createResource = false;

  constructor(
    private apiService: APIService,
    private officeFilterService: OfficeFilterService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.accountLocationSub = zip(
      this.officeFilterService.selectedAccount$,
      this.officeFilterService.selectedLocation$
    ).subscribe(([account, location]) => {
      if (account && location) {
        this.currentLocation = location;
        this.currentAccount = account;

        this.fetchResourceScheduledEvents();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.accountLocationSub) { this.accountLocationSub.unsubscribe(); }
  }

  async fetchResourceScheduledEvents(): Promise<void> {
    this.loadingEvents = true;
    try {
      const { items } = await this.apiService.ListResourceScheduledEvents(
        {
          accountId: { eq: this.currentAccount.id },
          locationId: { eq: this.currentLocation.id }
        }
      );

      this.events = items.map(e => (
        {
          id: `${e.category}+${e.id}`,
          start: new Date(e.start),
          startTimezone: e.startTimezone,
          end: new Date(e.end),
          endTimezone: e.endTimezone,
          isAllDay: e.isAllDay,
          title: e.title,
          description: e.description,
          recurrenceRule: e.recurrenceRule,
          recurrenceId: e.recurrenceId,
          // recurrenceException: e.recurrenceException
          // roomId: randomInt(1, 2),
          // attendees: e.invitees,
          resourceCategory: e.category
        } as SchedulerEvent
      ));

      this.eventResources = [
        {
          name: 'Resources',
          data: StringHelper.enumToArray(ResourceCategory).map(r => ({
            text: RESOURCE_CATEGORY_TEXT[r] ? RESOURCE_CATEGORY_TEXT[r] : r,
            value: r,
            color: RESOURCE_CATEGORY_COLOR[r] ? RESOURCE_CATEGORY_COLOR[r] : 'red'
          })),
          field: 'resourceCategory',
          valueField: 'value',
          textField: 'text',
          colorField: 'color'
        }
      ];
    } catch (e) {
      console.error(e);
    }
    this.loadingEvents = false;
  }

  changeTab(t: number): void {
    this.tab = t;
  }

  onResourceDelete(): void {
    this.fetchResourceScheduledEvents();
  }

  onResourceEdit(): void {
    this.fetchResourceScheduledEvents();
  }

  onResourceCreate(): void {
    this.fetchResourceScheduledEvents();
  }

  newResource(): void {
    this.createResource = true;
  }
}
