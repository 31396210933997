import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SessionService } from '../../../services/session.service'
import {
  APIService,
  AssessmentStatus,
  AssessmentType,
  RoomType,
  ScheduledEventAssessmentInput,
  ScheduledEventType,
  UserType
} from '../../../API.service'
import { TestApiService } from '../../../testing'
import { OfficeFilterService } from '../../../services/office-filter.service'

@Component({
  selector: 'app-telehealth-schedule-dialog',
  templateUrl: './telehealth-schedule-dialog.component.html',
  styleUrls: ['./telehealth-schedule-dialog.component.scss']
})
export class TelehealthScheduleDialogComponent implements OnInit {
  @Input() open: boolean
  @Output() openChange = new EventEmitter<boolean>()
  @Output() onCreate = new EventEmitter<any>()
  @Output() onCancel = new EventEmitter<any>()

  // TODO: For Supers implement a account selection
  ownerInput: any
  patientInput: any
  patients: Array<any> = []
  filteredPatients: Array<any> = []

  titleInput = ''
  descriptionInput = ''

  roomTypeInput = RoomType.AUDIO
  roomTypes = [RoomType.AUDIO, RoomType.VIDEO]

  selectedDate: Date
  selectedLength = { label: '15m', value: 900000 }
  timeLengths = [
    { label: '15m', value: 900000 },
    { label: '30m', value: 1.8e6 },
    { label: '45m', value: 2.7e6 },
    { label: '1h', value: 3.6e6 },
    { label: '1h15m', value: 4.5e6 },
    { label: '1h30m', value: 5.4e6 },
    { label: '1h45m', value: 6.3e6 },
    { label: '2h', value: 7.2e6 }
  ]

  tests: any[]
  selectedTests: ScheduledEventAssessmentInput[]

  constructor(
    private sessionService: SessionService,
    private apiService: APIService,
    private testApiService: TestApiService,
    private officeFilterService: OfficeFilterService
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedDate = new Date()
    this.ownerInput = this.sessionService.currentUser$.getValue()
    this.tests = []
    this.selectedTests = []
    await this.initPatients()
    await this.initTests()
  }

  isSuper$(): Observable<boolean> {
    return this.sessionService.userType$.pipe(map(v => v === 'Super'))
  }

  async initTests() {
    // Load the tests, and select only the title field
    const { objects: tests } = await this.testApiService.listTests(
      {},
      { select: ['title'] }
    )
    this.tests = tests
    console.log('Loaded tests', tests)
  }

  async initPatients() {
    const locationId = this.officeFilterService.selectedLocation$.getValue()?.id
    const accountId = this.officeFilterService.selectedAccount$.getValue()?.id

    const { items: patients } = await this.apiService.ListPatients({
      accountId: { eq: accountId }
    })
    console.log('Patients', patients)
    this.patients = patients.map(p => {
      // @ts-ignore
      p.fullName = p.givenName + ' ' + p.familyName
      return p
    })
    this.filteredPatients = this.patients.slice()
  }

  async create() {
    const locationId = this.officeFilterService.selectedLocation$.getValue()?.id
    const accountId = this.officeFilterService.selectedAccount$.getValue()?.id
    // P is a patient object
    const p = this.patientInput
    // O is a user object
    const o = this.ownerInput

    // Create the tests as assessments
    const assessments = await Promise.all(
      this.selectedTests.map(test => {
        return this.apiService.CreateAssessment({
          accountId: accountId,
          locationId: locationId,
          patientId: p.id,
          managerId: o.id,
          scheduledDate: new Date().toISOString(),
          status: AssessmentStatus.SCHEDULED,
          meta: 0,
          type: AssessmentType.ASSESSMENT,
          title: test.name,
          testId: test.id
        })
      })
    )

    // Create a scheduled event
    const result = await this.apiService.CreateScheduledEvent({
      participantIds: [p.id, o.id].filter(id => !!id),
      assessments: assessments.map(assessment => {
        return {
          name: assessment.title,
          id: assessment.id
        }
      }),
      invitees: [
        {
          name: `${p.givenName} ${p.familyName}`,
          id: p.id,
          type: UserType.PATIENT
        },
        {
          name: `${o.firstName} ${o.lastName}`,
          id: o.id,
          type: o.userType
        }
      ],
      ownerId: this.ownerInput.id,
      title: this.titleInput,
      accountId: accountId,
      type: ScheduledEventType.TELEHEALTH,
      start: this.selectedDate.toISOString(),
      end: new Date(
        this.selectedDate.getTime() + this.selectedLength.value
      ).toISOString(),
      isAllDay: false,
      description: this.descriptionInput,
      recurrenceExceptions: '',
      recurrenceRule: ''
    })

    this.onCreate.emit(result)
    this.openChange.emit((this.open = false))
  }

  handlePatientFilter(value) {
    this.filteredPatients = this.patients.slice().filter(p => {
      return p.fullName.toLowerCase().includes(value.toLowerCase())
    })
  }

  handlePatientSelect(fullName) {
    console.log(fullName)
  }

  cancel() {
    this.openChange.emit((this.open = false))
    this.onCancel.emit()
  }

  /**
   * When user selects tests, map it to selectedTests
   * @param tests
   */
  onSelectedTestsChange(tests) {
    this.selectedTests = tests.map(t => {
      return {
        name: t.title,
        id: t.id
      }
    })
  }
}
