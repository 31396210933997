import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-location-physicians',
  templateUrl: './physicians.component.html',
  styleUrls: ['./physicians.component.scss']
})
export class LocationPhysiciansComponent implements OnInit {
  physicians = [
    {
      name: 'Jason Rappaport',
      email: 'jason@qwantegy.com',
      patients: Math.floor(Math.random() * 100) + 1,
      assessments: Math.floor(Math.random() * 100) + 1,
      lastAssessment: new Date().getTime(),
      lastLogin: new Date().getTime()
    },
    {
      name: 'James Kucharski',
      email: 'jim@qwantegy.com',
      patients: Math.floor(Math.random() * 100) + 1,
      assessments: Math.floor(Math.random() * 100) + 1,
      lastAssessment: new Date().getTime(),
      lastLogin: new Date().getTime()
    },
    {
      name: 'Barry Manilow',
      email: 'barry@qwantegy.com',
      patients: Math.floor(Math.random() * 100) + 1,
      assessments: Math.floor(Math.random() * 100) + 1,
      lastAssessment: new Date().getTime(),
      lastLogin: new Date().getTime()
    },
    {
      name: 'Tom Jones',
      email: 'tom@qwantegy.com',
      patients: Math.floor(Math.random() * 100) + 1,
      assessments: Math.floor(Math.random() * 100) + 1,
      lastAssessment: new Date().getTime(),
      lastLogin: new Date().getTime()
    },
    {
      name: 'Bruce Wayne',
      email: 'bruce@qwantegy.com',
      patients: Math.floor(Math.random() * 100) + 1,
      assessments: Math.floor(Math.random() * 100) + 1,
      lastAssessment: new Date().getTime(),
      lastLogin: new Date().getTime()
    }
  ]

  constructor() {}

  ngOnInit(): void {}
}
