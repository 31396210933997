import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-quicklook-section',
  templateUrl: './quicklook-section.component.html',
  styleUrls: ['./quicklook-section.component.scss']
})
export class QuicklookSectionComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  async goToApprovals() {
    console.log('Going to Approvals')
    await this.router.navigate(['approvals'])
  }
}
