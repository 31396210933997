import { Injectable } from '@angular/core'
import { APIService, ContactType, ResourceType } from '../API.service'
import { SessionService } from './session.service'

@Injectable({
  providedIn: 'root'
})
export class SeedService {
  constructor(
    private api: APIService,
    private sessionService: SessionService
  ) {}

  async up() {
    const user = this.sessionService.currentUser$.getValue()
    //
    // const neuro = await this.api.CreateProgram({
    //   name: 'neuropsychological',
    //   questionnaireId: '7f00a198-df98-43a3-b652-4a24134ef961'
    // })
    //
    // console.log('Created neuro', neuro)
    //
    // const psycho = await this.api.CreateProgram({
    //   name: 'psychological',
    //   questionnaireId: 'c95dee3c-a0ec-4dca-9bbc-d7e7ce1fdb8d'
    // })
    //
    // console.log('Created Psycho', psycho)
    //
    // const mgpcog = await this.api.CreateProgramTest({
    //   name: 'MGPCOG',
    //   programTestsId: neuro.id,
    //   referenceTestId: '6023950d294096000931e453'
    // })
    //
    // console.log('Created mgpcog', mgpcog)
    //
    // const tym = await this.api.CreateProgramTest({
    //   name: 'TYM',
    //   programTestsId: neuro.id,
    //   referenceTestId: '5ff09a4481eaf60008dfbf37'
    // })
    //
    // console.log('Created tym', tym)
    //
    // const cesdr = await this.api.CreateProgramTest({
    //   name: 'CESD-R',
    //   programTestsId: psycho.id,
    //   referenceTestId: '6023a0f1733f4c000902031d'
    // })
    //
    // console.log('Created cesdr', cesdr)
    //
    // const harm = await this.api.CreateProgramTest({
    //   name: 'HARM',
    //   programTestsId: psycho.id,
    //   referenceTestId: '602661c85e2e680008920d7e'
    // })
    //
    // console.log('Created harm', harm)
    //
    // const phq9 = await this.api.CreateProgramTest({
    //   name: 'PHQ-9',
    //   programTestsId: psycho.id,
    //   referenceTestId: '6023a5ae97d0f90009ecf9b6'
    // })
    //
    // console.log('Created phq9', phq9)

    const userId = user.id
    const accountId = user.accountId
    const locationId = user.locations.items[0].location.id

    console.log('ENCOUNTER!!!!!', await this.api.ListEncounters())

    // await this.api.CreateEventResource({
    //   locationId: locationId,
    //   address: {
    //     coordinates: { lat: 0, lon: 0 },
    //     postal: '1608',
    //     country: 'United States',
    //     line1: '176 Harrow Street, Hoboken',
    //     line2: 'Unit 123, Apartment XYZ',
    //     region: 'New Jersey'
    //   },
    //   contact: {
    //     contactType: ContactType.BUSINESS,
    //     email: 'info@hoboken.medcity.com',
    //     familyName: 'Harton',
    //     givenName: 'Bradley'
    //   },
    //   name: 'Medical City Game Room',
    //   accountId: accountId,
    //   type: ResourceType.Physical,
    //   billedTime: 5,
    //   businessHours: '8am - 5pm WEEKDAYS',
    //   ownerId: userId,
    //   website: 'hoboken.medcity.com'
    // })
    //
    // await this.api.CreateEventResource({
    //   locationId: locationId,
    //   address: null,
    //   name: 'Medcity Game Online Games',
    //   accountId: accountId,
    //   type: ResourceType.Virtual,
    //   billedTime: 5,
    //   ownerId: userId,
    //   website: 'hoboken.medcity.com'
    // })
    //
    // console.log('!!!!!! EVENT RESOURCES', await this.api.ListEventResources())
    //
    // const { items: patients } = await this.api.ListPatients()
    // const patientId = patients[0].id
    //
    // const encounter = await this.api.CreateEncounter({
    //   accountId: accountId,
    //   managerId: userId,
    //   date: new Date().toISOString(),
    //   patientId: patientId,
    //   locationId: locationId,
    //   encounterPretestId: '68b4d6a6-0cee-4a9c-ac2c-2e82a97aba2b',
    //   ownerId: userId
    // })
    //
    // await this.api.UpdateAssessment({
    //   id: '92422d4a-d74a-4904-8dfe-27a2dd04b0d4',
    //   encounterAssessmentsId: encounter.id
    // })

    //
    // const invoice = await this.api.CreateInvoice({
    //   accountId: accountId,
    //   billDate: new Date('2021/01/15').toISOString(),
    //   locationId: locationId,
    //   dueDate: new Date('2021/02/15').toISOString(),
    //   totalBill: 200,
    //   billingPeriodStartDate: new Date('2020/12/05').toISOString(),
    //   billingPeriodEndDate: new Date('2021/01/05').toISOString()
    // })
    //
    // const invoiceId = invoice.id
    //
    // const payment = await this.api.CreatePayment({
    //   accountId: accountId,
    //   locationId: locationId,
    //   paymentMethod: {
    //     cardExpiry: new Date('2021/11/10').toISOString(),
    //     issuer: 'Mastercard',
    //     last4Digits: '0123',
    //     paymentToken: 'XXX'
    //   },
    //   paymentInvoiceId: invoiceId,
    //   userId,
    //   paymentDate: new Date().toISOString()
    // })
    //
    // const assessment = await this.api.CreateAssessment({
    //   accountID: accountId,
    //   assessed: 'Patient is good',
    //   managerID: userId,
    //   patientID: patients[0].id,
    //   locationID: locationId,
    //   type: 'MGPCOG',
    //   score: 5,
    //   result: 'Patient is fine'
    // })
    //
    // const assessmentId = assessment.id
    //
    // this.api.UpdateLocation({
    //   id: user.location.id,
    //   locationLastLoginUserId: user.id,
    //   physicianCount: 5,
    //   testsCount: 10,
    //   usersCount: 7,
    //   address: {
    //     coordinates: { lat: 0, lon: 0 },
    //     postal: '1608',
    //     country: 'United States',
    //     line1: '176 Harrow Street, Hoboken',
    //     line2: 'Unit 123, Apartment XYZ',
    //     region: 'New Jersey'
    //   },
    //   frontDesk: {
    //     contactType: ContactType.BUSINESS,
    //     email: 'info@hoboken.medcity.com',
    //     familyName: 'Harton',
    //     givenName: 'Bradley'
    //   },
    //   name: 'Hoboken Medcity',
    //   locationLastPaymentId: payment.id,
    //   accountID: accountId,
    //   locationLastAssessmentId: assessmentId
    // })
    // const user = this.sessionService.currentUser$.getValue()
    // const accountID = user.accountID
    // const locationID = user.location.id
    // const patients = (
    //   await this.api.ListPatients({ accountID: { eq: accountID } })
    // ).items
    //
    // patients.forEach(patient => {
    //   console.log('Creating Treatment for')
    //   this.api.CreateTreatmentPlan({
    //     accountID,
    //     created: new Date().toString(),
    //     locationID,
    //     patientID: patient.id,
    //     treatmentPlanManagerId: user.id,
    //     providers: []
    //   })
    // })
    // await this.upAccounts()
    // await this.upLocations()
    // await this.upPatients()
    // await this.upUsers()
  }

  async down() {
    // await this.downPatients()
    // await this.downUsers()
    // await this.downLocations()
    // await this.downAccounts()
  }

  private async upAccounts(): Promise<void> {
    await this.api.CreateAccount({
      id: 'account-id',
      name: 'Medical City',
      owner: ''
    })
  }

  private async downAccounts(): Promise<void> {
    await this.api.DeleteAccount({ id: 'account-id' })
  }

  private async upLocations(): Promise<void> {
    await this.api.CreateLocation({
      id: 'location-id',
      accountId: 'account-id',
      name: 'New Haven'
    })
  }

  private async downLocations(): Promise<void> {
    await this.api.DeleteLocation({ id: 'location-id' })
  }

  private async upUsers(): Promise<void> {
    await this.api.CreateUser({
      id: 'user-id',
      accountId: 'account-id',
      email: 'admin@medical-city.com'
    })
  }

  private async downUsers(): Promise<void> {
    await this.api.DeleteUser({ id: 'user-id' })
  }

  private async upPatients(): Promise<void> {
    await this.api.CreatePatient({
      id: 'patient-id',
      givenName: 'Theodore',
      familyName: 'Mosby',
      accountId: 'account-id',
      locationId: 'location-id',
      owner: 'owner'
    })
  }

  private async downPatients(): Promise<void> {
    await this.api.DeletePatient({ id: 'patient-id' })
  }
}
