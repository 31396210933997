import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { APIService, GetUserQuery } from '../API.service'
import { SessionService } from './session.service'
// TODO: This import is "broken" according to the compiler;
//  BUT this is the only workaround, if you use 'twilio-chat' instead,
//  it will actually BREAK!
// @ts-ignore
import * as Twilio from 'twilio-chat/lib'
import { Channel } from 'twilio-chat/lib/channel'
import { createDeferred } from '../util'
import { getIdsFromIdentifier } from '../common/chat/lib/helper'
import { Conversation } from '../common/chat/lib/chat-types'

@Injectable({
  providedIn: 'root'
})
export class ChatService implements OnInit, OnDestroy {
  /**
   * This variable is a double check that this function is executed
   * only once outside of the service
   * @private
   */
  private initialized: boolean = false
  /**
   * Actual twilio client
   */
  public chatClient: Twilio.Client
  /**
   * Paginator of the subscribed list of channels
   * This must be deferred because of timing issues
   * encountered before
   */
  public paginator: any
  /**
   * Identity used by this chat service
   * this is equivalent to the user id.
   */
  public identity: string

  public readonly responses: Subject<string> = new Subject<string>()

  constructor(
    private apiService: APIService,
    private sessionService: SessionService
  ) {
    // NOTE: The paginator is a special case because
    // It needs to be deferred so that the time of loading matches
    this.paginator = createDeferred()
  }

  public async initialize(user: GetUserQuery) {
    console.log('Initializing ChatService..')
    if (!this.initialized) {
      // Refresh token is to handle if the session has lost access and has
      // To refresh its access
      const refreshToken = async () => {
        const {
          accessToken,
          identity
        } = await this.apiService.RequestChatAccess({})
        this.chatClient.updateToken(accessToken)
        this.identity = identity
      }

      // Request for the access
      const { accessToken, identity } = await this.apiService.RequestChatAccess(
        {}
      )

      // Save the identity used in the access
      this.identity = identity

      console.log('Initialized Chat Service', { accessToken, identity })

      // Create a chat client
      this.chatClient = await Twilio.Client.create(accessToken)

      // Setup listeners so that we don't lose access
      this.chatClient.on('tokenAboutToExpire', function () {
        refreshToken()
      })

      // if the access token already expired, refresh it
      this.chatClient.on('tokenExpired', function () {
        refreshToken()
      })

      // Only resolve the paginator when it is actually ready
      this.paginator.resolve(await this.chatClient.getSubscribedChannels({}))
    }
  }

  /**
   * Opens or Crates a channel between the current user and another user
   * @param conversation
   */
  public async openConversation(conversation: Conversation): Promise<Channel> {
    console.log('Opening Conversation..', { conversation })
    let channel: Channel = null
    // Add the following people in the channel
    const ids = getIdsFromIdentifier(conversation.id)

    try {
      // The ID is used as the uniqueName
      channel = await this.chatClient.getChannelByUniqueName(conversation.id)
    } catch (err) {
      console.log('Cannot get channel', { channel, err })
    }

    if (!channel) {
      // If channel does not exist, then we need to create it first
      console.log('Creating channel from conversation', {
        uniqueName: conversation.id
      })

      channel = await this.chatClient.createChannel({
        friendlyName: conversation.name,
        uniqueName: conversation.id,
        isPrivate: true
      })
    }

    // If you are opening a conversation, it only means you can access it
    // So try joining. An error will be caught and that should be fine.
    try {
      console.log('Joining channel', { channel })
      await channel.join()
    } catch (err) {
      console.log('Failed to join channel', { channel, err })
    }

    // Suppose this is a new channel, so we add the rest of
    // the users into the channel
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i]
      try {
        const ret = await channel.add(id)
        console.log('Added to channel', { id, ret })
      } catch (err) {
        console.log('Failed to add to channel', { id, err })
      }
    }

    console.log('Opened Channel', { channel })

    return channel
  }

  /**
   * Get the deferred paginator
   */
  public getSubscribedChannelsPaginator() {
    return this.paginator
  }

  async ngOnInit(): Promise<void> {}
  async ngOnDestroy(): Promise<void> {
    await this.chatClient.shutdown()
  }
}
