import { Component, OnInit } from '@angular/core'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { PageChangeEvent } from '@progress/kendo-angular-dropdowns/dist/es2015/common/page-change-event'

@Component({
  selector: 'app-patient-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  public gridView: GridDataResult
  public pageSize = 10
  public skip = 0
  private data: Object[]

  private items: any[] = []

  constructor() {
    for (let i = 1; i < 23; i++) {
      this.items.push({
        id: i,
        date: 'Nov 20',
        note:
          'John Does completed all assessments without any complaints. He appeared to be in a happy mood'
      })
    }
    this.loadItems()
  }

  ngOnInit() {}

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip
    this.loadItems()
  }

  private loadItems(): void {
    this.gridView = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    }
  }
}
