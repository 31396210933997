import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import 'hammerjs'
import { BreAppRoutingModule } from './bre-app-routing.module'
import { BreAppComponent } from './bre-app.component'
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular'
import { DashboardComponent } from './dashboard/dashboard.component'
import { HeaderComponent } from './common/header/header.component'
import { FooterComponent } from './common/footer/footer.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { PatientsComponent } from './patients/patients.component'
import { AssessmentsComponent } from './assessments/assessments.component'
import { RemotePatientMonitoringComponent } from './remote-patient-monitoring/remote-patient-monitoring.component'
import { TreatmentActionPlansComponent } from './treatment-action-plans/treatment-action-plans.component'
import { BillingComponent } from './billing/billing.component'
import { SchedulingComponent } from './scheduling/scheduling.component'
import { AccountSettingsComponent } from './account-settings/account-settings.component'
import { FaqComponent } from './faq/faq.component'
import { LoginComponent } from './login/login.component'
import { ButtonsModule } from '@progress/kendo-angular-buttons'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LayoutModule } from '@progress/kendo-angular-layout'
import { IndicatorsModule } from '@progress/kendo-angular-indicators'
import { InputsModule } from '@progress/kendo-angular-inputs'
import { IconsModule } from '@progress/kendo-angular-icons'
import { NavigationModule } from '@progress/kendo-angular-navigation'
import { GaugesModule } from '@progress/kendo-angular-gauges'
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout'
import { NavComponent } from './common/nav/nav.component'
import { TelehealthComponent } from './telehealth/telehealth.component'
import { QuicklookSectionComponent } from './dashboard/parts/quicklook-section/quicklook-section.component'
import { ResourceSectionComponent } from './dashboard/parts/resource-section/resource-section.component'
import { RecentActivitySectionComponent } from './dashboard/parts/recent-activity-section/recent-activity-section.component'
import { ActiveUsersSectionComponent } from './dashboard/parts/active-users-section/active-users-section.component'
import { TrendsSectionComponent } from './dashboard/parts/trends-section/trends-section.component'
import { SearchSectionComponent } from './dashboard/parts/search-section/search-section.component'
import { GridModule } from '@progress/kendo-angular-grid'
import { BnNgIdleService } from 'bn-ng-idle'
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http'
import { AccountsComponent } from './accounts/accounts.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LabelModule } from '@progress/kendo-angular-label'
import { DropDownsModule } from '@progress/kendo-angular-dropdowns'
import { DialogsModule } from '@progress/kendo-angular-dialog'
import { LocationsComponent } from './locations/locations.component'
import { UsersComponent } from './users/users.component'
import { LiveRoomComponent } from './live-room/live-room.component'
import { LiveRoomParticipantComponent } from './live-room/parts/live-room-participant/live-room-participant.component'
import { LiveRoomSettingsComponent } from './live-room/parts/live-room-settings/live-room-settings.component'
import { RingNotificationComponent } from './shared/notification-components/ring-notification/ring-notification.component'
import { NotificationModule } from '@progress/kendo-angular-notification'
import { LiveRoomTransferComponent } from './live-room/parts/live-room-transfer/live-room-transfer.component'
import { TransferNotificationComponent } from './shared/notification-components/transfer-notification/transfer-notification.component'
import {
  SchedulerModule,
  ViewsSharedModule
} from '@progress/kendo-angular-scheduler'
import { EventSchedulerComponent } from './scheduling/event-scheduler/event-scheduler.component'
import { TelehealthTableSectionComponent } from './telehealth/parts/telehealth-table-section/telehealth-table-section.component'
import { TelehealthSidetabSectionComponent } from './telehealth/parts/telehealth-sidetab-section/telehealth-sidetab-section.component'
import { TelehealthScheduleDialogComponent } from './telehealth/dialog/telehealth-schedule-dialog/telehealth-schedule-dialog.component'
import {
  DatePickerModule,
  DateTimePickerModule,
  TimePickerModule
} from '@progress/kendo-angular-dateinputs'
import { IntlModule } from '@progress/kendo-angular-intl'
import { ChatComponent } from './common/chat/chat.component'
import { ChatModule } from '@progress/kendo-angular-conversational-ui'
import { ChatConversationComponent } from './common/chat/chat-conversation/chat-conversation.component'
import { ChatListComponent } from './common/chat/chat-list/chat-list.component'
import {
  CompletionQuestionComponent,
  CopySentenceQuestionComponent,
  DrawingQuestionComponent,
  EnumerationQuestionComponent,
  InstructionQuestionComponent,
  LabelingQuestionComponent,
  MultipleChoiceQuestionComponent,
  QuestionComponent,
  ScoreAnswerComponent,
  ScoreCompletionComponent,
  ScoreComponent,
  ScoreCopySentenceComponent,
  ScoreDrawingComponent,
  ScoreEnumerationComponent,
  ScoreLabelingComponent,
  ScoreMultipleChoiceComponent,
  ScoreSectionComponent,
  ScoreShortEssayComponent,
  ScoreSingleChoiceComponent,
  ShortEssayQuestionComponent,
  SingleChoiceQuestionComponent,
  ScoreInstructionComponent,
  TestComponent,
  TestSectionComponent,
  YesNoQuestionComponent
} from './testing'
import { TelehealthAssessmentComponent } from './telehealth/parts/telehealth-assessment/telehealth-assessment.component'
import { MarkdownModule } from 'ngx-markdown'
import { CallHudComponent } from './shared/components/call-hud/call-hud.component'
import { VoiceHudComponent } from './shared/components/voice-hud/voice-hud.component'
import { CustomDatePipe } from './shared/pipes/custom-date/custom-date.pipe'
import { LocationComponent } from './location/location.component'
import { LocationAssessmentComponent } from './location/assessments/assessments.component'
import { LocationPhysiciansComponent } from './location/physicians/physicians.component'
import { LocationResourcesComponent } from './location/resources/resources.component'
import { LocationPaymentsComponent } from './location/payments/payments.component'
import { LocationPaymentMethodsComponent } from './location/payment-methods/payment-methods.component'
import { LocationAdminComponent } from './location/admin/admin.component'
import { LocationUsersComponent } from './location/users/users.component'
import { LocationAddUserComponent } from './location/users/add-user/add-user.component'
import { ApprovalsComponent } from './approvals/approvals.component'
import { PretestApprovalsPopupComponent } from './shared/components/pretest-approvals-popup/pretest-approvals-popup.component'
import { PopupModule } from '@progress/kendo-angular-popup'
import { PendingPretestNotificationComponent } from './shared/notification-components/pending-pretest-notification/pending-pretest-notification.component'
import { ResourcesComponent } from './resources/resources.component'
import { ResourcesListComponent } from './shared/components/resources/resources-list/resources-list.component'
import { ColorPipe } from './shared/pipes/color/color.pipe'
import { ResourceScheduledEventsComponent } from './shared/components/resources/resources-list/resource-scheduled-events/resource-scheduled-events.component'
import { PatientChartsComponent } from './patient-charts/patient-charts.component'
import { PatientChartAssessmentsComponent } from './patient-charts/assessments/assessments.component'
import { PatientAssessmentsComponent } from './patient-assessments/patient-assessments.component'
import { ChartsModule } from '@progress/kendo-angular-charts'
import { PatientsDetailComponent } from './patients-detail/patients-detail.component';
import { TimelineComponent } from './patients-detail/timeline/timeline.component';
import { BillingComponent as PatientBillingComponent } from './patients-detail/billing/billing.component';
import { TreatmentsComponent } from './patients-detail/treatments/treatments.component';
import { ChartsComponent } from './patients-detail/charts/charts.component';
import { CommunicationComponent } from './patients-detail/communication/communication.component';
import { WriteUpComponent } from './write-up/write-up.component';
import { WriteUpSectionComponent } from './write-up/write-up-section/write-up-section.component';
import { WriteUpStatementComponent } from './write-up/write-up-section/write-up-statement/write-up-statement.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { SelectSoapNotesComponent } from './select-soap-notes/select-soap-notes.component';
import { EncounterComponent } from './encounter/encounter.component';
import { EncounterAssessmentsComponent } from './encounter/encounter-assessments/encounter-assessments.component';
import { EncounterSoapItemsComponent } from './encounter/encounter-soap-items/encounter-soap-items.component';
import { EncounterQuestionnairesComponent } from './encounter/encounter-questionnaires/encounter-questionnaires.component';
import { EncounterWriteUpsComponent } from './encounter/encounter-write-ups/encounter-write-ups.component';


@NgModule({
  declarations: [
    BreAppComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    PatientsComponent,
    AssessmentsComponent,
    RemotePatientMonitoringComponent,
    TreatmentActionPlansComponent,
    BillingComponent,
    SchedulingComponent,
    AccountSettingsComponent,
    FaqComponent,
    LoginComponent,
    NavComponent,
    TelehealthComponent,
    QuicklookSectionComponent,
    ResourceSectionComponent,
    RecentActivitySectionComponent,
    ActiveUsersSectionComponent,
    TrendsSectionComponent,
    SearchSectionComponent,
    AccountsComponent,
    LocationsComponent,
    UsersComponent,
    LiveRoomComponent,
    LiveRoomParticipantComponent,
    LiveRoomSettingsComponent,
    RingNotificationComponent,
    LiveRoomTransferComponent,
    TransferNotificationComponent,
    EventSchedulerComponent,
    TelehealthTableSectionComponent,
    TelehealthSidetabSectionComponent,
    TelehealthScheduleDialogComponent,
    ChatComponent,
    ChatConversationComponent,
    ChatListComponent,
    TestComponent,
    QuestionComponent,
    TestSectionComponent,
    SingleChoiceQuestionComponent,
    MultipleChoiceQuestionComponent,
    CompletionQuestionComponent,
    CopySentenceQuestionComponent,
    EnumerationQuestionComponent,
    ShortEssayQuestionComponent,
    LabelingQuestionComponent,
    DrawingQuestionComponent,
    InstructionQuestionComponent,
    YesNoQuestionComponent,
    ScoreComponent,
    ScoreSectionComponent,
    ScoreAnswerComponent,
    ScoreCompletionComponent,
    ScoreCopySentenceComponent,
    ScoreDrawingComponent,
    ScoreEnumerationComponent,
    ScoreLabelingComponent,
    ScoreMultipleChoiceComponent,
    ScoreShortEssayComponent,
    ScoreSingleChoiceComponent,
    ScoreInstructionComponent,
    TelehealthAssessmentComponent,
    CallHudComponent,
    VoiceHudComponent,
    CustomDatePipe,
    LocationComponent,
    LocationAssessmentComponent,
    LocationPhysiciansComponent,
    LocationResourcesComponent,
    LocationPaymentsComponent,
    LocationPaymentMethodsComponent,
    LocationAdminComponent,
    LocationUsersComponent,
    LocationAddUserComponent,
    ApprovalsComponent,
    PretestApprovalsPopupComponent,
    PendingPretestNotificationComponent,
    ResourcesComponent,
    ResourcesListComponent,
    ResourceScheduledEventsComponent,
    ColorPipe,
    PatientChartsComponent,
    PatientChartAssessmentsComponent,
    PatientAssessmentsComponent,
    PatientsDetailComponent,
    TimelineComponent,
    ChartsComponent,
    ChartsComponent,
    TreatmentsComponent,
    PatientBillingComponent,
    CommunicationComponent,
    WriteUpComponent,
    WriteUpSectionComponent,
    WriteUpStatementComponent,
    SelectSoapNotesComponent,
    EncounterComponent,
    EncounterAssessmentsComponent,
    EncounterSoapItemsComponent,
    EncounterQuestionnairesComponent,
    EncounterWriteUpsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BreAppRoutingModule,
    AmplifyUIAngularModule,
    ButtonsModule,
    BrowserAnimationsModule,
    LayoutModule,
    IndicatorsModule,
    InputsModule,
    IconsModule,
    NavigationModule,
    FlexModule,
    FlexLayoutModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    LabelModule,
    DropDownsModule,
    DialogsModule,
    NotificationModule,
    SchedulerModule,
    DateTimePickerModule,
    DatePickerModule,
    TimePickerModule,
    IntlModule,
    ChatModule,
    MarkdownModule.forRoot(),
    PopupModule,
    GaugesModule,
    ChartsModule,
    TooltipModule
  ],
  providers: [BnNgIdleService],
  bootstrap: [BreAppComponent]
})
export class BreAppModule {
}
