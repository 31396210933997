<span class="table heading">Resources</span>
<div class="resources has-loader">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="tabs">
      <button (click)="changeTab(0)" [ngClass]="{'selected': tab === 0}" kendoButton [primary]="tab === 0">Calendar</button>
      <button (click)="changeTab(1)" [ngClass]="{'selected': tab === 1}" kendoButton [primary]="tab === 1">Resources</button>
    </div>
    <div class="status-note" *ngIf="tab === 0" fxLayout="row wrap" style="width: 300px">
      <label *ngFor="let cat of categoriesList" fxFlex="0 0 33%">
        <span [ngStyle]="{'color': resourceCategoryColor[cat.id] | color:'darken':0.5, 'background-color': resourceCategoryColor[cat.id]}">{{cat.name}}</span>
      </label>
    </div>
    <div class="add" *ngIf="tab === 1">
      <button kendoButton [primary]="true" (click)="newResource()">New Resource</button>
    </div>
  </div>
  <div class="content">
    <div class="scheduler py-10" *ngIf="tab === 0">
      <kendo-scheduler [kendoSchedulerBinding]="events"
                       [resources]="eventResources"
                       [selectedViewIndex]="1"
                       [selectedDate]="selectedDate"
                       style="height: auto">

          <ng-template kendoSchedulerEventTemplate let-event="event" let-resource="resource" let-resources="resources">
            <div class="full-height item" fxLayoutAlign="start center" [ngStyle]="{'color': resourceCategoryColor[event.resourceCategory] | color:'darken':0.5}">
              <i [ngStyle]="{'color': resourceCategoryColor[event.resourceCategory] | color:'darken':0.5}" class="fa fa-circle"></i>
             {{event.start | customDate:false:'hh:mma' | lowercase}} {{ event.title }}
            </div>
          </ng-template>

          <kendo-scheduler-day-view startTime="07:00">
          </kendo-scheduler-day-view>

          <kendo-scheduler-month-view [eventHeight]="30">
          </kendo-scheduler-month-view>
        </kendo-scheduler>
      <div *ngIf="loadingEvents" class="k-i-loading"></div>
    </div>
    <div class="resources-content" *ngIf="tab === 1">
      <div style="margin-top: 10px">
        <app-resources-list-component *ngIf="currentLocation"
                                      [account]="currentAccount"
                                      [location]="currentLocation"
                                      [(createResource)]="createResource"
                                      [creatable]="true"
                                      [deletable]="true"
                                      [editable]="true"
                                      (onDelete)="onResourceDelete()"
                                      (onCreate)="onResourceCreate()"
                                      (onEdit)="onResourceEdit()">
        </app-resources-list-component>
      </div>
    </div>
  </div>

  <div *ngIf="loadingEvents" class="k-i-loading"></div>
</div>
