import { Component, OnInit, Input } from '@angular/core'
import {
  APIService,
  GetEncounterQuery,
  SoapItemType,
  GetPatientQuery,
  BilledTimeType
} from '../API.service'
import { ActivatedRoute, Router } from '@angular/router'
import { SessionService } from '../services/session.service'
import { BillingTimerService } from '../services/billing-timer.service'

@Component({
  selector: 'app-select-soap-notes',
  templateUrl: './select-soap-notes.component.html',
  styleUrls: ['./select-soap-notes.component.scss']
})
export class SelectSoapNotesComponent implements OnInit {
  @Input('encounter') encounter: GetEncounterQuery
  patient: GetPatientQuery
  soapMappings: Array<SoapMapping>
  redirectTo: string
  isEmbedded: boolean
  isInEditMode: boolean

  constructor(
    private apiService: APIService,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private billingTimerService: BillingTimerService,
    private router: Router
  ) {
    this.encounter = null
    this.soapMappings = null
    this.isEmbedded = false
    this.isInEditMode = false
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      console.log('Loaded Select Soap Notes Component', this.encounter)
      if (this.encounter) {
        // @ts-ignore
        this.patient = this.encounter.patient
        // Grab all the REPORTED
        this.soapMappings = this.mapToSoapMappings(
          this.encounter.soapItems || []
        )
        this.isEmbedded = true
      } else {
        if (params.encounterId) {
          this.encounter = await this.apiService.GetEncounter(
            params.encounterId
          )
          // @ts-ignore
          this.patient = this.encounter.patient
          // Grab all the REPORTED
          this.soapMappings = this.mapToSoapMappings(
            this.encounter.soapItems || []
          )

          if (params.redirectTo) {
            this.redirectTo = params.redirectTo
          }
        }
        this.isEmbedded = true
      }
    })
  }

  mapToSoapMappings(soapItems: Array<SoapItem>): Array<SoapMapping> {
    const soapMappings: Array<SoapMapping> = []

    soapItems.forEach(soapItem => {
      const findSoapMapping = soapMappings.find(s => s.code === soapItem.code)

      const keyName = {
        REPORTED: 'reported',
        OBSERVED: 'observed',
        DIAGNOSED: 'diagnosed'
      }[soapItem.type]

      const mappedSoapItem = {
        selected: soapItem.selected,
        text: soapItem.text,
        type: soapItem.type,
        code: soapItem.code
      }

      if (findSoapMapping) {
        // if it was found
        findSoapMapping[keyName] = mappedSoapItem
      } else {
        soapMappings.push({
          code: soapItem.code,
          [keyName]: mappedSoapItem
        })
      }
    })

    return soapMappings
  }

  mapFromSoapMap(soapMappings: Array<SoapMapping>): Array<SoapItem> {
    const soapItems: Array<SoapItem> = []
    soapMappings.forEach(soapMapping => {
      if (soapMapping.observed) soapItems.push(soapMapping.observed)
      if (soapMapping.diagnosed) soapItems.push(soapMapping.diagnosed)
      if (soapMapping.reported) soapItems.push(soapMapping.reported)
    })
    return soapItems
  }

  async saveChanges() {
    this.isInEditMode = false
    // In this section we need to bill this time
    const timerInfo = this.billingTimerService.stopTimer()

    console.log(this.soapMappings)

    // Save the selected SOAP mappings
    await this.apiService.UpdateEncounter({
      id: this.encounter.id,
      soapItems: this.mapFromSoapMap(this.soapMappings)
    })

    // Save the SOAP notes
    const currentUser = this.sessionService.currentUser$.getValue()

    const billedTime = await this.apiService.CreateBilledTime({
      date: timerInfo.start,
      type: BilledTimeType.ADMINISTRATIVE,
      minutes: timerInfo.billedMinutes,
      userId: currentUser.id
    })

    console.log('Created BilledTime', billedTime)

    const encounterBilledTimeConnection = await this.apiService.CreateEncounterBilledTimeConnection(
      {
        accountId: currentUser.accountId,
        billedTimeId: billedTime.id,
        encounterId: this.encounter.id
      }
    )
    console.log(
      'Created Encounter BilledTime Connection',
      encounterBilledTimeConnection
    )

    if (!this.isEmbedded) {
      this.close()
    }
  }

  cancel() {
    console.log(this.soapMappings)
    this.isInEditMode = false
    this.billingTimerService.stopTimer()
    if (!this.isEmbedded) {
      this.close()
    }
  }

  activateEditMode() {
    this.isInEditMode = true
    this.billingTimerService.startTimer()
  }

  async close() {
    const redirectTo = this.redirectTo
      ? decodeURI(this.redirectTo).split('/')
      : ['']
    await this.router.navigate(redirectTo)
  }
}

export type SoapItem = {
  type: SoapItemType | null
  text: string | null
  code: string | null
  selected: boolean | null
}

export type SoapMapping = {
  code: string
  reported?: SoapItem
  observed?: SoapItem
  diagnosed?: SoapItem
}
