<div *ngIf="soapItems && (soapItems.length > 0)" fxFlexFill class="bres-panel bres-panel-info">
  <div class="bres-panel-content">
    <div class="bres-panel-title"><i class="k-icon k-i-dictionary-add"></i>&nbsp;Select SOAP Items</div>
    <div class="bres-panel-body">
      <app-write-up [encounter]="encounter"></app-write-up>
    </div>
  </div>
</div>

<div fxFlexFill *ngIf="!soapItems || (soapItems && soapItems.length === 0)" fxLayoutAlign="center center">
  <div fxFlex fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="bres-panel-blank bres-panel-blank-secondary">
    <span>No SOAP items reported for this patient</span>
  </div>
</div>
