<div class="score-enumeration" fxFlexFill fxLayout="column" fxLayoutGap="20px">
  <div fxFlex="30px" fxLayoutAlign="center center">{{answer.questionRef.text}}</div>
  <div fxFlex fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center start">
    <div *ngFor="let blank of blanks" fxFlex="40px" fxLayout="row">
      <kendo-textbox [disabled]="true" fxFlex placeholder="" [(ngModel)]="inputs[blank]">
        <ng-template kendoTextBoxPrefixTemplate>
          <div [style]="'width:30px; text-align: center;'">{{blank}}</div>
          <kendo-textbox-separator></kendo-textbox-separator>
        </ng-template>
      </kendo-textbox>
    </div>
  </div>
</div>
