import { Test, TestType } from '../test/test'
import { Question, QuestionType } from '../test/question'
import { Section, SectionType } from '../test/section'

const accountId = 'b8600834-815b-49ad-bafa-b4d3338a0d2c'
const ownerId = '46040efb-36b8-4249-a724-db1d6bfd370b'

const question0_1: Question = {
  id: 'section0-1', // questionId
  type: QuestionType.MULTIPLE,
  scoring: {
    isAutomatic: true,
    maximumScore: 2
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Which of the following are dogs?',
      items: [
        {
          value: 'Dog1',
          image: 'https://i.imgur.com/A1S7txx.jpg'
        },
        {
          value: 'Cat1',
          image: 'https://i.imgur.com/JSvfGii.jpeg'
        },
        {
          value: 'Cat2',
          image: 'https://i.imgur.com/vrTQxaL.jpeg'
        },
        {
          value: 'Dog2',
          image: 'https://i.imgur.com/yNQJkqM.jpeg'
        }
      ],
      scoring: {
        scorer: {
          name: 'MultipleChoiceScorer',
          inputs: { correct: ['Dog1', 'Dog2'] }
        }
      }
      // scoring: {
      //   scorer: `
      //     const correct = ['Dog1','Dog2'];
      //     const score = Object.keys(answer).reduce((acc,cur)=>{
      //       if(answer[cur] && correct.includes(cur)) {
      //       // If ticked and it is correct
      //         return acc + 1;
      //       } else if (answer[cur] && !correct.includes(cur)) {
      //         // If ticked and it is wrong
      //         return acc - 1;
      //       }
      //       return acc;
      //     },0);
      //     return score > 0? score: 0;
      //   `
      // }
    }
  ]
}

const question0_2: Question = {
  id: 'section0-2', // questionId
  type: QuestionType.SINGLE,
  scoring: {
    isAutomatic: true,
    maximumScore: 1
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Please choose the clock that represents the time 9:30',
      items: [
        {
          image: 'https://i.imgur.com/Al7EJeC.png',
          value: '1:15pm'
        },
        { image: 'https://i.imgur.com/Al7EJeC.png', value: '1:30pm' },
        { image: 'https://i.imgur.com/Al7EJeC.png', value: '11:15pm' },
        { image: 'https://i.imgur.com/Al7EJeC.png', value: '10:15pm' }
      ],
      // scoring: {
      //   scorer: `
      //     return answer === '1:15pm' ? 1: 0;
      //   `
      // },
      scoring: {
        scorer: {
          name: 'SingleChoiceScorer',
          inputs: { correct: '1:15pm' }
        }
      }
    }
  ]
}

const section0: Section = {
  type: SectionType.BOX,
  title: 'Box 0',
  description: '',
  instructions: 'Answer the following questions',
  logic: {},
  scoring: {
    maximumScore: 3
  },
  options: {
    timeToAnswer: 60
  },
  items: [question0_1, question0_2]
}

const question1_1: Question = {
  id: 'section1-1', // questionId
  type: QuestionType.COMPLETION,
  scoring: {
    isAutomatic: true,
    maximumScore: 2
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Please write your full name.\n{fullName:Full Name:300px}',
      scoring: {
        guide:
          '2 points for correct full name (first and last).   Full middle name may also be included. \n' +
          '1 point for an initial or nickname used, or other minor error.  (refer to CCM for decision if unclear)',
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              fullName: 'John Doe'
            },
            threshold: 0.3,
            points: 2
          }
        }
      }
    }
  ]
}

const question1_2: Question = {
  id: 'section1-2', // questionId
  type: QuestionType.COMPLETION,
  scoring: { isAutomatic: true, maximumScore: 1 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Today is {dayOfTheWeek:Day of the Week:200px}.',
      scoring: {
        guide: '1 point if correct',
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              dayOfTheWeek: { derive: 'return utils.dayOfTheWeek()' }
            },
            threshold: 0.3
          }
        }
      }
    }
  ]
}

const question1_3: Question = {
  id: 'section1-3', // questionId
  type: QuestionType.COMPLETION,
  scoring: { isAutomatic: true, maximumScore: 3 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text:
        'Today’s date is the {date:Date:100px} \nof {month:Month:150px}, {year:Year:100px}.\n',
      scoring: {
        guide:
          '1 point for the date (if off by one day, still assign 1 point)\n' +
          '1 point for the correct month\n' +
          '1 point for the correct year',
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              date: { derive: 'return utils.dateToday()' },
              month: { derive: 'return utils.monthToday()' },
              year: { derive: 'return utils.yearToday()' }
            },
            threshold: 0.3
          }
        }
      }
    }
  ]
}

const question1_4: Question = {
  id: 'section1-4', // questionId
  type: QuestionType.COMPLETION,
  scoring: { isAutomatic: true, maximumScore: 1 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'How old are you? {years:ex.30:100px} years. \n',
      scoring: {
        guide: '1 point for the correct answer.',
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              years: '30'
            }
          }
        }
      }
    }
  ]
}

const question1_5: Question = {
  id: 'section1-5', // questionId
  type: QuestionType.COMPLETION,
  scoring: { isAutomatic: true, maximumScore: 3 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text:
        'On what date were you born?\n  {day:Day:100px} / {month:Month:100px}, {year:Year:100px}',
      scoring: {
        guide:
          '1 point for the date \n' +
          '1 point for the correct month\n' +
          '1 point for the correct year',
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              day: '1',
              month: '1',
              year: '1990'
            }
          }
        }
      }
    }
  ]
}

const section1: Section = {
  type: SectionType.BOX,
  title: 'Box 1', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: 'Fill In the blanks', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 10 },
  items: [question1_1, question1_2, question1_3, question1_4, question1_5]
}

const question2_1: Question = {
  id: 'section2-1', // questionId
  type: QuestionType.COPY_SENTENCE,
  scoring: {
    isAutomatic: true,
    maximumScore: 2,
    guide:
      'If all words are in the right order and spelled correctly, 2 points given.  Not case sensitive.  Ignore punctuation. \n' +
      'If one spelling error, or one misplaced or missing word, then assign 1 point. \n' +
      'If two spelling errors in different words, two misplaced words, or two missing works, 0 points. \n'
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Good citizens always wear stout shoes.',
      scoring: { scorer: { name: 'CopySentenceScorer' } }
    },
    {
      text: 'Young students learn well from creative teachers.',
      scoring: { scorer: { name: 'CopySentenceScorer' } }
    },
    {
      text: 'Shingled houses are usually seen in coastal communities.',
      scoring: { scorer: { name: 'CopySentenceScorer' } }
    },
    {
      text: 'Large puzzles with small pieces are quite challenging.',
      scoring: { scorer: { name: 'CopySentenceScorer' } }
    },
    {
      text: 'Solid houses always have good foundations.',
      scoring: { scorer: { name: 'CopySentenceScorer' } }
    },
    {
      text: 'The majority of people prefer warm, sunny weather.',
      scoring: { scorer: { name: 'CopySentenceScorer' } }
    }
  ]
}

const section2: Section = {
  type: SectionType.BOX,
  title: 'Box 2', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: 'Copy the following sentences', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 2 },
  items: [question2_1]
}

const question3_1: Question = {
  id: 'section3-1', // questionId
  type: QuestionType.COMPLETION,
  scoring: { isAutomatic: true, maximumScore: 2 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text:
        'Who is the current president? \n{firstName:First Name:150px} {lastName:Last Name:150px}',
      scoring: {
        guide:
          'Joe Biden – 2 points.\n' +
          'Just one name, such as Joe or Biden, 1 point. \n' +
          'Use fuzzy logic for spelling errors. \n',
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              firstName: 'Joe',
              lastName: 'Biden'
            }
          }
        }
      }
    }
  ]
}

const question3_2: Question = {
  id: 'section3-2', // questionId
  type: QuestionType.COMPLETION,
  scoring: { isAutomatic: true, maximumScore: 1 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'In what year did the First World War start? {year:Year}',
      scoring: {
        guide:
          '1914.  One point for the correct year.  0 points any other year. ',
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              year: '1914'
            }
          }
        }
      }
    },
    {
      text:
        'In what year did the United States declare independence from Great Britain? {year}'
    },
    {
      text:
        'In what year did Christopher Columbus first land in the Americas? {year}'
    },
    {
      text: 'In what year did the Second World War start in Europe? {year}'
    },
    {
      text: 'In what year was President John F. Kennedy assassinated? {year}'
    },
    {
      text: 'In what year did the first person land on the moon? {year}'
    }
  ]
}

const section3: Section = {
  type: SectionType.BOX,
  title: 'Box 3', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: 'Fill In the blanks', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 3 },
  items: [question3_1, question3_2]
}

const question4_1: Question = {
  id: 'section4-1', // questionId
  type: QuestionType.COMPLETION,
  scoring: {
    isAutomatic: true,
    maximumScore: 1,
    guide: 'One point for each correct answer.  0 points any other answer. '
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: '20 – 4 = {answer}',
      scoring: {
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              answer: '16'
            }
          }
        }
      }
    },
    {
      text: '47 – 8 = {1}'
    },
    {
      text: '24 – 6 = {1}'
    },
    {
      text: '37 – 12 = {1}'
    },
    {
      text: '24 – 7 = {1}'
    },
    {
      text: '46 – 9 = {1}'
    }
  ]
}
const question4_2: Question = {
  id: 'section4-2', // questionId
  type: QuestionType.COMPLETION,
  scoring: {
    isAutomatic: true,
    maximumScore: 1,
    guide: 'One point for each correct answer.  0 points any other answer. '
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: '16 + 17 = {answer}',
      scoring: {
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              answer: '33'
            }
          }
        }
      }
    },
    {
      text: '17 + 18 = {2}'
    },
    {
      text: '13 + 16 = {2}'
    },
    {
      text: '13 + 15 = {2}'
    },
    {
      text: '34 + 17 = {2}'
    },
    {
      text: '12 + 14 = {2}'
    }
  ]
}
const question4_3: Question = {
  id: 'section4-3', // questionId
  type: QuestionType.COMPLETION,
  scoring: {
    isAutomatic: true,
    maximumScore: 1,
    guide: 'One point for each correct answer.  0 points any other answer. '
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: '8 x 6 = {answer}',
      scoring: {
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              answer: '48'
            }
          }
        }
      }
    },
    {
      text: '5 x 4 = {3}'
    },
    {
      text: '7 x 8 = {3}'
    },
    {
      text: '9 x 6 = {3}'
    },
    {
      text: '7 x 4 = {3}'
    },
    {
      text: '6 x 7 = {3}'
    }
  ]
}
const question4_4: Question = {
  id: 'section4-4', // questionId
  type: QuestionType.COMPLETION,
  scoring: {
    isAutomatic: true,
    maximumScore: 1,
    guide: 'One point for each correct answer.  0 points any other answer. '
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: '4 + 15 – 17 = {answer}',
      scoring: {
        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              answer: '2'
            }
          }
        }
      }
    },
    {
      text: '7 + 12 – 4 = {4}'
    },
    {
      text: '4 + 8 – 5 = {4}'
    },
    {
      text: '23 + 8 – 4 = {4}'
    },
    {
      text: '33 + 9 – 5 = {4}'
    },
    {
      text: '21 - 6 + 4 = {4}'
    }
  ]
}

const section4: Section = {
  type: SectionType.BOX,

  title: 'Box 4', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: 'Compute for the following:', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 4 },
  items: [question4_1, question4_2, question4_3, question4_4]
}

const question5_1: Question = {
  id: 'section5-1', // questionId
  type: QuestionType.ENUMERATION,
  scoring: { isAutomatic: true, maximumScore: 4 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Please list four creatures beginning with ‘S’ e.g. Shark',
      scoring: {
        guide:
          'snake, salmon, sea lion, sea anemone, saber-tooth cat/tiger, seagull, seal, sheep, seahorse, shrimp, skunk, sloth, slug, snail, shrew, snow leopard, sparrow, sponge, starfish, stork, stingray, swallow, swan, swordfish',
        scorer: {
          name: 'EnumerationScorer',
          inputs: {
            enumeration:
              'snake, salmon, sea lion, sea anemone, saber-tooth cat/tiger, seagull, seal, sheep, seahorse, shrimp, skunk, sloth, slug, snail, shrew, snow leopard, sparrow, sponge, starfish, stork, stingray, swallow, swan, swordfish'
          }
        }
      },
      options: { enumerationCount: 4 }
    },
    {
      text: 'Please list four creatures beginning with T, e.g. Toucan',
      options: { enumerationCount: 4 }
    },
    {
      text: 'Please list four creatures beginning with M, e.g. Muskrat',
      options: { enumerationCount: 4 }
    },
    {
      text: 'Please list four creatures beginning with R, e.g. Rockfish',
      options: { enumerationCount: 4 }
    },
    {
      text: 'Please list four creatures beginning with C, e.g. Caribou',
      options: { enumerationCount: 4 }
    },
    {
      text: 'Please list four creatures beginning with B, e.g. Bison',
      options: { enumerationCount: 4 }
    }
  ]
}

const section5: Section = {
  type: SectionType.BOX,
  title: 'Box 5', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: '', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 4 },
  items: [question5_1]
}

const question6_1: Question = {
  id: 'section6-1', // questionId
  type: QuestionType.SHORT_ESSAY,
  scoring: {
    maximumScore: 2
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Why is a carrot like a potato?',
      scoring: {
        guide:
          '1 point: both are food, both grow, are plants, are in ground, are tubers (incorrect, but still 1 point)\n' +
          '2 points: vegetables (“veg “or “veggie” is ok), both are roots, grow in the ground, grow underground, require peeling, both have 6 letters, both are healthy / good for you'
      }
    },
    { text: 'Why is a banana like an orange?' },
    { text: 'Why is soda like champagne?' },
    { text: 'Why is a bicycle like an office chair?' },
    { text: 'Why is spider like an octopus?' },
    { text: 'Why is an apple like an orange?' }
  ]
}
const question6_2: Question = {
  id: 'section6-2', // questionId
  type: QuestionType.SHORT_ESSAY,
  scoring: { maximumScore: 2 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Why is a lion like a wolf?',
      scoring: {
        guide:
          '1 point: animals, have 4 legs, wild, fierce, canine (incorrect but still 1 point), \n' +
          '2 points: carnivores, both eat meat, both have fur, both are hunters, fierce and wild, have 4 letters\n'
      }
    },
    { text: 'Why is a fish like a snake?' },
    { text: 'Why is a dog like a cat?' },
    { text: 'Why is a tomato like a watermelon?' },
    { text: 'Why is ivy like seaweed?' },
    { text: 'Why is a refrigerator like an oven?' }
  ]
}
const section6: Section = {
  type: SectionType.BOX,
  title: 'Essay', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: 'Answer the following questions briefly with 1-2 sentences.', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 4 },
  items: [question6_1, question6_2]
}
const question7_1: Question = {
  id: 'section7-1', // questionId
  type: QuestionType.LABELING,
  scoring: {
    isAutomatic: true,
    maximumScore: 5
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      text: 'Label all items found in the figure',
      data: 'https://i.imgur.com/w8ktHrU.png',
      items: [
        { text: 'shirt,collar,shirt collar' },
        { text: 'lapel' },
        { text: 'tie' },
        { text: 'pocket,ticket pocket' },
        { text: 'button' }
      ],
      scoring: {
        guide:
          '1) Shirt / collar/ shirt collar \n' +
          '2) Lapel\n' +
          '3) Tie\n' +
          '4) Pocket / ticket pocket\n' +
          '5) Button.\n\n Use fuzzy logic for misspellings.  Plurals accepted. \n' +
          'One point for each question answered correctly.  No points for other answers.',

        scorer: {
          name: 'CompletionScorer',
          inputs: {
            correct: {
              1: 'shirt,collar,shirt collar',
              2: 'lapel',
              3: 'tie',
              4: 'pocket,ticket pocket',
              5: 'button'
            }
          }
        }
      }
    }
  ]
}
const section7: Section = {
  type: SectionType.BOX,
  title: 'Labeling', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: 'Please name these items', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 5 },
  items: [question7_1]
}
const question8_1: Question = {
  id: 'section8-1', // questionId
  type: QuestionType.DRAWING,
  scoring: { maximumScore: 3 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  options: {
    strokeWidth: 30,
    strokeAlpha: 0.2,
    imageAlpha: 1
  },
  meta: [
    {
      scoring: {
        guide:
          '3 points:  All the indicated shapes have been touched along the trace line to form the indicated letter (if tracing begins incorrectly but is corrected midway, still award 3 points) \n' +
          '2 points:  if another letter if formed (i.e. V instead of W)\n' +
          '1 point:  all the indicated shapes touched by the tracing or in another way selected (i.e. circling them), but no letter formed\n'
      },
      text:
        'Please join the circles together to form a letter (ignore the squares) - Letter W',
      data: 'https://i.imgur.com/VQj6YXh.png'
    }
  ]
}
const section8: Section = {
  type: SectionType.BOX,
  title: 'Drawing Exercise', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: '', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 3 },
  items: [question8_1]
}

const question9_1: Question = {
  id: 'section9-1', // questionId
  type: QuestionType.DRAWING,
  scoring: { maximumScore: 3 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  options: {
    strokeWidth: 6,
    strokeAlpha: 0.5,
    imageAlpha: 1
  },
  meta: [
    {
      scoring: {
        guide:
          'Assign 1 point if the numbers 1-12 listed. No points if the number 0 is used. \n' +
          '\n' +
          'Assign additional 1 point: The numbers 1-12 are in the correct order and fairly appropriately spaced around the circle, and that the clock hands meet approximately at the center.  No points if these are mistaken. \n' +
          '\n' +
          'Assign additional 1 point: for each hand that is pointing to the correct number (or close to it).  Full 2 points awarded even if the long hand and short hand are reversed.'
      },
      text:
        'Please draw in a clock face, put in the numbers 1-12 and place the hands at 9:20',
      data: 'https://i.imgur.com/ZNMc1pK.png'
    }
  ]
}
const section9: Section = {
  type: SectionType.BOX,
  title: 'Drawing Exercise', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: '', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 4 },
  items: [question9_1]
}

const question10_1: Question = {
  id: 'section10-1', // questionId
  type: QuestionType.SHORT_ESSAY,
  scoring: {
    maximumScore: 6,
    guide:
      'Assign 1 point for each word remembered, up to a maximum of 6 points.  Spelling not important, provided the word is recognizable (use fuzzy logic).  Ignore extra words.  Ignore punctuation and capitalization. '
  }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  meta: [
    {
      scoring: {
        guide:
          'Good citizens always wear stout shoes. Assign 1 point for each word remembered, up to a maximum of 6 points.  Spelling not important, provided the word is recognizable (use fuzzy logic).  Ignore extra words.  Ignore punctuation and capitalization.'
      },
      text: 'Please write down the sentence you copied earlier'
    }
  ]
}
const section10: Section = {
  type: SectionType.BOX,
  title: 'Essay', // Title of the Section
  description: '', // Description of the test-section not visible to respondent
  instructions: '', // Instruction text visible to respondent
  logic: {},
  options: {
    timeToAnswer: 60
  },
  scoring: { maximumScore: 4 },
  items: [question10_1]
}

export const tymTest: Test = {
  id: '5ff09a4481eaf60008dfbf37',
  accountId,
  ownerId,
  type: TestType.ASSESSMENT, // Type of test
  title: 'Test Your Memory', // Title of the test for display
  code: 'TYM', // Short code of the test
  description:
    'The **Test Your Memory (TYM)** test is a new short cognitive test \n' +
    "for the detection of Alzheimer's disease and other cognitive problems. \n",
  instructions:
    '## Test Your Memory\n' +
    '\n' +
    'The **Test Your Memory (TYM)** test is a new short cognitive test \n' +
    "for the detection of Alzheimer's disease and other cognitive problems. \n" +
    '\n' +
    '\n' +
    'You may press `Start` when you are ready to start the test.',
  items: [
    // section0,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    section8,
    section9,
    section10
  ], // (aka Box) refers to separation of questions
  scoring: {
    maximumScore: 50
  }, // scoring rules on the test level
  logic: {}, // show/hide logic of the test
  options: {} // options for the test
}
