import { Component, OnInit } from '@angular/core'
import { AssessmentsApprovalService } from '../../../services/assessments-approval.service'
import { GetAssessmentQuery } from '../../../API.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-pretest-approvals-popup',
  templateUrl: './pretest-approvals-popup.component.html',
  styleUrls: ['./pretest-approvals-popup.component.scss']
})
export class PretestApprovalsPopupComponent implements OnInit {
  constructor(
    public approvalsService: AssessmentsApprovalService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  public toggleText = 'Hide'
  show = false
  offset = { right: 100, bottom: 100 }

  public onToggle(): void {
    this.show = !this.show
    this.toggleText = this.show ? 'Hidе' : 'Show'
  }

  async goToApprovals(assessment: GetAssessmentQuery) {
    console.log('Going to Approvals')
    await this.router.navigate(['approvals'], {
      queryParams: {
        assessmentId: assessment.id
      }
    })
  }
}
