<div fxFlexFill *ngIf="callState$ | async as callState">
  <div fxFlex class="call-info" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start">
    <div fxFLex>
      <span class="call-info-title">{{callTitle$ | async}}</span>
    </div>
    <div fxFLex *ngIf="(callInfo$ | async)">
      {{callInfo$ | async}}
    </div>
  </div>
  <div fxFlex class="assessment-info" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center end">
    <div fxFLex fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
      <button kendoButton
              [iconClass]="callState === 'Disconnected' ?'fa fa-redo':'fa fa-phone-slash'"
              (click)="dialAction()">
      </button>
      <button kendoButton
              [iconClass]="isAudioOn? 'fa fa-microphone': 'fa fa-microphone-slash'" (click)="toggleMute()">
      </button>
    </div>
    <div fxFLex fxLayout="row" fxLayoutAlign="end center">
      <div fxFlex *ngIf="callState === 'Connected'">
        {{ (timeElapsed$ | async) || '00:00:00' }}
      </div>
      <div fxFLex *ngIf="callState === 'Ringing' || callState === 'Connecting'">
        Calling {{ callNumber$ | async }}
      </div>
      <div fxFLex *ngIf="callState === 'Disconnected'">
        Call Ended.
      </div>
    </div>
  </div>
</div>
