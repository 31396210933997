<div class="assessments">
  <kendo-grid fxFlexFill [data]="assessments" [height]="410">
    <kendo-grid-column field="date" title="Date" width="50">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.date | customDate:false:'PP'}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="patient" title="Patient" width="75">
      <ng-template kendoGridCellTemplate let-dataItem>
       <span>{{dataItem.patient.givenName}} {{dataItem.patient.familyName}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="pretest" title="Pretest" width="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.pretest.result}}</span>
<!--        <span *ngIf="dataItem.pretest.status === 'Qualified'" class="label-green">Qualified</span>-->
<!--        <span *ngIf="dataItem.pretest.status === 'NotQualified'" class="label-red">Not Qualified</span>-->
<!--        <span *ngIf="dataItem.pretest.date">&nbsp;({{dataItem.pretest.date}})</span>-->
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="assessments" title="Assessments" width="200" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div fxFlexFill fxLayout="row">
          <div *ngFor="let a of dataItem.assessments.items" fxFlex fxLayout="column" fxLayoutAlign="center center"
               class="assessment"
               [ngStyle]="{'background-color': getAssessmentColor(a.type)}">{{a.type}}</div>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ccm" title="Clinical Case Manager" width="75">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.manager.firstName}} {{dataItem.manager.lastName}}</span>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      No assessments found
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
