export const ASSESSMENT_TYPE_COLOR = {
  MGPCOG: 'red'
}

export const RESOURCE_CATEGORY_COLOR = {
  SOCIAL: '#e6fbe6',
  RELIGIOUS: '#f9edd7',
  PHYSICAL: '#c9e4ff',
  VOLUNTEER: '#efdbef',
  SUPPORT: '#ffe1e1'
}

export const RESOURCE_CATEGORY_TEXT = {
  SOCIAL: 'Social',
  RELIGIOUS: 'Religious',
  PHYSICAL: 'Physical',
  VOLUNTEER: 'Volunteer',
  SUPPORT: 'Support'
}

export const AGE_GROUP_TEXT = {
  AGES_18_PLUS: '18+',
  AGES_18_TO_50: '50+',
  AGES_65_PLUS: '65+'
}
