import { Component, Input, OnInit } from '@angular/core'
import { APIService, GetLocationQuery, GetUserQuery } from '../../API.service'
import { OfficeFilterService } from '../../services/office-filter.service'

@Component({
  selector: 'app-location-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class LocationUsersComponent implements OnInit {
  @Input() location: GetLocationQuery
  registrationOpen = false
  users: Array<GetUserQuery>
  loading = true

  constructor(
    private apiService: APIService,
    private officeFilterService: OfficeFilterService
  ) {
    this.users = []
  }

  ngOnInit(): void {
    this.loadUsers()
  }

  loadUsers(): void {
    this.loading = true
    const locationId = this.officeFilterService.selectedLocation$.getValue()?.id
    const connections = this.apiService.ListUserLocationConnections({
      locationId: { eq: locationId }
    })

    connections
      .then(result => {
        // @ts-ignore
        this.users = result.items.map(con => {
          return con.user
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  openRegistration(): void {
    this.registrationOpen = true
  }

  onRegistrationResult(result): void {
    if (result) {
      this.loadUsers()
    }
  }
}
