<div>
    <div class="treatments-board" fxFlexFill>
        <div class="treatments-board-title" fxFlexFill fxLayoutAlign="center center">
            Test Eligibility
        </div>
        <div class="treatments-board-body" fxFlexFill>
            <div fxLayout="row" fxFlexFill>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center end">
                    <div>Psych Assessment Test</div>
                    <div>(CESD-R)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center start">
                    <div>(Not set)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center end">
                    <div>Annual Depression Screening</div>
                    <div>(PHQ-9)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center start">
                    <div>(Not set)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center end">
                    <div>Addiction Screening</div>
                    <div>(DASI-10)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center start">
                    <div>(Not set)</div>
                </div>
            </div>
            <div fxLayout="row" fxFlexFill>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center end">
                    <div>Psych Assessment Test</div>
                    <div>(CESD-R)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center start">
                    <div>(Not set)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center end">
                    <div>Annual Depression Screening</div>
                    <div>(PHQ-9)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center start">
                    <div>(Not set)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center end">
                    <div>Addiction Screening</div>
                    <div>(DASI-10)</div>
                </div>
                <div fxLayout="column" fxFlex="calc(100 / 6)" fxLayoutAlign="center start">
                    <div>(Not set)</div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between stretch" >
        <div fxFlex="45" fxFlexFill>
            <div class="treatments-board" fxFlexFill>
                <div class="treatments-board-title" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <i class="fas fa-briefcase-medical"></i> Treatment Plans (0)
                    </div>
                    <button kendoButton [primary]="true">
                        Add New Plan
                    </button>
                </div>
                <div class="treatments-board-body" style="border: none;">
                    <div class="no-data"> No Treatments Plans Found</div>
                </div>
            </div>
        </div>
        <div fxFlex="52">
            <div class="treatments-board" fxFlexFill>
                <div class="treatments-board-title" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <i class="fas fa-briefcase-medical"></i> Sessions (0)
                    </div>
                </div>
                <div class="treatments-board-body" style="border: none;">
                    <kendo-grid
                        [data]="gridView"
                        [pageSize]="pageSize"
                        [skip]="skip"
                        [pageable]="true"
                        [height]="300"
                        (pageChange)="pageChange($event)">
                            <kendo-grid-column [width]="50" field="id" title="">
                                <ng-template kendoGridCellTemplate  let-column let-columnIndex="columnIndex">
                                    <div class="text-center">{{column.id}}</div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="date" title="Date"></kendo-grid-column>
                            <kendo-grid-column field="treatment" title="Treatment"></kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start"
        style="padding: 10px 0;">
        <button kendoButton [primary]="true">
            Manage upcomming patients events
        </button>
        <button kendoButton [primary]="true">
            Print Calendar
        </button>
    </div>
    <div class="scheduling-list">
        <kendo-scheduler [kendoSchedulerBinding]="events"
            [resources]="eventResources"
            [selectedViewIndex]="1"
            [selectedDate]="selectedDate"
            style="height: 650px;">
            <kendo-scheduler-day-view startTime="07:00">
            </kendo-scheduler-day-view>
    
            <kendo-scheduler-month-view [eventHeight]="30">
            </kendo-scheduler-month-view>
        </kendo-scheduler>
    </div>
</div>
