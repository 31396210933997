import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  constructor() {}

  play(assetName) {
    let audio = new Audio()
    audio.src = `../../../assets/sounds/${assetName}.wav`
    audio.load()
    audio.play()
  }
}
