import { Component, Input, OnInit } from '@angular/core'
import { APIService, GetLocationQuery } from '../../API.service'

@Component({
  selector: 'app-location-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class LocationPaymentsComponent implements OnInit {
  @Input() location: GetLocationQuery;
  payments = []
  loading = true;
  constructor(
    private apiService: APIService
  ) { }

  ngOnInit(): void {
    this.apiService.ListPayments({
      locationId: { eq: this.location.id }
    }).then((result) => {
      console.log('Payments', result)
      this.payments = result.items;
    }).finally(() => {
      this.loading = false;
    });
  }

}
