import { Component, Input, OnInit } from '@angular/core'
import { GetLocationQuery } from '../../API.service'

@Component({
  selector: 'app-location-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class LocationAdminComponent implements OnInit {
  @Input() location: GetLocationQuery;

  constructor() { }

  ngOnInit(): void {
  }

}
