import { Component, OnInit } from '@angular/core'
import { AuthState } from 'aws-amplify-angular/dist/src/providers'
import { Router } from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
