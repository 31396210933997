<div fxFlexFill *ngIf="!loading" fxLayout="column" class="live-room" [ngClass]="{'audio-only': audioOnly}">
  <div fxFlex="100px" class="live-room-participants" fxLayout="row">
    <ng-container *ngIf="!monitorOnly">
      <app-live-room-participant fxFlex="136px" fxLayout="row" [customStyle]="'height:100px;'"
                                 *ngFor="let participant of (getParticipants$() | async)" [participant]="participant"
                                 [priority]="'low'" [audioOnly]="audioOnly"></app-live-room-participant>
    </ng-container>
    <div fxFlex class="spacer" *ngIf="monitorOnly"></div>
    <div fxFlex fxLayoutAlign="center center" class="monitoring-title" *ngIf="monitorOnly">
      Monitoring {{ session?.title }}
    </div>
    <div fxFlex class="spacer"></div>
    <div *ngIf="(getActiveMonitorsCount$() | async) " class="live-room-monitoring" fxFlex="100px"
         fxLayoutAlign="center center" kendoTooltip>
      <i class="fa fa-eye" title="Users monitoring this session"></i>&nbsp;{{ (getActiveMonitorsCount$() | async) }}
    </div>
    <div fxFlex="50px" class="spacer" fxLayoutAlign="start center">
      <button kendoButton class="live-room-button" (click)="closeLiveRoom()" [look]="'flat'"
              [iconClass]="'fa fa-times'"></button>
    </div>
  </div>
  <div fxFlex class="live-room-pinned" fxLayout="row">
    <div *ngIf="(getPinnedParticipant$() | async) && !monitorOnly" class="live-room-pinned-container" fxFlex
         fxLayout="row" fxLayoutAlign="center stretch">
      <app-live-room-participant fxFlex fxLayout="row" fxLayoutAlign="center stretch" [priority]="'high'"
                                 [participant]="(getPinnedParticipant$() | async)"
                                 [audioOnly]="audioOnly"></app-live-room-participant>
    </div>
    <div *ngIf="(getNonMonitorParticipants$() | async) && monitorOnly" class="live-room-pinned-container"
         fxFlexFill fxLayoutAlign="space-evenly center" fxLayout="column wrap" fxLayoutGap="0px grid">
      <app-live-room-participant fxFlex="240px" fxLayout="row" fxLayoutAlign="center center"
                                 *ngFor="let participant of (getNonMonitorParticipants$() | async)"
                                 [customStyle]="'height:240px;'" [participant]="participant"
                                 [priority]="'low'" [audioOnly]="audioOnly">
      </app-live-room-participant>
    </div>
    <div class="live-room-settings-overlay">
      <app-live-room-settings
        *ngIf="showSettings"
        [roomConnection]="roomConnection"
        (onSave)="onSettingsSaved($event)"
        (onCancel)="onSettingsCancel($event)">
      </app-live-room-settings>
      <app-live-room-transfer
        *ngIf="showTransfer"
        (onSave)="onTransferSaved($event)"
        (onCancel)="onTransferCancel($event)">
      </app-live-room-transfer>
    </div>
  </div>
  <div fxFlex="120px" class="live-room-actions" fxLayout="row">
    <div fxFlex="30"></div>
    <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <button kendoButton class="live-room-button" *ngIf="!monitorOnly" (click)="toggleTransfer()" [look]="'flat'"
              [iconClass]="'fa fa-user-plus'"></button>
      <button kendoButton class="live-room-button" *ngIf="!monitorOnly" (click)="toggleAudio()" [look]="'flat'"
              [iconClass]="audioEnabled?'fa fa-microphone':'fa fa-microphone-slash'"></button>
      <button kendoButton class="live-room-button" *ngIf="!monitorOnly && !audioOnly" (click)="toggleVideo()"
              [look]="'flat'" [iconClass]="videoEnabled?'fa fa-video':'fa fa-video-slash'"></button>
      <button kendoButton class="live-room-button" *ngIf="!monitorOnly" (click)="toggleSettings()" [look]="'flat'"
              [iconClass]="'fa fa-cog'"></button>
      <button kendoButton class="live-room-button" [primary]="true" (click)="closeLiveRoom()" [look]="'flat'"
              [iconClass]="'fa fa-phone-slash'"></button>
    </div>
    <div fxFlex="30" fxLayout="row">
      <div fxFlex fxLayout="row" dir="rtl" *ngIf="(getLocalParticipant$() | async) && !monitorOnly">
        <app-live-room-participant [customStyle]="'height:120px;'" [participant]="(getLocalParticipant$() | async)"
                                   [priority]="'standard'" [audioOnly]="audioOnly"></app-live-room-participant>
      </div>
    </div>
  </div>
</div>
<div class="live-room-loader" *ngIf="loading" fxFlexFill fxLayoutAlign="center center">
  <kendo-loader *ngIf="!showSettings"
                [type]="'pulsing'"
                [themeColor]="'light'"
                [size]="'large'"
  ></kendo-loader>
</div>
