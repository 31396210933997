<div class="patient-charts grid-no-hover grid-no-odd" fxFlex fxLayout="column">
  <kendo-grid #grid fxFlexFill [data]="charts" [height]="'auto'"
              [selectable]="true"
              (selectionChange)="selectionChange(grid, $event)">
    <kendo-grid-column field="name" title="Patient" width="200">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div (click)="router.navigate(['/patient/47450714-309e-43af-8af4-b62ffdb267fb/assessments'])" style="cursor: pointer;">
          <b>Shawn Dao</b>
        </div>
        <div><i>Jan 29, 2021</i></div>
        <div><i>ID: PA00001</i></div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="name" title="History">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-patient-charts-assessment [item]="dataItem"></app-patient-charts-assessment>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
