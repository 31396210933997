<div fxFlex class='question-padder'>
  <ng-container *ngIf="questionMeta$ | async as questionMeta" [ngSwitch]="question.type">
    <app-single-choice-question fxFlex
                                [questionMeta]="questionMeta"
                                [question]="question"
                                [section]="section"
                                (answered)="onAnsweredChanged($event)"
                                *ngSwitchCase="QuestionType.SINGLE">
    </app-single-choice-question>
    <app-multiple-choice-question fxFlex
                                  [questionMeta]="questionMeta"
                                  [question]="question"
                                  [section]="section"
                                  (answered)="onAnsweredChanged($event)"
                                  *ngSwitchCase="QuestionType.MULTIPLE">
    </app-multiple-choice-question>
    <app-completion-question fxFlex
                             [questionMeta]="questionMeta"
                             [question]="question"
                             [section]="section"
                             (answered)="onAnsweredChanged($event)"
                             *ngSwitchCase="QuestionType.COMPLETION">
    </app-completion-question>
    <app-copy-sentence-question fxFlex
                                [questionMeta]="questionMeta"
                                [question]="question"
                                [section]="section"
                                (answered)="onAnsweredChanged($event)"
                                *ngSwitchCase="QuestionType.COPY_SENTENCE">
    </app-copy-sentence-question>
    <app-drawing-question fxFlex
                          [questionMeta]="questionMeta"
                          [question]="question"
                          [section]="section"
                          (answered)="onAnsweredChanged($event)"
                          *ngSwitchCase="QuestionType.DRAWING">
    </app-drawing-question>
    <app-enumeration-question fxFlex
                              [questionMeta]="questionMeta"
                              [question]="question"
                              [section]="section"
                              (answered)="onAnsweredChanged($event)"
                              *ngSwitchCase="QuestionType.ENUMERATION">
    </app-enumeration-question>
    <app-labeling-question fxFlex
                           [questionMeta]="questionMeta"
                           [question]="question"
                           [section]="section"
                           (answered)="onAnsweredChanged($event)"
                           *ngSwitchCase="QuestionType.LABELING">
    </app-labeling-question>
    <app-short-essay-question fxFlex
                              [questionMeta]="questionMeta"
                              [question]="question"
                              [section]="section"
                              (answered)="onAnsweredChanged($event)"
                              *ngSwitchCase="QuestionType.SHORT_ESSAY">
    </app-short-essay-question>
    <app-instruction-question fxFlex
                              [questionMeta]="questionMeta"
                              [question]="question"
                              [section]="section"
                              *ngSwitchCase="QuestionType.INSTRUCTION">
    </app-instruction-question>
    <app-yes-no-question fxFlex
                              [questionMeta]="questionMeta"
                              [question]="question"
                              [section]="section"
                              (answered)="onAnsweredChanged($event)"
                              *ngSwitchCase="QuestionType.YES_NO">
    </app-yes-no-question>
  </ng-container>
</div>

