
<div fxFlexFill fxLayout="column" class="write-up-section-container" fxLayoutGap="20px">
  <div fxFlex="40px" fxLayoutAlign="center center">
    <span class="section-name">{{ section.name }}</span>
    <span class="section-description" *ngIf="section.description">- {{ section.description }}</span>
  </div>
  <ng-container *ngFor="let statement of section.statements">
    <div *ngIf="showStatement(statement)" fxFlex fxLayout="column"
          fxLayoutGap="10px" class="write-up-section">
      <app-write-up-statement
        [context]="context"
        [questionnaire]="questionnaire"
        [section]="section"
        [statement]="statement"
        [parent]="section"
        [indent]="0">
      </app-write-up-statement>
    </div>
  </ng-container>
</div>
