import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import addMinutes from 'date-fns/addMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import { Subscription, zip } from 'rxjs';
import { AGE_GROUP_TEXT, RESOURCE_CATEGORY_COLOR, RESOURCE_CATEGORY_TEXT } from '../../../../constants';
import StringHelper from '../../../../helpers/StringHelper';
import { AgeGroup, APIService, ResourceCategory } from '../../../../../API.service';
import { OfficeFilterService } from '../../../../../services/office-filter.service';
import { SessionService } from '../../../../../services/session.service';

@Component({
  selector: 'app-resource-scheduled-events',
  templateUrl: './resource-scheduled-events.component.html',
  styleUrls: ['./resource-scheduled-events.component.scss']
})
export class ResourceScheduledEventsComponent implements OnInit, OnDestroy {
  @Input() resource;
  @Input() deletable = false;
  @Input() editable = false;
  @Input() creatable = false;
  @Output() onEdit = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  currentAccount;
  currentLocation;
  accountLocationSub: Subscription;
  resourceCategoryColor = RESOURCE_CATEGORY_COLOR;
  ageGroupText = AGE_GROUP_TEXT;
  resourceEvents = [];
  // Resource Event
  newResourceEventForm: FormGroup;
  showNewResourceEventModal = false;
  loading = false;
  // tslint:disable-next-line:typedef
  get fre() { return this.newResourceEventForm.controls; }

  categoryList = StringHelper.enumToArray(ResourceCategory).map(r => ({id: r, name: RESOURCE_CATEGORY_TEXT[r] ? RESOURCE_CATEGORY_TEXT[r] : r}));
  ageGroupList = StringHelper.enumToArray(AgeGroup).map(r => ({id: r, name: AGE_GROUP_TEXT[r] ? AGE_GROUP_TEXT[r] : r}));

  selectedItem;
  showRemoveModal = false;

  constructor(
    private apiService: APIService,
    private officeFilterService: OfficeFilterService,
    private sessionService: SessionService,
    private formBuilder: FormBuilder,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.accountLocationSub = zip(
      this.officeFilterService.selectedAccount$,
      this.officeFilterService.selectedLocation$
    ).subscribe(([account, location]) => {
      if (account && location){
        this.currentLocation = location;
        this.currentAccount = account;

        this.fetchResourceEvents();
      }
    });

    this.newResourceEventForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      category: [ResourceCategory.PHYSICAL, Validators.required],
      start: [new Date(), Validators.required],
      end: [addMinutes(new Date(), 30), Validators.required],
      isAllDay: [false],
      cost: [0, Validators.required],
      ageGroup: [AgeGroup.AGES_18_PLUS, Validators.required],
    });
  }

  ngOnDestroy(): void {
    if (this.accountLocationSub) { this.accountLocationSub.unsubscribe(); }
  }

  async fetchResourceEvents(): Promise<void>{
    this.loading = true;
    try {
      const { items: data } = await this.apiService.ListResourceScheduledEvents({
        accountId: { eq: this.currentAccount.id },
        resourceId: {eq: this.resource.id },
        locationId: {eq: this.currentLocation.id }
      });
      this.resourceEvents = data;
      this.loading = false;
    } catch (e){
      console.error(e);
      this.loading = false;
    }
  }

  newResourceEvent(): void {
    this.showNewResourceEventModal = true;
  }

  closeNewResourceEvent(): void {
    this.showNewResourceEventModal = false;
    this.resetNewResourceEventInputs();
  }

  async createAndCloseNewResourceEventModal(): Promise<void> {
    console.log('createAndCloseNewResourceEventModal', this.newResourceEventForm);
    if (this.newResourceEventForm.invalid) {
      return;
    }

    this.loading = true;
    const {value} = this.newResourceEventForm;

    const payload = {
      accountId: this.currentAccount.id,
      ownerId: this.sessionService.userId$.getValue(),
      locationId: this.currentLocation.id,
      title: value.title,
      start: value.start.toISOString(),
      end: value.end.toISOString(),
      startTimezone: value.startTimezone,
      endTimezone: value.endTimezone,
      isAllDay: value.isAllDay,
      description: value.description,
      // recurrenceRule?: string | null,
      // recurrenceExceptions?: string | null,
      // recurrenceId?: string | null,
      resourceId: this.resource.id,
      category: value.category,
      cost: value.cost,
      ageGroup: value.ageGroup,
    };

    try {
      if (this.selectedItem) {
        // Update
        await this.apiService.UpdateResourceScheduledEvent({
          id: this.selectedItem.id,
          ...payload
        });
      } else {
        // Create
        const result = await this.apiService.CreateResourceScheduledEvent(payload);
        console.log('Add new resource result', result);
      }
      this.loading = false;
      this.resetNewResourceEventInputs();
      this.closeNewResourceEvent();
      this.fetchResourceEvents();

      if (this.selectedItem) {
        this.onEdit.emit();
      } else {
        this.onCreate.emit();
      }
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  resetNewResourceEventInputs(): void {
    this.newResourceEventForm.reset({
      title: '',
      description: '',
      category: ResourceCategory.PHYSICAL,
      start: new Date(),
      end: new Date(),
      isAllDay: false,
      cost: 0,
      ageGroup: AgeGroup.AGES_18_PLUS,
    });
  }

  isSameDate(left, right): boolean {
    return differenceInDays(new Date(left), new Date(right)) === 0;
  }

  deleteItem(item): void {
    this.selectedItem = item;
    this.showRemoveModal = true;
  }

  editItem(item): void {
    this.selectedItem = item;
    this.newResourceEventForm.setValue({
      title: item.title,
      description: item.description,
      category: item.category,
      start: new Date(item.start),
      end: new Date(item.end),
      isAllDay: item.isAllDay,
      cost: item.cost,
      ageGroup: item.ageGroup,
    });
    this.newResourceEvent();
  }

  closeRemoveModal(): void {
    this.showRemoveModal = false;
    this.selectedItem = null;
  }

  async removeAndCloseModal(): Promise<void> {
    this.loading = true;
    try {
      await this.apiService.DeleteResourceScheduledEvent({
        id: this.selectedItem.id
      });
      this.closeRemoveModal();
      this.fetchResourceEvents();
      this.onDelete.emit();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
