<div class="accounts" fxLayout="column" fxFlexFill>
  <div class="accounts-list" fxFlex fxLayout="column">
    <kendo-grid fxFlexFill [data]="accounts" [height]="410">
      <ng-template kendoGridToolbarTemplate>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header">
          <h6>Accounts Management</h6>
          <button (click)="openRegistration()" [primary]="true" fxFlex="15" kendoButton>New Account</button>
        </div>
      </ng-template>

      <kendo-grid-column field="id" title="ID" width="50"></kendo-grid-column>
      <kendo-grid-column field="name" title="Name" width="75"></kendo-grid-column>
      <kendo-grid-column field="owner" title="Owner" width="50"></kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="spacer" fxFlex="3"></div>
</div>

<kendo-dialog title="Create Account" [width]="450" [height]="175" *ngIf="registrationDialogOpen" (close)="closeRegistration()">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Account Name</div>
      <kendo-textbox fxFlex placeholder="ex. Everlast Medical Group" [(ngModel)]="nameInput"></kendo-textbox>
    </div>
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeRegistration()">Cancel</button>
    <button kendoButton (click)="createAccount()" primary="true">Create</button>
  </kendo-dialog-actions>
</kendo-dialog>
