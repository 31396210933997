import { Component, Input, OnInit } from '@angular/core'
import {
  WriteUpContext,
  WriteUpQuestionnaire,
  WriteUpSection,
  WriteUpStatement
} from '../write-up.type'
import { FormGroup } from '@angular/forms'
import { WriteUpService } from '../write-up.service'

@Component({
  selector: 'app-write-up-section',
  templateUrl: './write-up-section.component.html',
  styleUrls: ['./write-up-section.component.scss']
})
export class WriteUpSectionComponent implements OnInit {
  /**
   * Main context object
   */
  @Input('context') context: WriteUpContext
  /**
   * Section this component represents
   */
  @Input('section') section: WriteUpSection
  /**
   * Section this component represents
   */
  @Input('questionnaire') questionnaire: WriteUpQuestionnaire

  constructor(private writeUpService: WriteUpService) {}

  ngOnInit(): void {}

  showStatement(statement: WriteUpStatement): boolean {
    return this.writeUpService.isStatementVisible(statement, this.context)
  }
}
