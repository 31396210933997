<div fxLayout='column' class='question-padder' fxLayoutGap='10px'>
  <div class='score-guide-text'>
    {{ answer.questionRef.questionMetaScoring?.guide || answer.questionRef.questionScoring?.guide }}
  </div>
  <div fxLayout="row" fxLayoutGap='20px'>
    <div fxFlex="120px" class="score-container" fxLayout='column' [ngClass]="{'green': answer.score > 0, 'gray': answer.score === 0, 'blue': answer.isAutomaticScored}" >
      <div fxLayout='row' fxFlex='40px' fxLayoutAlign='space-around center'>
        <div fxFlex='40px' class="score-label" fxLayoutAlign='center'>
          <button fxFlexFill kendoButton class='start-button'
                  [iconClass]="'fa fa-caret-left fa-2x'"
                  look='flat'
                  [primary]='false'
                  (click)='minusPoint()'></button>
        </div>
        <div fxFlex class="score-label" fxLayoutAlign='center'>
          {{answer.score}}
        </div>
        <div fxFlex='40px' class="score-label" fxLayoutAlign='center'>
          <button fxFlexFill kendoButton class='start-button'
                  [iconClass]="'fa fa-caret-right fa-2x'"
                  look='flat'
                  [primary]='false'
                  (click)='addPoint()'></button>
        </div>
      </div>
      <div fxFlex fxLayoutAlign='center start'>
        <span class='scoring-auto-label'>{{ answer.isAutomaticScored ? 'auto' : 'manual' }}</span> &nbsp; <span class='scoring-max-label'>(max: {{ answer.questionRef.questionScoring?.maximumScore || 0 }})</span>
      </div>
    </div>
    <div fxFlex class="question-container"  [ngClass]="{'green': answer.score > 0, 'gray': answer.score === 0, 'blue': answer.isAutomaticScored}">
      <ng-container [ngSwitch]="answer.questionRef.type">
        <app-score-single-choice fxFlex
                                [answer]="answer"
                                *ngSwitchCase="QuestionType.SINGLE">
        </app-score-single-choice>
        <app-score-multiple-choice fxFlex
                                  [answer]="answer"
                                  *ngSwitchCase="QuestionType.MULTIPLE">
        </app-score-multiple-choice>
        <app-score-completion fxFlex
                             [answer]="answer"
                             *ngSwitchCase="QuestionType.COMPLETION">
        </app-score-completion>
        <app-score-copy-sentence fxFlex
                                [answer]="answer"
                                *ngSwitchCase="QuestionType.COPY_SENTENCE">
        </app-score-copy-sentence>
        <app-score-drawing fxFlex
                          [answer]="answer"
                          *ngSwitchCase="QuestionType.DRAWING">
        </app-score-drawing>
        <app-score-enumeration fxFlex
                              [answer]="answer"
                              *ngSwitchCase="QuestionType.ENUMERATION">
        </app-score-enumeration>
        <app-score-labeling fxFlex
                           [answer]="answer"
                           *ngSwitchCase="QuestionType.LABELING">
        </app-score-labeling>
        <app-score-short-essay fxFlex
                              [answer]="answer"
                              *ngSwitchCase="QuestionType.SHORT_ESSAY">
        </app-score-short-essay>
        <app-score-instruction fxFlex
                               [answer]="answer"
                               *ngSwitchCase="QuestionType.INSTRUCTION">
        </app-score-instruction>
      </ng-container>
    </div>
  </div>
</div>




