<div class="users">
  <div class="header" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="openRegistration()" [primary]="true" fxFlex="15" kendoButton>New User</button>
  </div>
  <kendo-grid fxFlexFill [data]="users" [height]="410">
    <kendo-grid-column field="name" title="Name">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div>{{dataItem.lastName}} {{dataItem.firstName}}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="type" title="Type"></kendo-grid-column>
    <kendo-grid-column field="lastLogin" title="Last Login">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngIf="dataItem.lastLogin; else elseBlock">{{dataItem.lastLogin.date | customDate:true}} ({{dataItem.lastLogin.date | customDate:false:'LLL dd @hh:mmaa'}}) IP: {{dataItem.lastLogin.ip}}</div>
        <ng-template #elseBlock>
          <div style="color: #4c0101">
            NEVER
          </div>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="accessBilling" title="Access Billing" width="120">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="k-text-center k-color-success" *ngIf="dataItem.accessBilling; else elseBlock"><kendo-icon [name]="'check'" [size]="'small'"></kendo-icon></div>
        <ng-template #elseBlock>
          <div class="k-text-center k-color-error"><kendo-icon [name]="'close'" [size]="'small'"></kendo-icon></div>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="accessPayment" title="Access Payment" width="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="k-text-center k-color-success" *ngIf="dataItem.accessPayment; else elseBlock"><kendo-icon [name]="'check'" [size]="'small'"></kendo-icon></div>
        <ng-template #elseBlock>
          <div class="k-text-center k-color-error"><kendo-icon [name]="'close'" [size]="'small'"></kendo-icon></div>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="manageUsers" title="Manage Users" width="120">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="k-text-center k-color-success" *ngIf="dataItem.manageUsers; else elseBlock"><kendo-icon [name]="'check'" [size]="'small'"></kendo-icon></div>
        <ng-template #elseBlock>
          <div class="k-text-center k-color-error"><kendo-icon [name]="'close'" [size]="'small'"></kendo-icon></div>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="createPhysician" title="Create Physician" width="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="k-text-center k-color-success" *ngIf="dataItem.createPhysician; else elseBlock"><kendo-icon [name]="'check'" [size]="'small'"></kendo-icon></div>
        <ng-template #elseBlock>
          <div class="k-text-center k-color-error"><kendo-icon [name]="'close'" [size]="'small'"></kendo-icon></div>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="createPatient" title="Create Patient" width="120">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="k-text-center k-color-success" *ngIf="dataItem.createPatient; else elseBlock"><kendo-icon [name]="'check'" [size]="'small'"></kendo-icon></div>
        <ng-template #elseBlock>
          <div class="k-text-center k-color-error"><kendo-icon [name]="'close'" [size]="'small'"></kendo-icon></div>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="viewAssessments" title="View Assessments" width="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="k-text-center k-color-success" *ngIf="dataItem.viewAssessments; else elseBlock"><kendo-icon [name]="'check'" [size]="'small'"></kendo-icon></div>
        <ng-template #elseBlock>
          <div class="k-text-center k-color-error"><kendo-icon [name]="'close'" [size]="'small'"></kendo-icon></div>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      No users found
    </ng-template>
  </kendo-grid>
  <app-location-add-user [(open)]="registrationOpen" (onResult)="onRegistrationResult($event)" [location]="location"></app-location-add-user>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
