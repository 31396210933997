<div class="timeline">
    
    <div class="timeline-blank"></div>

    <div class="timeline-content">
        <div class="timeline-title">
            <i class="k-icon k-i-connector"></i>
            <span>Mar 1nd, 2020</span>
        </div>
        <div class="timeline-body">
            <div class="box box-danger">
                <div class="box-content">
                    <div class="box-title small-title">RETEST</div>
                    <div class="box-body">
                        <span class="modest-bold">FLAG</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="timeline-content">
        <div class="timeline-title">
            <i class="k-icon k-i-connector"></i>
            <span>Mar 1nd, 2020</span>
        </div>
        <div class="timeline-body">
            <div class="box box-danger">
                <div class="box-content">
                    <div class="box-title small-title">RETEST</div>
                    <div class="box-body">
                        <span class="modest-bold">FLAG</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>