<div class="short-essay-question" fxFlexFill fxLayout="column" fxLayoutGap="20px">
  <div fxFlex fxLayout="row">
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <div fxFlex="30px" fxLayoutAlign="start center">
        {{ questionMeta.text }}
      </div>
      <div fxFlex="100px">
        <textarea fxFlex kendoTextArea [rows]="3" [autoSize]="false" [(ngModel)]="input" (ngModelChange)="onChange($event)"></textarea>
      </div>
    </div>
  </div>
</div>

<!--Question 11:-->

<!--Version 1:-->
<!--Why is a carrot like a potato? -->
<!--Why is a lion like a wolf?-->

<!--Version 2:-->
<!--Why is a banana like an orange?-->
<!--Why is a fish like a snake?-->

<!--Version 3:-->
<!--Why is soda like champagne?-->
<!--Why is a dog like a cat?-->

<!--Version 4:-->
<!--Why is a bicycle like an office chair?-->
<!--Why is a tomato like a watermelon? -->

<!--Version 5:-->
<!--Why is spider like an octopus?-->
<!--Why is ivy like seaweed?-->

<!--Version 6:-->
<!--Why is an apple like an orange?-->
<!--Why is a refrigerator like an oven?-->
