import { Component, OnInit, ViewChild } from '@angular/core'
import { APIService, RoomType, TelehealthSessionStatus } from '../API.service'
import { SessionService } from '../services/session.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { WsConnectionService } from '../services/ws-connection.service'
import { TelehealthTableSectionComponent } from './parts/telehealth-table-section/telehealth-table-section.component'

@Component({
  selector: 'app-telehealth',
  templateUrl: './telehealth.component.html',
  styleUrls: ['./telehealth.component.scss']
})
export class TelehealthComponent implements OnInit {
  @ViewChild(TelehealthTableSectionComponent)
  child: TelehealthTableSectionComponent

  scheduleDialogOpen = false

  constructor(
    private apiService: APIService,
    private sessionService: SessionService,
    private wsConnectionService: WsConnectionService
  ) {}

  async ngOnInit(): Promise<void> {}

  openCreate() {
    this.scheduleDialogOpen = true
  }

  notifyCreated() {
    this.child.refreshEvents()
  }

  onCloseScheduleDialog() {
    // this.scheduleDialogOpen = false
  }

  isSuper$(): Observable<boolean> {
    return this.sessionService.userType$.pipe(map(v => v === 'Super'))
  }

  // async refreshSessions() {
  //   const { items: sessions } = this.accountFilter
  //     ? await this.apiService.ListTelehealthSessions({
  //         accountId: { eq: this.accountFilter }
  //       })
  //     : await this.apiService.ListTelehealthSessions()
  //   console.log('Sessions', this.accountFilter, sessions)
  //   this.sessions = sessions.map(item => {
  //     const startDate = new Date(item.startedAt)
  //     const endDate = new Date(item.endedAt)
  //     if (
  //       item.startedAt &&
  //       item.endedAt &&
  //       item.status === TelehealthSessionStatus.INACTIVE
  //     ) {
  //       const start = startDate.getTime()
  //       const end = endDate.getTime()
  //       const totalSecs = Math.floor((end - start) / 1000)
  //       const totalMins = Math.floor(totalSecs / 60)
  //       const remainderSecs = totalSecs % 60
  //       item['duration'] = `${totalMins}m${remainderSecs}s`
  //       item['statusText'] = 'Completed'
  //     } else if (item.status === TelehealthSessionStatus.ONGOING) {
  //       item[
  //         'duration'
  //       ] = `started ${startDate.getHours()}:${startDate.getMinutes()}`
  //       item['statusText'] = 'Ongoing'
  //     } else if (item.status === TelehealthSessionStatus.PENDING) {
  //       item['duration'] = 'Not Started'
  //       item['statusText'] = 'Scheduled'
  //     } else {
  //       item['duration'] = 'NA'
  //       item['statusText'] = 'NA'
  //     }
  //     return item
  //   })
  // }
}
