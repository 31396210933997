import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { SessionService } from '../../../services/session.service'
import { ChatService } from '../../../services/chat.service'
import { Paginator } from 'twilio-chat/lib/interfaces/paginator'
import { Channel } from 'twilio-chat/lib/channel'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { APIService } from '../../../API.service'
import { map } from 'rxjs/operators'
import { values } from 'lodash'
import {
  getConversationNameFromChatUsers,
  getIdentifierFromIds,
  getIdsFromIdentifier,
  getInitialsFromName
} from '../lib/helper'
import { ChatUser, Conversation } from '../lib/chat-types'

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {
  /**
   * Emits when a conversation was selected
   */
  @Output() selected = new EventEmitter<any>()
  /**
   * Emits when the chat list has loaded and is ready
   */
  @Output() ready = new EventEmitter<any>()
  /**
   * Paginator for the channel is used to load the subscribed channels into view
   */
  _paginator: Paginator<Channel>
  /**
   * Refers to all rendered conversations AFTER filtering
   */
  filteredConversations$: Observable<Array<Conversation>>
  /**
   * Refers to all conversations after merging the list of channels and users
   */
  conversations$: Observable<Array<Conversation>>
  /**
   * Refers to all the loaded channels into view
   */
  channels$: BehaviorSubject<Array<Channel>>
  /**
   * Refers to all the users that were loaded into view
   */
  users$: BehaviorSubject<Array<ChatUser>>
  /**
   * A set of "ChatUser" that were selected as a filter for conversation list
   */
  conversationFilter$: BehaviorSubject<Array<ChatUser>>
  /**
   * Control to show the create Group section in conversation list
   */
  showCreateGroup: boolean
  /**
   * Group Name Input model
   */
  groupNameInput: string
  /**
   * This is the current user's ID
   */
  currentUserId: string
  /**
   * This is the array of ChatUsers that were selected as filter,
   * and can be used to create a new group chat
   */
  selectedUsers: Array<ChatUser>

  constructor(
    private sessionService: SessionService,
    private chatService: ChatService,
    private apiService: APIService
  ) {
    this.channels$ = new BehaviorSubject<Array<Channel>>([])
    this.users$ = new BehaviorSubject<Array<ChatUser>>([])
    this.conversationFilter$ = new BehaviorSubject<Array<ChatUser>>([])
    this.selectedUsers = []
    this.groupNameInput = 'New Group'
  }

  async ngOnInit(): Promise<void> {
    // console.log('Initializing Chat List')

    // Make sure to only do stuff AFTER currentUser is loaded
    this.sessionService.currentUser$.subscribe(async currentUser => {
      if (currentUser === null) return
      this.currentUserId = currentUser['id']

      // Grab all users that are in the account
      const { items } = await this.apiService.ListUsers({
        accountId: { eq: currentUser['accountId'] }
      })

      // Load the users to the users stream
      this.users$.next(
        items
          .filter(u => {
            // Make sure to remove the CURRENT user
            // (shouldn't be able to chat to oneself)
            return u.id !== this.currentUserId
          })
          .map(user => {
            // Convert the users to a ChatUser type
            const name = user.firstName + ' ' + user.lastName
            return {
              name: name,
              id: user.id,
              initials: getInitialsFromName(name),
              status: user.onlineStatus || 'OFFLINE'
            }
          })
      )

      // Load the chatService _paginator onto this component
      this.chatService.getSubscribedChannelsPaginator().then(paginator => {
        // console.log('Got Paginator', paginator)
        this._paginator = paginator
        //Load the channels into the channels stream
        this.channels$.next(this._paginator.items)
        this.ready.next(true)

        // TODO: LEAVE THIS ONE!!
        // Uncomment to debug, so that all channels will be deleted
        // this._paginator.items.forEach(c => {
        //   console.log('Deleted channel', c)
        //   c.delete()
        // })
      })

      // The conversations stream is the merging of the channels and users stream
      // Make it into a coherent object type suitable for the view
      this.conversations$ = combineLatest(this.channels$, this.users$).pipe(
        map(([channels, users]) => {
          // console.log('Generating conversations list', channels, users)
          const conversations: { [key: string]: Conversation } = {}

          // We need to map users to conversations
          users
            .map(
              (user: ChatUser): Conversation => {
                return {
                  name: getConversationNameFromChatUsers([
                    user,
                    this.getCurrentChatUser()
                  ]),
                  id: getIdentifierFromIds([user.id, this.currentUserId]),
                  lastMessage: undefined,
                  initials: user.initials,
                  status: user.status,
                  title: user.name
                }
              }
            )
            .forEach(c => {
              conversations[c.id] = c
            })

          // console.log('Got the following channels', channels)

          // And map channels to conversations
          channels
            .map(
              (channel: Channel): Conversation => {
                return {
                  id: channel.uniqueName,
                  lastMessage: '',
                  name: channel.friendlyName,
                  initials: getInitialsFromName(channel.friendlyName),
                  status: 'GROUP',
                  title: channel.friendlyName
                }
              }
            )
            .forEach(fromChannel => {
              // Then finally merge them if they collide by id
              // A collision means, there is an existing channel and user
              const fromConvo = conversations[fromChannel.id]
              if (fromConvo) {
                // If that item exists among the convo merge them
                conversations[fromConvo.id] = {
                  title: fromConvo.title || fromChannel.title,
                  name: fromConvo.name || fromChannel.name,
                  status: fromConvo.status || fromChannel.status,
                  lastMessage: fromConvo.lastMessage || fromChannel.lastMessage,
                  initials: fromConvo.initials || fromChannel.initials,
                  id: fromConvo.id
                }
              } else {
                conversations[fromChannel.id] = fromChannel
              }
            })
          return values(conversations)
        })
      )

      // Create a new stream to enable filtering by selected users
      this.filteredConversations$ = combineLatest(
        this.conversationFilter$,
        this.conversations$
      ).pipe(
        map(([filter, conversations]) => {
          // console.log('Mapping conversations', filter, conversations)
          let filtered = conversations
          if (filter.length > 0) {
            filtered = conversations.filter(c => {
              // Using the conversation id, fetch the ids
              const conversationIds: string[] = getIdsFromIdentifier(c.id)
              // Map the filterIds array from array of users
              const filterIds: string[] = filter.map(
                (user: ChatUser): string => {
                  return user.id
                }
              )
              // Check that every filter (id) is part (member) of the conversation
              return filterIds.every(el => {
                return conversationIds.indexOf(el) !== -1
              })
            })
          }
          // Finally sort them always by name
          return filtered.sort((a, b) => {
            return a.title.localeCompare(b.title)
          })
        })
      )
    })
  }

  /**
   * Loads the next page in paginator and concatenates
   * the new loaded channels into the stream
   */
  public async loadNextPage() {
    const currentChannels = this.channels$.getValue()
    this._paginator = await this._paginator.nextPage()
    this.channels$.next(this._paginator.items.concat(currentChannels))
  }

  /**
   * When a conversation in the view is selected, emit it
   * to be processed in another view
   * @param conversation
   */
  selectConversation(conversation) {
    this.selected.emit(conversation)
  }

  /**
   * This is called when the search filter changes in the invitees
   * suppose it needs to filter names from ALL inputs
   * @param value
   */
  public onChatUserFilterChanged(value) {
    // update the filteredInvitees array from a copy of all invitees
    console.log('onChatUserFilterChanged', {
      value,
      selectedUsers: this.selectedUsers
    })
  }

  /**
   * This is called when a new set of users have been selected as filter
   * - It should apply a conversation filter
   * @param value
   */
  public onChatUserSelectionChanged(value) {
    console.log('onChatUserSelectionChanged', {
      value,
      selectedUsers: this.selectedUsers
    })
    // Update the filter stream
    this.conversationFilter$.next(this.selectedUsers)
    // Update the "suggested" groupName
    this.groupNameInput = getConversationNameFromChatUsers([
      ...this.selectedUsers,
      this.getCurrentChatUser()
    ])
  }

  /**
   * Control toggle the create group view
   */
  public toggleShowCreateGroup() {
    this.showCreateGroup = !this.showCreateGroup
  }

  /**
   * Invokes when the create conversation (or group) button is clicked
   */
  public onCreateConversation() {
    const selectedUsers = this.selectedUsers
    // Determine all users who will be in the said group
    const allUsers = [...selectedUsers, this.getCurrentChatUser()]
    // Generate a conversation ID for the set of users
    const conversationIdentifier = getIdentifierFromIds(allUsers.map(u => u.id))
    // Create a conversation object
    const conversation: Conversation = {
      title: this.groupNameInput,
      name: this.groupNameInput,
      id: conversationIdentifier,
      initials: allUsers.map(u => u.name.charAt(0)).join(),
      lastMessage: '',
      status: undefined
    }
    // console.log('Creating a conversation', this.selectedUsers)

    // Load that conversation as if user is selecting an already existing one
    this.selected.emit(conversation)

    // Make sure to clear the fields
    this.selectedUsers = []
    this.conversationFilter$.next(this.selectedUsers)
    this.showCreateGroup = false
    this.groupNameInput = ''
  }

  /**
   * Creates a ChatUser based of the current user
   * @private
   */
  private getCurrentChatUser(): ChatUser {
    const currentUser = this.sessionService.currentUser$.getValue()
    const self: ChatUser = {
      id: currentUser['id'],
      name: currentUser['firstName'] + ' ' + currentUser['lastName']
    }
    return self
  }
}
