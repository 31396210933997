<div class="score-short-essay" fxFlexFill fxLayout="column" fxLayoutGap="20px">
  <div fxFlex fxLayout="row">
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <div fxFlex="30px" fxLayoutAlign="start center">
        {{ answer.questionRef.text }}
      </div>
      <div fxFlex="100px">
        <textarea fxFlex [disabled]="true" kendoTextArea [rows]="3" [autoSize]="false" [(ngModel)]="input"></textarea>
      </div>
    </div>
  </div>
</div>
