import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { NavigationEnd, Router } from '@angular/router'
import { AuthService } from './services/auth.service'
import { SessionService } from './services/session.service'
import { Subscription } from 'rxjs'
import { BnNgIdleService } from 'bn-ng-idle'
import { SeedService } from './services/seed.service'
import { WsConnectionService } from './services/ws-connection.service'
import { WsNotificationService } from './services/ws-notification.service'
import { ChatService } from './services/chat.service'
import { APIService } from './API.service'
import { CallService } from './call.service'
import { Amplify } from 'aws-amplify'
import { AssessmentsApprovalService } from './services/assessments-approval.service'

@Component({
  selector: 'app-root',
  templateUrl: './bre-app.component.html',
  styleUrls: ['./bre-app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreAppComponent implements OnInit, OnDestroy {
  title = 'breconsole'
  loading = true
  loadedSessionSub: Subscription
  bnIdleSub: Subscription
  routeSub: Subscription
  messageSub: Subscription
  sessionSub: Subscription

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private ngZone: NgZone,
    private bnIdle: BnNgIdleService,
    private authService: AuthService,
    private seedService: SeedService,
    private wsConnectionService: WsConnectionService,
    private wsNotificationService: WsNotificationService,
    private chatService: ChatService,
    private callService: CallService,
    private apiService: APIService,
    private assessmentsApproval: AssessmentsApprovalService
  ) {}

  ngOnInit() {
    this.loadedSessionSub = this.sessionService.loadedSession$.subscribe(
      loaded => {
        this.loading = !loaded
      }
    )

    this.routeSub = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.sessionService.currentRoute$.next(evt.urlAfterRedirects)
      }
    })

    // This controls the Authenticator UI.
    onAuthUIStateChange((authState, authData) => {
      this.ngZone.run(() => {
        if (authState === 'signedin') {
          // When the UI changes to has successfully signedin, try routing to dashboard
          this.router.navigate(['/'])
        } else if (authState === 'signin' || authState === 'signedout') {
          // When the UI has signedout, try routing to login page
          this.router.navigate(['/login'])
        }
        // Whenever there is a change in auth, try loading session
        this.sessionService.loadSession()
      })
    })

    // TODO: Enable this when used
    this.bnIdleSub = this.bnIdle.startWatching(300).subscribe(isTimedOut => {
      if (isTimedOut) {
        // this.authService.logout().then(() => {
        //   return this.router.navigate(['/login'])
        // })
      }
    })

    // TODO: Need to triple check the cleanup when switching between users
    // Start listening to wsConnections once jwtToken becomes accessible from the session (means logged in)
    this.sessionSub = this.sessionService.jwtToken$.subscribe(token => {
      if (token != null) {
        // Before establishing a NEW connection, make sure to disconnect first
        this.wsConnectionService.disconnect()
        // And turn off any existing subscriptions
        if (this.messageSub) this.messageSub.unsubscribe()

        // Connect to the service
        this.wsConnectionService.connect(token)

        // Listen for messages
        this.messageSub = this.wsConnectionService.message$.subscribe(
          message => {
            console.log('Received', message)
            this.wsNotificationService.processMessage(message)
          }
        )
      }
    })

    this.sessionService.currentUser$.subscribe(async currentUser => {
      if (currentUser) {
        await this.chatService.initialize(currentUser)
        await this.callService.initialize(currentUser)
        await this.assessmentsApproval.initialize(currentUser)
        this.seedService.down().then(() => {
          return this.seedService.up()
        })
      }
    })
  }

  ngOnDestroy() {
    if (this.loadedSessionSub) this.loadedSessionSub.unsubscribe()
    if (this.bnIdleSub) this.bnIdleSub.unsubscribe()
    if (this.messageSub) this.messageSub.unsubscribe()
    if (this.sessionSub) this.sessionSub.unsubscribe()
    if (this.wsConnectionService) this.wsConnectionService.disconnect()
    return onAuthUIStateChange
  }
}
