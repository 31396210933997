<div class="scheduling" fxLayout="column" fxFlexFill>
  <div class="spacer" fxFlex="20px"></div>
  <div class="scheduling-search scheduling-section" fxFlex="100px" fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <kendo-textbox fxFlex placeholder="Search Events">
        <ng-template kendoTextBoxPrefixTemplate>
          <kendo-icon [name]="'search'"></kendo-icon>
        </ng-template>
      </kendo-textbox>
      <button fxFlex="12" kendoButton>Search</button>
      <div class="spacer" fxFlex="20px"></div>
      <button [primary]="true" fxFlex="12" kendoButton (click)="openScheduleDialog()">Schedule</button>
    </div>
  </div>
  <div class="spacer" fxFlex="20px"></div>
  <div class="scheduling-list" fxFlex="740px" fxLayout="column">
    <app-event-scheduler #eventScheduler></app-event-scheduler>
  </div>
  <div class="spacer" fxFlex="20px"></div>
</div>
