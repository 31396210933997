import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  RendererFactory2,
  ViewChild,
  ViewChildren,
  AfterViewInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges
} from '@angular/core'
import { RoomConnectionParticipant } from '../../helpers/room'

@Component({
  selector: 'app-live-room-participant',
  templateUrl: './live-room-participant.component.html',
  styleUrls: ['./live-room-participant.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveRoomParticipantComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('participantAudio') participantAudio: ElementRef
  @ViewChild('participantVideo') participantVideo: ElementRef
  // @ViewChild('participantContainer') participantContainer: ElementRef
  @Input('participant') participant: RoomConnectionParticipant
  @Input('customStyle') customStyle: string
  @Input('priority') priority: 'low' | 'standard' | 'high' = 'low'
  @Input('audioOnly') audioOnly: boolean
  renderer = null

  audioEnabled = true
  videoEnabled = true
  videoMuted = false
  audioMuted = false

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null)
    this.audioOnly = false
  }

  ngOnInit(): void {
    console.log('ngOnInit LiveRoomParticipantComponent')
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy LiveRoomParticipantComponent')
    this.detachParticipant(this.participant)
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit LiveRoomParticipantComponent')
    this.attachParticipant(this.participant)
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    console.log('ngOnChanges', changes)
    const {
      participant: { previousValue, currentValue, firstChange }
    } = changes
    if (!firstChange) {
      if (previousValue) {
        this.detachParticipant(previousValue)
      }
      if (currentValue) {
        this.attachParticipant(currentValue)
      }
    }
  }

  refresh() {
    console.log('Refreshing..')
    this.detachParticipant(this.participant)
    this.attachParticipant(this.participant)
  }

  attachParticipant(participant) {
    console.log('attachParticipant', participant)

    // Set video priority of participant before even attaching it
    // this.setVideoPriorityOfParticipant(participant)

    // Go through all existing tracks, attach them
    participant.tracks.forEach(pub => this.trackPublished(pub))

    // If the participant publishes a new track, attach it
    participant.on('trackPublished', pub => this.trackPublished(pub))
    // if the participant unpublishes a track, then remove it
    participant.on('trackUnpublished', pub => this.trackUnpublished(pub))

    // Just comment this out for now
    // participant.on('trackAdded', this.trackPublished)
    // participant.on('trackRemoved', this.trackUnpublished)
  }

  setVideoPriorityOfParticipant(participant) {
    console.log('setVideoPriorityOfParticipant', participant)
    participant.videoTracks.forEach(publication => {
      const track = publication.track
      if (track && track.setPriority) {
        console.log(
          'Setting video priority of participant',
          participant,
          publication,
          this.priority
        )
        track.setPriority(this.priority)
      } else {
        console.log(
          'Could not find setPriority method in publication',
          publication
        )
      }
    })
  }

  detachParticipant(participant) {
    console.log('detachParticipant', participant)
    // Go through each publication and unpublish them
    participant.tracks.forEach(pub => this.trackUnpublished(pub))
  }

  trackPublished(publication) {
    console.log('trackPublished', publication, publication.constructor.name)
    if (
      publication.isSubscribed ||
      (this.isLocal(publication) && publication.kind === 'video')
    ) {
      this.attachTrack(publication.track)
    }
    publication.on('subscribed', t => this.attachTrack(t))
    publication.on('unsubscribed', t => this.detachTrack(t))
  }

  trackUnpublished(publication) {
    console.log('trackUnpublished', publication)
    // Detach all tracks regardless
    this.detachTrack(publication.track)
  }

  attachTrack(track) {
    console.log('attachTrack', track)
    const _attachTrack = t => {
      if (!t) {
        console.log('Cannot attach track', t)
      } else if (t.kind === 'audio') {
        t.attach(this.participantAudio.nativeElement)
      } else {
        t.attach(this.participantVideo.nativeElement)
      }
    }

    if (track && Array.isArray(track)) {
      return track.forEach(t => _attachTrack(t))
    } else {
      return _attachTrack(track)
    }
  }

  detachTrack(track) {
    console.log('detachTrack', track)
    const _detachTrack = t => {
      if (!t) {
        console.log('Cannot detach track', t)
      } else if (t.kind === 'audio') {
        t.detach(this.participantAudio.nativeElement)
      } else {
        t.detach(this.participantVideo.nativeElement)
      }
    }

    if (track && Array.isArray(track)) {
      return track.forEach(t => _detachTrack(t))
    } else {
      return _detachTrack(track)
    }
  }

  isLocal(obj) {
    return obj.constructor.name.toString().startsWith('Local')
  }
}
