import {
  ScheduledEventType,
  UserOnlineStatus,
  UserType
} from '../../API.service'
import { SchedulerEvent as KendoSchedulerEvent } from '@progress/kendo-angular-scheduler'

export enum UserGroup {
  Super = 'Super',
  CCM = 'CCM',
  Providers = 'Providers',
  Administrators = 'Administrators',
  Billing = 'Billing'
}

export enum ConsoleUser {
  Administrator = 'Administrator',
  Biller = 'Biller',
  CCM = 'CCM',
  Nurse = 'Nurse',
  Provider = 'Provider',
  Representative = 'Representative',
  Super = 'Super'
}

export type DashRoute = {
  isDisplayed: boolean
  name: string
  short?: string
  path: string | RegExp
  icon: string
  src?: string
  svg: string
  accessGroups: Array<UserGroup>
}

export class Access {
  name: string
  userGroups: Array<UserGroup>
  dashRoutes: Array<DashRoute>
  constructor() {}
}

export type ScheduledEvent = {
  id: string
  accountId: string
  ownerId: string
  invitees: Array<ScheduledEventInvitee>
  title: string
  start: Date
  end: Date
  startTimezone?: string
  endTimezone?: string
  isAllDay?: boolean
  description?: string
  recurrenceRule?: string
  recurrenceExceptions?: Date[]
  type: ScheduledEventType
  assessments: ScheduledEventAssessment[]

  timeLabel: string
}

export type ScheduledEventInvitee = {
  name: string
  type?: UserType
  id?: string
}

export type ScheduledEventAssessment = {
  name: string
  id: string
}

export type User = {
  id: string
  accountId: string
  userLocationId: string | null
  owner: string | null
  email: string | null
  phone: string | null
  online: boolean | null
  firstName: string | null
  lastName: string | null
  titles: Array<string | null> | null
  npi: string | null
  image: string | null
  userType: UserType | null
  connectionId: string | null
  onlineStatus: UserOnlineStatus | null
  createdAt: string
  updatedAt: string
}

/**
 * Use to map objects coming FROM server
 * @param event
 */
export const toScheduledEvent = (event: any): ScheduledEvent => {
  const getEventTimeSummary = e => {
    const start = new Date(e.start)
    if (e.isAllDay) {
      return 'All Day'
    }
    const hourStart = ('0' + start.getHours()).slice(-2)
    const minuteStart = ('0' + start.getMinutes()).slice(-2)
    return `${hourStart}:${minuteStart}`
  }

  const ret = {
    // Map to correct data types
    start: new Date(event.start),
    end: new Date(event.end),

    // Copy the rest of the properties
    id: event.id,
    title: event.title,
    accountId: event.accountId,
    isAllDay: event.isAllDay,
    startTimezone: event.startTimezone,
    endTimezone: event.endTimezone,
    description: event.description,
    ownerId: event.ownerId,
    invitees: (event.invitees || []).map(toScheduledEventInvitee),
    recurrenceExceptions: parseRecurrenceExceptions(event.recurrenceExceptions),
    recurrenceRule: event.recurrenceRule,
    type: event.type,
    assessments: event.assessments || [],

    timeLabel: getEventTimeSummary(event)
  }

  console.log('toScheduledEvent', ret)

  return ret
}

/**
 * Use to map objects before passing to server
 * @param event
 */
export const fromScheduledEvent = (event: ScheduledEvent): any => {
  console.log('fromScheduledEvent', event)
  return {
    // Map to correct data types
    start: event.start.toISOString(),
    end: event.end.toISOString(),

    // Copy the rest of the properties
    id: event.id,
    title: event.title,
    accountId: event.accountId,
    isAllDay: event.isAllDay,
    startTimezone: event.startTimezone,
    endTimezone: event.endTimezone,
    description: event.description,
    ownerId: event.ownerId,
    assessments: event.assessments,
    invitees: (event.invitees || []).map(fromScheduledEventInvitee),
    participantIds: (event.invitees || [])
      .map(invitee => invitee.id)
      .filter(id => !!id),
    recurrenceExceptions: serializeRecurrenceExceptions(
      event.recurrenceExceptions
    ),
    recurrenceRule: event.recurrenceRule,
    type: event.type
  }
}

/**
 * Use to map objects coming FROM server
 * @param invitee
 */
export const toScheduledEventInvitee = (
  invitee: any
): ScheduledEventInvitee => {
  return {
    name: invitee.name,
    type: invitee.type,
    id: invitee.id
  }
}

/**
 * Use to map objects before passing to server
 * @param invitee
 */
export const fromScheduledEventInvitee = (
  invitee: ScheduledEventInvitee
): any => {
  return {
    name: invitee.name,
    type: invitee.type,
    id: invitee.id
  }
}

/**
 * Parse for recurrence exceptions
 * @param datesString
 */
export const parseRecurrenceExceptions = (datesString: string): Date[] => {
  if (datesString) {
    return datesString.split(';').map(_ => new Date(_))
  } else {
    return null
  }
}

/**
 * Serialize for recurrence exceptions
 * @param dateArray
 */
export const serializeRecurrenceExceptions = (dateArray: Date[]): string => {
  if (dateArray) {
    return dateArray.map(_ => _.toISOString()).join(';')
  } else {
    return null
  }
}
