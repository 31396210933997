<!--  Meant to just cover the UI -->
<div class='header-cover'></div>
<div class='footer-cover'></div>

<div *ngIf='!(testService.test$ | async)' class="loading-cover" fxFlexFill fxLayoutAlign='center center'></div>

<!-- This is the main test container -->
<ng-container *ngIf='testService.test$ | async as test'>
  <!--  INTRO State means the assessment/pretest is loaded and just waiting for patient to hit start -->
  <kendo-dialog *ngIf="(testService.displaySegment$ | async) === displaySegments.INTRO"
                [maxWidth]="'60%'">
    <div style='text-align: center; margin: 20px;'>
      <div markdown [data]='test.instructions'></div>
      <br />
      <br />
      <p>
        <button kendoButton class='start-button' [primary]='true' (click)='startTest()'>Start</button>
      </p>
    </div>
  </kendo-dialog>

  <!--  Overlay controls for this test component -->
  <ng-container *ngIf='(testService.timerCount$ | async) as timerCount'>
    <div class='top-right-overlay-controls' fxLayoutAlign='center center' fxLayout='column' fxLayoutGap='20px'
         *ngIf='timerCount <= 15'>
      <span class='timer-count-info' *ngIf='testService.hasNextSection$ | async'>Next section in:</span>
      <span class='timer-count-info' *ngIf='!(testService.hasNextSection$ | async)'>Completing Test in:</span>
      <span class='timer-count'>{{ timerCount }}</span>
    </div>
  </ng-container>

  <!--  BACK BUTTON -->
  <!--  <div class='left-overlay-controls' fxLayoutAlign='center center'>-->
  <!--    <button fxFlexFill kendoButton class='start-button'-->
  <!--            [iconClass]="'fa fa-caret-left fa-2x'"-->
  <!--            look='outline'-->
  <!--            [primary]='false'-->
  <!--            [disabled]='currentSectionIndex === 0'-->
  <!--            (click)='previousSection()'></button>-->
  <!--  </div>-->
  <!--  NEXT BUTTON -->
  <div class='right-overlay-controls' fxLayoutAlign='center center'
       *ngIf='hasCompletedCurrentSection() && currentSectionIndex + 1 < stepperSteps?.length'>
    <button fxFlexFill kendoButton class='start-button'
            [iconClass]="'fa fa-caret-right fa-2x'"
            look='outline'
            [primary]='true'
            (click)='nextSection()'></button>
  </div>

  <div class='top-left-overlay-controls' fxLayoutAlign='center center' fxLayoutGap='20px' *ngIf='patientName'>
    <div class='top-left-info-title'>{{test.title}}</div>
    <div class='top-left-info-patient'>{{patientName}}</div>
  </div>

  <div class='bottom-overlay-controls' fxLayoutAlign='center center'
       *ngIf='(currentSectionIndex + 1=== stepperSteps?.length ) && hasCompletedCurrentSection()'>
    <div class='finish-button-box'>
      <button fxFlexFill kendoButton class='finish-button'
              [primary]='true'
              [disabled]='testService.isSubmittingAnswers$ | async'
              (click)='submitAnswers()'>Finish
      </button>
    </div>
  </div>

  <!--  Main Test Container -->
  <div fxLayout='column' class='test-container' fxFlexFill>
    <!--    <div class='spacer' fxFlex='50px'></div>-->
    <div fxFlex fxLayout='row'>
      <div class='spacer' fxFlex='80px'></div>
      <app-test-section fxFlex></app-test-section>
      <div class='spacer' fxFlex='80px'></div>
    </div>
    <!--    <div class='spacer' fxFlex='24px'></div>-->
  </div>
</ng-container>

<!--  WAITING PRETEST State happens while waiting for the NEXT assessment to load -->
<kendo-dialog *ngIf="(testService.displaySegment$ | async) === displaySegments.WAITING"
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces>
      ## Waiting for Next Assessment
    </div>
  </div>
</kendo-dialog>

<!--  WAITING PRETEST State happens shortly after answering a pretest -->
<kendo-dialog *ngIf="(testService.displaySegment$ | async) === displaySegments.WAITING_PRETEST"
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces style='width: 300px'>
      ## Waiting for Approval
      ### Your Pre-Test is under review. Please wait for a moment. This will refresh automatically.
    </div>
  </div>
</kendo-dialog>

<!-- COMPLETED State happens when a completed/unqualified/qualified assessment is opened and cannot be re-answered ofcourse -->
<kendo-dialog *ngIf="(testService.displaySegment$ | async) === displaySegments.COMPLETED"
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces>
      ## This Assessment has already been completed.
      ### There's nothing to do here.
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton [primary]='true' (click)='close()'>Close</button>
  </kendo-dialog-actions>
</kendo-dialog>

<!--  LOADING State happens whenever loading is intended -->
<kendo-dialog *ngIf="(testService.displaySegment$ | async) === displaySegments.LOADING"
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces style='width: 300px'>
      ## Loading Next Assessment
    </div>
  </div>
</kendo-dialog>

<!--  LOADING State happens whenever loading is intended -->
<kendo-dialog *ngIf="(testService.displaySegment$ | async) === displaySegments.UNQUALIFIED"
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces style='width: 300px'>
      ## Please see the Case Manager
      ### Test has been unqualified. Return to the Case Manager for the next steps.
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton [primary]='true' (click)='close()'>Close</button>
  </kendo-dialog-actions>
</kendo-dialog>

<!--  LOADING State happens whenever loading is intended -->
<kendo-dialog *ngIf="(testService.displaySegment$ | async) === null"
              [maxWidth]="'60%'">
  <kendo-loader
    [themeColor]="'primary'"
  ></kendo-loader>
</kendo-dialog>

<!-- At any time, this state can be invoked in case it is submitting answers -->
<kendo-dialog *ngIf='testService.isSubmittingAnswers$ | async'
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces>
      ## Submitting Answers
    </div>
    <kendo-loader
      [themeColor]="'primary'"
    ></kendo-loader>
  </div>
</kendo-dialog>







