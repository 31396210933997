import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ApprovalsGuard implements CanActivate {
  constructor(private router: Router) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const params = route.queryParams
    if (params.assessmentId) {
      // params must have answerSheetId
      // So proceed
      return true
    } else {
      await this.router.navigate(['/404'], {
        queryParams: {
          statusCode: 404,
          error: 'Missing or Invalid Answer Sheet ID'
        }
      })
      return false
    }
  }
}
