import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import {
  APIService,
  AssessmentStatus,
  AssessmentType,
  GetAssessmentQuery,
  GetUserQuery
} from '../API.service'
import { SessionService } from './session.service'

/**
 * This is in charge of polling and listening for Assessments
 *
 */
@Injectable({
  providedIn: 'root'
})
export class AssessmentsApprovalService {
  pendingAssessments$: BehaviorSubject<Array<GetAssessmentQuery>>
  constructor(private apiService: APIService) {
    this.pendingAssessments$ = new BehaviorSubject<Array<GetAssessmentQuery>>(
      null
    )
  }

  async initialize(currentUser: GetUserQuery) {
    await this.reload()
  }

  async reload() {
    const { items: assessments } = await this.apiService.ListAssessments({
      status: { eq: AssessmentStatus.PENDING },
      type: { eq: AssessmentType.PRETEST }
    })

    console.log('Initialized AssessmentsApprovalService', assessments)

    // @ts-ignore
    this.pendingAssessments$.next(assessments)
  }
}
