<div class="physicians">
  <kendo-grid fxFlexFill [data]="physicians" [height]="410">
    <kendo-grid-column field="name" title="Name"></kendo-grid-column>
    <kendo-grid-column field="email" title="Email"></kendo-grid-column>
    <kendo-grid-column field="patients" title="Patients" width="100"></kendo-grid-column>
    <kendo-grid-column field="assessments" title="Assessments" width="100"></kendo-grid-column>
    <kendo-grid-column field="lastAssessment" title="Last Assessment" width="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div>{{dataItem.lastAssessment | customDate:false:'PP'}}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastLogin" title="Last Login" width="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div>{{dataItem.lastLogin | customDate:false:'PP'}}</div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
