import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-encounter-write-ups',
  templateUrl: './encounter-write-ups.component.html',
  styleUrls: ['./encounter-write-ups.component.scss']
})
export class EncounterWriteUpsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
