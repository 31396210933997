import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {DrawerItem} from '@progress/kendo-angular-layout';
import {Router} from '@angular/router';
import {SessionService} from '../../services/session.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public isLoggedIn$: Observable<boolean>
  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.sessionService.loggedIn$;
  }

}
