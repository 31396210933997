<div class="multiple-choice-question" fxFlexFill fxLayout="row" >
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex="30px">{{questionMeta.text}}</div>
    <div fxFlex fxLayout="row wrap" fxLayoutGap="20px grid">
      <div fxFlex="45" *ngFor="let choice of questionMeta.items" >
        <div fxFlex="24px">
          <input type="checkbox" [(ngModel)]="inputs[choice.value]" (ngModelChange)="onChange($event,choice.value)" kendoCheckBox />
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="10px" >
          <div fxFlex>
            {{choice.text || ' '}}
          </div>
          <div fxFlex="75px" *ngIf="choice.image">
            <img [src]="choice.image" [width]="75" [height]="75"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

