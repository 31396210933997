<span class="table heading">Locations</span>
    <kendo-grid #grid [data]="locations" [selectable]="true"
                (selectionChange)="selectionChange(grid, $event)">
      <ng-template kendoGridToolbarTemplate>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header">
          <button (click)="openRegistration()" [primary]="true" fxFlex="15" kendoButton [icon]="'plus'">New Location</button>
        </div>
      </ng-template>
      <kendo-grid-column field="name" title="Name"></kendo-grid-column>
      <kendo-grid-column field="frontDesk" title="Front desk" width="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.frontDesk">
           {{ dataItem.frontDesk.givenName }} {{ dataItem.frontDesk.familyName }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="physicianCount" title="Physicians" width="100"></kendo-grid-column>
      <kendo-grid-column field="usersCount" title="Users" width="70"></kendo-grid-column>
      <kendo-grid-column field="testsCount" title="Tests" width="70"></kendo-grid-column>
      <kendo-grid-column field="lastPayment" title="Invoice Status" width="150" class="no-padding">
        <ng-template kendoGridCellTemplate let-dataItem>
              <div class="whole-cell" [style.backgroundColor]="invoiceStatusColor(dataItem.lastPayment.invoice.status)"
                   *ngIf="dataItem.lastPayment && dataItem.lastPayment.invoice && dataItem.lastPayment.invoice.status; else elseBlock">
                <span>{{dataItem.lastPayment.invoice.status}}</span>
              </div>
          <ng-template #elseBlock>
            <div class="whole-cell" [style.backgroundColor]="invoiceStatusColor('NOT_ACTIVE')">
              <span>Not Active</span>
            </div>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastAssessment" title="Last Test" width="310">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div *ngIf="dataItem.lastAssessment && dataItem.lastAssessment.patient; else elseBlock">
            <div>Last assessment was created {{dataItem.lastAssessment.createdAt | customDate:true}}</div>
            <div>{{dataItem.lastAssessment.createdAt | customDate:false:'PP'}} by {{dataItem.lastAssessment.patient.familyName}} {{dataItem.lastAssessment.patient.givenName}}</div>
          </div>

          <ng-template #elseBlock>
            <div>Last assessment was created {{dataItem.createdAt | customDate:true}}</div>
            <div>{{dataItem.createdAt | customDate:false:'PP'}} by James Kucharski</div>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastLoginUser" title="Last Login">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div *ngIf="dataItem.lastLoginUser && dataItem.lastLoginUser.lastLogin; else elseBlock">{{dataItem.lastLoginUser.lastLogin.date | customDate:true}} ({{currentDate | customDate:false:'LLL dd @hh:mmaa'}})</div>
          <ng-template #elseBlock>
            <div style="color: #4c0101">
              NEVER
            </div>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

<kendo-dialog title="Create Location" [width]="450" [height]="250" *ngIf="registrationDialogOpen" (close)="closeRegistration()">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="30">Location Name</div>
      <kendo-textbox fxFlex placeholder="ex. The Medical City Napa Valley" [(ngModel)]="nameInput"></kendo-textbox>
    </div>
    <div *ngIf="isSuper$()" fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex>Account: {{ currentAccount.name }}</div>
    </div>
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeRegistration()">Cancel</button>
    <button kendoButton (click)="createLocation()" primary="true">Create</button>
  </kendo-dialog-actions>
</kendo-dialog>
