import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import {
  MessageEnvelope,
  PendingPretestMessageData,
  RingMessageData
} from '../../message.types'
import { WsConnectionService } from '../../../services/ws-connection.service'
import { Router } from '@angular/router'
import { AssessmentsApprovalService } from '../../../services/assessments-approval.service'

@Component({
  selector: 'app-pending-pretest-notification',
  templateUrl: './pending-pretest-notification.component.html',
  styleUrls: ['./pending-pretest-notification.component.scss']
})
export class PendingPretestNotificationComponent implements OnInit {
  @Output() public dismissed: EventEmitter<any> = new EventEmitter()
  public data: MessageEnvelope<PendingPretestMessageData>

  constructor(
    private wsConnectionService: WsConnectionService,
    private router: Router,
    private assessmentsApprovals: AssessmentsApprovalService
  ) {}

  public dismiss(event: Event): void {
    event.preventDefault()
    this.dismissed.emit()
  }

  ngOnInit(): void {
    this.data = this.wsConnectionService.message$.getValue()
    console.log('ngOnInit data is', this.data)

    // When this notification happens, make sure to also
    // notify the assessments approval service
    this.assessmentsApprovals.reload()
  }

  async goToAssessment(event) {
    console.log('Go To Specific Assessment', this.data)
    this.dismiss(event)
    const assessmentId = this.data?.message?.assessmentId
    const submitterId = this.data?.message?.submitterId
    if (assessmentId) {
      console.log('Going to Approvals')
      await this.router.navigate(['approvals'], {
        queryParams: {
          assessmentId: assessmentId,
          submitterId: submitterId
        }
      })
    }
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit data is', this.data)
  }
}
