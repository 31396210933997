<div fxFlex fxLayout="column">
  <div class="telehealth-search telehealth-section" fxFlex="100px" fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex fxLayoutAlign="start center">
        <kendo-textbox fxFlex placeholder="Search Contacts">
          <ng-template kendoTextBoxPrefixTemplate>
            <kendo-icon [name]="'search'"></kendo-icon>
          </ng-template>
        </kendo-textbox>
      </div>
      <button fxFlex="12" kendoButton>Search</button>
      <div class="spacer" fxFlex="2"></div>
      <button [primary]="true" fxFlex="12" (click)="openCreate()" kendoButton>Schedule</button>
    </div>
  </div>
  <div class="spacer" fxFlex="20px"></div>
  <div class="telehealth-list" fxFlex fxLayout="column">
    <kendo-scheduler [kendoSchedulerBinding]="events"
                     [selectedDate]="selectedDate"
                     style="height: 740px;" >
      <ng-template kendoSchedulerEventTemplate let-event="event">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <div fxFlex fxLayout="column" fxLayoutAlign="space-around start">
            <span class="scheduler-agenda-title">{{ event.title }}</span>
            <span class="scheduler-agenda-description">{{ event.description }}</span>
          </div>
          <div *ngIf="allowComms(event)" fxFlex="130px" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
            <button kendoButton (click)="openAssessments(event)" *ngIf="event.dataItem.assessments?.length"><i class="fa fa-clipboard-list"></i></button>
            <button kendoButton (click)="openVideoChat(event)"><i class="fa fa-video"></i></button>
            <button kendoButton (click)="openMonitoring(event)"><i class="fa fa-eye"></i></button>
          </div>
        </div>
      </ng-template>
      <kendo-scheduler-agenda-view startTime="07:00">
      </kendo-scheduler-agenda-view>
    </kendo-scheduler>
    <!--        <kendo-grid fxFlexFill [data]="sessions" [height]="410">-->
    <!--          <kendo-grid-column field="title" title="Title" width="100"></kendo-grid-column>-->
    <!--          <kendo-grid-column field="statusText" title="Status" width="30"></kendo-grid-column>-->
    <!--          <kendo-grid-column field="duration" title="Session Duration" width="40"></kendo-grid-column>-->
    <!--          <kendo-grid-column field="roomType" title="Type" width="30"></kendo-grid-column>-->
    <!--          <kendo-grid-column field="call" title="Actions" width="60">-->
    <!--            <ng-template kendoGridCellTemplate let-dataItem>-->
    <!--              <button *ngIf="dataItem.roomType === null || dataItem.roomType === 'VIDEO'"-->
    <!--                      kendoButton (click)="openVideoChat(dataItem)"  [iconClass]="'fa fa-video'"></button>-->
    <!--              <button kendoButton (click)="openVoiceChat(dataItem)"  [iconClass]="'fa fa-phone-square-alt'"></button>-->
    <!--              <button kendoButton (click)="openMonitoring(dataItem)"  [iconClass]="'fa fa-eye'"></button>-->
    <!--            </ng-template>-->
    <!--          </kendo-grid-column>-->
    <!--        </kendo-grid>-->
  </div>
</div>

<div class="telehealth-assessment" *ngIf="assessmentWindow">
  <app-telehealth-assessment [scheduledEvent]="selectedScheduledEvent" (onSessionClosed)="onSessionClosed()"></app-telehealth-assessment>
</div>
