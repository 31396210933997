import { WriteUpQuestionnaire } from '../write-up.type'

export const neuropsychologicalWriteup: WriteUpQuestionnaire = {
  sections: [
    {
      name: 'Demographics, Reporting and Observations',
      groups: ['Provider', 'Manager'],
      statements: [
        {
          type: 'label',
          text:
            'Marvin face to face performed on February 13th 2021, completed write up on February 16th 2021.'
        },
        {
          type: 'label',
          text:
            'Patient has multiple risk factors relating to cognitive impairment.',
          groups: ['Provider']
        },
        {
          type: 'checkbox',
          text: 'Marvin is aged 65 or older.',
          checked: false,
          groups: ['Provider']
        },
        {
          type: 'checkbox',
          text:
            'he consumes alcohol to excess as the patient is male reporting they consume more than 5 drinks in one sitting or 15 drinks per week.',
          checked: false,
          groups: ['Provider']
        },
        {
          type: 'checkbox',
          text: 'Patient reported nicotine dependence.',
          checked: false,
          groups: ['Provider']
        },
        {
          type: 'radio',
          groups: ['Provider'],
          options: [
            {
              text: 'Marvin is obese.',
              type: 'selection',
              hint:
                'BMI = (weight in kg) / (height in meters)2  BMI > 30 = obese'
            },
            {
              text: 'Patient is underweight.',
              hint: 'BMI < 18 = underweight',
              type: 'selection'
            }
          ]
        },
        {
          type: 'checkbox',
          readonly: true,
          groups: ['Provider'],
          text: 'Marvin has a history of',
          options: [
            {
              type: 'checkbox',
              text: 'cardiovascular disease'
            },
            {
              type: 'checkbox',
              text: 'hypertension'
            },
            {
              type: 'checkbox',
              text: 'diabetes'
            },
            {
              type: 'checkbox',
              text: 'high cholesterol'
            }
          ]
        },
        {
          type: 'label',
          readonly: true,
          groups: ['Provider'],
          text: 'Marvin',
          options: [
            {
              type: 'checkbox',
              text:
                'has a personal history of dementia (not Alzheimer’s) or other cognitive function issue',
              checked: true,
              auto: true,
              groups: ['Provider']
            },
            {
              type: 'checkbox',
              text: 'has previously been diagnosed with speech disturbances',
              checked: false,
              auto: true,
              groups: ['Provider']
            },
            {
              type: 'checkbox',
              text:
                'is unable to see well enough to read, even with contacts or glasses',
              checked: true,
              groups: ['Provider']
            },
            {
              type: 'checkbox',
              text:
                'is unable to hear well enough to converse normally, even with hearing aids',
              checked: true,
              groups: ['Provider']
            }
          ]
        },
        {
          type: 'checkbox',
          readonly: true,
          groups: ['Provider'],
          text:
            'The patient is also taking medications that can affect cognition, including ',
          options: [
            {
              type: 'checkbox',
              text: 'Benzodiazepines'
            },
            {
              type: 'checkbox',
              text: 'other anxiolytics'
            },
            {
              type: 'checkbox',
              text: 'Opioids'
            },
            {
              type: 'checkbox',
              text: 'Corticosteroids'
            },
            {
              type: 'checkbox',
              text: 'Anticholinergics'
            },
            {
              type: 'checkbox',
              text: 'Anti-psychotics'
            },
            {
              type: 'checkbox',
              text: 'Mood-stabilizers'
            },
            {
              type: 'checkbox',
              text: 'NSAIDS'
            },
            {
              type: 'checkbox',
              text: 'a recently started or increased dose of a statin'
            },
            {
              type: 'checkbox',
              input: true
            }
          ]
        },
        {
          type: 'checkbox',
          readonly: true,
          groups: ['Provider'],
          text: 'In addition, ',
          options: [
            {
              type: 'checkbox',
              text: 'the patient self-reports issues with memory or thinking',
              checked: true
            },
            {
              type: 'checkbox',
              text:
                'the patient reports feeling disoriented for no specific reason',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'the patient reports feeling restless or anxious',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'the patient reports feeling unhappy',
              checked: false
            },
            {
              type: 'checkbox',
              text:
                'the patient reports being unable to communicate well verbally',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'the patient presented under the influence of alcohol',
              checked: false
            },
            {
              type: 'checkbox',
              text:
                'a family member reports concerns about the patient’s memory or thinking'
            },
            {
              type: 'checkbox',
              text:
                'a caregiver reports concerns about the patient’s memory or thinking'
            },
            {
              type: 'checkbox',
              text:
                'a friend reports concerns about the patient’s memory or thinking'
            },
            {
              type: 'checkbox',
              text:
                'I have directly observed the patient having issues with memory or thinking'
            }
          ]
        },
        {
          type: 'checkbox',
          groups: ['Provider', 'Manager'],
          text: 'Upon visual presentation, the patient was also observed to ',
          readonly: true,
          options: [
            {
              type: 'checkbox',
              text:
                'be disoriented or confused about their surroundings and/or situation',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'appear restless or anxious',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'appear unhappy',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'have difficulty communicating verbally',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'ask the same questions repeatedly'
            },
            {
              type: 'checkbox',
              text: 'say the same things repeatedly'
            },
            {
              type: 'checkbox',
              text: 'be unable to recognize familiar people'
            },
            {
              type: 'checkbox',
              text: 'be unable to recognize familiar places'
            },
            {
              type: 'checkbox',
              text: 'not behave according to normal social conventions'
            },
            {
              type: 'checkbox',
              text: 'behave uncharacteristically rudely or angrily'
            },
            {
              type: 'checkbox',
              text:
                'be dressed inappropriately for attending the doctor’s office or considering weather conditions',
              groups: ['Manager']
            }
          ]
        },
        {
          type: 'checkbox',
          groups: ['Manager'],
          text: 'During the assessment process, patient was observed to ',
          readonly: true,
          options: [
            {
              type: 'checkbox',
              text:
                'be disoriented or confused about their surroundings and/or situation',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'appear restless or anxious',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'appear unhappy',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'have difficulty communicating verbally',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'be concentrating very hard'
            },
            {
              type: 'checkbox',
              text: 'have difficulty focusing'
            },
            {
              type: 'checkbox',
              text:
                'have significant difficulty following the test instructions'
            },
            {
              type: 'checkbox',
              text: 'unable to focus on the assessment'
            }
          ]
        }
      ]
    },
    {
      name: 'Neuropsychological Write-up for TYM',
      qualified: true,
      groups: [],
      statements: [
        {
          type: 'label',
          text:
            'Today the patient scored 45/50 on the Test Your Memory cognitive test and needed ',
          options: [
            {
              type: 'radio',
              required: true,
              options: [
                {
                  text: 'no help',
                  type: 'selection',
                  selected: false
                },
                {
                  text: 'trivial help',
                  type: 'selection',
                  selected: false
                },
                {
                  text: 'minor help',
                  type: 'selection',
                  selected: false
                },
                {
                  text: 'major help',
                  type: 'selection',
                  selected: false
                }
              ]
            }
          ],
          append: 'to complete it'
        },
        {
          type: 'radio',
          text: 'This score and level of assistance indicate possible',
          options: [
            {
              text: 'normal function',
              type: 'selection',
              selected: false,
              options: [
                {
                  type: 'checkbox',
                  text: 'for a person aged 18–69 years, 47-50 points',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text: 'for a person aged 70-79 years, 46-50 points',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text:
                    'for a person aged at least 80  years old, 45-50 points',
                  checked: false
                }
              ]
            },
            {
              text: 'Mild Cognitive Impairment, MCI',
              type: 'selection',
              selected: true,
              options: [
                {
                  type: 'checkbox',
                  text: 'for a person aged 18–69 years, 43-46 points',
                  checked: true
                },
                {
                  type: 'checkbox',
                  text: 'for a person aged 70-79 years, 43-45 points',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text:
                    'for a person aged at least 80  years old, 43-44 points',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text:
                    'and because they had difficulty remembering the sentence',
                  checked: true
                }
              ]
            },
            {
              text: 'mild cognitive dysfunction',
              type: 'selection',
              selected: false
            },
            {
              text: 'moderate cognitive dysfunction',
              type: 'selection',
              selected: false,
              options: [
                {
                  type: 'checkbox',
                  silent: true,
                  text: 'a score of 30-42 at any age',
                  checked: false
                }
              ]
            },
            {
              text:
                'severe cognitive issues and should be referred to a specialist for further review',
              selected: false,
              type: 'selection',
              options: [
                {
                  type: 'checkbox',
                  silent: true,
                  text: 'a score 24 points or below',
                  checked: false
                }
              ]
            }
          ]
        },
        {
          type: 'radio',
          qualified: true,
          text: "Today's result is",
          options: [
            {
              text: 'significantly better',
              type: 'selection',
              selected: false
            },
            {
              text: 'better',
              type: 'selection',
              selected: false
            },
            {
              text: 'about the same',
              type: 'selection',
              selected: true
            },
            {
              text: 'worse',
              type: 'selection',
              selected: false
            },
            {
              text: 'significantly worse',
              type: 'selection',
              selected: false
            }
          ],
          append: 'than their immediately previous score, of 45/50'
        },
        {
          type: 'checkbox',
          qualified: true,
          text:
            'On prior tests the patient scored, most recent to oldest, 45 and 45.',
          checked: true
        },
        {
          type: 'checkbox',
          qualified: true,
          text: "Overall, the patient's score is ",
          checked: true,
          options: [
            {
              type: 'checkbox',
              text: 'trending',
              checked: false,
              options: [
                {
                  type: 'checkbox',
                  text: 'upwards',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text: 'downwards',
                  checked: false
                }
              ]
            },
            {
              type: 'checkbox',
              text: 'remaining steady',
              checked: true
            }
          ]
        },
        {
          type: 'label',
          text:
            'The Test Your Memory cognitive exam, TYM, tests for orientation, verbal recall, semantic knowledge and visuospatial skills that are typically affected in cognitive functional decline from any etiology.  For more than the past decade, the TYM has been studied across thousands of patients and been demonstrated to correlate closely with other similar tests such as the Addenbrookes Cognitive Examination Revised (ACE-R) score with a high Pearson coefficient.  By essentially doubling the numeric TYM score comparisons to ACE-R or ACE-III can be made. The usefulness of the TYM test has been investigated and supported in over 20 published studies in peer-reviewed journals covering different languages, cultures and alphabets.'
        },
        {
          type: 'label',
          paragraph: true,
          text:
            'As a summary of the assessment testing information gathered for this experience to help determine patient condition relative to neuropsychological considerations, below is a summary of the results.'
        },
        {
          type: 'checkbox',
          text:
            'In the category of orientation, the patient scored 7 out of a possible 10 points.',
          options: [
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient scored well on this section assessing orientation.',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient scored particularly poorly on this section assessing orientation',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'Previously, they scored 9 and 9 on assessment dates 01/04/21 and 12/04/20 on this section',
              checked: true
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is increasing compared to last assessment',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is decreasing compared to last assessment',
              checked: true
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is remaining steady compared to last assessment',
              checked: false
            }
          ]
        },
        {
          type: 'checkbox',
          text:
            'In the category of verbal recall, the patient scored 6 out of a possible 17 points.',
          options: [
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient within acceptable parameters for the verbal recall section',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient scored particularly poorly for the category of verbal recall',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'Previously, they scored 6 and 5 on assessment dates 01/04/21 and 12/04/20 on this section',
              checked: true
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is increasing compared to last assessment',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is decreasing compared to last assessment',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is remaining steady compared to last assessment',
              checked: true
            }
          ]
        },
        {
          type: 'checkbox',
          text:
            'In the category of semantic knowledge, the patient scored 5 out of a possible 11 points.',
          options: [
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient within acceptable parameters for the verbal recall section',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient scored particularly poorly for the category of verbal recall',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'Previously, they scored 6 and 5 on assessment dates 01/04/21 and 12/04/20 on this section',
              checked: true
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is increasing compared to last assessment',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is decreasing compared to last assessment',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is remaining steady compared to last assessment',
              checked: true
            }
          ]
        },
        {
          type: 'checkbox',
          text:
            'In the category of semantic knowledge, the patient scored 6 out of a possible 7 points.',
          options: [
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient within acceptable parameters for the verbal recall section',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: false,
              text:
                'Patient scored particularly poorly for the category of verbal recall',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'Previously, they scored 6 and 6 on assessment dates 01/04/21 and 12/04/20 on this section',
              checked: true
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is increasing compared to last assessment',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is decreasing compared to last assessment',
              checked: false
            },
            {
              type: 'checkbox',
              qualified: true,
              text:
                'The score of the assessment performed on 02/04/21 is remaining steady compared to last assessment',
              checked: true
            }
          ]
        }
      ]
    },
    {
      name: 'Neuropsychological Write-up for Modified GP-COG',
      qualified: true,
      groups: [],
      statements: [
        {
          type: 'radio',
          text:
            'Today the patient scored 9/9 on the Test Your Memory cognitive test. This score indicates',
          options: [
            {
              text: 'normal function',
              type: 'selection',
              selected: true
            },
            {
              text: 'some impairment possible',
              type: 'selection',
              selected: false
            },
            {
              text: 'very likely impairment',
              type: 'selection',
              selected: false
            }
          ]
        },
        {
          type: 'checkbox',
          qualified: false,
          text: 'The patient did not satisfactorily answer the question(s) on',
          options: [
            {
              type: 'checkbox',
              text: 'the date',
              checked: true
            },
            {
              type: 'checkbox',
              text: 'clock drawing',
              checked: true
            },
            {
              type: 'checkbox',
              text: 'recent events',
              checked: false
            },
            {
              type: 'checkbox',
              text: 'short term recall of name and address',
              checked: false,
              options: [
                {
                  type: 'checkbox',
                  text: 'missing the first name',
                  checked: true
                },
                {
                  type: 'checkbox',
                  text: 'missing the last name',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text: 'missing the house number',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text: 'missing the street name',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text: 'missing the city',
                  checked: false
                }
              ]
            }
          ]
        },
        {
          type: 'radio',
          qualified: true,
          text: "Today's result is",
          options: [
            {
              text: 'significantly better',
              type: 'selection',
              selected: false,
              append: 'than'
            },
            {
              text: 'better',
              type: 'selection',
              selected: false,
              append: 'than'
            },
            {
              text: 'about the same',
              type: 'selection',
              selected: true,
              append: 'compared to'
            },
            {
              text: 'worse',
              type: 'selection',
              selected: false,
              append: 'than'
            },
            {
              text: 'significantly worse',
              type: 'selection',
              selected: false,
              append: 'than'
            }
          ],
          append: 'their immediately previous score, of 8/9 on 01/04/21'
        },
        {
          type: 'checkbox',
          qualified: true,
          text: 'Previously, the patient has scored 8 most to least recent.',
          checked: true
        },
        {
          type: 'checkbox',
          qualified: true,
          text: "Overall, the patient's score is ",
          checked: true,
          options: [
            {
              type: 'checkbox',
              text: 'trending',
              checked: false,
              options: [
                {
                  type: 'checkbox',
                  text: 'upwards',
                  checked: false
                },
                {
                  type: 'checkbox',
                  text: 'downwards',
                  checked: false
                }
              ]
            },
            {
              type: 'checkbox',
              text: 'remaining steady',
              checked: true
            }
          ]
        }
      ]
    },
    {
      name: 'Modified GPCOG Write Up',
      groups: ['Provider', 'Manager'],
      qualified: true,
      statements: [
        {
          type: 'radio',
          text:
            'Given their clinical presentation, medical history, and neuropsychological testing results, I have a ',
          options: [
            {
              text: 'very low',
              type: 'selection',
              selected: true
            },
            {
              text: 'low',
              type: 'selection',
              selected: false
            },
            {
              text: 'moderate',
              type: 'selection',
              selected: false
            },
            {
              text: 'moderately high',
              type: 'selection',
              selected: false
            },
            {
              text: 'high',
              type: 'selection',
              selected: false
            }
          ],
          append:
            'suspicion that the patient is experiencing symptoms of cognitive dysfunction at this time'
        },
        {
          type: 'radio',
          required: true,
          text: 'For Patient Marvin,',
          options: [
            {
              text:
                'it is unclear if medications are contributing to cognitive dysfunction at this time',
              type: 'selection',
              selected: true
            },
            {
              text:
                'medication side effects and or interactions are likely contributing to their possible',
              type: 'selection',
              options: [
                {
                  type: 'checkbox',
                  text: 'cognitive impairment'
                },
                {
                  type: 'checkbox',
                  text: 'mild cognitive dysfunction'
                },
                {
                  type: 'checkbox',
                  text: 'moderate cognitive dysfunction'
                },
                {
                  type: 'checkbox',
                  text: 'severe cognitive dysfunction  '
                }
              ],
              append:
                'as the patient is taking medications known to potentially effect cognition'
            },
            {
              type: 'checkbox',
              text: 'This would include: ',
              options: [
                {
                  type: 'checkbox',
                  text: 'Benzodiazepines'
                },
                {
                  type: 'checkbox',
                  text: 'Opioids'
                },
                {
                  type: 'checkbox',
                  text: 'Corticosteroids'
                },
                {
                  type: 'checkbox',
                  text: 'Anticholinergics'
                },
                {
                  type: 'checkbox',
                  text: 'Anti-psychotics'
                },
                {
                  type: 'checkbox',
                  text: 'Mood-stabilizers'
                },
                {
                  type: 'checkbox',
                  text: 'NSAIDS'
                },
                {
                  type: 'checkbox',
                  text: 'a recently started or increased dose of a statin'
                },
                {
                  type: 'checkbox',
                  input: true
                }
              ]
            }
          ]
        },
        {
          type: 'checkbox',
          text: "In addition, the patient's",
          options: [
            {
              type: 'checkbox',
              text: 'social isolation or loneliness'
            },
            {
              type: 'checkbox',
              text: 'lack of appropriate physical activity'
            },
            {
              type: 'checkbox',
              text: 'lack of appropriate cognitive stimulation'
            },
            {
              type: 'checkbox',
              text: 'lack of appropriate nutrition'
            },
            {
              type: 'checkbox',
              qualified: true,
              checked: true,
              text:
                'possible depressed mood as evidenced from reported results from other mental health questions on 02/04/2021'
            },
            {
              type: 'checkbox',
              checked: false,
              text: 'use of alcohol'
            },
            {
              type: 'checkbox',
              checked: false,
              text: 'use of non-prescribed drugs'
            },
            {
              type: 'checkbox',
              text: 'liver disease'
            },
            {
              type: 'checkbox',
              text: 'kidney disease'
            },
            {
              type: 'checkbox',
              text: 'hyperparathyroidism'
            },
            {
              type: 'checkbox',
              text: 'thyroid disease'
            },
            {
              type: 'checkbox',
              text: 'nutritional deficiencies',
              options: [
                {
                  type: 'checkbox',
                  prepend: 'in',
                  input: true
                }
              ]
            },
            {
              type: 'checkbox',
              input: true
            }
          ],
          append: 'is likely also contributing to lowered cognitive function'
        },
        {
          type: 'label',
          paragraph: true,
          text:
            'There are a number of different medical and lifestyle interventions that can help improve cognitive function or at least have been shown to slow the progression of cognitive decline.  To help preserve and potentially improve patient’s independence, recommended lifestyle interventions for this patient according to their capability, preferences stated during the face to face portion of this encounter, activity availability based on specific timing or other considerations, seasonality, etc., are included in the take-home Treatment Action Plan and will be updated accordingly for subsequent neuropsychological encounters. See take-home treatment action plan as part of this neuropsychological encounter disposition and calendar for this patient for the specific recommendations to address cognitive status.  Track progress by phone or as needed prior to next cognitive function assessment, evaluation and disposition update.  Patient’s health condition warrants close monitoring for progress or decline relative to cognitive function indications.'
        },
        {
          type: 'radio',
          text: "Given the patient's cognitive risk factors,",
          required: true,
          options: [
            {
              text:
                'I am ordering standardized cognitive function testing to evaluate this patient for the coming 12 months',
              selected: true,
              type: 'selection',
              options: [
                {
                  type: 'radio',
                  required: true,
                  options: [
                    {
                      selected: false,
                      type: 'selection',
                      text:
                        'Initially the patient will be tested monthly.  If he scores normally for three assessments in a row, cognitive function testing will be reduced to quarterly.  If Marvin progresses to score within normal limits, for three quarters in a row, neuropsychological testing will be further reduced to semi-annually until re-evaluation of relevant risk factors following each 12 months of neuropsych testing participation. If Marvin regresses at any testing interval, he will be put back to a monthly interval until three successive scores within normal limits are achieved.'
                    },
                    {
                      selected: true,
                      type: 'selection',
                      text:
                        'Initially Marvin will be tested quarterly.  If he scores within normal limits for 3 quarters in a row, neuropsychological testing will be reduced to semi-annually until re-evaluation following each 12 months of neuropsych assessment participation. If Marvin improves to a less frequent interval, but then regresses, patient will be placed on either monthly or quarterly interval until stabilized or improved to within normal limits.'
                    },
                    {
                      text:
                        'Marvin will be tested again in 6 months and then re-evaluated after 12 months.'
                    }
                  ]
                }
              ]
            },
            {
              text: 'I am ordering a cognitive assessment',
              type: 'selection',
              options: [
                {
                  type: 'radio',
                  required: true,
                  options: [
                    {
                      type: 'selection',
                      text:
                        'one time immediately, then follow up by separate encounter.'
                    },
                    {
                      type: 'selection',
                      text:
                        'three times, once per month, then follow up by separate encounter.'
                    },
                    {
                      type: 'selection',
                      text:
                        'four times, once per quarter, then follow up by separate encounter.'
                    }
                  ]
                }
              ]
            },
            {
              text:
                'Because Marvin scored within normal limits for three cognitive assessments in a row, I am changing my previous order to',
              type: 'selection',
              selected: false,
              options: [
                {
                  type: 'radio',
                  required: true,
                  options: [
                    {
                      selected: true,
                      type: 'selection',
                      text: 'quarterly testing.'
                    },
                    {
                      type: 'selection',
                      text: 'semiannual testing.'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          type: 'checkbox',
          text: 'In order to address the issue of cognitive decline, I will',
          readonly: true,
          options: [
            {
              type: 'checkbox',
              text:
                'Continue to monitor the patient and recheck the patient’s cognitive function',
              options: [
                {
                  type: 'radio',
                  required: true,
                  options: [
                    {
                      selected: false,
                      type: 'selection',
                      text: 'next month'
                    },
                    {
                      selected: true,
                      type: 'selection',
                      text: 'in three months'
                    },
                    {
                      type: 'selection',
                      text: 'in six months'
                    },
                    {
                      type: 'selection',
                      text:
                        'after 12 months from last annual mental health screen'
                    }
                  ]
                }
              ]
            },
            {
              type: 'checkbox',
              text: 'adjust medications',
              options: [
                {
                  type: 'checkbox',
                  input: true
                }
              ]
            },
            {
              type: 'checkbox',
              text:
                'Order lab tests to evaluate for possible causes of cognitive issues:',
              readonly: true,
              options: [
                {
                  type: 'checkbox',
                  text: 'Vitamin B12'
                },
                {
                  type: 'checkbox',
                  text: 'Vitamin D'
                },
                {
                  type: 'checkbox',
                  text: 'Thyroid Panel'
                },
                {
                  type: 'checkbox',
                  text: 'BUN'
                },
                {
                  type: 'checkbox',
                  text: 'Liver Panel'
                },
                {
                  type: 'checkbox',
                  input: true
                }
              ]
            },
            {
              type: 'checkbox',
              text: 'Refer the patient for further evaluation by a ',
              readonly: true,
              options: [
                {
                  type: 'checkbox',
                  text: 'neurologist'
                },
                {
                  type: 'checkbox',
                  text: 'psychiatrist'
                },
                {
                  type: 'checkbox',
                  input: true
                }
              ]
            },
            {
              type: 'checkbox',
              text: 'Refer the patient back to their Primary Care Physician'
            },
            {
              type: 'checkbox',
              text:
                'Coordinate care with their existing neuropsychiatrist specialist'
            },
            {
              type: 'checkbox',
              text: "Coordinate care with the patient's Primary Care Physician"
            },
            {
              type: 'checkbox',
              text: 'adjust the take home Treatment Action Plan by',
              readonly: true,
              options: [
                {
                  type: 'checkbox',
                  text: 'changing or adding social activities'
                },
                {
                  type: 'checkbox',
                  text: 'changing or adding physical activities'
                },
                {
                  type: 'checkbox',
                  text: 'changing or adding nutritional support'
                },
                {
                  type: 'checkbox',
                  text: 'changing or adding cognitive activities '
                },
                {
                  type: 'checkbox',
                  text: 'changing or adding other supportive actions'
                },
                {
                  type: 'checkbox',
                  text:
                    'investigating care measures to ensure proper medication usage as directed'
                }
              ]
            },
            {
              type: 'label',
              text:
                'See take home treatment plan created from evaluation, assessment and clinical information gathering from patient.'
            },
            {
              type: 'checkbox',
              text: 'Progress was discussed ',
              readonly: true,
              options: [
                {
                  type: 'checkbox',
                  text: 'with the patient'
                },
                {
                  type: 'checkbox',
                  prepend: 'their family member',
                  input: true
                },
                {
                  type: 'checkbox',
                  prepend: 'their caregiver',
                  input: true
                }
              ]
            },
            {
              type: 'checkbox',
              text:
                'Plan for follow-up testing, write up, interpretation, evaluation and disposition to assess the efficacy of treatment changes in 3 months'
            }
          ]
        }
      ]
    }
  ]
}
