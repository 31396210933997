import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-remote-patient-monitoring',
  templateUrl: './remote-patient-monitoring.component.html',
  styleUrls: ['./remote-patient-monitoring.component.scss']
})
export class RemotePatientMonitoringComponent implements OnInit {
  remotes = [
    {
      name: 'Cecily Woolley',
      date: '1/17/19',
      status: 'Voicemail Left',
      rpm: '4 of 12',
      lastRpmDate: '1/15/19'
    },
    {
      name: 'Karol Stafford',
      date: '1/17/19',
      status: 'Requested Callback',
      rpm: '4 of 12',
      lastRpmDate: '1/15/19'
    },
    {
      name: 'Kristopher Cleveland',
      date: '1/17/19',
      status: 'Voicemail Left',
      rpm: '4 of 12',
      lastRpmDate: '1/15/19'
    },
    {
      name: 'Athena Oakley',
      date: '1/17/19',
      status: 'See Notes',
      rpm: '4 of 12',
      lastRpmDate: '1/15/19'
    }
  ]

  calls = [
    { name: 'Howard Douglas', date: '10:09 AM PST', elapsed: '23m' },
    { name: 'Henry Washington', date: '10:09 AM PST', elapsed: '14m' },
    { name: 'Ken McKingly', date: '10:09 AM PST', elapsed: '15m' }
  ]

  voiceMails = [
    { name: 'Howard Douglas', date: '10:09 AM PST', elapsed: '23m' },
    { name: 'Henry Washington', date: '10:09 AM PST', elapsed: '14m' },
    { name: 'Ken McKingly', date: '10:09 AM PST', elapsed: '15m' }
  ]

  messages = [
    { name: 'Howard Douglas', date: '10:09 AM PST', elapsed: '23m' },
    { name: 'Henry Washington', date: '10:09 AM PST', elapsed: '14m' },
    { name: 'Ken McKingly', date: '10:09 AM PST', elapsed: '15m' }
  ]

  constructor() {}

  ngOnInit(): void {}
}
