import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { Observable, Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { SessionService } from '../../services/session.service'
import { UserGroup } from '../../shared/types/types'
import { map } from 'rxjs/operators'
import { OfficeFilterService } from '../../services/office-filter.service'
import {
  GetAccountQuery,
  GetLocationQuery,
  GetUserQuery
} from '../../API.service'
import { BillingTimerService } from '../../services/billing-timer.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  today = Date()
  accounts: Array<GetAccountQuery>
  locations: Array<GetLocationQuery>

  private accountsSub: Subscription
  private locationsSub: Subscription
  private selectAccountSub: Subscription
  private selectLocationSub: Subscription

  selectedLocationId: any
  selectedAccountId: any

  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private router: Router,
    private officeFilterService: OfficeFilterService,
    public timerService: BillingTimerService
  ) {
    this.accounts = null
    this.locations = null
  }

  ngOnInit(): void {
    // Make sure to watch the accounts list and reload
    this.accountsSub = this.officeFilterService.accountSelection$.subscribe(
      accounts => {
        this.accounts = accounts
      }
    )
    // Make sure to watch the locations list and reload
    this.locationsSub = this.officeFilterService.locationSelection$.subscribe(
      locations => {
        this.locations = locations
      }
    )

    // Should there be a selected account, keep watch of the id
    // So that it will be selected in the UI
    this.selectAccountSub = this.officeFilterService.selectedAccount$.subscribe(
      account => {
        this.selectedAccountId = account?.id
      }
    )

    // Should there be a selected location, keep watch of the id
    // So that it will be selected in the UI
    this.selectLocationSub = this.officeFilterService.selectedLocation$.subscribe(
      location => {
        this.selectedLocationId = location?.id
      }
    )
  }

  ngOnDestroy() {
    this.accountsSub.unsubscribe()
    this.locationsSub.unsubscribe()
    this.selectAccountSub.unsubscribe()
    this.selectLocationSub.unsubscribe()
  }

  accessGroups$(): Observable<Array<UserGroup>> {
    return this.sessionService.accessGroups$.asObservable()
  }

  username$(): Observable<string> {
    return this.sessionService.email$
      .asObservable()
      .pipe(
        map(email => (email ? email.substring(0, email.indexOf('@')) : email))
      )
  }

  pageName$(): Observable<string> {
    return this.sessionService.currentPageName$
  }

  user$(): Observable<GetUserQuery> {
    return this.sessionService.currentUser$.asObservable()
  }

  userType$(): Observable<string> {
    return this.sessionService.userType$.asObservable()
  }

  loggedIn$(): Observable<boolean> {
    return this.sessionService.loggedIn$.asObservable()
  }

  logout(): void {
    this.authService.logout().then(() => {
      return this.router.navigate(['/login'])
    })
  }

  async goToDashboard(): Promise<void> {
    await this.router.navigate(['/'])
  }

  onAccountSelection(value) {
    // The value here is an account ID
    // because of how we used the dropdown
    // so we need to select the correct object
    // and set that to the service
    this.officeFilterService.selectAccount(
      this.accounts.find(l => {
        return l.id === value
      })
    )
  }

  onLocationSelection(value) {
    // The value here is an location ID
    // because of how we used the dropdown
    // so we need to select the correct object
    // and set that to the service
    this.officeFilterService.selectLocation(
      this.locations.find(l => {
        return l.id === value
      })
    )
  }
}
