import { Component, Input, OnInit } from '@angular/core'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { PageChangeEvent } from '@progress/kendo-angular-dropdowns/dist/es2015/common/page-change-event'
import { GetAssessmentQuery, GetEncounterQuery } from 'src/app/API.service'
import { Router } from '@angular/router'
import { AssessmentStatus } from 'src/app/API.service'
@Component({
  selector: 'app-encounter-assessments',
  templateUrl: './encounter-assessments.component.html',
  styleUrls: ['./encounter-assessments.component.scss']
})
export class EncounterAssessmentsComponent implements OnInit {
  @Input('assessments') assessments: Array<GetAssessmentQuery>
  @Input('encounter') encounter: GetEncounterQuery

  constructor(private router: Router) {}

  ngOnInit() {}

  async openAssessment(assessment: GetAssessmentQuery) {
    if (assessment.status !== AssessmentStatus.SCHEDULED) {
      // Navigate to single assessment view
      await this.router.navigate(['scoring'], {
        queryParams: {
          assessmentId: assessment.id,
          redirectTo: encodeURI(`encounter/${this.encounter.id}`)
        }
      })
    }
  }
}
