import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { BaseEditService } from '@progress/kendo-angular-scheduler'
import { APIService, ScheduledEventType } from '../../API.service'
import { SessionService } from '../../services/session.service'
import {
  fromScheduledEvent,
  ScheduledEvent,
  toScheduledEvent
} from '../../shared/types/types'

// const fields: SchedulerModelFields = {
//   id: 'TaskID',
//   title: 'Title',
//   description: 'Description',
//   startTimezone: 'StartTimezone',
//   start: 'Start',
//   end: 'End',
//   endTimezone: 'EndTimezone',
//   isAllDay: 'IsAllDay',
//   recurrenceRule: 'RecurrenceRule',
//   recurrenceId: 'RecurrenceID',
//   recurrenceExceptions: 'RecurrenceException'
// }

@Injectable()
export class EventSchedulerEditService extends BaseEditService<ScheduledEvent> {
  public loading = false

  constructor(
    private http: HttpClient,
    private apiService: APIService,
    private sessionService: SessionService
  ) {
    super({})
    this.loading = true
  }

  /**
   * This reloads the events and sets new data to the events source
   */
  public async loadEvents(): Promise<void> {
    this.loading = true
    const events = await this.listEvents()
    this.data = events
    this.source.next(this.data)
    this.loading = false
  }

  /**
   * This is an implementation of save state. This is called whenever anything
   * happens with the data of the scheduler (ex. event was deleted or added)
   *
   * The events and changes are summarized here, suppose to optimize for a backend call.
   * @param created
   * @param updated
   * @param deleted
   * @protected
   */
  protected save(
    created: ScheduledEvent[],
    updated: ScheduledEvent[],
    deleted: ScheduledEvent[]
  ): void {
    // Possible discrepancies with the args
    // So to handle that, we need to map to the correct types
    console.log('Saving changes', { created, updated, deleted })
    const completed = []
    if (deleted.length) {
      completed.push(this.deleteEvents(deleted))
    }

    if (updated.length) {
      completed.push(this.updateEvents(updated))
    }

    if (created.length) {
      completed.push(this.createEvents(created))
    }

    Promise.all(completed).then(value => {
      console.log('Reloading Events', value)
      this.loadEvents()
    })
  }

  /**
   * Actually creates events in the backend
   * @param items
   * @private
   */
  private createEvents(
    items: ScheduledEvent[]
  ): Promise<Array<ScheduledEvent>> {
    return Promise.all(items.map(evt => this.createEvent(evt)))
  }

  private async createEvent(item: ScheduledEvent): Promise<ScheduledEvent> {
    // Make sure everything that is created is associated to the current user as ownerId and its accountId
    const input = Object.assign({}, item, {
      accountId: this.sessionService.currentUser$.getValue().accountId,
      ownerId: this.sessionService.userId$.getValue(),
      type: item.type || ScheduledEventType.GENERAL
    })
    const created = await this.apiService.CreateScheduledEvent(
      fromScheduledEvent(input)
    )
    return toScheduledEvent(created)
  }

  /**
   * Actually updates events in the backend
   * @param items
   * @private
   */
  private updateEvents(
    items: ScheduledEvent[]
  ): Promise<Array<ScheduledEvent>> {
    return Promise.all(items.map(evt => this.updateEvent(evt)))
  }

  private async updateEvent(item: ScheduledEvent): Promise<ScheduledEvent> {
    const updated = await this.apiService.UpdateScheduledEvent(
      fromScheduledEvent(item)
    )
    return toScheduledEvent(updated)
  }

  /**
   * Actually deletes events in the backend
   * @param items
   * @private
   */
  private deleteEvents(
    items: ScheduledEvent[]
  ): Promise<Array<ScheduledEvent>> {
    return Promise.all(items.map(evt => this.deleteEvent(evt)))
  }

  private async deleteEvent(item: ScheduledEvent): Promise<ScheduledEvent> {
    const id = item.id
    const deleted = await this.apiService.DeleteScheduledEvent({ id })
    return toScheduledEvent(deleted)
  }

  /**
   * List all events
   * @private
   */
  private async listEvents(): Promise<Array<ScheduledEvent>> {
    const { items } = await this.apiService.ListScheduledEvents({
      accountId: {
        eq: this.sessionService.currentUser$.getValue().accountId
      }
    })
    return items.map(toScheduledEvent)
  }
}
