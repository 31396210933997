/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:20f56a03-24c3-44f1-86e3-4fb9f5e178d1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2TO7JcLJT",
    "aws_user_pools_web_client_id": "175se9l90pa4otk4q35fk3bdlg",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6rqtiylq4rbbhc5kmojkkfc3gi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://qvj189ivj1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "breconsole-20201229113346-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3tiazmu5lxc5a.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Assessment-7dyr2uxaandylbsjibobcvxtxm-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
