<div fxFlexFill fxLayoutAlign="center center">
  <div fxFlex fxLayoutGap="10px" fxLayout="column" class="live-room-transfer">
    <div fxFlex fxLayoutGap="10px" fxLayout="column">
      <div fxFlex="30px" fxLayoutAlign="start center">Invite To Session</div>
      <div fxFlex="30px" fxLayoutAlign="start center" fxLayout="row">
        <kendo-autocomplete fxFlex #autocomplete [data]="onlineUsers"  [valueField]="'fullName'" [placeholder]="'Type name..'"
            [filterable]="true" (filterChange)="onFilter($event)" (valueChange)="onValueChange($event)"  [clearButton]="true"
        >
          <ng-template kendoAutoCompleteItemTemplate let-dataItem>
            <div  fxFlexFill fxLayout="row">
              <div fxFlex="80"><span fxFlex="80">{{ dataItem.fullName }}</span></div>
              <div fxFlex="20" fxLayoutAlign="end center">
                <i [ngClass]="dataItem['connectionStatusClass']" class="fa-circle"></i>
              </div>
            </div>
          </ng-template>
        </kendo-autocomplete>
      </div>
    </div>
    <div fxFlex="10px" class="spacer"></div>
    <div fxFlex="30px" fxLayoutAlign="end center" fxLayout="row">
      <button kendoButton [disabled]="!selectedUser" [primary]="true" (click)="save()">Invite</button>
      <button kendoButton (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>

