<div class="resource-events has-loader" fxFlex fxLayout="column">
  <div class="content">
    <kendo-grid #grid fxFlexFill [data]="resourceEvents" style="height: auto">
      <ng-template kendoGridToolbarTemplate *ngIf="creatable">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="grid-header p-5">
<!--          <span class="table heading" style="margin: 0">Scheduled Events</span>-->
          <button (click)="newResourceEvent()" [primary]="true" fxFlex="15" kendoButton>Add new event</button>
        </div>
      </ng-template>
      <kendo-grid-column field="type" title="" [width]="40">
        <ng-template kendoGridCellTemplate let-dataItem>
          <i [ngStyle]="{'color': resourceCategoryColor[dataItem.category]}" class="fa fa-circle"></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="title" title="Name"></kendo-grid-column>
      <kendo-grid-column field="start" title="Date">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="isSameDate(dataItem.start, dataItem.end)">
            {{dataItem.start | customDate:false:'PP'}} ({{dataItem.start | customDate:false:'hha' | lowercase}}-{{dataItem.end | customDate:false:'hha' | lowercase}})
          </span>
          <span *ngIf="!isSameDate(dataItem.start, dataItem.end)">
              {{dataItem.start | customDate:false:'PP'}} ({{dataItem.start | customDate:false:'hha' | lowercase}}) - {{dataItem.end | customDate:false:'PP'}} ({{dataItem.end | customDate:false:'hha' | lowercase}})
          </span>
          <kendo-icon *ngIf="dataItem.recurrenceRule" [name]="'reload'"></kendo-icon>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="cost" title="Cost" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.cost | currency }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="age" title="Age" [width]="60">
        <ng-template kendoGridCellTemplate let-dataItem>
         {{ ageGroupText[dataItem.ageGroup] }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="Action" width="180" *ngIf="deletable || editable">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button *ngIf="editable" kendoGridEditCommand class="k-primary" (click)="editItem(dataItem)">Edit</button>
          <button *ngIf="deletable" kendoGridRemoveCommand (click)="deleteItem(dataItem)">Remove</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>

<kendo-dialog title="New Event" [width]="600" *ngIf="showNewResourceEventModal" (close)="closeNewResourceEvent()">
  <form [formGroup]="newResourceEventForm" (ngSubmit)="createAndCloseNewResourceEventModal()">
    <div fxFlexFill fxLayout="column" fxLayoutGap="10px" class="form-area">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Title</div>
        <input fxFlex kendoTextBox placeholder="Title" formControlName="title" [disabled]="loading"/>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Description</div>
        <textarea kendoTextArea fxFlex placeholder="Description" [rows]="3" [autoSize]="false" formControlName="description" [disabled]="loading"></textarea>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Category</div>
        <kendo-dropdownlist
          fxFlex
          formControlName="category"
          [data]="categoryList"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true">
        </kendo-dropdownlist>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Age Group</div>
        <kendo-dropdownlist
          fxFlex
          formControlName="ageGroup"
          [data]="ageGroupList"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="true">
        </kendo-dropdownlist>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">Start</div>
        <kendo-datepicker fxFlex="150px" formControlName="start" [disabled]="loading">
        </kendo-datepicker>
        <kendo-timepicker fxFlex="150px" formControlName="start" [disabled]="loading" *ngIf='!fre.isAllDay.value'>
        </kendo-timepicker>
        <div fxFlex class="spacer"></div>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">End</div>
        <kendo-datepicker fxFlex="150px" formControlName="end" [disabled]="loading">
        </kendo-datepicker>
        <kendo-timepicker fxFlex="150px" formControlName="end" [disabled]="loading" *ngIf='!fre.isAllDay.value'>
        </kendo-timepicker>
        <div fxFlex class="spacer"></div>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="20px">
        <div fxFlex="100px" fxLayoutAlign="end start">All Day Event</div>
        <input type='checkbox' id='k-is-allday-chkbox' class='k-checkbox' formControlName="isAllDay" [disabled]="loading" />
      </div>

      <!--      <div *ngIf="isEditingSeries(editMode)" fxFlex fxLayout="column">-->
      <!--        <kendo-recurrence-editor formControlName="recurrenceRule" [disabled]="loading"-->
      <!--                                 [start]="fre.start.value">-->
      <!--        </kendo-recurrence-editor>-->
      <!--      </div>-->

      <kendo-dialog-actions [layout]="'normal'">
        <button kendoButton (click)="closeNewResourceEvent()" [disabled]="loading" type="button">Cancel</button>
        <button kendoButton primary="true" [disabled]="loading" type="submit">Submit</button>
      </kendo-dialog-actions>
    </div>
  </form>
</kendo-dialog>

<kendo-dialog title="Remove Event" [width]="600" *ngIf="showRemoveModal" (close)="closeRemoveModal()">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    Are you sure you want to remove event <b class="d-contents">{{selectedItem.title}}</b>?
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeRemoveModal()" [disabled]="loading" type="button">Cancel</button>
    <button kendoButton primary="true" [disabled]="loading" type="button" (click)="removeAndCloseModal()">Yes</button>
  </kendo-dialog-actions>
</kendo-dialog>
