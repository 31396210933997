import { Component, OnInit } from '@angular/core'
import {
  APIService,
  AssessmentStatus,
  AssessmentType,
  GetAssessmentEligibilityMutation,
  GetAssessmentQuery,
  ProcessPretestApprovalMutation,
  BilledTimeType
} from '../API.service'
import { AnswerSheet, AnswerSheetApiService, TestApiService } from '../testing'
import { SessionService } from '../services/session.service'
import { ActivatedRoute, Router } from '@angular/router'
import { WsConnectionService } from '../services/ws-connection.service'
import { WsActions } from '../shared/types/ws-actions'
import { BillingTimerService } from '../services/billing-timer.service'
import { AssessmentsApprovalService } from '../services/assessments-approval.service'

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss']
})
export class ApprovalsComponent implements OnInit {
  selectedAssessmentAnswer: AnswerSheet
  selectedAssessment: GetAssessmentQuery
  eligibleTests: EligibleTest[]
  selectedEligibleTests: EligibleTest[]
  isCheckingEligibility: boolean
  isLoadingPretest: boolean
  showCompletedDialog: boolean

  constructor(
    private answerSheetService: AnswerSheetApiService,
    private testService: TestApiService,
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    private apiService: APIService,
    private wsConnectionService: WsConnectionService,
    private router: Router,
    private billingTimerService: BillingTimerService,
    private approvalService: AssessmentsApprovalService
  ) {
    this.isCheckingEligibility = true
    this.eligibleTests = []
    this.isLoadingPretest = true
    this.showCompletedDialog = false
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params.assessmentId) {
        this.isLoadingPretest = true

        this.selectedAssessment = await this.apiService.GetAssessment(
          params.assessmentId
        )

        if (this.selectedAssessment.status === AssessmentStatus.PENDING) {
          this.showCompletedDialog = false
        } else {
          this.showCompletedDialog = true
        }

        this.selectedAssessmentAnswer = await this.answerSheetService.getAnswerSheet(
          this.selectedAssessment.answerSheetId
        )

        this.isLoadingPretest = false

        this.billingTimerService.startTimer()

        // If it is a pretest (definitely) approvals will have to go through the GetAssessmentEligibility resolver
        if (this.selectedAssessment.type === AssessmentType.PRETEST) {
          const result: GetAssessmentEligibilityMutation = await this.apiService.GetAssessmentEligibility(
            {
              patientId: this.selectedAssessment.patientId
            }
          )
          console.log('Got Eligibility', result)
          this.eligibleTests = result.eligibleTests.map(e => {
            return {
              code: e.code,
              title: e.title,
              recommended: e.recommended,
              testId: e.testId,
              required: e.required
            }
          })
          this.selectedEligibleTests = this.eligibleTests.filter(
            _ => _.recommended || _.required
          )

          this.isCheckingEligibility = false
        }
      }
    })
  }

  /**
   * Process Approval will notify the approval result and submit it
   */
  async processApproval(accepted: boolean) {
    const approverId = this.sessionService.userId$.getValue()
    const currentUser = this.sessionService.currentUser$.getValue()
    if (this.selectedAssessment && this.selectedAssessmentAnswer) {
      const soapCodes = []
      this.selectedAssessmentAnswer.items.forEach(section => {
        section.items.forEach(item => {
          if (item.answerValue) {
            soapCodes.push(item.questionRef.id)
          }
        })
      })

      const result = await this.apiService.ProcessPretestApproval({
        soapCodes,
        pretestAssessmentId: this.selectedAssessment.id,
        approverId: approverId,
        isApproved: accepted,
        selectedEligibleTests: this.selectedEligibleTests
      })

      // In this section we need to bill this time
      const timerInfo = this.billingTimerService.stopTimer()
      const billedTime = await this.apiService.CreateBilledTime({
        date: timerInfo.start,
        type: BilledTimeType.ADMINISTRATIVE,
        minutes: timerInfo.billedMinutes,
        userId: approverId
      })

      console.log('Created BilledTime', billedTime)

      const assessmentBilledTimeConnection = await this.apiService.CreateAssessmentBilledTimeConnection(
        {
          accountId: currentUser.accountId,
          assessmentId: this.selectedAssessment.id,
          billedTimeId: billedTime.id
        }
      )
      console.log(
        'Created Assessment BilledTime Connection',
        assessmentBilledTimeConnection
      )

      console.log('Notifying Patient App', this.selectedAssessment, result)
      this.approvalService.reload()
      this.notifyPatientApp(result)
      await this.close()
    }
  }

  private notifyPatientApp(result: ProcessPretestApprovalMutation) {
    this.wsConnectionService.send(
      this.selectedAssessment.submitterId,
      WsActions.PretestApproval,
      {
        pretestAssessmentId: result.pretestAssessmentId,
        selectedAssessments: result.assessmentIds,
        wasApproved: result.wasApproved
      }
    )
  }

  isAssessmentRecommended({ dataItem, index }) {
    return dataItem.required
  }

  onRemoveEligibleAssessment(event) {
    console.log('Remove', event)
    const eligibleTest: EligibleTest = event.dataItem
    if (eligibleTest.recommended) {
      event.preventDefault()
    }
  }

  onSelectedEligibleTestsChange(event) {
    console.log('selectedChanged', event)
  }

  async close() {
    await this.router.navigate([''])
  }
}

export type EligibleTest = {
  title: string
  testId: string
  code: string
  recommended: boolean
  required: boolean
}
