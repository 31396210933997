import { Component, OnDestroy, OnInit } from '@angular/core'
import {
  APIService,
  AssessmentStatus,
  AssessmentType,
  Gender,
  Status,
  Salutation
} from '../API.service'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { SessionService } from '../services/session.service'
import { OfficeFilterService } from '../services/office-filter.service'
import { TestApiService } from '../testing'
import { Router } from '@angular/router'

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit, OnDestroy {
  accounts = []
  locations = []

  registrationDialogOpen = false
  accountFilter = undefined

  patients: Array<any> = []
  genders = [Gender.MALE, Gender.FEMALE]
  salutations = [
    Salutation.MR,
    Salutation.MS,
    Salutation.MRS,
    Salutation.JR,
    Salutation.SR
  ]

  // Set in the form
  emailInput: string
  passwordInput: string
  mobileInput: string
  givenNameInput: string
  middleNameInput: string
  familyNameInput: string
  addressLine1Input: string
  addressLine2Input: string
  regionInput: string
  countryInput: string
  postalInput: string
  dobInput: Date
  salutationInput: Salutation
  genderInput: Gender

  accountObservable: Subscription
  selectedAccount
  selectedLocation

  constructor(
    private apiService: APIService,
    private sessionService: SessionService,
    private officeFilterService: OfficeFilterService,
    private testApiService: TestApiService,
    private router: Router
  ) {
    this.clearInputFields()
  }

  async ngOnInit(): Promise<void> {
    this.accountObservable = this.officeFilterService.selectedLocation$.subscribe(
      val => {
        console.log('account changed', val)
        if (val) {
          this.refreshPatients()
        }
      }
    )
  }

  ngOnDestroy(): void {
    if (this.accountObservable) {
      this.accountObservable.unsubscribe()
      this.accountObservable = null
    }
  }

  openRegistration() {
    this.registrationDialogOpen = true
    this.selectedAccount = this.officeFilterService.selectedAccount$.getValue()
    this.selectedLocation = this.officeFilterService.selectedLocation$.getValue()
  }

  closeRegistration() {
    this.registrationDialogOpen = false
  }

  isSuper$(): Observable<boolean> {
    return this.sessionService.userType$.pipe(map(v => v === 'Super'))
  }

  async refreshPatients() {
    const accountId = this.officeFilterService.selectedAccount$.getValue().id
    const locationId = this.officeFilterService.selectedLocation$.getValue().id

    console.log('Listing Patients..', accountId, locationId)

    const { items: patients } = await this.apiService.ListPatients({
      locationId: { eq: locationId },
      accountId: { eq: accountId }
    })

    console.log('Patients', accountId, patients)

    // @ts-ignore
    this.patients = patients.map(patient => {
      // @ts-ignore
      patient.assessmentsCount = (patient.assessments || []).length
      return patient
    })
  }

  clearInputFields() {
    this.emailInput = 'jason.wong+patient0@qwantegy.com'
    this.mobileInput = '+642041857381'
    this.givenNameInput = 'John'
    this.middleNameInput = 'Johnson'
    this.familyNameInput = 'Johnsons'
    this.postalInput = '123'
    this.regionInput = 'Metro Manila'
    this.addressLine1Input = '123 ABC Village, Greenhills, San Juan City'
    this.addressLine2Input = 'Unit 321'
    this.countryInput = 'Philippines'
    this.salutationInput = Salutation.MR
    this.genderInput = Gender.MALE
    this.passwordInput = 'Password12345'
    this.dobInput = new Date(1954, 3, 21)
  }

  async createPatient() {
    const managerUserId = this.sessionService.userId$.getValue()
    const locationId = this.officeFilterService.selectedLocation$.getValue().id
    const accountId = this.officeFilterService.selectedAccount$.getValue().id
    // First, add the patient account (for login)
    const patient = await this.apiService.AddPatientAccount({
      email: this.emailInput,
      password: this.passwordInput,
      mobile: this.mobileInput,
      accountId: accountId,
      givenName: this.givenNameInput,
      familyName: this.familyNameInput,
      locationId: locationId
    })

    console.log('Registered Patient', patient)
    // Update the patient profile
    const updatePatient = await this.apiService.UpdatePatient({
      id: patient.id,
      salutation: this.salutationInput,
      middleName: this.middleNameInput,
      status: Status.ACTIVE,
      gender: this.genderInput,
      dob: this.dobInput.toISOString(),
      patientManagerId: managerUserId,
      address: {
        postal: this.postalInput,
        region: this.regionInput,
        line1: this.addressLine1Input,
        line2: this.addressLine2Input,
        country: this.countryInput
      }
    })

    console.log('Updated Patient', updatePatient)

    this.clearInputFields()

    // Fetch the pretest data
    const { objects: pretests } = await this.testApiService.listTests({
      code: 'PRETEST'
    })

    console.log('Listing PRETEST', pretests)

    if (pretests.length === 0) {
      throw new Error('No Pretests Available to use for this account')
    }

    const pretest = pretests[0]

    // Then generate a (pretest) assessment for the newly registered patient
    const pretestAssessment = await this.apiService.CreateAssessment({
      accountId: accountId,
      locationId: locationId,
      patientId: patient.id,
      managerId: managerUserId,
      scheduledDate: new Date().toISOString(),
      status: AssessmentStatus.SCHEDULED,
      meta: 0,
      type: AssessmentType.PRETEST,
      title: 'Pretest',
      testId: pretest.id
      // The following would be nulls
      // providers, // Refers to the list of providers who can access the assessment
      // result, // Refers to the result of the pretest in text
      // score, // Score of the pretest which will be irrelevant
      // assessed, // Date when the pretest has been assessed
      // answerSheetId, // Answer sheet used for the pretest
      // encounterAssessmentsId, // Encounter where the pretest would belong
    })

    console.log('Created Pretest', pretestAssessment)

    this.clearInputFields()
    this.closeRegistration()
    await this.refreshPatients()
  }

  refreshRandomPassword() {
    this.passwordInput = this.nextRandomPassword()
  }

  nextRandomPassword(): string {
    const adjectives = [
      'Amazing',
      'Awesome',
      'Playful',
      'Excellent',
      'Fabulous',
      'Fantastic',
      'Favorable',
      'Curious',
      'Gorgeous',
      'Incredible',
      'Tricky',
      'Exciting',
      'Virtuous',
      'Perfect',
      'Faithful',
      'Remarkable',
      'Rousing',
      'Spectacular',
      'Splendid',
      'Stellar',
      'Stupendous',
      'Superb',
      'Upbeat',
      'Stunning',
      'Wondrous'
    ]
    const animals = [
      'Shark',
      'Bird',
      'Bear',
      'Fish',
      'Chicken',
      'Duck',
      'Horse',
      'Cat',
      'Dog',
      'Monkey',
      'Whale',
      'Dolphin',
      'Rabbit',
      'Snake',
      'Rabbit',
      'Spider',
      'Lobster'
    ]
    const getRandom = array => {
      return array[Math.floor(Math.random() * array.length)]
    }

    return getRandom(adjectives) + getRandom(animals)
  }

  detail({ dataItem }) {
    this.router.navigateByUrl('/patient/' + dataItem['accountId'])
  }
}
