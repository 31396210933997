<div class="enumeration-question" fxFlexFill fxLayout="column" fxLayoutGap="20px">
  <div fxFlex="30px" fxLayoutAlign="center center">{{questionMeta.text}}</div>
  <div fxFlex fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center start">
    <div *ngFor="let blank of blanks" fxFlex="40px" fxLayout="row">
      <kendo-textbox fxFlex [(ngModel)]="inputs[blank]" (ngModelChange)="onChange($event,blank)">
        <ng-template kendoTextBoxPrefixTemplate>
          <div [style]="'width:30px; text-align: center;'">{{blank}}</div>
          <kendo-textbox-separator></kendo-textbox-separator>
        </ng-template>
      </kendo-textbox>
    </div>
  </div>
</div>

<!--Box 5:  “Please list four creatures beginning with …”.-->

<!--Question 10:-->

<!--Version 1:-->
<!--Please list four creatures beginning with ‘S’ e.g. Shark-->
<!--snake, salmon, sea lion, sea anemone, saber-tooth cat/tiger, seagull, seal, sheep, seahorse, shrimp, skunk, sloth, slug, snail, shrew, snow leopard, sparrow, sponge, starfish, stork, stingray, swallow, swan, swordfish-->

<!--Version 2:-->
<!--Please list four creatures beginning with T, e.g. Toucan-->
<!--tadpole, tarantula, Tasmanian devil, tern, termite, thornbill, thrush, tit (a small bird), toad, tortoise, tiger, trout, tuna, turkey, turtle-->

<!--Please list four creatures beginning with M, e.g. Muskrat-->
<!--macaw, magpie, mallard, mammoth, manatee, manta ray, marlin, marmot, marsupial, mastodon, meadowlark, meerkat, millipede, miniature horse, mink, minnow, mockingbird, mole, mongoose, monkey, moose, moth, mosquito, mountain goat, mudpuppy, mud skipper, muskox, muskrat, mice-->

<!--Please list four creatures beginning with R, e.g. Rockfish-->
<!--rabbit, racoon, rat, rattlesnake, raven, red panda, reindeer, rhinoceros, river dolphin, roadrunner, robin, rook-->

<!--Please list four creatures beginning with C, e.g. Caribou-->
<!--camel, cardinal, carp, cat, caterpillar, catfish, cattle, centipede, chameleon, cheetah, chicken, chimpanzee, chinchilla, chipmunk, cicada, clam, clouded leopard, clownfish, cobra, cockatoo, cockroach, conch, coot, cormorant, copperhead, cougar, cow, coyote, crab, cricket, crayfish, crocodile, crow, cuckoo, curlew, cuttlefish-->

<!--Please list four creatures beginning with B, e.g. Bison-->
<!--baboon, badger, bald eagle, bat, bear, beaver, bed bug, bee, beetle, bird, blue whale,  bluebird, blue jay, boa constrictor, bobcat, bull, bunny, butterfly, buzzard, boar, bug-->


<!--One point for each correct answer – up to 4.  The most common answers (listed above in blue) to be automatically scored (using fuzzy logic to account for poor spelling).  Any answers not identified automatically will go to CCM for decision on scoring.-->
<!--Box 5 maximum possible points:  4-->
