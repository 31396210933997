import { Component, OnInit } from '@angular/core'
import * as allIcons from '@progress/kendo-svg-icons'
import { Router } from '@angular/router'
import { SessionService } from '../../../services/session.service'
import { Subscription } from 'rxjs'
import { accessFromGroups } from '../../../shared/mappings'

@Component({
  selector: 'app-resource-section',
  templateUrl: './resource-section.component.html',
  styleUrls: ['./resource-section.component.scss']
})
export class ResourceSectionComponent implements OnInit {
  private accessGroupsSub: Subscription
  public icons = allIcons
  resources = []
  constructor(private router: Router, private sessionService: SessionService) {}

  ngOnInit(): void {
    this.accessGroupsSub = this.sessionService.accessGroups$.subscribe(
      accessGroups => {
        const access = accessFromGroups(accessGroups)
        this.resources = access.dashRoutes
          .filter(n => n.isDisplayed)
          .map(r => {
          return { text: r.name, path: r.path, icon: r.svg, src: r.src, short: r.short }
        })
      }
    )
  }

  goToPage(page: string): void {
    this.router.navigate([page])
  }
}
