import { Component, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid'
import { Router } from '@angular/router'

@Component({
  selector: 'app-patient-charts',
  templateUrl: './patient-charts.component.html',
  styleUrls: ['./patient-charts.component.scss']
})
export class PatientChartsComponent implements OnInit {

  charts = [
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
    {name: 'shawn'},
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  rowClassCallback(context: RowClassArgs): any {
    return {
      success: false,
    };
  }

  selectionChange(grid, selection): void {
    const selectedData = selection.selectedRows[0].dataItem;
    // this.router.navigate(['/location', selectedData.id]);
  }

}
