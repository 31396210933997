import {
  Component,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core'
import {
  ScheduledEvent,
  ScheduledEventAssessment
} from '../../../shared/types/types'
import { APIService, GetAssessmentQuery, UserType } from '../../../API.service'
import { AnswerSheet } from '../../../testing'
import { LiveRoomComponent } from '../../../live-room/live-room.component'
import { interval, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CallService } from '../../../call.service'
import { CallHudComponent } from '../../../shared/components/call-hud/call-hud.component'

@Component({
  selector: 'app-telehealth-assessment',
  templateUrl: './telehealth-assessment.component.html',
  styleUrls: ['./telehealth-assessment.component.scss']
})
export class TelehealthAssessmentComponent implements OnInit {
  /**
   * If this is enabled, the call will be triggered from the call service
   */
  @Input('usePhone') usePhone: boolean
  /**
   * This is the scheduled event that is handled by this Assessment component
   */
  @Input('scheduledEvent') scheduledEvent: ScheduledEvent
  /**
   * Current title of the session
   */
  title: string
  /**
   * Patient id of this session
   */
  patientId: string
  /**
   * Patient name of this session
   */
  patientName: string

  /**
   * Patient contact number
   */
  patientContact: string

  /**
   * Assessments that is covered by this session
   */
  assessments: ScheduledEventAssessment[]

  /**
   * Controls the current assessment
   */
  currentAssessmentIndex: number
  /**
   * The current assessment which loads the test component
   */
  currentAssessment: ScheduledEventAssessment
  /**
   * The next assessment to keep things staged
   */
  nextAssessment: ScheduledEventAssessment
  /**
   * This is a flag to use for the mute button
   */
  isAudioOn: boolean

  timeElapsed$: Observable<string>
  startedAt: number

  @Output() onSessionClosed = new EventEmitter<void>()

  @ViewChild('liveRoom') liveRoom: LiveRoomComponent
  @ViewChild('callHud') callHud: CallHudComponent

  openConnectDialog: boolean

  constructor(
    private callService: CallService,
    private apiService: APIService
  ) {
    // Use the phone by default not audio
    this.usePhone = true
    this.isAudioOn = true
    this.openConnectDialog = true
  }

  async ngOnInit(): Promise<void> {
    // Upon initializing, load the scheduled event and
    // grab all details
    console.log('Got the ff scheduled event', this.scheduledEvent)
    const { assessments, title, invitees } = this.scheduledEvent
    this.title = title
    const patient = invitees.find(p => {
      return p.type === UserType.PATIENT
    })

    if (patient) {
      const patientDetails = await this.apiService.GetPatient(patient.id)
      this.patientContact = patientDetails.mobile
      this.patientId = patient.id
      this.patientName = patient.name
    } else {
      // Possible to happen if NO patient was selected as an invitee in a scheduled event
      throw new Error('No patient selected for this session')
    }

    this.assessments = assessments
    if (assessments.length) {
      this.nextAssessment = assessments[0]
      this.currentAssessmentIndex = -1
    }
  }

  /**
   * Starts the assessment, loads it to the test component
   */
  startNextAssessment() {
    this.currentAssessmentIndex++
    this.currentAssessment = this.assessments[this.currentAssessmentIndex]
    const next = this.assessments[this.currentAssessmentIndex + 1]
    if (next) {
      this.nextAssessment = next
    } else {
      this.nextAssessment = null
    }
  }

  /**
   * When the current assessment finishes, must disable the TestComponent
   * and have it reload with the next test
   * @param answerSheet
   */
  onAssessmentFinished(result: GetAssessmentQuery) {
    this.currentAssessment = null
  }

  closeSession() {
    if (this.liveRoom) {
      this.liveRoom.closeLiveRoom(false)
    } else if (this.callHud) {
      this.callHud.close()
    }
    this.onSessionClosed.emit()
  }

  toggleMute() {
    this.isAudioOn = this.liveRoom.toggleAudio()
  }

  continueCall(usePhone) {
    this.openConnectDialog = false

    this.usePhone = usePhone
    this.startedAt = Date.now()

    // If you are using the phone, callService supports the activeElapsed
    if (this.usePhone) {
      console.log('Trying to call', this.patientContact)
      this.callService.call({
        phone: this.patientContact,
        title: this.patientName,
        info: this.scheduledEvent.title
      })

      // activeElapsed will return  how many seconds into the active call
      this.timeElapsed$ = interval(1000).pipe(
        map(() => {
          const call = this.callService.activeCall$.getValue()
          if (call) {
            return new Date(Date.now() - call.startedAt)
              .toISOString()
              .slice(11, -5)
          } else {
            return null
          }
        })
      )
    } else {
      // TODO: Maybe move the stream from here into RoomConnection instead
      // Need to use an interval to compute so
      this.timeElapsed$ = interval(1000).pipe(
        map(n => {
          return new Date(n * 1000).toISOString().slice(11, -5)
        })
      )
    }
  }
}
