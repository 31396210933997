import { Pipe, PipeTransform } from '@angular/core';
import Color from 'color';

@Pipe({
  name: 'color'
})
export class ColorPipe implements PipeTransform {

  transform(value: string, filter: string, filterValue: number): string {
    if (filter && filterValue) {
      return Color(value)[filter](filterValue).hex();
    }

    return value;
  }

}
