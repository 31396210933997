import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SessionService } from './session.service'
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs'
import { map, take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class BillingTimerService {
  /**
   * Current timer for the section before automatically moving to the next section
   */
  public timer$: BehaviorSubject<number>
  public showTimer$: BehaviorSubject<boolean>
  public timerDisplay: Observable<string>
  private timerSub: Subscription
  private startedAt: Date

  constructor(private sessionService: SessionService) {
    this.timer$ = new BehaviorSubject<number>(0)
    this.showTimer$ = new BehaviorSubject<boolean>(false)

    this.timerDisplay = this.timer$.pipe(
      map(time => {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        const secondsDisplay = ('0' + seconds).slice(-2)
        const minutesDisplay = ('0' + minutes).slice(-2)
        return `${minutesDisplay}:${secondsDisplay}`
      })
    )
  }

  startTimer() {
    console.log('startTimer is called')
    this.clearTimer()

    this.startedAt = new Date()

    this.timerSub = interval(1000).subscribe(value => {
      this.timer$.next(value)
    })

    this.showTimer$.next(true)
  }

  stopTimer(): BilledTimeInfo {
    console.log('stopTimer is called', this.startedAt)
    if (this.startedAt) {
      const start = this.startedAt.toISOString()
      this.showTimer$.next(false)
      const lastTimeValue = this.timer$.getValue()
      this.clearTimer()
      return {
        totalSeconds: lastTimeValue,
        start,
        billedMinutes: Math.ceil(lastTimeValue / 60)
      }
    }
    return null
  }

  private clearTimer() {
    // Check if we have a timerSub already watching the counter
    // If it exists, unsubscribe
    if (this.timerSub) {
      this.timerSub.unsubscribe()
      this.timer$.next(0)
      this.startedAt = null
    }
  }
}

export type BilledTimeInfo = {
  start: string
  totalSeconds: number
  billedMinutes: number
}
