<div *ngIf='!(scoreService.answerSheet$ | async)' fxFlexFill fxLayoutAlign='center center'>
  <kendo-loader
    [type]="'converging-spinner'"
    [themeColor]="'primary'"
    [size]="'large'"
  ></kendo-loader>
</div>
<ng-container *ngIf='scoreService.answerSheet$ | async as answerSheet'>

  <kendo-dialog *ngIf="scoreService.isSubmittingScores$ | async"
                [maxWidth]="'60%'">
    <div style='text-align: center; margin: 20px;'>
      <div markdown ngPreserveWhitespaces>
        ## Saving
      </div>
      <kendo-loader
        [themeColor]="'primary'"
      ></kendo-loader>
    </div>
  </kendo-dialog>

  <!--  Meant to just cover the UI -->
  <div class='header-cover'></div>
  <div class='footer-cover'></div>

  <!--  Overlay controls for this test component -->
  <div class='top-overlay-controls' fxLayoutAlign='center center'>
    <div fxFlex="30px" class="spacer"></div>
    <kendo-stepper
      fxFlex
      (activate)='$event.preventDefault()'
      [steps]='stepperSteps'
      [stepType]="'indicator'"
      [(currentStep)]='currentSectionIndex'
      [linear]='true'>
    </kendo-stepper>
    <div fxFlex="30px" class="spacer"></div>
    <button kendoButton
            [iconClass]="'fa fa-times fa-2x'"
            look='outline'
            [primary]='false'
            (click)='close()'></button>
    <div fxFlex="30px" class="spacer"></div>
  </div>
  <!--  BACK BUTTON -->
  <div class='left-overlay-controls' fxLayoutAlign='center center' *ngIf='scoreService.hasPreviousSection$ | async'>
    <button fxFlexFill kendoButton class='start-button'
            [iconClass]="'fa fa-caret-left fa-2x'"
            look='outline'
            [primary]='false'
            (click)='previousSection()'></button>
  </div>
  <!--  NEXT BUTTON -->
  <div class='right-overlay-controls' fxLayoutAlign='center center' *ngIf='scoreService.hasNextSection$ | async'>
    <button fxFlexFill kendoButton class='start-button'
            [iconClass]="'fa fa-caret-right fa-2x'"
            look='outline'
            [primary]='false'
            (click)='nextSection()'></button>
  </div>

  <div class='bottom-overlay-controls' fxLayoutAlign='center center' fxLayoutGap='30px' *ngIf='patientName'>
    <div class='bottom-info-title'>{{answerSheet.testRef.title}}</div>
    <div class='bottom-info-patient'>{{patientName}}</div>
    <div class='bottom-info-score'>Total: {{answerSheet.totalScore}}</div>
    <button kendoButton class='start-button'
            look='outline'
            [primary]='true'
            [disabled]='scoreService.isSubmittingScores$ | async'
            (click)='submitScores()'>Finish Scoring</button>
    <ng-container *ngIf="scoreService.assessment$ | async as assessment">
      <button kendoButton class='start-button'
              *ngIf="assessment.status === 'SCORED'"
              look='outline'
              [primary]='true'
              [disabled]='scoreService.isSubmittingScores$ | async'
              (click)='approveAssessment()'>Approve</button>
    </ng-container>

  </div>

  <!--  Main Test Container -->
  <div fxLayout='column' class='test-container' fxFlexFill>
    <div class='spacer' fxFlex='50px'></div>
    <div fxFlex fxLayout='row'>
      <div class='spacer' fxFlex='80px'></div>
      <app-score-section fxFlex></app-score-section>
      <div class='spacer' fxFlex='80px'></div>
    </div>

    <div class='spacer' fxFlex='24px'></div>
  </div>
</ng-container>





