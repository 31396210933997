<div *ngIf="!loading" fxLayout="column" fxFlexFill>
  <app-nav fxFlex="grow"></app-nav>
</div>
<div *ngIf="loading" fxFlexFill fxLayoutAlign="center center">
  <kendo-loader
    [type]="'converging-spinner'"
    [themeColor]="'primary'"
    [size]="'large'"
  ></kendo-loader>
</div>
