<div class="score-labeling" fxFlexFill fxLayout="column" fxLayoutGap="20px">
  <div fxFlex="30px" fxLayoutAlign="start center">{{answer.questionRef.text}}</div>
  <div fxFlex fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="300px" fxLayoutAlign="end">
      <img [src]="answer.questionRef.data" [height]="300" [width]="300"/>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="20px">
      <div *ngFor="let blank of blanks" fxFlex="30px" fxLayout="row">
        <kendo-textbox [disabled]="true" fxFlex="200px" placeholder="" [(ngModel)]="inputs[blank]">
          <ng-template kendoTextBoxPrefixTemplate>
            <div [style]="'width:30px; text-align: center;'">{{blank}}</div>
            <kendo-textbox-separator></kendo-textbox-separator>
          </ng-template>
        </kendo-textbox>
      </div>
    </div>
    <div fxFlex class="spacer"></div>
  </div>
</div>
