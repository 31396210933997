<div class="chat-container" fxLayout="row" fxLayoutGap="5px">
  <div class="chat-window">
    <div class="chat-window-toolbar" fxLayout="row" (click)="toggleWindow()">
      <div *ngIf="open && conversation" fxFlex="20px" fxLayoutAlign="start center">
        <a class="close-button" (click)="backToList()"><i class="fa fa-arrow-left"></i></a>
      </div>
      <div fxFlex fxLayoutAlign="start center">
        <span *ngIf="!conversation" class="chat-title">Chat</span>
        <span *ngIf="conversation" class="chat-title">{{ conversation.title }}</span>
      </div>
      <div fxFlex="50px" fxLayoutAlign="end center">
        <a class="close-button"><i class="fa" [ngClass]="{'fa-window-restore':!open&&!loading, 'fa-minus':open&&!loading, 'fa-circle-notch fa-spin': loading}"></i></a>
      </div>
    </div>
    <div [ngClass]="{'hidden':!open}" class="chat-window-content">
      <app-chat-conversation [conversation]="conversation" *ngIf="conversation"></app-chat-conversation>
      <app-chat-list *ngIf="!conversation" (ready)="onReady()" (selected)="onConversationSelect($event)"></app-chat-list>
    </div>
  </div>
</div>
