<!-- PRETEST LIST -->
<div *ngIf="encounter" fxFlexFill fxLayout="row" class="soap-notes-container">
  <div fxFlex="30px" class="spacer"></div>
  <div fxFlex fxLayout="column" fxLayoutGap="20px" >
    <div fxFlex="60px" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="soap-note-item">
      <button *ngIf="isInEditMode" kendoButton [primary]="true" look="outline" (click)="saveChanges()">
        Save Changes
      </button>
      <button *ngIf="isInEditMode" kendoButton [primary]="true" look="outline" (click)="cancel()">
        Cancel
      </button>
      <button *ngIf="!isInEditMode" kendoButton [primary]="true" look="outline" (click)="activateEditMode()">
        Edit
      </button>
    </div>
    <div *ngIf="!isEmbedded" fxFlex="100px" fxLayoutAlign="center start" fxLayout="column">
      <div style="font-weight: bold">Suggested SOAP Notes</div>
      <br/>
      <div>Patient: {{ patient?.givenName + ' ' + patient?.familyName }}</div>
      <div>Date of Birth: {{ patient?.dob }}</div>
    </div>
    <div *ngFor="let soapMapping of soapMappings" fxLayout="column" fxLayoutGap="10px" class="soap-note-item">
      <div fxFlex="30px" style="font-weight: bold">{{soapMapping.reported?.text}}</div>
      <div fxFlex fxLayout="row" fxLayoutGap="20px">
        <div fxFlex fxLayout="row" *ngIf="soapMapping.observed" class="soap-note-observation" fxLayoutGap="10px">
          <div fxFlex="20px">
            <input [disabled]="!isInEditMode" type="checkbox" [(ngModel)]="soapMapping.observed.selected" kendoCheckBox />
          </div>
          <div fxFlex>
            <div style="font-weight: bold">Observation</div>
            <div>{{ soapMapping.observed.text }}</div>
          </div>
        </div>
        <div fxFlex fxLayout="row" *ngIf="soapMapping.diagnosed" class="soap-note-diagnosis" fxLayoutGap="10px">
          <div fxFlex="20px">
            <input [disabled]="!isInEditMode" type="checkbox" [(ngModel)]="soapMapping.diagnosed.selected" kendoCheckBox />
          </div>
          <div fxFlex>
            <div style="font-weight: bold">Diagnosis</div>
            <div>{{ soapMapping.diagnosed.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="60px" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="soap-note-item">
      <button *ngIf="isInEditMode" kendoButton [primary]="true" look="outline" (click)="saveChanges()">
        Save Changes
      </button>
      <button *ngIf="isInEditMode" kendoButton [primary]="true" look="outline" (click)="cancel()">
        Cancel
      </button>
      <button *ngIf="!isInEditMode" kendoButton [primary]="true" look="outline" (click)="activateEditMode()">
        Edit
      </button>
    </div>
  </div>
  <div fxFlex="30px" class="spacer"></div>
</div>

<kendo-dialog *ngIf="false"
              [maxWidth]="'60%'">
  <div style='text-align: center; margin: 20px;'>
    <div markdown ngPreserveWhitespaces style='width: 300px'>
      ## Pretest Complete
      ### This Pretest has been completed and feedback has already been sent.
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton [primary]='true'>Close</button>
  </kendo-dialog-actions>
</kendo-dialog>

<div *ngIf="false" fxFlexFill fxLayout="row" class="pretest-approvals-container" fxLayoutAlign="center center">
  <kendo-loader *ngIf="false"
                [type]="'pulsing'"
                [themeColor]="'light'"
                [size]="'large'"
  ></kendo-loader>
</div>

