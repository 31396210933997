<form [formGroup]="formGroup">
  <div class="yes-no-question" fxFlexFill fxLayout="row">
    <div fxFlex fxLayout="row" fxLayoutGap="80px">
      <div fxFlex fxLayout="column">
        <div fxFlex="100px" class="question-text" fxLayoutAlign="start center">
          {{questionMeta.text}}
        </div>
      </div>
      <div fxFlex="190px" fxLayout="row wrap" fxLayoutGap="20px">
        <div fxFlex>
          <div fxFlex="24px" fxLayoutAlign="start center">
            <input type="radio" [name]="question.id" [value]="true" (ngModelChange)="onChange($event)"
                   [formControlName]="question.id" kendoRadioButton/>
          </div>
          <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px" class="choice-true">Yes</div>
        </div>
        <div fxFlex>
          <div fxFlex="24px" fxLayoutAlign="start center">
            <input type="radio" [name]="question.id" [value]="false" (ngModelChange)="onChange($event)"
                   [formControlName]="question.id" kendoRadioButton/>
          </div>
          <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px" class="choice-false">No</div>
        </div>
      </div>
    </div>
  </div>
</form>
