<div class="wrapper">
  <div fxLayout="column" fxFlexFill>
    <div fxFlex fxLayout="column">
      <div class="patient-detail" fxLayout="row">
        <div class="general-info" fxLayout="row">
          <div fxLayout="column">
            <div class="patient-detail-avatar">
              <span class="k-icon k-i-user"></span>
            </div>
          </div>
          <div fxLayout="column">
            <div class="patient-detail-content">
              <p class="patient-detail-name text-nowrap">
                {{patientDetail.name}}
              </p>
              <p class="patient-detail-dob text-nowrap">
                <i>Dob: {{patientDetail.dob}} | SS: {{patientDetail.ss}} | Age: {{patientDetail.age}}</i>
              </p>
              <p class="patient-detail-id text-nowrap">
                ID: {{patientDetail.id}} | {{patientDetail.type}} | Last test: <span
                class="highlight">{{patientDetail.lastTest}}</span>
              </p>
            </div>
          </div>
          <div fxLayout="column">
            <div class="patient-title">
              <span>Dr. ABC Specialists</span>
              <i class="patient-icon k-icon k-i-pencil"></i>
            </div>
          </div>
        </div>
        <div class="col-3th" fxLayout="column" fxFlex="100">
          <div class="results">
            <div class="patient-btn">
              <div>CESD-R</div>
              <div>-</div>
            </div>
            <div class="patient-btn">
              <div>PHQ-9</div>
              <div>-</div>
            </div>
            <div class="patient-btn">
              <div>PMQ-R</div>
              <div>-</div>
            </div>
            <div class="patient-btn">
              <div>DAST-10</div>
              <div>-</div>
            </div>
            <div class="patient-btn">
              <div>CAGE</div>
              <div>-</div>
            </div>
            <div class="patient-btn">
              <span>MOD GPCOG</span>
              <div>-</div>
            </div>
          </div>
        </div>
      </div>

      <div class="patient-assessments" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
        <div class="patient-center" fxFlex="70" fxLayout="column">
          <div class="tabs" fxLayout="row">
            <div fxLayout="column" (click)="onChangeTab('timeline')" [ngClass]="{'active': currentTab === 'timeline'}"
                 fxFlex="20" class="timeline">
              Timeline
            </div>
            <div fxLayout="column" (click)="onChangeTab('treatments')"
                 [ngClass]="{'active': currentTab === 'treatments'}" fxFlex="20" class="treatments">
              Treatments
            </div>
            <div fxLayout="column" (click)="onChangeTab('billing')" [ngClass]="{'active': currentTab === 'billing'}"
                 fxFlex="20" class="billing">
              Billing
            </div>
            <div fxLayout="column" (click)="onChangeTab('communication')"
                 [ngClass]="{'active': currentTab === 'communication'}" fxFlex="20" class="communication">
              Communication
            </div>
            <div fxLayout="column" (click)="onChangeTab('charts')" [ngClass]="{'active': currentTab === 'charts'}"
                 fxFlex="20" class="charts">
              Charts
            </div>
          </div>
          <div class="patient-details">
            <div [ngSwitch]="currentTab">
              <div *ngSwitchCase="'timeline'">
                <app-patient-timeline></app-patient-timeline>
              </div>
              <div *ngSwitchCase="'treatments'">
                <app-patient-treatments></app-patient-treatments>
              </div>
              <div *ngSwitchCase="'billing'">
                <app-patient-billing></app-patient-billing>
              </div>
              <div *ngSwitchCase="'communication'">
                <app-patient-communication></app-patient-communication>
              </div>
              <div *ngSwitchCase="'charts'">
                <app-patient-detail-charts></app-patient-detail-charts>
              </div>
            </div>
          </div>
        </div>
        <div class="patient-sidebar" fxFlex="28" fxLayout="column">
          <div class="ex1-panel ex1-panel-info">
            <div class="ex1-panel-content">
              <div class="ex1-panel-title">
                                <span>
                                    <span class="k-icon k-i-clock"></span> Patient Info
                                </span>
                <span>
                                    <span class="ex1-btn">Update</span>
                                </span>
              </div>
              <div class="ex1-panel-body">
                <dl>
                  <dd>John Does</dd>
                  <dd>123 Northern Evenue</dd>
                  <dd>San Franciso, CA 92111</dd>
                  <dd><span class="k-icon k-i-home"></span> (109) 248-0980</dd>
                  <dd><span class="k-icon k-i-email"></span></dd>
                </dl>
              </div>
            </div>
            <div class="ex1-panel-content">
              <div class="ex1-panel-title">
                                <span>
                                    <span class="k-icon k-i-clock"></span> Insurance
                                </span>
              </div>
              <div class="ex1-panel-body">
              </div>
            </div>
            <div class="ex1-panel-content">
              <div class="notification">Commercial</div>
            </div>
            <div class="ex1-panel-content">
              <div class="alert alert-info">
                <div class="alert-title"><b>Aetna</b></div>
                <div class="alert-content">
                  <span>ID: 0129480980</span> <span>Group: 109280980</span>
                </div>
              </div>
            </div>
            <div class="ex1-panel-content mt-15">
              <div class="ex1-panel-title">
                                <span>
                                    <span class="k-icon k-i-clock"></span>
                                    <span>Emergency Contacts</span>
                                </span>
                <span>
                                    <span class="ex1-btn">Print</span>
                                    <span class="ex1-btn">Update</span>
                                </span>
              </div>
              <div class="ex1-panel-body">
                <div class="alert alert-info">
                  <div class="alert-title"><b>Johnson Clark</b></div>
                  <div class="alert-content">
                    <span>(913) 749-8000</span>
                  </div>
                </div>
                <div class="alert alert-info">
                  <div class="alert-title"><b>Johnson Clark</b></div>
                  <div class="alert-content">
                    <span>(913) 749-8000</span>
                  </div>
                </div>
              </div>
              <div class="ex1-panel-foot">
                <span class="txt-blur">Last updated:</span> <span class="created-at"><b>Aug 24, 2020</b></span>
              </div>
            </div>
            <div class="ex1-panel-content mt-15">
              <div class="ex1-panel-title">
                                <span>
                                    <span class="k-icon k-i-clock"></span>
                                    <span>Important Contacts</span>
                                </span>
                <span>
                                    <span class="ex1-btn">Update</span>
                                </span>
              </div>
            </div>

          </div>
          <div class="ex1-panel ex1-panel-info mt-10 text-center">
            No current interest data found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
