<ng-container *ngIf="!usePhone && !openConnectDialog">
  <app-live-room #liveRoom [scheduledEventId]="scheduledEvent.id" [audioOnly]="true"></app-live-room>
</ng-container>

<div class="test-overlay" *ngIf="currentAssessment && !openConnectDialog">
  <app-test [assessmentId]="currentAssessment.id" [disablePatientSession]="true" (finished)="onAssessmentFinished($event)"></app-test>
</div>


<div *ngIf="!usePhone && !openConnectDialog" class="session-info" fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex class="call-info" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start">
    <div fxFLex>
      <span class="call-info-title">{{title}}</span> with <span class="call-info-patient">{{patientName}}</span></div>
    <div fxFLex *ngIf="currentAssessment">
      Assessment {{currentAssessmentIndex + 1}}/{{assessments.length}}: {{currentAssessment.name}}
    </div>
  </div>
  <div fxFlex="100px" class="assessment-info" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center end">
    <div fxFLex fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
      <button kendoButton
              [iconClass]="'fa fa-phone-slash'"
      (click)="closeSession()">
      </button>
      <button kendoButton
              [iconClass]="isAudioOn? 'fa fa-microphone': 'fa fa-microphone-slash'" (click)="toggleMute()">
      </button>
    </div>
    <div fxFLex fxLayout="row" fxLayoutAlign="end center">
      {{ (timeElapsed$ | async) || '00:00:00' }}
    </div>
  </div>
</div>

<div class="close-overlay" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <button kendoButton [iconClass]="'fa fa-times fa-2x'" look="clear" (click)="closeSession()"></button>
</div>

<div class="assessment-overlay" *ngIf="!currentAssessment && nextAssessment && !openConnectDialog" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div class="next-assessment-info" fxFlex="40">Next Assessment:</div>
  <div class="next-assessment-title" fxFlex="30">{{nextAssessment.name}}</div>
  <div fxFlex="30" fxLayoutAlign="center center">
    <button kendoButton [primary]="true" (click)="startNextAssessment()">Start Assessment</button>
  </div>
</div>

<div class="assessment-overlay" *ngIf="!currentAssessment && !nextAssessment && !openConnectDialog" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
  <div class="next-assessment-info" fxLayoutAlign="center center">Completed the following tests:</div>
  <div class="next-assessment-title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div fxFlex *ngFor="let assessment of assessments" fxLayoutAlign="center center">
      {{assessment.name}}
    </div>
  </div>
</div>

<div *ngIf="usePhone && !openConnectDialog" class="session-info" fxLayout="row" fxLayoutAlign="center center">
  <app-call-hud #callHud fxFlexFill></app-call-hud>
</div>


<kendo-dialog [title]="'Contact ' +patientName" *ngIf="patientName && patientContact && openConnectDialog" [minWidth]="250" [width]="450">
  <p>Phone Number:</p>
  <kendo-textbox [(ngModel)]="patientContact"></kendo-textbox>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeSession()">Cancel</button>
    <button kendoButton (click)="continueCall(false)">Use App Instead</button>
    <button kendoButton (click)="continueCall(true)" primary="true">Call Phone</button>
  </kendo-dialog-actions>
</kendo-dialog>
