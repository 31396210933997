import { Injectable } from '@angular/core'
import { v4 as uuidv4 } from 'uuid'
import {
  WriteUpContext,
  WriteUpQuestionnaire,
  WriteUpSection,
  WriteUpStatement,
  WriteUpUser
} from './write-up.type'
import { SessionService } from '../services/session.service'
import { ConsoleUser } from '../shared/types/types'

@Injectable({
  providedIn: 'root'
})
export class WriteUpService {
  constructor(private sessionService: SessionService) {}

  public normalizeWriteUpStatement(
    statement: WriteUpStatement
  ): WriteUpStatement {
    if (!statement.id) {
      statement.id = uuidv4()
    }

    if (statement.type === 'radio' && statement.selected) {
      statement.options.forEach(option => {
        if (option.id === statement.selected) {
          option.selected = true
        } else {
          option.selected = false
        }
      })
    }

    if (statement.options) {
      statement.options = statement.options.map(option => {
        return this.normalizeWriteUpStatement(option)
      })
    }
    return statement
  }

  public normalizeWriteUpSection(section: WriteUpSection): WriteUpSection {
    if (!section.id) {
      section.id = uuidv4()
    }
    if (section.statements) {
      section.statements = section.statements.map(statements => {
        return this.normalizeWriteUpStatement(statements)
      })
    }
    return section
  }

  public normalizeWriteUpQuestionnaire(
    questionnaire: WriteUpQuestionnaire
  ): WriteUpQuestionnaire {
    if (questionnaire.sections) {
      questionnaire.sections = questionnaire.sections.map(section => {
        return this.normalizeWriteUpSection(section)
      })
    }
    return questionnaire
  }

  public isStatementValid(
    statement: WriteUpStatement,
    context: WriteUpContext
  ): boolean {
    let optionsValid = true
    if (statement.options) {
      optionsValid = statement.options
        .filter(statement => {
          return this.isStatementVisible(statement, context)
        })
        .reduce((value, statement) => {
          return value && this.isStatementValid(statement, context)
        }, true)
    }
    if (statement.required) {
      if (statement.selected && statement.type === 'radio') {
        return optionsValid
      } else {
        return false
      }
    } else {
      return optionsValid
    }
  }

  public isSectionValid(
    section: WriteUpSection,
    context: WriteUpContext
  ): boolean {
    return (section.statements || [])
      .filter(statement => {
        return this.isStatementVisible(statement, context)
      })
      .reduce((value, statement) => {
        return value && this.isStatementValid(statement, context)
      }, true)
  }

  public isQuestionnaireValid(
    questionnaire: WriteUpQuestionnaire,
    context: WriteUpContext
  ): boolean {
    return (questionnaire.sections || [])
      .filter(section => {
        return this.isSectionVisible(section, context)
      })
      .reduce((value, section) => {
        return value && this.isSectionValid(section, context)
      }, true)
  }

  public getWriteUpContext(): WriteUpContext {
    return {
      userType: this.getWriteUpUser(this.sessionService.userType$.getValue())
    }
  }

  public getWriteUpUser(consoleUser: ConsoleUser): WriteUpUser {
    switch (consoleUser) {
      case ConsoleUser.Administrator:
      case ConsoleUser.CCM:
        return WriteUpUser.Manager

      case ConsoleUser.Nurse:
      case ConsoleUser.Provider:
        return WriteUpUser.Provider

      case ConsoleUser.Biller:
      case ConsoleUser.Super:
      case ConsoleUser.Representative:
        return WriteUpUser.Other
    }
  }

  public isStatementVisible(
    statement: WriteUpStatement,
    context: WriteUpContext
  ): boolean {
    return (
      (statement.groups === undefined ||
        statement.groups.includes(context.userType)) &&
      (statement.qualified === undefined || statement.qualified === true)
    )
  }

  public isSectionVisible(
    section: WriteUpSection,
    context: WriteUpContext
  ): boolean {
    return (
      (section.groups === undefined ||
        section.groups.includes(context.userType)) &&
      (section.qualified === undefined || section.qualified === true)
    )
  }
}
