import { Component, OnDestroy, OnInit } from '@angular/core'
import { CallConnectionState, CallService } from '../../../call.service'
import { map } from 'rxjs/operators'
import { BehaviorSubject, interval, Observable } from 'rxjs'

@Component({
  selector: 'app-call-hud',
  templateUrl: './call-hud.component.html',
  styleUrls: ['./call-hud.component.scss']
})
export class CallHudComponent implements OnInit, OnDestroy {
  timeElapsed$: Observable<string>
  callTitle$: Observable<string>
  callInfo$: Observable<string>
  callNumber$: Observable<string>
  callState$: Observable<CallConnectionState>
  isAudioOn: boolean

  constructor(private callService: CallService) {
    this.isAudioOn = true
    this.timeElapsed$ = new BehaviorSubject<string>(null)
  }

  ngOnInit(): void {
    this.timeElapsed$ = interval(1000).pipe(
      map(() => {
        const call = this.callService.activeCall$.getValue()
        if (call && call.startedAt) {
          return new Date(Date.now() - call.startedAt)
            .toISOString()
            .slice(11, -5)
        } else {
          return null
        }
      })
    )
    this.callTitle$ = this.callService.activeCall$.pipe(
      map(call => call?.title)
    )
    this.callInfo$ = this.callService.activeCall$.pipe(map(call => call?.info))
    this.callState$ = this.callService.activeCall$.pipe(
      map(call => call?.state)
    )
    this.callNumber$ = this.callService.activeCall$.pipe(
      map(call => call?.phone)
    )
  }

  ngOnDestroy() {
    console.log('ngOnDestroy CallHudComponent')
    this.close()
  }

  toggleMute() {
    this.isAudioOn = !this.isAudioOn
    this.callService.mute(!this.isAudioOn)
  }

  dialAction() {
    const call = this.callService.activeCall$.getValue()
    if (call.state === CallConnectionState.DISCONNECTED) {
      this.callService.redialCall()
    } else {
      this.callService.endCall()
    }
  }

  close() {
    const call = this.callService.activeCall$.getValue()
    if (call.state !== CallConnectionState.DISCONNECTED) {
      this.callService.endCall()
    }
  }
}
