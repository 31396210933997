import { Channel } from 'twilio-chat/lib/channel'
import {
  ChannelAttributes,
  ChatUser,
  UpdateUserAttributeInput
} from './chat-types'

const IDENTIFIER_SEPARATOR = ';'

export const getIdentifierFromIds = (userIds: string[]): string => {
  return userIds.sort().join(IDENTIFIER_SEPARATOR)
}

export const getIdsFromIdentifier = (identifier: string): string[] => {
  return identifier.split(IDENTIFIER_SEPARATOR)
}

export const getInitialsFromName = (name: string): string => {
  console.log('Getting initials for ', name)
  return (name || '')
    .replace(/,/g, '')
    .split(' ')
    .map(s => s.charAt(0))
    .join('')
}

export const getConversationNameFromChatUsers = (users: ChatUser[]): string => {
  return users
    .map(u => u.name.split(' ').shift())
    .sort()
    .join(', ')
}
