import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import {
  Message as KendoMessage,
  SendMessageEvent,
  User
} from '@progress/kendo-angular-conversational-ui'
import { ChatService } from '../../../services/chat.service'
import { map, scan, tap } from 'rxjs/operators'
import { Channel } from 'twilio-chat/lib/channel'
import { Paginator } from 'twilio-chat/lib/interfaces/paginator'
import { Message as TwilioMessage } from 'twilio-chat/lib/message'
import { SessionService } from '../../../services/session.service'
import { getIdsFromIdentifier } from '../lib/helper'
import { APIService } from '../../../API.service'
import { ChatUser, Conversation } from '../lib/chat-types'

@Component({
  selector: 'app-chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.scss']
})
export class ChatConversationComponent implements OnInit {
  /**
   * The conversation object that was selected by the user
   * @private
   */
  @Input('conversation') private conversation: Conversation
  /**
   * The current twilio channel that is open for this conversation component
   * @private
   */
  private _channel: Channel
  /**
   * The current _paginator that is loaded for this conversation component
   * @private
   */
  private _paginator: Paginator<TwilioMessage>
  /**
   * The current twilio message feed
   * @private
   */
  private _pastMessages$: BehaviorSubject<TwilioMessage[]>
  /**
   * These are the messages that are loaded PAST join time
   */
  public pastMessages$: Observable<KendoMessage[]>
  /**
   * These is the message stream received via chat client
   */
  private receivedMessage$: BehaviorSubject<KendoMessage>
  /**
   * These are all the collected current messages
   */
  public currentMessages$: Observable<KendoMessage[]>
  /**
   * Actual message feed that will be rendered in the conversation component
   */
  public messages$: Observable<KendoMessage[]>
  /**
   * This user represents the current user
   */
  public user: User
  /**
   * This user mapping is a local copy to fetch names
   */
  public userMap: { [key: string]: User }

  constructor(
    private chatService: ChatService,
    private sessionService: SessionService,
    private apiService: APIService
  ) {
    this._pastMessages$ = new BehaviorSubject<TwilioMessage[]>([])
    this.receivedMessage$ = new BehaviorSubject<KendoMessage>(null)
  }

  async ngOnInit() {
    console.log('Conversation Component Initialized', this.conversation)
    // First open the conversation via ChatService, it returns a Twilio Channel
    this._channel = await this.chatService.openConversation(this.conversation)
    // Load all users in the channel to a local user map
    this.userMap = await this.getChannelUserMap(this._channel)
    // Get current chat user upon initialization
    this.user = this.getCurrentChatUser()

    // Listen to messages incoming
    this._channel.on('messageAdded', (message: TwilioMessage) => {
      this.receivedMessage$.next({
        author: this.userMap[message.author],
        text: message.body
      })
    })

    // Transform _pastMessages from Twilio to Kendo Message
    this.pastMessages$ = this._pastMessages$.pipe(
      map((messages: TwilioMessage[]) => {
        return messages.map(message => {
          const mappedMessage = {
            author: this.userMap[message.author],
            text: message.body
          }
          console.log('Mapping past message', mappedMessage)
          return mappedMessage
        })
      })
    )

    // Current messages would be an accumulation of the sent messages and received messages
    this.currentMessages$ = this.receivedMessage$.pipe(
      // ... and emit an array of all messages
      scan((acc: KendoMessage[], x: KendoMessage) => {
        if (x) {
          return [...acc, x]
        }
        return acc
      }, [])
    )

    // Construct messages so that it is an updated array of past messages and current messages
    this.messages$ = combineLatest(
      this.pastMessages$,
      this.currentMessages$
    ).pipe(
      map(([past, current]) => {
        return [].concat(past, current)
      })
    )

    // Need to fetch all messages in that channel
    this._paginator = await this._channel.getMessages()
    console.log('Loading past messages', this._paginator.items)

    // Load the first set of items in our messages stream
    this._pastMessages$.next(this._paginator.items)
  }

  /**
   * Send a message to the current channel
   * @param e
   */
  public sendMessage(e: SendMessageEvent): void {
    console.log('Sending message', e)
    const message = e.message.text.trim()
    if (message.length > 0) {
      this._channel.sendMessage(message)
    }
  }

  /**
   * Fetches the current user as a ChatUser
   * @private
   */
  private getCurrentChatUser(): ChatUser {
    const currentUser = this.sessionService.currentUser$.getValue()
    const self: ChatUser = {
      id: currentUser['id'],
      name: currentUser['firstName'] + ' ' + currentUser['lastName']
    }
    return self
  }

  /**
   * Fetch all details of the channel members as a user mapping given a channel
   * @param channel
   * @private
   */
  private async getChannelUserMap(
    channel: Channel
  ): Promise<{ [key: string]: User }> {
    const ids = getIdsFromIdentifier(channel.uniqueName)
    const users = await Promise.all(ids.map(id => this.apiService.GetUser(id)))
    return users.reduce((acc: { [key: string]: User }, cur, i) => {
      acc[cur.id] = {
        id: cur.id,
        name: cur['firstName'] + ' ' + cur['lastName']
      }
      return acc
    }, {})
  }
}
