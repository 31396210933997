import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { getDevices, getMediaSetup, MediaKind } from '../../helpers/media'
import { RoomConnection, RoomConnectionParticipant } from '../../helpers/room'
import { APIService, UserOnlineStatus } from '../../../API.service'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { SessionService } from '../../../services/session.service'
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns'

@Component({
  selector: 'app-live-room-transfer',
  templateUrl: './live-room-transfer.component.html',
  styleUrls: ['./live-room-transfer.component.scss']
})
export class LiveRoomTransferComponent implements OnInit, OnDestroy {
  @ViewChild('autocomplete', { static: false })
  public autocomplete: AutoCompleteComponent

  @Output() onCancel = new EventEmitter<string>()
  @Output() onSave = new EventEmitter<any>()

  onlineUsers: Array<any>
  selectedUser: any

  searchFilter: string = ''
  searchFilterChanged: Subject<string> = new Subject<string>()
  searchFilterChangedSubscription: Subscription

  constructor(
    private apiService: APIService,
    private sessionService: SessionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.searchFilterChangedSubscription = this.searchFilterChanged
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe(t => {
        this.searchFilter = t
        this.refreshUserResults()
      })

    await this.refreshUserResults()
  }

  ngOnDestroy(): void {
    if (this.searchFilterChangedSubscription)
      this.searchFilterChangedSubscription.unsubscribe()
  }

  async refreshUserResults() {
    let result = null
    if (this.searchFilter.length === 0) {
      result = await this.apiService.SearchUsers({})
    } else {
      result = await this.apiService.SearchUsers({
        and: [
          {
            or: [
              {
                firstName: { matchPhrasePrefix: this.searchFilter }
              },
              { lastName: { matchPhrasePrefix: this.searchFilter } }
            ]
          }
        ]
      })
    }

    const currentUserId = this.sessionService.userId$.getValue()
    this.onlineUsers = result.items
      .filter(user => {
        return user.id !== currentUserId
      })
      .map(u => {
        console.log(u)
        u['fullName'] = `${u.firstName} ${u.lastName}`

        let connectionStatusClass
        if (!!u.connectionId) {
          // user is connected
          switch (u['onlineStatus']) {
            case UserOnlineStatus.BUSY:
              connectionStatusClass = 'fas red'
              break
            case UserOnlineStatus.AVAILABLE:
            default:
              connectionStatusClass = 'fas green'
              break
          }
        } else {
          connectionStatusClass = 'far grey'
        }
        u['connectionStatusClass'] = connectionStatusClass
        return u
      })
  }

  save() {
    if (this.selectedUser) {
      this.onSave.emit(this.selectedUser)
    }
  }

  selectUser(user) {
    this.selectedUser = user
  }

  cancel() {
    this.onCancel.emit()
  }

  isSelected(user) {
    return this.selectedUser?.id === user.id
  }

  onFilter(event) {
    console.log('onFilter', event)
    this.selectedUser = null
    this.searchFilterChanged.next(event)
  }

  onValueChange(event) {
    console.log('onValueChange', event)
    this.selectedUser = this.onlineUsers.find(u => u.fullName === event)
  }
}
