<div class="score-copy-sentence" fxFlexFill fxLayoutAlign="center center" >
  <div fxFlex="20"></div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
    <div fxFlex fxLayoutAlign="start center">
      <canvas #canvasElement [width]="500" [height]="60"></canvas>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <kendo-textbox [disabled]="true" fxFlex="500px" [(ngModel)]="input"></kendo-textbox>
    </div>
  </div>
  <div fxFlex="20"></div>
</div>
