import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voice-hud',
  templateUrl: './voice-hud.component.html',
  styleUrls: ['./voice-hud.component.scss']
})
export class VoiceHudComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
