import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs'
import {
  AnswerSheet,
  AnswerSheetItem,
  AnswerSheetStatus,
  DisplaySegment
} from '../types'
import { TestApiService } from './test-api.service'
import { AnswerSheetApiService } from './answer-sheet-api.service'
import { map, mergeMap } from 'rxjs/operators'
import { AnswerSheetSection } from '../types'
import { Router } from '@angular/router'
import {
  APIService,
  GetAssessmentQuery,
  AssessmentStatus,
  BilledTimeType
} from 'src/app/API.service'
import { SessionService } from '../../services/session.service'
import { BillingTimerService } from '../../services/billing-timer.service'

@Injectable({
  providedIn: 'root'
})
export class ScoreService {
  public assessment$: BehaviorSubject<GetAssessmentQuery>
  public answerSheet$: BehaviorSubject<AnswerSheet>
  public sections$: Observable<AnswerSheetSection[]>

  public displaySegment$: BehaviorSubject<DisplaySegment>
  public currentSection$: BehaviorSubject<AnswerSheetSection>
  public itemsInSection$: Observable<AnswerSheetItem[]>
  public currentSectionIndex$: BehaviorSubject<number>

  public hasNextSection$: Observable<boolean>
  public hasPreviousSection$: Observable<boolean>
  public isSubmittingScores$: BehaviorSubject<boolean>

  private redirectTo: string

  constructor(
    private testApiService: TestApiService,
    private answerSheetApiService: AnswerSheetApiService,
    private apiService: APIService,
    private sessionService: SessionService,
    private router: Router,
    private billingTimerService: BillingTimerService
  ) {
    // Initialize all streams
    this.assessment$ = new BehaviorSubject<GetAssessmentQuery>(null)
    this.answerSheet$ = new BehaviorSubject<AnswerSheet>(null)
    this.displaySegment$ = new BehaviorSubject<DisplaySegment>(null)
    this.currentSectionIndex$ = new BehaviorSubject<number>(0)
    this.currentSection$ = new BehaviorSubject<AnswerSheetSection>(null)
    this.isSubmittingScores$ = new BehaviorSubject(false)

    combineLatest(this.assessment$, this.sessionService.currentUser$)
      .pipe(
        mergeMap(async ([assessment, user]) => {
          if (assessment && user) {
            const answerSheet: AnswerSheet = await this.answerSheetApiService.getAnswerSheet(
              assessment.answerSheetId
            )
            answerSheet.scorerId = user?.id
            return answerSheet
          }
          return null
        })
      )
      .subscribe(answersheet => {
        this.answerSheet$.next(answersheet)
      })

    // Sections will always be based on the current answer sheet
    this.sections$ = this.answerSheet$.pipe(
      map(answerSheet => {
        if (answerSheet) return answerSheet.items
        return []
      })
    )

    this.hasNextSection$ = combineLatest([
      this.currentSectionIndex$,
      this.sections$
    ]).pipe(
      map(([index, sections]) => {
        return index < sections.length - 1
      })
    )

    this.hasPreviousSection$ = this.currentSectionIndex$.pipe(
      map(index => {
        return index > 0
      })
    )
  }

  ngOnInit(): void {
    // We need to link all streams
  }

  /**
   * Initialize a rating session
   * @param answerSheetId
   * @param scorerId
   */
  async initRating(
    assessmentId: string,
    scorerId: string,
    redirectTo?: string
  ) {
    const assessment = await this.apiService.GetAssessment(assessmentId)
    // Fetch the answer sheet that we want to score
    const answerSheet = await this.answerSheetApiService.getAnswerSheet(
      assessment.answerSheetId
    )
    console.log('Got answersheet', answerSheet)

    // Save scorerId
    answerSheet.scorerId = scorerId
    this.assessment$.next(assessment)
    this.answerSheet$.next(answerSheet)
    this.displaySegment$.next(DisplaySegment.INTRO)
    this.redirectTo = redirectTo
  }

  /**
   * Computes for the total score
   */
  public computeTotalScore() {
    const answerSheet = this.answerSheet$.getValue()
    let totalScore = 0
    let totalMaxScore = 0
    answerSheet.items.forEach(section => {
      let sectionScore = 0
      section.items.forEach(item => {
        sectionScore += item.score || 0
      })
      sectionScore =
        sectionScore > section.sectionRef.scoring?.maximumScore
          ? section.sectionRef.scoring?.maximumScore
          : sectionScore
      totalMaxScore += section.sectionRef.scoring?.maximumScore
      totalScore += sectionScore
    })
    return { totalScore, totalMaxScore }
  }

  /**
   * Starts the rating session
   */
  startRating() {
    this.billingTimerService.startTimer()

    const currentSheet = this.answerSheet$.getValue()
    if (!currentSheet) return null

    // TODO: Change to index 0 so it starts on the first page
    const index = 0
    this.displaySegment$.next(DisplaySegment.MAIN)
    this.currentSectionIndex$.next(index)
    const section = currentSheet.items[index]

    // Create a new subscription
    this.itemsInSection$ = this.currentSection$.pipe(
      map(sec => {
        if (sec) {
          return sec.items
        } else {
          return []
        }
      })
    )

    this.currentSection$.next(section)
    console.log('Loaded Answersheet-section', this.currentSection$.getValue())

    this.updateTotalScore()
  }

  /**
   * Navigates to the next section
   */
  nextSection() {
    const currentSheet = this.answerSheet$.getValue()
    if (!currentSheet) return null

    const sections = currentSheet.items
    let index = this.currentSectionIndex$.getValue()

    if (index < sections.length - 1) index += 1
    const section = sections[index]
    this.currentSection$.next(section)
    this.currentSectionIndex$.next(index)
    console.log('Moving to next answer-sheet-section', section)
  }

  /**
   * Navigates to the previous section
   */
  previousSection() {
    const currentSheet = this.answerSheet$.getValue()
    if (!currentSheet) return null
    const sections = currentSheet.items
    let index = this.currentSectionIndex$.getValue()

    if (index > 0) index -= 1
    const section = sections[index]
    this.currentSection$.next(sections[index])
    this.currentSectionIndex$.next(index)
    console.log('Moving to previous answer-sheet-section', section)
  }

  /**
   * Update the answer sheet by submitting the new set of scores
   */
  async submitScores() {
    const currentUser = this.sessionService.currentUser$.getValue()
    const assessment = this.assessment$.getValue()

    this.isSubmittingScores$.next(true)
    const currentSheet: AnswerSheet = this.answerSheet$.getValue()
    if (!currentSheet) return null

    const { totalMaxScore, totalScore } = this.computeTotalScore()
    currentSheet.totalScore = totalScore
    currentSheet.status = AnswerSheetStatus.SCORED

    const updatedSheet = await this.answerSheetApiService.updateAnswerSheet(
      currentSheet.id,
      currentSheet
    )

    await this.apiService.UpdateAssessment({
      id: assessment.id,
      score: totalScore,
      status: AssessmentStatus.SCORED
    })

    // In this section we need to bill this time
    const timerInfo = this.billingTimerService.stopTimer()
    const billedTime = await this.apiService.CreateBilledTime({
      date: timerInfo.start,
      type: BilledTimeType.SCORING,
      minutes: timerInfo.billedMinutes,
      userId: currentUser.id
    })

    console.log('Created BilledTime', billedTime)

    const assessmentBilledTimeConnection = await this.apiService.CreateAssessmentBilledTimeConnection(
      {
        accountId: currentUser.accountId,
        assessmentId: assessment.id,
        billedTimeId: billedTime.id
      }
    )
    console.log(
      'Created Assessment BilledTime Connection',
      assessmentBilledTimeConnection
    )

    this.isSubmittingScores$.next(false)
    console.log('Updated Answer sheet', updatedSheet)
    await this.close()
  }

  /**
   * Approve Assessment
   */
  async approveAssessment() {
    const currentUser = this.sessionService.currentUser$.getValue()
    const assessment = this.assessment$.getValue()
    let updated
    this.isSubmittingScores$.next(true)

    if (assessment.status === AssessmentStatus.SCORED) {
      updated = await this.apiService.UpdateAssessment({
        id: assessment.id,
        status: AssessmentStatus.COMPLETED
      })
    }

    // In this section we need to bill this time
    const timerInfo = this.billingTimerService.stopTimer()
    const billedTime = await this.apiService.CreateBilledTime({
      date: timerInfo.start,
      type: BilledTimeType.SCORING,
      minutes: timerInfo.billedMinutes,
      userId: currentUser.id
    })

    console.log('Created BilledTime', billedTime)

    const assessmentBilledTimeConnection = await this.apiService.CreateAssessmentBilledTimeConnection(
      {
        accountId: currentUser.accountId,
        assessmentId: assessment.id,
        billedTimeId: billedTime.id
      }
    )
    console.log(
      'Created Assessment BilledTime Connection',
      assessmentBilledTimeConnection
    )

    this.isSubmittingScores$.next(false)
    console.log('Updated Answer sheet', updated)
    await this.close()
  }

  /**
   * Updates the score of an answer-item given a questionId
   * @param questionId
   * @param score
   */
  updateScore(questionId: string, score: number) {
    const currentSheet: AnswerSheet = this.answerSheet$.getValue()
    const sections = currentSheet.items
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i]
      const questions = section.items
      for (let j = 0; j < questions.length; j++) {
        const question = questions[j]
        if (question.questionRef?.id === questionId) {
          question.score = score
          break
        }
      }
    }
    this.answerSheet$.next(currentSheet)
    return null
  }

  updateTotalScore() {
    const currentSheet: AnswerSheet = this.answerSheet$.getValue()
    const { totalMaxScore, totalScore } = this.computeTotalScore()
    currentSheet.totalScore = totalScore
  }

  async close() {
    this.billingTimerService.stopTimer()
    const redirectTo = this.redirectTo
      ? decodeURI(this.redirectTo).split('/')
      : ['']
    this.reinitialize()
    await this.router.navigate(redirectTo)
  }

  /**
   * Reinitialize data of the service
   */
  reinitialize() {
    this.assessment$.next(null)
    this.answerSheet$.next(null)
    this.displaySegment$.next(null)
    this.currentSectionIndex$.next(0)
    this.currentSection$.next(null)
    this.redirectTo = null
  }
}
