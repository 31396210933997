import { Injectable } from '@angular/core'
import { Auth as auth, API as api } from 'aws-amplify'
import { APIService } from '../API.service'

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor() {}

  async signupUser(email: string, password: string, phoneNumber: string) {
    const result = await auth.signUp({
      username: email,
      password,
      attributes: {
        email: email,
        phone_number: phoneNumber
      }
    })
    return result
  }

  async confirmSignUp(username: string) {
    return await api.post('AdminQueries', '/confirmUserSignUp', {
      body: { username },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    })
  }

  async listUserAccounts() {
    const { Users } = await api.get('AdminQueries', '/listUsers', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    })
    return Users
  }
}
