<div class="payment-method">
  <h4>Payment Method</h4>
  <div class="number">
    <img src="/assets/images/visa.png" />
    **** **** **** 5689 9/2023
  </div>
  <div class="name">
    James Kucharski
  </div>
  <div class="address">
    7000 Terminal Square, Suite 100B
  </div>
  <div class="address2">
    Upper Darby, PA 19082
  </div>
  <button kendoButton [primary]="true" class="btn">
    Update billing information
  </button>
</div>
