<div class="wrapper" *ngIf="encounter">
  <div fxLayout="column" fxFlexFill>
    <div fxFlex fxLayout="column">
      <div class="patient-detail" fxLayout="row">
        <div class="general-info" fxLayout="row">
          <div fxLayout="column">
            <div class="patient-detail-content">
              <p class="patient-detail-name text-nowrap">
                Assessments: {{patient.givenName + ' ' + patient.familyName}}
              </p>
              <p class="patient-detail-dob text-nowrap">
                {{ encounter.date | date  }}
              </p>
              <p class="patient-detail-dob text-nowrap">
                <i>Dob: {{patient.dob}} | SS: {{patient.ss}} | Age: {{patient.age}}</i>
              </p>
            </div>
          </div>
          <div fxLayout="column">
            <div class="patient-title">
              <span>Dr. ABC Specialists</span>
              <i class="patient-icon k-icon k-i-pencil"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="patient-assessments" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
        <div class="patient-center" fxFlex fxLayout="column">
          <div class="tabs" fxLayout="row">
            <div fxLayout="column" (click)="onChangeTab('assessments')" [ngClass]="{'active': currentTab === 'assessments'}"
                 fxFlex="20" class="timeline">
              Assessments
            </div>
            <div fxLayout="column" (click)="onChangeTab('soap-items')"
                 [ngClass]="{'active': currentTab === 'soap-items'}" fxFlex="20" class="treatments">
              SOAP Items
            </div>
            <div fxLayout="column" (click)="onChangeTab('questionnaires')" [ngClass]="{'active': currentTab === 'questionnaires'}"
                 fxFlex="20" class="billing">
              Questionnaires
            </div>
            <div fxLayout="column" (click)="onChangeTab('write-ups')"
                 [ngClass]="{'active': currentTab === 'write-ups'}" fxFlex="20" class="communication">
              Write-Ups
            </div>
          </div>
          <div class="patient-details">
            <div [ngSwitch]="currentTab">
              <div *ngSwitchCase="'assessments'">
                <app-encounter-assessments [assessments]="assessments" [encounter]="encounter"></app-encounter-assessments>
              </div>
              <div *ngSwitchCase="'soap-items'">
                <app-encounter-soap-items [soapItems]="soapItems" [encounter]="encounter"></app-encounter-soap-items>
              </div>
              <div *ngSwitchCase="'questionnaires'">
                <app-encounter-questionnaires [soapItems]="soapItems" [encounter]="encounter"></app-encounter-questionnaires>
              </div>
              <div *ngSwitchCase="'write-ups'">
                <app-encounter-write-ups></app-encounter-write-ups>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="patient-sidebar" fxFlex="28" fxLayout="column">-->
<!--          <div class="ex1-panel ex1-panel-info">-->
<!--            <div class="ex1-panel-content">-->
<!--              <div class="ex1-panel-title">-->
<!--                                <span>-->
<!--                                    <span class="k-icon k-i-clock"></span> Patient Info-->
<!--                                </span>-->
<!--                <span>-->
<!--                                    <span class="ex1-btn">Update</span>-->
<!--                                </span>-->
<!--              </div>-->
<!--              <div class="ex1-panel-body">-->
<!--                <dl>-->
<!--                  <dd>John Does</dd>-->
<!--                  <dd>123 Northern Evenue</dd>-->
<!--                  <dd>San Franciso, CA 92111</dd>-->
<!--                  <dd><span class="k-icon k-i-home"></span> (109) 248-0980</dd>-->
<!--                  <dd><span class="k-icon k-i-email"></span></dd>-->
<!--                </dl>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="ex1-panel-content">-->
<!--              <div class="ex1-panel-title">-->
<!--                                <span>-->
<!--                                    <span class="k-icon k-i-clock"></span> Insurance-->
<!--                                </span>-->
<!--              </div>-->
<!--              <div class="ex1-panel-body">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="ex1-panel-content">-->
<!--              <div class="notification">Commercial</div>-->
<!--            </div>-->
<!--            <div class="ex1-panel-content">-->
<!--              <div class="alert alert-info">-->
<!--                <div class="alert-title"><b>Aetna</b></div>-->
<!--                <div class="alert-content">-->
<!--                  <span>ID: 0129480980</span> <span>Group: 109280980</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="ex1-panel-content mt-15">-->
<!--              <div class="ex1-panel-title">-->
<!--                                <span>-->
<!--                                    <span class="k-icon k-i-clock"></span>-->
<!--                                    <span>Emergency Contacts</span>-->
<!--                                </span>-->
<!--                <span>-->
<!--                                    <span class="ex1-btn">Print</span>-->
<!--                                    <span class="ex1-btn">Update</span>-->
<!--                                </span>-->
<!--              </div>-->
<!--              <div class="ex1-panel-body">-->
<!--                <div class="alert alert-info">-->
<!--                  <div class="alert-title"><b>Johnson Clark</b></div>-->
<!--                  <div class="alert-content">-->
<!--                    <span>(913) 749-8000</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="alert alert-info">-->
<!--                  <div class="alert-title"><b>Johnson Clark</b></div>-->
<!--                  <div class="alert-content">-->
<!--                    <span>(913) 749-8000</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="ex1-panel-foot">-->
<!--                <span class="txt-blur">Last updated:</span> <span class="created-at"><b>Aug 24, 2020</b></span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="ex1-panel-content mt-15">-->
<!--              <div class="ex1-panel-title">-->
<!--                                <span>-->
<!--                                    <span class="k-icon k-i-clock"></span>-->
<!--                                    <span>Important Contacts</span>-->
<!--                                </span>-->
<!--                <span>-->
<!--                                    <span class="ex1-btn">Update</span>-->
<!--                                </span>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--          <div class="ex1-panel ex1-panel-info mt-10 text-center">-->
<!--            No current interest data found-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>
