import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { ScheduledEvent, toScheduledEvent } from '../../../shared/types/types'
import { SessionService } from '../../../services/session.service'
import { APIService, ScheduledEventType } from '../../../API.service'
import { WsConnectionService } from '../../../services/ws-connection.service'
import { Router } from '@angular/router'
import { OfficeFilterService } from '../../../services/office-filter.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-telehealth-table-section',
  templateUrl: './telehealth-table-section.component.html',
  styleUrls: ['./telehealth-table-section.component.scss']
})
export class TelehealthTableSectionComponent implements OnInit, OnDestroy {
  @Output() onOpenCreateDialog = new EventEmitter<void>()

  public selectedDate: Date = new Date()
  public events: Array<ScheduledEvent>

  assessmentWindow: Boolean = false
  assessments: string[]
  selectedScheduledEvent: ScheduledEvent
  locationSub: Subscription

  constructor(
    private sessionService: SessionService,
    private apiService: APIService,
    private wsConnectionService: WsConnectionService,
    private router: Router,
    private officeFilterService: OfficeFilterService
  ) {}

  async ngOnInit(): Promise<void> {
    this.locationSub = this.officeFilterService.selectedLocation$.subscribe(
      value => {
        if (value) {
          this.refreshEvents()
        }
      }
    )
  }

  async ngOnDestroy(): Promise<void> {
    if (this.locationSub) {
      this.locationSub.unsubscribe()
    }
  }

  async refreshEvents() {
    const accountId = this.officeFilterService.selectedAccount$.getValue().id
    const locationId = this.officeFilterService.selectedLocation$.getValue().id

    // TODO: Implement locationFilter in ScheduledEvent
    // Select all scheduled events that are telehealth or assessments
    const { items: events } = await this.apiService.ListScheduledEvents({
      accountId: { eq: accountId },
      type: { eq: ScheduledEventType.TELEHEALTH }
    })

    this.events = events.map(toScheduledEvent)
    console.log(this.events)
  }

  notifyCreate() {}

  openCreate() {
    this.onOpenCreateDialog.emit()
  }

  async openVideoChat(event) {
    // this.notifyParticipants(event)
    this.router.navigate(['live-room'], {
      queryParams: {
        // sessionId: session.id,
        scheduledEventId: event.id,
        redirectTo: 'telehealth'
      }
    })
  }

  async openAssessments(event) {
    console.log(event)
    // this.notifyParticipants(event)
    this.selectedScheduledEvent = event.dataItem
    this.assessmentWindow = true
  }

  async openMonitoring(event) {
    // When an admin is monitoring, no need to notify participants
    this.router.navigate(['live-room'], {
      queryParams: {
        // sessionId: session.id,
        scheduledEventId: event.id,
        monitorOnly: true,
        redirectTo: 'telehealth'
      }
    })
  }

  /**
   * Check condition if communication actions should show in the event rendering
   * @param event
   */
  allowComms(event) {
    const current = new Date().getTime()
    const start = event.start.getTime()
    const end = event.end.getTime()
    return current > start && current < end
  }

  // TODO: Fix notification for calls!!
  notifyParticipants(session) {
    // const userId = this.sessionService.userId$.getValue()
    // const user = this.sessionService.currentUser$.getValue()
    // const { participantIds, ownerId } = session
    // const title = user
    //   ? `${user['firstName']} ${user['lastName']} has started ${session.title}`
    //   : `Someone has started ${session.title}`
    //
    // if (ownerId !== userId) {
    //   console.log('Sending ring to owner', ownerId)
    //   this.wsConnectionService.send(ownerId, 'ring', {
    //     title,
    //     sessionId: session.id
    //   })
    // }
    //
    // participantIds.forEach(id => {
    //   if (userId !== id) {
    //     console.log('Sending ring to participants', id)
    //     this.wsConnectionService.send(id, 'ring', {
    //       title,
    //       sessionId: session.id
    //     })
    //   }
    // })
    console.log(session)
  }

  onSessionClosed() {
    this.assessmentWindow = false
  }
}
