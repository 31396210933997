import { Component, OnInit } from '@angular/core'
import { APIService, UserType } from '../API.service'
import { AccountService } from '../services/account.service'
import { SessionService } from '../services/session.service'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  accounts = []

  registrationDialogOpen = false

  // Input fields
  accountIDInput
  nameInput = ''

  constructor(
    private apiService: APIService,
    private sessionService: SessionService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.refreshAccounts()
  }

  openRegistration() {
    this.registrationDialogOpen = true
  }

  closeRegistration() {
    this.registrationDialogOpen = false
  }

  isSuper$(): Observable<boolean> {
    return this.sessionService.userType$.pipe(map(v => v === 'Super'))
  }

  async refreshAccounts() {
    const { items: accounts } = await this.apiService.ListAccounts()
    this.accounts = accounts
  }

  clearInputFields() {
    this.nameInput = ''
    this.accountIDInput = undefined
  }

  async createAccount() {
    const result = await this.apiService.CreateAccount({
      name: this.nameInput,
      owner: this.sessionService.userId$.getValue()
    })
    this.clearInputFields()
    this.closeRegistration()
    await this.refreshAccounts()
  }
}
