import { Component, OnInit } from '@angular/core'
import { ChatService } from '../../services/chat.service'
import {
  Message,
  SendMessageEvent,
  User
} from '@progress/kendo-angular-conversational-ui'
import { from, merge, Observable, Subject } from 'rxjs'
import { map, scan } from 'rxjs/operators'
import { SessionService } from '../../services/session.service'
import { Conversation } from './lib/chat-types'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  loading: boolean = true
  open: boolean = false
  conversation: Conversation = null

  constructor(
    private chatService: ChatService,
    private sessionService: SessionService
  ) {
    this.loading = true
  }

  ngOnInit() {}

  public toggleWindow() {
    if (!this.loading) this.open = !this.open
  }

  public backToList() {
    this.conversation = null
  }

  public onConversationSelect(conversation) {
    this.conversation = conversation
  }

  public onReady() {
    this.loading = false
  }
}
