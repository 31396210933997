import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { Router } from '@angular/router'
import { WsConnectionService } from '../../../services/ws-connection.service'
import { MessageEnvelope, RingMessageData } from '../../message.types'

@Component({
  selector: 'app-ring-notification',
  templateUrl: './ring-notification.component.html',
  styleUrls: ['./ring-notification.component.scss']
})
export class RingNotificationComponent implements OnInit, AfterViewInit {
  @Output() public dismissed: EventEmitter<any> = new EventEmitter()
  public data: MessageEnvelope<RingMessageData>

  constructor(
    private wsConnectionService: WsConnectionService,
    private router: Router
  ) {}

  public dismiss(event: Event): void {
    event.preventDefault()
    this.dismissed.emit()
  }

  ngOnInit(): void {
    this.data = this.wsConnectionService.message$.getValue()
    console.log('ngOnInit data is', this.data)
  }

  joinCall(event) {
    console.log('Joining call', this.data)
    this.dismiss(event)
    const sessionId = this.data?.message?.sessionId
    if (sessionId) {
      this.router.navigate(['telehealth'], {
        queryParams: { sessionId: sessionId }
      })
    }
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit data is', this.data)
  }
}
