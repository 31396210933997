import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SoapItemType, GetEncounterQuery } from 'src/app/API.service'
@Component({
  selector: 'app-encounter-soap-items',
  templateUrl: './encounter-soap-items.component.html',
  styleUrls: ['./encounter-soap-items.component.scss']
})
export class EncounterSoapItemsComponent implements OnInit {
  @Input('soapItems') soapItems: Array<{
    __typename: 'SoapItem'
    type: SoapItemType | null
    text: string | null
    code: string | null
    selected: boolean | null
  }>
  @Input('encounter') encounter: GetEncounterQuery

  constructor(private router: Router) {}

  ngOnInit() {}

  async openSoapItemSelection() {
    // Navigate to single assessment view
    await this.router.navigate(['select-soap-notes'], {
      queryParams: {
        encounterId: this.encounter.id,
        redirectTo: encodeURI(`encounter/${this.encounter.id}`)
      }
    })
  }
}
