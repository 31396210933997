<div class="bres-panel bres-panel-info">
  <div class="bres-panel-content">
    <div class="bres-panel-title"><i class="k-icon k-i-dictionary-add"></i>&nbsp;Assessments in the Encounter</div>
    <div class="bres-panel-body">
      <kendo-grid
        (cellClick)="openAssessment($event.dataItem)"
        [data]="assessments"
        [height]="400">
        <kendo-grid-column width="100" class="cursor-pointer" field="title" title="Title"></kendo-grid-column>
        <kendo-grid-column width="40" class="cursor-pointer" field="testCode" title="Code"></kendo-grid-column>
        <kendo-grid-column width="50" class="cursor-pointer" field="status" title="Status"></kendo-grid-column>
        <kendo-grid-column width="30" class="cursor-pointer" field="score" title="Score"></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
