import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-active-users-section',
  templateUrl: './active-users-section.component.html',
  styleUrls: ['./active-users-section.component.scss']
})
export class ActiveUsersSectionComponent implements OnInit {
  users = [
    {
      name: 'Hellen Williams',
      status: 'In Office',
      role: 'Office Administrator',
      img:
        'https://media.istockphoto.com/photos/close-up-portrait-of-brunette-woman-picture-id1154642632',
      initials: 'HW'
    },
    {
      name: 'Harry Henderson',
      status: 'With Patient',
      role: 'Physician',
      img: null,
      initials: 'HH'
    }
  ]
  constructor() {}

  ngOnInit(): void {}
}
