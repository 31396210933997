<div *ngIf='scoreService.currentSection$ | async as currentSection' class='test-section-container' fxFlexFill
     fxLayout='column'>
  <div fxFlex='20px' class='spacer'></div>
  <div fxFlex fxLayout='row'>
    <div fxFlex='20px' class='spacer'></div>
    <div fxFlex fxLayout='column' fxLayoutGap='10px'>
      <div class='question-container' fxFlex fxLayoutAlign='center center'>
        <div fxLayout='column' fxLayoutGap='20px'>
          <div *ngIf='currentSection.sectionRef.instructions.length' class='instructions-container' fxLayout='column' fxLayoutAlign='center center'>
            <div class='section-instructions'>{{ currentSection.sectionRef.instructions }}</div>
          </div>
          <app-score-answer fxFlex *ngFor='let answer of currentSection.items' [answer]='answer'
                        [section]='currentSection'></app-score-answer>
        </div>
      </div>
    </div>
    <div fxFlex='20px' class='spacer'></div>
  </div>
  <div fxFlex='20px' class='spacer'></div>
</div>
<div *ngIf='!(scoreService.currentSection$ | async)' fxFlexFill fxLayoutAlign='center center'>
  <kendo-loader
    [themeColor]="'primary'"
    [size]="'large'"
  ></kendo-loader>
</div>
