<div class="bres-panel bres-panel-info">
    <div class="bres-panel-content">
        <div class="bres-panel-title">
            <span><i class="k-icon k-i-file-txt"></i>&nbsp;CESD-R History</span>
            <div class="bres-panel-subtext">
                <div><kendo-icon [name]="'clock'"></kendo-icon>&nbsp;<small>Eligible: (Not set)</small></div>
                <div><small>No schedule</small></div>
            </div>
        </div>
        <div class="bres-panel-body">
            <div fxLayout="row" class="billing-table thead">
                <div fxFlex="49" fxLayout="column">
                    <div class="bres-panel-blank bres-panel-blank-secondary">
                        Patient not tested
                    </div>
                </div>
                <div fxFlex="2" fxLayout="column"></div>
                <div fxFlex="49" fxLayout="column">
                    <div class="alert">Score legend</div>
                    <div class="HIGH mb-5" >
                        Between 0 to 9 shows none to mild depression severity
                    </div>
                    <div class="MEDIUM mb-5" >
                        Between 0 to 9 shows moderate to moderately severe depression
                    </div>
                    <div class="LOW mb-5" >
                        Over 20 shows severe depression severity
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bres-panel bres-panel-info">
    <div class="bres-panel-content">
        <div class="bres-panel-title"><i class="k-icon k-i-file-txt"></i>&nbsp;PHQ-9 History</div>
        <div class="bres-panel-body">
            <div fxLayout="row" class="billing-table thead">
                <div fxFlex="49" fxLayout="column">
                    <div class="bres-panel-blank bres-panel-blank-secondary">
                        Patient not tested
                    </div>
                </div>
                <div fxFlex="2" fxLayout="column"></div>
                <div fxFlex="49" fxLayout="column">
                    <div class="alert">Score legend</div>
                    <div class="HIGH mb-5" >
                        Between 0 to 9 shows none to mild depression severity
                    </div>
                    <div class="MEDIUM mb-5" >
                        Between 0 to 9 shows moderate to moderately severe depression
                    </div>
                    <div class="LOW mb-5" >
                        Over 20 shows severe depression severity
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="bres-panel bres-panel-info">
    <div class="bres-panel-content">
        <div class="bres-panel-title"><i class="k-icon k-i-file-txt"></i>&nbsp;PMQ-R History</div>
        <div class="bres-panel-body">
            <div fxLayout="row" class="billing-table thead">
                <div fxFlex="49" fxLayout="column">
                    <div class="bres-panel-blank bres-panel-blank-secondary">
                        Patient not tested
                    </div>
                </div>
                <div fxFlex="2" fxLayout="column"></div>
                <div fxFlex="49" fxLayout="column">
                    <div class="alert">Score legend</div>
                    <div class="HIGH mb-5" >
                        Between 0 to 9 shows none to mild depression severity
                    </div>
                    <div class="MEDIUM mb-5" >
                        Between 0 to 9 shows moderate to moderately severe depression
                    </div>
                    <div class="LOW mb-5" >
                        Over 20 shows severe depression severity
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>