<div class="patient-chart-assessment" fxLayout="column">
  <div fxFlex="0" fxLayout="row" fxLayoutAlign="center center" class="statistic">
    <div>Completed tests: 2</div>
    <div>Last test: <b>January 29, 2021</b></div>
    <div class="filter" fxLayout="row">Show: <span class="selected">All Data</span> <span>Last year</span> <span>Last 2 years</span>
    </div>
  </div>
  <div fxFlex="100">
    <table>
      <tr>
        <td class="head">Harm</td>
        <td class="danger">
          <kendo-icon [name]="'close'"></kendo-icon>
        </td>
        <td class="warning circle">
          16
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td class="head">Depression</td>
        <td class="warning circle">
          16
        </td>
        <td class="danger circle">
          <kendo-icon [name]="'close'"></kendo-icon>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td class="head">Addiction - O</td>
        <td class="warning circle">
          16
        </td>
        <td class="success">
          16
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td class="head">Psych</td>
        <td class="warning circle">
          16
        </td>
        <td class="warning">
          16
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td class="head">Neuropsych</td>
        <td class="warning circle">
          16
        </td>
        <td class="danger">
          37
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>
